import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    changeActiveShoppingCartProductionId,
    getAdmiraDevices,
    renameActiveShoppingCart,
} from '../shopping-cart-state/shopping-cart.actions';
import { ShoppingCartDto } from '@faro/order-angular-client/model/shoppingCartDto';
import { getOrderStatusLabel } from '../shopping-cart-state/shopping-cart.mapper';
import { AdmiraDeviceDto, ShoppingCartStatus } from '@faro/order-angular-client';
import { selectAdmiraDevices } from '../shopping-cart-state/shopping-cart.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-shopping-cart-info',
    templateUrl: './shopping-cart-info.component.html',
    styleUrls: ['./shopping-cart-info.component.scss'],
})
export class ShoppingCartInfoComponent implements OnInit, OnDestroy {
    @Input()
    set shoppingCart(cart: ShoppingCartDto) {
        this.shoppingCartInfo = {
            id: cart.id,
            name: cart.name,
            number: cart.number,
            creationDate: cart.creationDate,
            isSizeLimitExceeded: cart.isSizeLimitExceeded || false,
            status: cart.status,
            eMail: cart.eMail,
            entriesMetadata: cart.entriesMetadata,
            mediaCutsMetadata: cart.mediaCutsMetadata,
            productionId: cart.productionId,
            canActivate: cart.canActivate,
            exportDate: cart.exportDate,
            exportPath: cart.exportPath,
            exportDevice: cart.exportDevice,
            exportDeviceId: cart.exportDeviceId,
            audioSizeInBytes: cart.audioSizeInBytes,
            maxAudioSizeInBytes: cart.maxAudioSizeInBytes,
            maxVideoSizeInBytes: cart.maxVideoSizeInBytes,
            videoSizeInBytes: cart.videoSizeInBytes,
        };
    }

    @Input()
    loading: boolean | null = false;

    @Input()
    readonly: boolean = false;

    availableDevices: AdmiraDeviceDto[] = [];

    shoppingCartInfo: ShoppingCartDto = {
        audioSizeInBytes: 0,
        maxAudioSizeInBytes: 0,
        maxVideoSizeInBytes: 0,
        videoSizeInBytes: 0,
        id: '',
        name: '',
        number: '',
        creationDate: '',
        isSizeLimitExceeded: false,
        status: ShoppingCartStatus.UnderConstruction,
        eMail: '',
        entriesMetadata: [] as any,
        mediaCutsMetadata: [],
        productionId: '',
        canActivate: false,
        exportDate: null,
        exportPath: null,
        exportDevice: null,
        exportDeviceId: null,
    };

    private _destroyed$ = new Subject<void>();

    constructor(private store: Store) {
        this.store.dispatch(getAdmiraDevices());
    }

    ngOnInit() {
        this.store
            .select(selectAdmiraDevices)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.availableDevices = data;
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    onShoppingCartNameChanged(event: string): void {
        this.store.dispatch(renameActiveShoppingCart({ name: event }));
    }

    onPidChanged(event: string): void {
        this.store.dispatch(changeActiveShoppingCartProductionId({ pid: event }));
    }

    getStatusDisplayString(status: ShoppingCartStatus): string {
        return getOrderStatusLabel(status);
    }

    exportDeviceDisplayString(): string {
        const device = this.availableDevices.filter(v => v.id === this.shoppingCartInfo.exportDeviceId)[0];
        if (device) {
            const devicePath = this.shoppingCartInfo.exportPath?.split(device.devicePath!)[1];
            return `${this.shoppingCartInfo.exportDevice}/${devicePath}`;
        } else {
            return '';
        }
    }
}
