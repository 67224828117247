import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from '../duration';

@Pipe({
    name: 'durationFormatPipe',
})
export class DurationFormatPipe implements PipeTransform {
    transform(value: Duration): string {
        return value.format('HH.mm.ss');
    }
}
