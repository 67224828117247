import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'faro-single-date-input',
    templateUrl: './single-date-input.component.html',
    styleUrls: ['./single-date-input.component.scss'],
})
export class SingleDateInputComponent {
    @Input()
    minDate: Date = new Date('1900-1-1');

    @Input()
    maxDate: Date = new Date('2222-12-31');

    @Input()
    placeholder: string = '';

    @Input()
    set initialDate(date: Date | null) {
        if (date) {
            this.displayDate = date.toLocaleDateString('de-CH');
        }
    }

    @Output()
    dateSelected = new EventEmitter<Date>();

    @ViewChild('singleDateCalendarPanel')
    private _calendarPanel?: OverlayPanel;

    displayDate: string | null = '';

    constructor() {}

    toggleCalendar(event: Event, target: HTMLElement): void {
        if (this._calendarPanel) {
            this._calendarPanel.toggle(event, target);
        }
    }

    clearDate() {
        this.displayDate = '';
        this.dateSelected.emit(new Date());
    }

    onDateSelected(event: Date) {
        this.displayDate = event.toLocaleDateString('de-CH');
        this._calendarPanel?.hide();
        this.dateSelected.emit(event);
    }

    onChange(event: string) {
        const dateformat: RegExp = /^(?<d>(0?[1-9]|[12]\d|3[01]))\.(?<m>(0?[1-9]|1[012]))\.(?<y>(\d{4}))$/;
        if (event.match(dateformat)) {
            const matches = dateformat.exec(event);
            if (matches !== null) {
                const day = matches.groups?.['d'];
                const month = matches.groups?.['m'];
                const year = matches.groups?.['y'];
                if (day && month && year) {
                    this.dateSelected.emit(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)));
                }
            }
        }
    }
}
