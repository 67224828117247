export interface InformationEntry {
    label: string;
    objectKey: string;
    type?: string;
}

export enum InformationKeys {
    /**ITEM*/
    DURATION = 'DURATION',
    TOPIC = 'TOPIC',
    FDES = 'FDES',
    ORIGINATOR = 'ORIGINATOR',
    REMARKS = 'REMARKS',
    USAGE = 'USAGE',
    RIGHTS = 'RIGHTS',
    RIGHTS_HOLDER = 'RIGHTS_HOLDER',
    PRODUCTION_INFO = 'PRODUCTION_INFO',
    SPORT = 'SPORT',
    PEOPLE = 'PEOPLE',
    DESCRIPTORS = 'DESCRIPTORS',
    DOCUMENTS = 'DOCUMENTS',
    USED_MUSIC = 'USED_MUSIC',
    LINKED_ITEMS = 'LINKED_ITEMS',
    FORM = 'FORM',
    GEODESCRIPTORS = 'GEODESCRIPTORS',
    /**PROGRAM*/
    DATE_CHANNEL = 'DATE_CHANNEL',
    MODERATION = 'MODERATION',
    EDITORS = 'EDITORS',
}

export function getListEntries(type: string): Map<InformationKeys, InformationEntry> {
    let list: Map<InformationKeys, InformationEntry> = new Map<InformationKeys, InformationEntry>();
    if (type === 'item') {
        list = itemMap;
    }
    if (type === 'program') {
        list = programMap;
    }
    return list;
}

export const itemMap: Map<InformationKeys, InformationEntry> = new Map<InformationKeys, InformationEntry>([
    [
        InformationKeys.DURATION,
        {
            label: 'Länge',
            objectKey: 'length',
        },
    ],
    [
        InformationKeys.TOPIC,
        {
            label: 'Thema',
            objectKey: 'abstract',
            type: 'standard',
        },
    ],
    [
        InformationKeys.FDES,
        {
            label: '-',
            objectKey: 'fdes',
            type: 'standard',
        },
    ],
    [
        InformationKeys.ORIGINATOR,
        {
            label: 'Urheberschaft',
            objectKey: 'originator',
            type: 'standard',
        },
    ],
    [
        InformationKeys.REMARKS,
        {
            label: 'Bemerkungen',
            objectKey: 'remark',
            type: 'standard',
        },
    ],
    [
        InformationKeys.USAGE,
        {
            label: 'Verwendung',
            objectKey: 'constraintInformation',
            type: 'constraints',
        },
    ],
    [
        InformationKeys.RIGHTS,
        {
            label: 'Rechte',
            objectKey: 'rights',
            type: 'standard',
        },
    ],
    [
        InformationKeys.RIGHTS_HOLDER,
        {
            label: 'Rechteinhaber',
            objectKey: 'licenseHolder',
            type: 'standard',
        },
    ],
    [
        InformationKeys.PRODUCTION_INFO,
        {
            label: 'Produktionsart / Produzent',
            objectKey: 'productionInformation',
            type: 'standard',
        },
    ],
    [
        InformationKeys.SPORT,
        {
            label: 'Rang / Tore',
            objectKey: 'sportRankings',
        },
    ],
    [
        InformationKeys.PEOPLE,
        {
            label: 'Personen',
            objectKey: 'persons',
        },
    ],
    [
        InformationKeys.DESCRIPTORS,
        {
            label: 'Sachdeskriptor',
            objectKey: 'descriptors',
        },
    ],
    [
        InformationKeys.FORM,
        {
            label: 'Form',
            objectKey: 'form',
        },
    ],
    [
        InformationKeys.GEODESCRIPTORS,
        {
            label: 'Geographischer Deskriptor',
            objectKey: 'geoDescriptors',
        },
    ],
    [
        InformationKeys.DOCUMENTS,
        {
            label: 'Unterlagen',
            objectKey: 'documents',
        },
    ],
    [
        InformationKeys.USED_MUSIC,
        {
            label: 'Verwendete Musik',
            objectKey: 'usedMusic',
        },
    ],
    [
        InformationKeys.LINKED_ITEMS,
        {
            label: 'Verknüpfte Beiträge',
            objectKey: 'linkedItems',
        },
    ],
]);

const programMap: Map<InformationKeys, InformationEntry> = new Map<InformationKeys, InformationEntry>([
    [
        InformationKeys.DATE_CHANNEL,
        {
            label: 'Sendedatum / Sender',
            objectKey: 'dateChannelInformation',
            type: 'standard',
        },
    ],
    [
        InformationKeys.DURATION,
        {
            label: 'Länge',
            objectKey: 'programLength',
        },
    ],
    [
        InformationKeys.MODERATION,
        {
            label: 'Moderation',
            objectKey: 'presenter',
            type: 'standard',
        },
    ],
    [
        InformationKeys.EDITORS,
        {
            label: 'Redaktion',
            objectKey: 'workgroup',
            type: 'standard',
        },
    ],
]);
