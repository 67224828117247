import { Facet, FilterType, ValueFilter } from '@faro/searchapi-angular-client';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNotUndefinedNotNull } from '../../shared/typeguards';
import { ItemFacetKeys, selectFacet } from '../filters/facet.selectors';
import { HITLIST_SORTING_ATTRIBUTE_OPTIONS, SearchOptions } from './search-options.state';
import { ListFacetComponentModel } from '@faro/ngx-faro-ui';

export const searchOptionsFeatureKey = 'searchOptions';

export const selectSearchOptions = createFeatureSelector<SearchOptions>(searchOptionsFeatureKey);

export const selectValueFilters = (facetKey: ItemFacetKeys) =>
    createSelector(selectSearchOptions, (state: SearchOptions) => state.filters.filter(f => f.field === facetKey));

export const selectCountSelectedFilters = (facetKey: ItemFacetKeys) =>
    createSelector(
        selectValueFilters(facetKey),
        (filters: ValueFilter[]) => filters.filter(f => f.filterType !== FilterType.None).length
    );

export const selectListFacet = (facetKey: ItemFacetKeys) =>
    createSelector(
        selectFacet(facetKey),
        selectValueFilters(facetKey),
        (facets: Facet | undefined, filterValues: ValueFilter[]) => {
            return generateFacetsList(facets, filterValues);
        }
    );

export const selectQuery = createSelector(selectSearchOptions, (state: SearchOptions) => state.query);

export const selectHitlistSortingAttributeOption = createSelector(selectSearchOptions, (state: SearchOptions) =>
    HITLIST_SORTING_ATTRIBUTE_OPTIONS.find(option => option.value === state.sorting.attribute)
);

export const selectHitlistSortingAttribute = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.sorting.attribute
);

export const selectHitlistSortingOrder = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.sorting.order
);

export const selectAllExcludedStorageCategories = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.allExcludedStorageCategories
);

export const selectShowFilterInformation = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.filters.length > 0 || state.dateFilter.from !== null || state.dateFilter.to !== null
);

export const selectDateRange = createSelector(selectSearchOptions, (state: SearchOptions) => state.dateFilter);

export const selectSearchUpdateAvailable = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.isSearchUpdateAvailable
);

export const selectSearchFieldSelection = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.searchType
);

export const selectAvailableStorageCategories = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.availableStorageCategories
);

export const selectLoadingStorageCategories = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.loading.storageCategories
);

export const selectFilterOperator = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.filterOperators
);

export const selectActiveDisplaySearchInfoDialog = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.displayActivateSearchInfoDialog
);

export const selectAllFacetSearchResetTriggered = createSelector(
    selectSearchOptions,
    (state: SearchOptions) => state.resetAllFacetSearchResetTriggered
);

function generateFacetsList(facets: Facet | undefined, filterValues: ValueFilter[]): ListFacetComponentModel {
    const field = facets?.field;
    const dynamicItems =
        facets?.items
            ?.map(f => {
                return !field || !f.name
                    ? null
                    : {
                          field: field,
                          term: f.name,
                          value: f.value,
                          cardinality: f.count,
                          state: convertFilterTypeToState(filterValues, f.value),
                      };
            })
            .filter(isNotUndefinedNotNull) ?? [];
    return { dynamicItems };
}

function convertFilterTypeToState(filterValues: ValueFilter[], filterValue: string | null | undefined): boolean | null {
    return filterValues.filter(fv => fv.value === filterValue).length > 0
        ? filterValues.filter(fv => fv.value === filterValue)[0].filterType === 'Inclusion'
        : null;
}
