import { createReducer, on } from '@ngrx/store';
import { setDateFacetCollapsedState, setFacetCollapsedState, setSearchProfileSettings } from './search-profile.actions';
import { SearchProfileState } from './search-profile.state';
import { resetSearch } from './search-options.actions';

export const initialState: SearchProfileState = {
    settings: {
        storageCategorySettings: {
            excluded: [],
            filters: [],
        },
        excludedMediaTypes: [],
        searchInDocumentTexts: false,
        includeSportsFilters: false,
        isDefault: true,
    },
    facetCollapsedState: new Map<string, boolean>(),
    dateFacetCollapsedState: true,
    displaySportFacets: false,
};

export const searchProfileReducer = createReducer(
    initialState,
    on(setSearchProfileSettings, (state: SearchProfileState, { searchProfile }): SearchProfileState => {
        return { ...state, settings: searchProfile };
    }),
    on(setFacetCollapsedState, (state: SearchProfileState, { facets }): SearchProfileState => {
        return { ...state, facetCollapsedState: facets };
    }),
    on(setDateFacetCollapsedState, (state: SearchProfileState, { dateFacetCollapsedState }): SearchProfileState => {
        return { ...state, dateFacetCollapsedState: dateFacetCollapsedState };
    }),
    on(resetSearch, (state: SearchProfileState): SearchProfileState => {
        let tempMap = new Map<string, boolean>();
        state.facetCollapsedState.forEach((v, k) => {
            tempMap.set(k, true);
        });
        return { ...state, facetCollapsedState: tempMap, dateFacetCollapsedState: true };
    })
);
