import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationValues } from './paginator.interface';

@Component({
    selector: 'app-hitlist-paginator',
    templateUrl: './hitlist-paginator.component.html',
    styleUrls: ['./hitlist-paginator.component.scss'],
})
export class HitlistPaginatorComponent {
    @Input()
    hitIndex: number = 1;

    @Input()
    hitListLength: number = 0;

    @Input()
    paginatorImgSrcAndDescription = {
        first: { icon: '', description: '' },
        prev: { icon: '', description: '' },
        next: { icon: '', description: '' },
        last: { icon: '', description: '' },
    };

    @Output()
    pageChanged = new EventEmitter<PaginationValues>();

    constructor() {}

    loadLastHit(): void {
        this.pageChanged.emit(PaginationValues.LAST_PAGE);
    }

    loadFirstHit(): void {
        this.pageChanged.emit(PaginationValues.FIRST_PAGE);
    }

    loadNextHit(): void {
        this.pageChanged.emit(PaginationValues.NEXT_PAGE);
    }

    loadPreviousHit(): void {
        this.pageChanged.emit(PaginationValues.PREV_PAGE);
    }
}
