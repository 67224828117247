<ng-container *ngrxLet="exportSettings$; let exportSettings">
    <div class="active-shopping-cart-export" *ngIf="!loading" [ngClass]="{ 'has-entries': hasEntries }">
        <h2 class="title">Exporteinstellungen</h2>
        <div class="shopping-cart-input-field search-field-container">
            <label id="eMail">E-Mail</label>
            <div class="input-field">
                <input
                    aria-labelledby="eMail"
                    (blur)="onEmailInputFocusLost($event.target)"
                    [ngModel]="exportSettings.eMail"
                    type="text"
                    pInputText
                />
            </div>
        </div>
        <div class="shopping-cart-input-field search-field-container">
            <label id="exportTarget">Exportziel</label>
            <div class="input-field">
                <input
                    id="exportDestination"
                    aria-labelledby="exportTarget"
                    readonly
                    type="text"
                    pInputText
                    class="export-input-field"
                    (mouseover)="setExportTargetTooltip($event)"
                    [ngModel]="exportTargetDisplayString"
                    (click)="openExportDestinationDialog()"
                />
                <button (click)="openExportDestinationDialog()" aria-label="Exportziel auswählen">
                    <img src="assets/icons/edit-gray.svg" alt="" />
                </button>
            </div>
        </div>
        <div class="shopping-cart-input-field search-field-container">
            <label id="orderDate">Bestelldatum</label>
            <faro-single-date-input
                aria-labelledby="orderDate"
                [initialDate]="dateOrNullForToday(exportSettings.exportDate)"
                [minDate]="currentDate"
                placeholder="Jetzt"
                (dateSelected)="onDateSelected($event)"
            ></faro-single-date-input>
        </div>
    </div>
</ng-container>
