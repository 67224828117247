<div>
    <ng-container *ngrxLet="isLoading$; let isLoading">
        <p-table
            [hidden]="!hitlist || hitlist.length === 0"
            #scrollTable
            [value]="tableRows"
            scrollHeight="var(--hitlist-scroll-height)"
            [scrollable]="true"
            responsiveLayout="scroll"
            selectionMode="single"
            data-cy="hitlist-table"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let h of tableHeader" class="{{ h.scssClass }}">{{ h.value }}</th>
                    <th class="action-header" pFrozenColumn alignFrozen="right"><span class="h-offscreen">Treffer Aktionen</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-hit let-i="rowIndex">
                <tr *ngIf="hit.isLoadingIndicator" class="loading-next-page-row">
                    <ng-container>
                        <td colspan="8">
                            <p-progressSpinner strokeWidth="2" styleClass="custom-spinner"></p-progressSpinner>
                        </td>
                    </ng-container>
                </tr>
                <tr *ngIf="hit.isSearchRestricted && minTableRowsReached() && !isLoading" class="search-restricted-row">
                    <td colspan="8">
                        <div class="filter-info">
                            <div class="search-text-title">
                                <div>
                                    <img class="search-warning-icon" src="/assets/icons/warning-icon.svg" alt="" />
                                </div>
                                <div>Suchresultate eingeschränkt</div>
                            </div>
                            <div
                                *ngIf="(areProfileFiltersSet$ | async) === false && minTableRowsReached() && !isLoading"
                                class="help-text"
                            >
                                Die Trefferliste wird durch deine Einstellungen eingeschränkt. Es werden möglicherweise
                                nicht alle Resultate angezeigt.
                                <a class="info-link" (click)="openProfileFiltersDialog()">Sucheinstellungen anpassen</a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr
                    [appHighlightRow]="i"
                    (highlightedRowChanged)="highlightedRowIndex = $event"
                    *ngIf="!hit.isSearchRestricted && !hit.isLoadingIndicator"
                    (click)="goToDetails($event, hit)"
                    class="table-row"
                >
                    <td class="hit-list-image" id="accessibility-duration{{ i }}">
                        <img
                            [src]="
                                ($any(hit.keyframeBlob$ | async) | blobToObjectURL) ??
                                'assets/images/empty-keyframe.svg'
                            "
                            alt="Treffer {{ i + 1 }}"
                        />
                        <div class="h-offscreen">Laufzeit {{ hit.durationAccessibilityString }}</div>
                        <div class="duration" aria-hidden="true">
                            {{ hit.duration ?? "0" | millisecondsToDuration }}
                        </div>
                        <div class="table-entry-number" *ngIf="highlightedRowIndex === i" aria-hidden="true">
                            {{ i + 1 }}
                        </div>
                    </td>
                    <td class="hit-list-program" [innerHTML]="hit.title | unsanitizedHtml"></td>
                    <td class="hit-list-date" [innerHTML]="hit.date | highlightedIso8601Date | unsanitizedHtml"></td>
                    <td
                        class="hit-list-title"
                        [pTooltip]="hit.itemTitleCellTip"
                        [showDelay]="0"
                        [escape]="false"
                        tooltipPosition="bottom"
                        tooltipStyleClass="table-tooltip"
                        (click)="$event.preventDefault()"
                        [innerHTML]="hit.itemTitle | unsanitizedHtml"
                    ></td>
                    <td
                        class="hit-list-topic"
                        [pTooltip]="hit.descriptionCellTip"
                        [showDelay]="0"
                        [escape]="false"
                        tooltipPosition="bottom"
                        tooltipStyleClass="table-tooltip desc-tooltip"
                        [innerHtml]="hit.description | unsanitizedHtml"
                    ></td>
                    <td class="hit-list-source" [innerHTML]="hit.source | unsanitizedHtml"></td>
                    <td class="action-icon-td" (click)="showActions($event, i)" pFrozenColumn alignFrozen="right">
                        <button
                            data-no-search
                            type="button"
                            class="p-table-icon-button"
                            id="{{ 'action-button-' + i }}"
                            *ngIf="highlightedRowIndex !== i"
                            (click)="showActions($event, i)"
                        >
                            <img src="assets/icons/ellipsis.svg" alt="Optionen" />
                        </button>
                        <div class="p-table-action-button-container" *ngIf="highlightedRowIndex === i">
                            <button
                                data-no-search
                                title="Mit Vermerk bestellen"
                                pRipple
                                type="button"
                                class="p-table-action-button"
                                id="{{ 'order-with-remark-' + i }}"
                                (click)="orderWithRemark($event, hit, 'order-with-remark-' + i)"
                            >
                                <img src="assets/icons/order-with-remark.svg" alt="" />
                            </button>
                            <button
                                data-no-search
                                title="In den Warenkorb"
                                pRipple
                                type="button"
                                class="p-table-action-button"
                                id="{{ 'add-to-cart-' + i }}"
                                (click)="addToShoppingCart($event, hit, 'add-to-cart-' + i)"
                            >
                                <img src="assets/icons/shopping-cart-white.svg" alt="" />
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
</div>
