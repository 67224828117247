import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { RestrictionDto } from '@faro/metadata-angular-client/model/restrictionDto';
import { TimeService } from '../shared/services/time.service';
import { Duration } from '../../shared/duration';
import { Subject, takeUntil } from 'rxjs';
import { parseTimeSpan } from '../../shared/timespan';

@Component({
    selector: 'app-restriction-bar',
    templateUrl: './restriction-bar.component.html',
    styleUrls: ['./restriction-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestrictionBarComponent implements OnInit, OnDestroy {
    @Input()
    set restrictions(navRestrictions: RestrictionDto[]) {
        this.availableRestrictions = navRestrictions.map((v: RestrictionDto) => {
            return {
                ...v,
                vtcInDuration: parseTimeSpan(v.vtcIn),
                vtcOutDuration: parseTimeSpan(v.vtcOut),
            };
        });
    }

    currentRestriction: RestrictionDtoModel | undefined;
    private availableRestrictions: RestrictionDtoModel[] | undefined;

    private _destroyed$ = new Subject<void>();

    constructor(private timeService: TimeService, private zone: NgZone, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            this.timeService.currentVtc$.pipe(takeUntil(this._destroyed$)).subscribe((currentVtc: Duration | null) => {
                if (this.availableRestrictions && currentVtc) {
                    const currentVtcMs = currentVtc.asMilliseconds();
                    const newCurrentRestriction = this.availableRestrictions.find((v: RestrictionDtoModel) => {
                        return (
                            currentVtcMs >= v.vtcInDuration.asMilliseconds() &&
                            currentVtcMs <= v.vtcOutDuration.asMilliseconds()
                        );
                    });
                    if (newCurrentRestriction !== this.currentRestriction) {
                        this.currentRestriction = newCurrentRestriction;
                        this.cdr.detectChanges();
                    }
                }
            });
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}

export interface RestrictionDtoModel extends RestrictionDto {
    vtcInDuration: Duration;
    vtcOutDuration: Duration;
}
