import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';

import {
    selectDialogAvailableMaterial,
    selectLoadingEntryMaterial,
} from '../../shopping-cart-state/shopping-cart.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MediaCutDto } from '@faro/metadata-angular-client';
import { mediaCutInfoString } from '../shopping-cart-entries.model';

@Component({
    selector: 'app-media-selection-dialog',
    templateUrl: './media-selection-dialog.component.html',
    styleUrls: ['./media-selection-dialog.component.scss'],
})
export class MediaSelectionDialogComponent implements OnInit, OnDestroy {
    availableMedia: MediaCutDto[] = [];
    selectedMedia: MediaCutDto[] = [];
    mediaSelection: string[] = [];
    displayTapeMessage: boolean = false;
    loading$: Observable<boolean>;
    private _destroyed$ = new Subject<void>();

    constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig, private store: Store) {
        this.loading$ = this.store.select(selectLoadingEntryMaterial);
        this.mediaSelection = this.config.data.selectedMedia.map((v: MediaCutDto) => v.id);
    }

    ngOnInit() {
        this.store
            .select(selectDialogAvailableMaterial)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: MediaCutDto[]) => {
                this.availableMedia = data;
                this.setTapeDisplayWarningIfApplicable();
            });
    }

    setTapeDisplayWarningIfApplicable() {
        const tapeMedia = this.availableMedia.filter(avMedia => avMedia.mediaType === 'Tape').map(v => v.id);
        this.displayTapeMessage = this.mediaSelection.some(v => tapeMedia.includes(v));
    }

    mediaTypeLabel(media: MediaCutDto): string {
        return mediaCutInfoString(media);
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    closeDialog() {
        this.ref.close();
    }

    accept() {
        this.ref.close(this.mediaSelection);
    }
}
