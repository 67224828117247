<div class="dialog-content" role="dialog" aria-labelledby="dialogTitle">
    <div class="dialog-header">
        <h2 class="title" id="dialogTitle">Suchen</h2>
        <div>
            <button
                data-no-search
                pRipple
                class="close-icon"
                autofocus
                aria-label="Dialog schliessen"
                (click)="closeDialog()"
            >
                <img src="assets/icons/close-icon.svg" alt="Dialog schliessen" />
            </button>
        </div>
    </div>
    <div class="dialog-main-content" [ngClass]="{'no-margin': !config.data.deactivatable}">
        <div class="information-text">
            <h3 class="info-header">Boolsche Operatoren</h3>
            <div>Im Suchfeld können Boolsche Operatoren eingesetzt werden: AND, OR, NOT (in Grossbuchstaben). Weitere Tipps dazu gibt es in der <a (click)="openInfo()">Dokumentation zur Mediendatenbank</a>.</div>
            <h3 class="info-header">Suchen nach</h3>
            <div>Es gibt drei Suchmöglichkeiten mit unterschiedlicher Ausrichtung:</div>
        </div>
    <ng-container *ngFor="let searchType of searchTypes">
        <div class="information-example">
            <div class="information-image"><img src="{{searchType.image}}"></div>
            <div class="information-example-text">
                <div class="search-type-title">{{ searchType.title }}</div>
                <div class="search-type-description">
                    {{ searchType.description.main }}
                    <ng-container *ngFor="let example of searchType.description.examples">
                        <ul class="example-list">
                            <li><div>{{example.description}}</div>
                                <div class="secondary-text">{{example.example}}</div>
                            </li>
                        </ul>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
    <div *ngIf="config.data.deactivatable" class="checkbox-container">
        <!--Aria label required for screen reader-->
        <p-checkbox
            [(ngModel)]="doNotDisplayMessageAgain"
            [binary]="true"
            ariaLabel="Diese Meldung nicht mehr anzeigen"
            label="Diese Meldung nicht mehr anzeigen"
            data-no-search
        ></p-checkbox>
    </div>
    </div>
    <div *ngIf="config.data.deactivatable" class="dialog-footer">
        <button
            data-no-search
            class="search-button"
            type="button"
            pButton
            icon="pi pi-check"
            (click)="accept()"
            label="Ok"
        ></button>
        <button
            data-no-search
            class="dialog-secondary-button"
            type="button"
            pButton
            icon="pi pi-times"
            (click)="closeDialog()"
            label="Abbrechen"
        ></button>
    </div>
</div>
