import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-restriction-dialog',
    templateUrl: './restriction-dialog.component.html',
    styleUrls: ['./restriction-dialog.component.scss'],
})
export class RestrictionDialogComponent {
    constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

    closeDialog() {
        this.ref.close();
    }
}
