import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TreeSelectModule } from 'primeng/treeselect';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { DateFacetComponent } from './date-facet/date-facet.component';
import { DemoComponent } from './demo/demo.component';
import { DialogPanelComponent } from './dialog-panel/dialog-panel.component';
import { FacetPanelComponent } from './facet-panel/facet-panel.component';
import { ListFacetComponent } from './list-facet/list-facet.component';
import { ListFacetSectionComponent } from './list-facet/list-facet-section/list-facet-section.component';
import { LoadingIndicatorDotsComponent } from './loading-indicator-dots/loading-indicator-dots.component';
import { OptionFacetComponent } from './option-facet/option-facet.component';
import { TrafficLightListComponent } from './traffic-light-list/traffic-light-list.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { HitlistTableComponent } from './hitlist-table/hitlist-table.component';
import { HitListActionsComponent } from './hit-list-actions/hit-list-actions.component';
import { UnsanitizedHtmlPipe } from './pipes/unsanitized-html.pipe';
import { SingleDateInputComponent } from './single-date-input/single-date-input.component';
import { InlineEditFieldComponent } from './inline-edit-field/inline-edit-field.component';
import { DateRangeInputComponent } from './date-range-input/date-range-input.component';
import { HighlightedIso8601DatePipe } from './pipes/highlighted-iso8601-date.pipe';

const primeNgModules = [
    CalendarModule,
    DynamicDialogModule,
    OverlayPanelModule,
    TooltipModule,
    TriStateCheckboxModule,
    ButtonModule,
    InputTextModule,
    RippleModule,
    SelectButtonModule,
    ScrollPanelModule,
    CheckboxModule,
    AutoCompleteModule,
    TreeSelectModule,
    OrganizationChartModule,
    InputSwitchModule,
    TableModule,
    DropdownModule,
    ProgressSpinnerModule,
];

@NgModule({
    declarations: [
        DemoComponent,
        DateFacetComponent,
        DialogPanelComponent,
        FacetPanelComponent,
        TrafficLightListComponent,
        AutocompleteComponent,
        OptionFacetComponent,
        LoadingIndicatorDotsComponent,
        ListFacetComponent,
        ListFacetSectionComponent,
        TreeSelectComponent,
        HitlistTableComponent,
        HitListActionsComponent,
        UnsanitizedHtmlPipe,
        SingleDateInputComponent,
        InlineEditFieldComponent,
        DateRangeInputComponent,
        HighlightedIso8601DatePipe,
    ],
    imports: [CommonModule, FormsModule, ...primeNgModules],
    exports: [
        DemoComponent,
        DateFacetComponent,
        DialogPanelComponent,
        FacetPanelComponent,
        TrafficLightListComponent,
        AutocompleteComponent,
        OptionFacetComponent,
        LoadingIndicatorDotsComponent,
        ListFacetComponent,
        TreeSelectComponent,
        HitlistTableComponent,
        HitListActionsComponent,
        UnsanitizedHtmlPipe,
        SingleDateInputComponent,
        InlineEditFieldComponent,
        DateRangeInputComponent,
        HighlightedIso8601DatePipe,
    ],
})
export class FaroUiModule {}
