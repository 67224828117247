import { Component, Input } from '@angular/core';
import { columns } from './shopping-cart-hitlist.model';
import { Router } from '@angular/router';
import { SearchResultDto, SearchResultCartStatusDto } from '@faro/order-angular-client';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectShoppingCartSearchResult } from '../../shopping-cart-state/shopping-cart.selectors';
import { getOrderStatusLabel } from '../../shopping-cart-state/shopping-cart.mapper';
import { focusElement } from '../../../shared/focus-element.helper';
import { activateShoppingCart, duplicateShoppingCart } from '../../shopping-cart-state/shopping-cart.actions';

@Component({
    selector: 'app-shopping-cart-hitlist',
    templateUrl: './shopping-cart-hitlist.component.html',
    styleUrls: ['./shopping-cart-hitlist.component.scss'],
})
export class ShoppingCartHitlistComponent {
    @Input()
    loading: boolean | null = false;

    highlightedRowIndex: number | undefined;
    entryList$: Observable<SearchResultDto[]>;
    cols = columns;

    constructor(private router: Router, private store: Store) {
        this.entryList$ = this.store.select(selectShoppingCartSearchResult);
    }

    showActions(event: Event, index: number): void {
        this.highlightedRowIndex = index;
        /**
         * Timeout to make sure buttons are in DOM
         * */
        setTimeout(() => {
            if (document.getElementById(`duplicate-cart-${index}`)) {
                focusElement(`duplicate-cart-${index}`);
            }
        }, 100);
        event.stopPropagation();
    }

    async openShoppingCartPreview(cartId: string) {
        await this.router.navigate(['shopping-cart/preview', cartId]);
    }

    async openActiveCart() {
        await this.router.navigate(['shopping-cart/active']);
    }

    activateShoppingCart(event: Event, entry: any) {
        event.stopPropagation();
        this.store.dispatch(activateShoppingCart({ cartId: entry.id }));
    }

    duplicateShoppingCart(event: Event, entry: any) {
        event.stopPropagation();
        this.store.dispatch(duplicateShoppingCart({ cartId: entry.id }));
    }

    getOrderStatusDisplayLabel(label: SearchResultCartStatusDto): string {
        return getOrderStatusLabel(label);
    }

    readonly SearchResultCartStatusDto = SearchResultCartStatusDto;
}
