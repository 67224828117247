import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SearchModule } from '../search/search.module';
import { HeaderActionsComponent } from './header/header-actions/header-actions.component';
import { RippleModule } from 'primeng/ripple';
import { NotFoundComponent } from './not-found/not-found.component';
import { MenuModule } from 'primeng/menu';
import { HeaderBannerComponent } from './header/header-banner/header-banner.component';

@NgModule({
    declarations: [HeaderComponent, HeaderActionsComponent, NotFoundComponent, HeaderBannerComponent],
    exports: [HeaderComponent],
    imports: [CommonModule, SearchModule, RippleModule, MenuModule],
})
export class CoreModule {}
