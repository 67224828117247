import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { notifyOfFacetSearchError, notifyOfSearchError } from './search-result.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { notifyOfProfileError } from './search-profile.actions';

@Injectable()
export class ErrorEffects {
    constructor(private readonly actions$: Actions, private readonly notification: NotificationService) {}

    showSearchErrorNotificationEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfSearchError),
                tap(action => {
                    this.notification.notifyOfSearchError();
                    console.error(action.errorResponse);
                })
            ),
        { dispatch: false }
    );

    showFacetSearchErrorNotificationEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfFacetSearchError),
                tap(action => {
                    this.notification.notifyOfFacetSearchError();
                    console.error(action.errorResponse);
                })
            ),
        { dispatch: false }
    );

    showProfileErrorNotificationEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfProfileError),
                tap(action => {
                    this.notification.notifyOfProfileSearchError();
                    console.error(action.errorResponse);
                })
            ),
        { dispatch: false }
    );
}
