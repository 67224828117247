import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShoppingCartMainComponent } from './shopping-cart-main/shopping-cart-main.component';
import { ActiveShoppingCartComponent } from './active-shopping-cart/active-shopping-cart.component';
import { SearchShoppingCartComponent } from './search-shopping-cart/search-shopping-cart.component';
import { ShoppingCartDetailComponent } from './shopping-cart-detail/shopping-cart-detail.component';
import { ShoppingCartPreviewComponent } from './shopping-cart-preview/shopping-cart-preview.component';
import { OrderSuccessComponent } from './order-success/order-success.component';

const routes: Routes = [
    {
        path: '',
        component: ShoppingCartMainComponent,
        children: [
            {
                path: 'active',
                component: ActiveShoppingCartComponent,
            },
            {
                path: 'active/:cartEntryId',
                component: ShoppingCartDetailComponent,
            },
            {
                path: 'search',
                component: SearchShoppingCartComponent,
            },
            {
                path: 'preview/:cartId',
                component: ShoppingCartPreviewComponent,
            },
            {
                path: 'preview/:cartId/:cartEntryId',
                component: ShoppingCartDetailComponent,
            },
            {
                path: 'success',
                component: OrderSuccessComponent,
            },
        ],
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OrderRoutingModule {}
