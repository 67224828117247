import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    setSelectedAdmiraDevice,
    storeSelectedAdmiraDevice,
    updateShoppingCartExportDate,
    updateShoppingCartExportEmail,
} from '../../shopping-cart-state/shopping-cart.actions';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ExportDestinationDialogComponent } from './export-destination-dialog/export-destination-dialog.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TreeNode } from 'primeng/api';
import {
    selectAdmira,
    selectExportSettings,
    selectSelectedAdmiraDevice,
} from '../../shopping-cart-state/shopping-cart.selectors';
import { StoreUtilsService } from '../../../state/store-utils.service';
import { mapTreeNodeToDevice } from './export-destination-dialog/tree-node.helper';

@Component({
    selector: 'app-export-settings',
    templateUrl: './export-settings.component.html',
    styleUrls: ['./export-settings.component.scss'],
})
export class ExportSettingsComponent implements OnDestroy {
    @Input()
    loading: boolean | null = false;

    @Input()
    exportTargetDisplayString: string = '';

    @Input()
    hasEntries: boolean = false;

    exportSettings$: Observable<ShoppingCartExportInfoModel>;

    readonly currentDate: Date = new Date();

    private _destroyed$ = new Subject<void>();

    constructor(
        private store: Store,
        private dialogService: DialogService,
        private ref: DynamicDialogRef,
        private readonly storeUtils: StoreUtilsService
    ) {
        this.exportSettings$ = this.store.select(selectExportSettings);
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    onDateSelected(event: Date): void {
        this.store.dispatch(updateShoppingCartExportDate({ exportDate: event }));
    }

    dateOrNullForToday(date: Date): Date | null {
        return this.isToday(date) ? null : date;
    }

    isToday(date: Date): boolean {
        return date.toLocaleDateString('de-CH') === this.currentDate.toLocaleDateString('de-CH');
    }

    onEmailInputFocusLost(event: EventTarget | null) {
        const inputValue = (event as HTMLInputElement).value;
        this.store.dispatch(updateShoppingCartExportEmail({ eMail: inputValue }));
    }

    async openExportDestinationDialog() {
        const selectedAdmiraDevice = await this.storeUtils.snapshot(selectSelectedAdmiraDevice);
        this.ref = this.dialogService.open(ExportDestinationDialogComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'export-dialog',
            data: { title: 'Exportziel', selectedDevice: selectedAdmiraDevice },
        });

        this.ref.onClose
            .pipe(takeUntil(this._destroyed$))
            .subscribe(async (data: { selectedDevice: TreeNode; rootPath: string; rootName: string }) => {
                if (data) {
                    const device = mapTreeNodeToDevice(data.selectedDevice);
                    this.store.dispatch(
                        setSelectedAdmiraDevice({
                            selectedAdmiraDevice: device,
                            rootPath: data.rootPath,
                            rootName: data.rootName,
                        })
                    );
                    const admiraDevices = await this.storeUtils.snapshot(selectAdmira);
                    const rootPath = admiraDevices.admiraDevices.filter(d => d.id === device.id)[0].devicePath ?? '';
                    const rootName = admiraDevices.admiraDevices.filter(d => d.id === device.id)[0].deviceName ?? '';
                    this.store.dispatch(
                        storeSelectedAdmiraDevice({ selectedAdmiraDevice: device, rootPath, rootName })
                    );
                }
                (document.getElementById('exportDestination') as HTMLInputElement).focus();
            });
    }

    setExportTargetTooltip(event: MouseEvent) {
        const element = event.currentTarget as HTMLElement;
        if (element && element.scrollWidth > element.clientWidth) {
            element.setAttribute('title', this.exportTargetDisplayString ?? '');
        } else {
            element.setAttribute('title', '');
        }
    }
}

interface ShoppingCartExportInfoModel {
    eMail: string;
    exportDate: Date;
}
