import { Component, OnDestroy } from '@angular/core';
import { SearchFieldSelectionOptions, searchFiltersToLabel } from './search-toggle.model';
import { setSearchFieldSelection } from '../../search-state/search-options.actions';
import { dispatchEmptySearch, dispatchEmptySearchAfterTypeChange } from '../../search-state/search-result.actions';
import { selectSearchFieldSelection } from '../../search-state/search-options.selectors';
import { Subject, takeUntil } from 'rxjs';
import { SEARCH_FILTERS, SearchFieldSelectionOption } from '../../search-state/search-options.state';
import { Store } from '@ngrx/store';
import { selectDisplaySavedSearch } from '../../search-state/search-result.selectors';

@Component({
    selector: 'app-search-toggle',
    templateUrl: './search-toggle.component.html',
    styleUrls: ['./search-toggle.component.scss'],
})
export class SearchToggleComponent implements OnDestroy {
    selectedValue: SearchFieldSelectionOptions | undefined;
    selectOptions: SearchFieldSelectionOptions[] = [];
    displaySavedSearch: boolean = false;
    private _destroyed$: Subject<void> = new Subject<void>();
    constructor(private store: Store) {
        this.selectOptions = searchFiltersToLabel(SEARCH_FILTERS);
        this.store
            .select(selectSearchFieldSelection)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: SearchFieldSelectionOption) => {
                this.selectedValue = searchFiltersToLabel([data])[0];
            });
        this.store
            .select(selectDisplaySavedSearch)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.displaySavedSearch = data;
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    onSelectionChange(event: SearchFieldSelectionOptions) {
        if (event) {
            this.store.dispatch(setSearchFieldSelection({ searchFieldSelection: event }));
            if (!this.displaySavedSearch) {
                this.store.dispatch(dispatchEmptySearch());
            }
            if (this.displaySavedSearch) {
                this.store.dispatch(dispatchEmptySearchAfterTypeChange());
            }
        }
    }
}
