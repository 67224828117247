import { Duration } from '../../shared/duration';
import { MediaTimeMapping } from './media-time-mapping.model';

/**
 * Formats a duration as timecode with frame number ("HH:mm:ss/FF")
 * @example formatTimeCode(fromObject({seconds: 2, milliseconds: 51}, 25) == '00:00:02/03'
 */
export function formatTimeCode(tc: Duration, frameRate: number): string {
    const frameDuration = 1000 / frameRate;

    const time = tc.format('HH:mm:ss');
    const frameNumber = Math.floor(tc.getMilliseconds() / frameDuration);

    return `${time}/${String(frameNumber).padStart(2, '0')}`;
}

export type FormatRtcFunction = (vtc: Duration, defaultValue: string) => string;

export function formatRtcAsDefaultValue(_: Duration, defaultValue: string): string {
    return defaultValue;
}

export function formatRtcUsingMap(timeCodeMap: MediaTimeMapping, frameRate: number): FormatRtcFunction {
    function format(vtc: Duration, defaultValue: string): string {
        try {
            const rtc = timeCodeMap.vtcToRtc(vtc);
            return formatTimeCode(rtc, frameRate);
        } catch (_) {
            return defaultValue;
        }
    }

    return format;
}
