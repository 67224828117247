import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Hit } from '@faro/searchapi-angular-client';
import { HitMapperService } from '../../search/content/hitlist/hit-mapper.service';
import { BreadcrumbModel } from '../../ui-library/breadcrumbs/breadcrumbs.component';
import { ItemNavInfoDto } from '@faro/metadata-angular-client/model/itemNavInfoDto';
import { PaginationValues } from '../../ui-library/hitlist-paginator/paginator.interface';
import { Store } from '@ngrx/store';
import { dispatchSearchNextPage } from '../../search/search-state/search-result.actions';
import { SearchFieldSelectionEnum, SearchFieldSelectionOption } from '../../search/search-state/search-options.state';
import { selectSearchFieldSelection } from '../../search/search-state/search-options.selectors';
import { Subject, takeUntil } from 'rxjs';
import { selectCurrentHitId } from '../../state/shared.selectors';
import { setCurrentHitId } from '../../state/shared.actions';

export interface ProgramInfo {
    programId: string;
    programTitle: string;
    items: ItemNavInfoDto[];
}

@Component({
    selector: 'app-detail-header',
    templateUrl: './detail-header.component.html',
    styleUrls: ['./detail-header.component.scss'],
})
export class DetailHeaderComponent implements OnChanges, OnDestroy {
    @Input()
    programInfo: ProgramInfo | null = null;

    /** If set, the item with the given id is shown in the header. */
    @Input()
    itemId: string | undefined | null;

    @Input()
    isItemHighlighted: boolean = false;

    @Input()
    hitlist: Hit[] = [];

    crumbs: Crumb[] = [];
    activeCrumb: Crumb | undefined;

    private selectedSearchOption: SearchFieldSelectionOption = { value: SearchFieldSelectionEnum.TOPIC };
    private currentHitId: string | undefined;
    private _destroyed$ = new Subject<void>();

    paginatorImgSrcAndDescription = {
        first: { icon: '/assets/icons/paginator-fast-back.svg', description: 'Erster Treffer' },
        prev: { icon: '/assets/icons/paginator-prev.svg', description: 'Vorheriger Treffer' },
        next: { icon: '/assets/icons/paginator-next.svg', description: 'Nächster Treffer' },
        last: { icon: '/assets/icons/paginator-fast-forward.svg', description: 'Letzter Treffer' },
    };

    paginator: {
        show: boolean;
        index: number;
        length: number;
    } = { show: false, index: 0, length: 0 };

    constructor(private hitMapper: HitMapperService, private router: Router, private store: Store) {
        this.store
            .select(selectSearchFieldSelection)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => (this.selectedSearchOption = data));

        this.store
            .select(selectCurrentHitId)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.currentHitId = data;
                this.resetPaginator();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.resetCrumbs();
        this.setActiveCrumb();
        this.resetPaginator();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private resetCrumbs(): void {
        this.crumbs = [
            {
                label: 'Trefferliste',
                type: CrumbType.HITLIST,
                id: 'HITLIST',
            },
        ];

        if (this.programInfo?.programId) {
            this.crumbs.push({
                label: this.programInfo.programTitle,
                type: CrumbType.PROGRAM,
                id: this.programInfo.programId,
            });
        }

        const items = this.programInfo?.items || [];
        const item = items.find((i: any) => i.id === this.itemId);

        if (item) {
            this.crumbs.push({
                label: item.title || '?',
                type: CrumbType.ITEM,
                id: item.id,
            });
        }
    }

    private setActiveCrumb() {
        const highlightedId = this.isItemHighlighted ? this.itemId : this.programInfo?.programId;
        this.activeCrumb = this.crumbs.find(c => c.id === highlightedId);
    }

    private resetPaginator() {
        const hitIndex = this.hitlist.findIndex(h => h.id === this.currentHitId);
        this.paginator = {
            show: this.hitlist.length > 0 && hitIndex >= 0,
            length: this.hitlist.length,
            index: hitIndex,
        };
    }

    onPaginatorChanged(event: PaginationValues): void {
        if (this.hitlist.length === 0) return;

        let hit: Hit | undefined;
        switch (event) {
            case 'first':
                hit = this.hitlist[0];
                break;
            case 'last':
                hit = this.hitlist[this.hitlist.length - 1];
                this.store.dispatch(dispatchSearchNextPage());
                break;
            case 'next':
                const requestedIndex = this.paginator.index + 1;
                hit = this.hitlist[requestedIndex];
                if (requestedIndex === this.hitlist.length - 1) {
                    this.store.dispatch(dispatchSearchNextPage());
                }
                break;
            case 'prev':
                hit = this.hitlist[this.paginator.index - 1];
                break;
        }

        const programId = this.hitMapper.programId(hit!, this.selectedSearchOption);
        if (!programId) throw new Error('ProgramId not found on hit!');
        const itemId =
            this.selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE && hit!.items
                ? hit!.items['Sequence.Hitlist.ItemId'].value?.toLowerCase()
                : hit!.id;
        this.store.dispatch(setCurrentHitId({ hitId: hit?.id }));
        this.router.navigateByUrl(`details/${programId!}/${itemId}`);
    }

    async navigateToCrumb(c: BreadcrumbModel): Promise<boolean> {
        const crumb = c as Crumb;
        const programId = this.programInfo?.programId;

        switch (crumb.type) {
            case CrumbType.ITEM:
                return this.router.navigateByUrl(`details/${programId}/${this.itemId}`);
            case CrumbType.PROGRAM:
                return this.router.navigate([`details/${programId}`], {
                    queryParams: { item_crumb: this.itemId },
                });
            default:
                return this.router.navigateByUrl('search');
        }
    }
}

export interface Crumb extends BreadcrumbModel {
    type: CrumbType;
    id: string;
}

enum CrumbType {
    ITEM = 'item',
    PROGRAM = 'title',
    HITLIST = 'hitlist',
}
