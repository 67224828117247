import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, switchMap, take, catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import {
    FaroFacetSearchResponse,
    FaroSearchRequest,
    FaroSearchResponse,
    SearchService,
} from '@faro/searchapi-angular-client';
import {
    dispatchEmptySearch,
    dispatchEmptySearchAfterTypeChange,
    dispatchSearch,
    dispatchSearchNextPage,
    notifyOfFacetSearchError,
    notifyOfSearchError,
    setEmptySearchResponse,
    setFacetSearchResponse,
    setFacetsOnly,
    setSearchResponse,
} from './search-result.actions';
import {
    updateProfileSettings,
    getSearchProfile,
    notifyOfProfileError,
    setDefaultProfileSettings,
} from './search-profile.actions';
import {
    selectHitlistSortingAttribute,
    selectHitlistSortingOrder,
    selectSearchOptions,
} from './search-options.selectors';
import {
    setSearchInfoDialogPreferences,
    getAvailableStorageCategories,
    setAllExcludedStorageCategories,
    setAvailableStorageCategories,
    setHitlistSortingAttribute,
    setHitlistSortingOrder,
    updateDateFacetRange,
    updateFacetSearchInput,
    updateSearchInput,
    getSortingDirection,
    setHitlistSortingOrderAndAttribute,
    callSuccessAction,
    SearchOptionsActions,
} from './search-options.actions';
import { selectSearchCanceled, selectSearchResponse } from './search-result.selectors';
import { createFaroFacetSearchRequest, createFaroSearchRequest } from './search.mapper';
import { setSearchProfileSettings } from './search-profile.actions';
import {
    SearchSettingsService,
    StorageCategoriesService,
    StorageCategory,
    UserSetting,
    UserSettingsService,
} from '@faro/profile-angular-client';
import { SearchSettings } from '@faro/profile-angular-client/model/searchSettings';
import { selectUserId } from '../../state/user.selectors';

@Injectable()
export class SearchEffects {
    constructor(
        private readonly store: Store,
        private readonly actions$: Actions,
        private readonly searchSettingsService: SearchSettingsService,
        private readonly storageCategoriesService: StorageCategoriesService,
        private readonly searchService: SearchService,
        private readonly userSettingsService: UserSettingsService
    ) {}

    dispatchSearchEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dispatchSearch),
            switchMap(_ => {
                return this.searchContent().pipe(
                    map((searchResponse: FaroSearchResponse) => {
                        const dateFilter = searchResponse.request.dateFilter;
                        /**
                         * Sets date of search input e.g. Tagesschau 4.4.2021
                         * */
                        if (dateFilter.to || dateFilter.from) {
                            this.store.dispatch(
                                updateDateFacetRange({
                                    from: dateFilter.from ? new Date(dateFilter.from) : null,
                                    to: dateFilter.to ? new Date(dateFilter.to) : null,
                                })
                            );
                        }
                        return setSearchResponse({ searchResponse });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfSearchError({ errorResponse }));
                    })
                );
            })
        )
    );

    dispatchEmptySearchEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dispatchEmptySearch),
            switchMap(_ => {
                return this.searchContent().pipe(
                    map((searchResponse: FaroSearchResponse) => {
                        return setEmptySearchResponse({ searchResponse });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfSearchError({ errorResponse }));
                    })
                );
            })
        )
    );

    dispatchEmptySearchAfterTypeChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dispatchEmptySearchAfterTypeChange),
            switchMap(_ => {
                return this.searchContent().pipe(
                    map((searchResponse: FaroSearchResponse) => {
                        return setFacetsOnly({ searchResponse });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfSearchError({ errorResponse }));
                    })
                );
            })
        )
    );

    dispatchSearchEffectNextPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dispatchSearchNextPage),
            concatLatestFrom(_ => this.store.select(selectSearchResponse)),
            switchMap(([_, searchResult]) => {
                const requestInfo: FaroSearchRequest | undefined = searchResult.lastResponse?.request;
                const nextPage = requestInfo?.page! + 1;
                const totalPageCount = searchResult?.lastResponse?.hitList.totalPageCount!;
                if (nextPage > totalPageCount) {
                    return of();
                } else {
                    const searchParams = { ...requestInfo!, page: nextPage };
                    return this.searchService.searchContentPost(searchParams).pipe(
                        map((searchResponse: FaroSearchResponse) => setSearchResponse({ searchResponse })),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfSearchError({ errorResponse }));
                        })
                    );
                }
            })
        )
    );

    conditionalSearchTriggerEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateSearchInput),
            switchMap(action => {
                return action.triggerSearch ? of(dispatchSearch()) : of();
            })
        )
    );

    SearchTriggerEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setHitlistSortingOrder, setHitlistSortingAttribute),
            switchMap(() => {
                return of(dispatchSearch());
            })
        )
    );

    storeSortingDirection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setHitlistSortingOrder, setHitlistSortingAttribute),
            concatLatestFrom(action => [
                action.type === SearchOptionsActions.SET_HITLIST_SORTING_ORDER
                    ? of(action.sortingOrder)
                    : this.store.select(selectHitlistSortingOrder),
                action.type === SearchOptionsActions.SET_HITLIST_SORTING_ATTRIBUTE
                    ? of(action.sortingAttribute)
                    : this.store.select(selectHitlistSortingAttribute),
                this.store.select(selectUserId).pipe(filter(v => !!v)),
            ]),
            switchMap(([_, sortingOrder, sortingAttribute, userId]) => {
                const param: UserSetting = {
                    key: 'ArMen.SearchOptions.SortingDirection',
                    value: JSON.stringify({
                        sortingOrder: sortingOrder,
                        sortingAttribute: sortingAttribute,
                    }),
                    valueType: 'String',
                };
                return this.userSettingsService.userSettingsStore(userId!, param).pipe(
                    map(_ => {
                        return callSuccessAction();
                    })
                );
            })
        )
    );

    getSortingDirection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSortingDirection),
            concatLatestFrom(() => this.store.select(selectUserId).pipe(filter(v => !!v))),
            switchMap(([_, userId]) => {
                const key = 'ArMen.SearchOptions.SortingDirection';
                return this.userSettingsService.userSettingsGet(userId!, key).pipe(
                    map((res: any) => {
                        const obj = JSON.parse(res.value);
                        return setHitlistSortingOrderAndAttribute({
                            sortingOrder: obj.sortingOrder,
                            sortingAttribute: obj.sortingAttribute,
                        });
                    })
                );
            })
        )
    );

    dispatchFacetSearchEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateFacetSearchInput),
            switchMap(action => {
                return this.searchFacet(action.facetField, action.query).pipe(
                    map((facetSearchResponse: FaroFacetSearchResponse) => {
                        return setFacetSearchResponse({ facetSearchResponse });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfFacetSearchError({ errorResponse }));
                    })
                );
            })
        )
    );

    private searchContent(): Observable<FaroSearchResponse> {
        const searchCancelled$ = this.store.select(selectSearchCanceled).pipe(filter(isCanceled => isCanceled));

        return this.store.select(selectSearchOptions).pipe(
            filter(searchOptions => !searchOptions.allExcludedStorageCategoriesOutdated),
            takeUntil(searchCancelled$),
            take(1),
            mergeMap(searchOptions => {
                const searchParams = createFaroSearchRequest(searchOptions);
                return this.searchService.searchContentPost(searchParams).pipe(takeUntil(searchCancelled$));
            })
        );
    }

    private searchFacet(facetField: string, query: string): Observable<FaroFacetSearchResponse> {
        return this.store.select(selectSearchOptions).pipe(
            filter(searchOptions => !searchOptions.allExcludedStorageCategoriesOutdated),
            take(1),
            mergeMap(searchOptions => {
                const searchParams = createFaroFacetSearchRequest(query, facetField, searchOptions);
                return this.searchService.searchFacetPost(searchParams);
            })
        );
    }

    getSearchProfileSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSearchProfile),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([_, userId]) => {
                if (userId) {
                    return this.searchSettingsService.searchSettingsGet(userId).pipe(
                        map((res: SearchSettings) => {
                            return setSearchProfileSettings({
                                searchProfile: res,
                            });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfProfileError({ errorResponse }));
                        })
                    );
                }
                return of();
            })
        )
    );

    getExcludesStorageCategories$ = createEffect(() =>
        this.actions$.pipe(
            // on action setSearchProfileSettings excluded storage categories need to be reloaded from backend
            // search profile settings are modified by user
            ofType(setSearchProfileSettings),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([_, userId]) => {
                if (userId) {
                    return this.searchSettingsService.searchSettingsListExcludedStorageCategories(userId).pipe(
                        map((filters: string[]) => {
                            return setAllExcludedStorageCategories({ excluded: filters });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfProfileError({ errorResponse }));
                        })
                    );
                }
                return of();
            })
        )
    );

    getAvailableStorageCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAvailableStorageCategories),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([{ filters }, userId]) => {
                if (userId) {
                    return this.storageCategoriesService.storageCategoriesGet(userId, filters).pipe(
                        map((res: StorageCategory[]) => {
                            return setAvailableStorageCategories({ storageCategories: res });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfProfileError({ errorResponse }));
                        })
                    );
                }
                return of();
            })
        )
    );

    updateProfileSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateProfileSettings),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([params, userId]) => {
                if (userId) {
                    return this.searchSettingsService.searchSettingsStore(userId, params.searchProfile).pipe(
                        map((searchProfile: SearchSettings) => {
                            return setSearchProfileSettings({ searchProfile });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfProfileError({ errorResponse }));
                        })
                    );
                }
                return of();
            })
        )
    );

    resetProfileSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setDefaultProfileSettings),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([_, userId]) => {
                if (userId) {
                    return this.searchSettingsService.searchSettingsReset(userId).pipe(
                        map(_ => {
                            return getSearchProfile();
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfProfileError({ errorResponse }));
                        })
                    );
                }
                return of();
            })
        )
    );

    setDisplaySearchInfoDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setSearchInfoDialogPreferences),
                tap(action => localStorage.setItem('showSearchInfoDialog', action.doNotShowAgain.toString()))
            ),
        { dispatch: false }
    );
}
