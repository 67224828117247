export interface ShoppingCartSearchMaskInputModel {
    label: string;
    field: string;
    value: string;
}

export const inputTextFields: ShoppingCartSearchMaskInputModel[] = [
    {
        label: 'Bezeichnung',
        field: 'description',
        value: '',
    },
    {
        label: 'Nummer',
        field: 'number',
        value: '',
    },
    {
        label: 'PID',
        field: 'pid',
        value: '',
    },
    {
        label: 'Vorname',
        field: 'firstName',
        value: '',
    },
    {
        label: 'Name',
        field: 'lastName',
        value: '',
    },
];
