<ng-container *ngrxLet="activeShoppingCart$; let activeShoppingCart">
    <app-shopping-cart-header
        [showPaginator]="false"
        [showPrint]="hasEntries(activeShoppingCart)"
        (printReport)="printShoppingCartReport(activeShoppingCart)"
    ></app-shopping-cart-header>
    <div class="shopping-cart-view container">
        <ng-container *ngrxLet="orderingShoppingCart$; let orderingShoppingCart">
            <div class="side-bar grid-sidebar">
                <app-shopping-cart-info
                    [shoppingCart]="activeShoppingCart"
                    [loading]="loadingShoppingCart$ | async"
                    [readonly]="false"
                ></app-shopping-cart-info>
                <app-export-settings
                    [exportTargetDisplayString]="displayString"
                    [loading]="loadingShoppingCart$ | async"
                    [hasEntries]="hasEntries(activeShoppingCart)"
                ></app-export-settings>
                <div class="shopping-cart-actions">
                    <div class="search-button-container">
                        <button
                            *ngIf="hasEntries(activeShoppingCart)"
                            (click)="createNewShoppingCart()"
                            pRipple
                            type="button"
                            class="secondary-button"
                        >
                            <img src="/assets/icons/add.svg" alt="" />Neuen Warenkorb erstellen
                        </button>
                        <button
                            (click)="orderShoppingCart()"
                            class="search-button"
                            type="button"
                            pRipple
                            [disabled]="!hasEntries(activeShoppingCart) || orderingShoppingCart || (selectedDevice$ | async) === undefined"
                            [ngClass]="{
                                'has-entries': hasEntries(activeShoppingCart)
                            }"
                        >
                            <img src="/assets/icons/shopping-cart-white.svg" alt="" />Bestellung abschicken
                        </button>
                    </div>
                </div>
            </div>
            <div class="grid-content">
                <div *ngIf="orderingShoppingCart" class="search-overlay">
                    <p-progressSpinner strokeWidth="2" styleClass="custom-spinner"></p-progressSpinner>
                    <div class="search-text">Bestellung wird verarbeitet</div>
                </div>
                <app-shopping-cart-entries
                    [cartId]="activeShoppingCart.id"
                    [entries]="activeShoppingCart.entries"
                    [entriesMetadata]="$any(activeShoppingCart.entriesMetadata)"
                    [entriesMediaCutMetadata]="activeShoppingCart.mediaCutsMetadata"
                    [loading]="loadingShoppingCart$ | async"
                    [readonly]="false"
                ></app-shopping-cart-entries>
            </div>
        </ng-container>
    </div>
</ng-container>
