<div class="search-field-container">
    <ng-container *ngrxLet="query$; let query">
        <div class="input-field">
            <input
                #inputField
                pInputText
                type="text"
                (keydown)="search($event)"
                [ngModel]="query"
                (input)="queryInputChanged($event)"
                autofocus
                placeholder="Suchen"
                aria-label="Suchen"
                data-cy="main-search-input-field"
            />
            <ng-container *ngIf="!stringNullOrEmpty(query)">
                <button data-no-search (click)="clearSearchInput()" data-cy="clear-main-search-input-field">
                    <i class="pi pi-times" aria-label="Suchfeld löschen"></i>
                </button>
            </ng-container>
            <ng-container *ngIf="stringNullOrEmpty(query)">
                <i class="pi pi-search" style="font-size: 16px; color: #4e4d47"></i>
            </ng-container>
        </div>
    </ng-container>
    <div class="filter-header">
        <div><h2 class="title">Suchen nach</h2></div>
        <div class="button-container">
            <button
                data-no-search
                pRipple
                type="button"
                title="Tipps zur Suche"
                class="p-filter-dialog-button"
                (click)="openInfoDialog($event)"
            >
                <img src="assets/icons/info.svg" alt="Informationen zu Sucheinstellungen"/>
            </button>
            <ng-container *ngrxLet="areProfileSettingsDefault$; let isDefault">
                <button
                    *ngIf="isDefault"
                    data-no-search
                    pRipple
                    type="button"
                    title="Sucheinstellungen"
                    class="p-filter-dialog-button"
                    (click)="openProfileFiltersDialog($event)"
                >
                    <img src="assets/icons/global-filter.svg" alt="Sucheinstellungen im Standardmodus"/>
                </button>
                <button
                    *ngIf="!isDefault"
                    data-no-search
                    pRipple
                    type="button"
                    title="Sucheinstellungen"
                    class="p-filter-dialog-button"
                    (click)="openProfileFiltersDialog($event)"
                >
                    <img src="assets/icons/global-filter-selected.svg" alt="Sucheinstellungen eingeschränkt"/>
                </button>
            </ng-container>
        </div>
    </div>
    <div class="toggle-container">
        <app-search-toggle></app-search-toggle>
    </div>
</div>
