<div #chart class="envelope-container" [ngClass]="{'envelope-container-smaller' : !itemId}">
    <div *ngIf="envelopeLoaded" class="chart top-chart">
        <ngx-charts-area-chart
            class="chart-container"
            [legend]="envelopeOptions.legend"
            [xAxis]="envelopeOptions.xAxis"
            [yAxis]="envelopeOptions.yAxis"
            [animations]="envelopeOptions.animations"
            [results]="leftChannelMulti"
            [customColors]="envelopeOptions.colorScheme"
            [tooltipDisabled]="envelopeOptions.tooltipDisabled"
            [curve]="envelopeOptions.curve"
            [yAxisTicks]="envelopeOptions.yAxisTicks"
            [xAxisTicks]="xAxisTicks"
            [autoScale]="false"
        >
        </ngx-charts-area-chart>
    </div>
    <div class="envelope-divider"></div>
    <div *ngIf="envelopeLoaded" class="chart bottom-chart">
        <ngx-charts-area-chart
            class="chart-container"
            [legend]="envelopeOptions.legend"
            [xAxis]="envelopeOptions.xAxis"
            [yAxis]="envelopeOptions.yAxis"
            [animations]="envelopeOptions.animations"
            [results]="rightChannelMulti"
            [customColors]="envelopeOptions.colorScheme"
            [tooltipDisabled]="envelopeOptions.tooltipDisabled"
            [curve]="envelopeOptions.curve"
            [yAxisTicks]="envelopeOptions.yAxisTicks"
            [xAxisTicks]="xAxisTicks"
            [autoScale]="false"
        >
        </ngx-charts-area-chart>
    </div>
    <div class="slider-container">
        <p-slider
            class="chart-progress-bar"
            [(ngModel)]="relativePosition"
            [min]="sliderTimeCodes.vtcIn"
            [max]="sliderTimeCodes.vtcOut"
            [step]="0.001"
            (onChange)="onRelativePositionChanged($event.value)"
            (mousedown)="onRelativePositionHandleActivated()"
        ></p-slider>
    </div>
    <div class="zoom-buttons-container">
        <button pRipple class="zoom-button" [disabled]="maxZoomInReached()" (click)="zoom('IN')">
            <img src="assets/icons/zoom-in.svg" alt="zoom-in" aria-label="In Hüllkurve hineinzoomen" />
        </button>
        <button pRipple class="zoom-button" [disabled]="maxZoomOutReached()" (click)="zoom('OUT')">
            <img src="assets/icons/zoom-out.svg" alt="zoom-out" aria-label="In Hüllkurve herauszoomen" />
        </button>
        <button pRipple class="zoom-button" [disabled]="maxZoomOutReached()" (click)="zoom('ORIGINAL')">
            <img src="assets/icons/zoom-normal.svg" alt="original" aria-label="Gesamte Hüllkurve anzeigen" />100%
        </button>
    </div>
</div>
