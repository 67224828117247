import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import { getAdmiraDevices, getAdmiraFolders } from '../../../shopping-cart-state/shopping-cart.actions';
import { selectAdmiraDevices, selectAdmiraFolders } from '../../../shopping-cart-state/shopping-cart.selectors';
import { Subject, takeUntil } from 'rxjs';
import { AdmiraDeviceDto, AdmiraFolderResponse } from '@faro/order-angular-client';
import { TreeNode } from 'primeng/api';
import {
    addFoldersToNode,
    createDummyTreeNode,
    expandUntilTop,
    flattenTree,
    mapDeviceToTreeNode,
} from './tree-node.helper';

@Component({
    selector: 'app-export-destination-dialog',
    templateUrl: './export-destination-dialog.component.html',
    styleUrls: ['./export-destination-dialog.component.scss'],
})
export class ExportDestinationDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    admiraDevicesRoot: TreeNode;
    selectedNode: TreeNode | undefined = undefined;

    private rootPath: string = '';
    private rootName: string = '';
    private _destroyed$ = new Subject<void>();
    private nodeToExpand: TreeNode | undefined = undefined;

    constructor(
        private ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private readonly store: Store,
        private readonly zone: NgZone
    ) {
        this.admiraDevicesRoot = createDummyTreeNode();
        this.store.dispatch(getAdmiraDevices());
    }

    ngOnInit() {
        this.store
            .select(selectAdmiraDevices)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: AdmiraDeviceDto[]) => {
                if (data.length > 0) {
                    this.admiraDevicesRoot.children = data.map(device => mapDeviceToTreeNode(device));
                    this.admiraDevicesRoot.children.sort((a, b) => (a.label! > b.label! ? 1 : -1));
                    if (this.config.data.selectedDevice) {
                        this.selectedNode = mapDeviceToTreeNode(this.config.data.selectedDevice);
                        this.rootPath = this.getRootPath();
                        this.rootName = this.getRootName();
                        this.nodeToExpand = this.selectedNode;
                        this.store.dispatch(
                            getAdmiraFolders({ deviceId: this.selectedNode.data.deviceId, path: this.rootPath })
                        );
                    }
                }
            });

        this.store
            .select(selectAdmiraFolders)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: AdmiraFolderResponse) => {
                if (data && data.deviceId) {
                    const device = this.admiraDevicesRoot.children?.filter(d => d.data.deviceId === data.deviceId)[0];
                    if (device && data.folders?.children) {
                        addFoldersToNode(data.folders.children, device, data.deviceId);
                        if (this.nodeToExpand) {
                            const selectedNodeFromTree = flattenTree(this.admiraDevicesRoot).filter(
                                v => v.label === this.nodeToExpand?.label
                            )[0];
                            selectedNodeFromTree.expanded = false;
                            expandUntilTop(selectedNodeFromTree.parent);
                            this.nodeToExpand = undefined;
                            this.admiraDevicesRoot = { ...this.admiraDevicesRoot };
                        }
                    }
                }
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                const selectedElement = document.getElementsByClassName('p-highlight')[0];
                if (selectedElement) {
                    console.log(selectedElement);
                    selectedElement.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
                }
            }, 100);
        });
    }

    closeDialog() {
        this.ref.close();
    }

    chooseDestination() {
        let closeDialogParams: CloseDialogParams = {
            selectedDevice: this.selectedNode,
            rootPath: this.rootPath,
            rootName: this.rootName,
        };
        this.ref.close(closeDialogParams);
    }

    nodeExpand(node: TreeNode) {
        if (!node.parent && !this.ftpFolderStructureHasBeenLoadedBefore(node.data.deviceId)) {
            this.store.dispatch(getAdmiraFolders({ deviceId: node.data.deviceId, path: node.data.path }));
        }
    }

    nodeSelected(node: TreeNode) {
        node.expanded = true;
        this.selectedNode = node;
        this.rootPath = this.getRootPath();
        this.rootName = this.getRootName();
        if (!node.parent && !this.ftpFolderStructureHasBeenLoadedBefore(node.data.deviceId)) {
            this.store.dispatch(getAdmiraFolders({ deviceId: node.data.deviceId, path: node.data.path }));
        }
    }

    private ftpFolderStructureHasBeenLoadedBefore(deviceId: string) {
        return this.admiraDevicesRoot.children?.filter(v => v.data.deviceId === deviceId)[0].children?.length! > 0;
    }

    private getRootPath(): string {
        return (
            this.admiraDevicesRoot?.children?.filter(device => this.selectedNode?.data.path.includes(device.key))[0]
                .key ?? ''
        );
    }

    private getRootName(): string {
        return (
            this.admiraDevicesRoot?.children?.filter(device => this.selectedNode?.data.path.includes(device.key))[0]
                .label ?? ''
        );
    }
}

interface CloseDialogParams {
    selectedDevice: TreeNode | undefined;
    rootPath: string;
    rootName: string;
}
