import { SearchFieldSelectionEnum, SearchFieldSelectionOption } from '../../search-state/search-options.state';

export interface SearchFieldSelectionOptions {
    label: string;
    value: SearchFieldSelectionEnum;
    tooltip: string;
}

export function searchFiltersToLabel(options: SearchFieldSelectionOption[]): SearchFieldSelectionOptions[] {
    let optionFields: SearchFieldSelectionOptions[] = [];
    options.forEach(f => {
        switch (f.value) {
            case SearchFieldSelectionEnum.TOPIC:
                optionFields.push({
                    label: 'Thema',
                    value: f.value,
                    tooltip: 'Themensuche – beispielsweise alles zum Thema "Kosten im Gesundheitswesen"',
                });
                break;
            case SearchFieldSelectionEnum.IMAGE:
                optionFields.push({
                    label: 'Bild',
                    value: f.value,
                    tooltip: 'Suche nach Videosequenzen – beispielsweise "Schriftzug SBB"',
                });
                break;
            case SearchFieldSelectionEnum.WORD:
                optionFields.push({
                    label: 'Wort',
                    value: f.value,
                    tooltip: 'Suche nach Gesprochenem – beispielsweise Statements zur "Bundesratskandidatur"',
                });
                break;
        }
    });
    return optionFields;
}
