import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '../../shared/services/notification.service';
import { tap } from 'rxjs';
import { notifyOfItemAlreadyExists, notifyOfSuccessfullyAddedItem } from './shopping-cart.actions';

@Injectable()
export class NotificationEffects {
    constructor(private readonly actions$: Actions, private readonly notification: NotificationService) {}

    showAddItemSuccessNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfSuccessfullyAddedItem),
                tap(() => {
                    this.notification.notifyOfAddItemSuccessfully();
                })
            ),
        { dispatch: false }
    );

    showItemAlreadyExistsNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfItemAlreadyExists),
                tap(() => {
                    this.notification.notifyOfItemAlreadyExists();
                })
            ),
        { dispatch: false }
    );
}
