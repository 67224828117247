<ng-container *ngrxLet="loading$; let loading">
    <div class="dialog-content" role="dialog" aria-labelledby="dialogTitle">
        <div class="dialog-header">
            <h2 class="title" id="dialogTitle">{{ config.data.title }}</h2>
            <div>
                <button pRipple class="close-icon" autofocus aria-label="Dialog schliessen" (click)="closeDialog()">
                    <img src="assets/icons/close-icon.svg" alt="Dialog schliessen" />
                </button>
            </div>
        </div>
        <div class="media-item-title">
            <h3 class="item-title">{{ config.data.entryTitle }}</h3>
        </div>
        <ng-container *ngIf="!loading">
            <div class="media-container">
                <ng-container *ngFor="let mediaType of availableMedia">
                    <p-checkbox
                        name="checkbox"
                        class="media-checkbox"
                        [value]="mediaType.id"
                        [label]="mediaTypeLabel(mediaType)"
                        [(ngModel)]="mediaSelection"
                        (onChange)="setTapeDisplayWarningIfApplicable()"
                        data-cy="media-checkbox"
                    ></p-checkbox>
                </ng-container>
                <div class="tape-message" *ngIf="displayTapeMessage">
                    <h3 class="tape-message-title"><img src="assets/icons/warning-icon.svg" alt="" />Hinweis zu Tapes</h3>
                    <div class="tape-message-text">
                        <p>
                            Trägerbestellungen können nur noch im FARO Richclient ausgelöst werden. Die R&A-Desks bieten für
                            solche Bestellungen Unterstützung.
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="loading-entries">
                <p-progressSpinner
                    strokeWidth="2"
                    animationDuration="5s"
                    styleClass="custom-spinner"
                ></p-progressSpinner>
            </div>
        </ng-container>
        <div class="dialog-footer">
            <button class="search-button" (click)="accept()" pRipple data-cy="accept-media-selection-button">
                <img src="assets/icons/check-icon.svg" alt="" />Übernehmen
            </button>
            <button
                class="dialog-secondary-button"
                type="button"
                pButton
                icon="pi pi-times"
                (click)="closeDialog()"
                label="Abbrechen"
            ></button>
        </div>
    </div>
</ng-container>
