<div class="container">
    <img src="assets/images/empty-state.svg" alt="" />
    <div *ngrxLet="searchTerm$; let searchTerm" class="search-text">
        <ng-container *ngrxLet="areProfileFiltersSet$; let areProfileFiltersSet">
            <div class="search-text-title no-results" data-cy="no-results-warning">
                <div><img class="search-warning-icon" src="/assets/icons/warning-icon.svg" alt="" /></div>
                <div *ngIf="areProfileFiltersSet">
                    Keine Resultate <span *ngIf="searchTerm">für "{{ searchTerm }}"</span>
                </div>
                <div *ngIf="!areProfileFiltersSet" class="no-hits-filter">Suchresultate eingeschränkt</div>
            </div>
            <div *ngIf="!areProfileFiltersSet" class="help-text">
                Die Trefferliste wird durch deine Einstellungen eingeschränkt. Es werden möglicherweise nicht alle
                Resultate angezeigt.
                <a class="info-link" (click)="openProfileFiltersDialog()">Einstellungen anpassen</a>
            </div>
        </ng-container>
    </div>
</div>
