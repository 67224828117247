import { createAction, props } from '@ngrx/store';
import {
    FilterOperator,
    HitlistSortingAttribute,
    HitlistSortingOrder,
    SearchFieldSelectionOption,
} from './search-options.state';
import { ValueFilter } from '@faro/searchapi-angular-client';
import { StorageCategoryFilters } from '../profile-service/search-profile.interfaces';
import { StorageCategory } from '@faro/profile-angular-client';

export enum SearchOptionsActions {
    UPDATE_SEARCH_INPUT = '[SearchOptions] Update Search Input',
    UPDATE_FILTER = '[SearchOption] Update Filter',
    RESET_ALL_FILTERS = '[SearchOption] Reset All Filters',
    TRIGGER_RESET_FACET_SEARCH = '[SearchOption] Reset facet search from header',
    UPDATE_FACET_SEARCH_INPUT = '[SearchOption] Update Facet Search Input',
    UPDATE_DATE_FACET_RANGE = '[SearchOption] Update Date Facet Range',
    RESET_FILTERS_FOR_FACET = '[SearchOption] Reset Filters For Facet',

    SET_HITLIST_SORTING_ORDER = '[SearchOption] Set Hitlist Sorting Order',
    SET_HITLIST_SORTING_ATTRIBUTE = '[SearchOption] Set Hitlist Sorting Attribute',
    SET_HITLIST_SORTING_ORDER_AND_ATTRIBUTE = '[SearchOption] Set Hitlist Sorting Order And Attribute',
    CALL_SUCCESS = '[SearchOption] Call Success',
    GET_SORTING_DIRECTION = '[SearchOption] Get Sorting Direction',
    SET_PROFILE_FILTER = '[SearchOption] Set Profile Filter',
    SET_SEARCH_FIELD_SELECTION = '[SearchOption] Set search field selection',

    GET_AVAILABLE_STORAGE_CATEGORIES = '[SearchOption] Get available Storage Categories',
    SET_AVAILABLE_STORAGE_CATEGORIES = '[SearchOption] Set available Storage Categories',
    SET_ALL_EXCLUDED_STORAGE_CATEGORIES = '[SearchOption] Set excluded Storage Categories',

    SET_FILTER_OPERATOR = '[SearchOption] Set filter operator',
    RESET_SEARCH = '[SearchOption] reset all search filters',
    DO_NOT_SHOW_SEARCH_INFO_DIALOG = '[SearchOption] Do not show new search info dialog',
}

export const updateSearchInput = createAction(
    SearchOptionsActions.UPDATE_SEARCH_INPUT,
    props<{ query: string; triggerSearch: boolean }>()
);
export const updateFacetSearchInput = createAction(
    SearchOptionsActions.UPDATE_FACET_SEARCH_INPUT,
    props<{ facetField: string; query: string }>()
);

export const updateFilter = createAction(SearchOptionsActions.UPDATE_FILTER, props<{ filter: ValueFilter }>());

export const triggerResetFacetSearch = createAction(SearchOptionsActions.TRIGGER_RESET_FACET_SEARCH);
export const resetAllFilters = createAction(SearchOptionsActions.RESET_ALL_FILTERS);
export const resetFiltersForFacet = createAction(
    SearchOptionsActions.RESET_FILTERS_FOR_FACET,
    props<{ field: string }>()
);
export const updateDateFacetRange = createAction(
    SearchOptionsActions.UPDATE_DATE_FACET_RANGE,
    props<{ from: Date | null; to: Date | null }>()
);

export const setHitlistSortingOrder = createAction(
    SearchOptionsActions.SET_HITLIST_SORTING_ORDER,
    props<{ sortingOrder: HitlistSortingOrder }>()
);
export const setHitlistSortingAttribute = createAction(
    SearchOptionsActions.SET_HITLIST_SORTING_ATTRIBUTE,
    props<{ sortingAttribute: HitlistSortingAttribute }>()
);

export const setHitlistSortingOrderAndAttribute = createAction(
    SearchOptionsActions.SET_HITLIST_SORTING_ORDER_AND_ATTRIBUTE,
    props<{ sortingOrder: HitlistSortingOrder; sortingAttribute: HitlistSortingAttribute }>()
);

export const callSuccessAction = createAction(SearchOptionsActions.CALL_SUCCESS);

export const getSortingDirection = createAction(SearchOptionsActions.GET_SORTING_DIRECTION);

export const setProfileFilters = createAction(SearchOptionsActions.SET_PROFILE_FILTER, props<{ filters: string[] }>());

export const setSearchFieldSelection = createAction(
    SearchOptionsActions.SET_SEARCH_FIELD_SELECTION,
    props<{ searchFieldSelection: SearchFieldSelectionOption }>()
);

export const setAllExcludedStorageCategories = createAction(
    SearchOptionsActions.SET_ALL_EXCLUDED_STORAGE_CATEGORIES,
    props<{ excluded: string[] }>()
);

export const getAvailableStorageCategories = createAction(
    SearchOptionsActions.GET_AVAILABLE_STORAGE_CATEGORIES,
    props<{ filters: StorageCategoryFilters[] }>()
);

export const setAvailableStorageCategories = createAction(
    SearchOptionsActions.SET_AVAILABLE_STORAGE_CATEGORIES,
    props<{ storageCategories: StorageCategory[] }>()
);

export const setFilterOperator = createAction(
    SearchOptionsActions.SET_FILTER_OPERATOR,
    props<{ field: string; operator: FilterOperator }>()
);

export const resetSearch = createAction(SearchOptionsActions.RESET_SEARCH);

export const setSearchInfoDialogPreferences = createAction(
    SearchOptionsActions.DO_NOT_SHOW_SEARCH_INFO_DIALOG,
    props<{ doNotShowAgain: boolean }>()
);
