<div class="dialog-content export-dialog-content" role="dialog" aria-labelledby="dialogTitle">
    <div class="dialog-header">
        <h2 class="title" id="dialogTitle">{{ config.data.title }}</h2>
        <div>
            <button pRipple class="close-icon" autofocus aria-label="Dialog schliessen" (click)="closeDialog()">
                <img src="assets/icons/close-icon.svg" alt="Dialog schliessen" />
            </button>
        </div>
    </div>
    <div class="export-description"><p>Bitte wählen Sie einen Pfad für ihr Exportziel:</p></div>
    <div class="export-title-container">
        <h3 class="export-title">Dateiablage-Baum</h3>
    </div>
    <div class="tree-container" *ngIf="admiraDevicesRoot && admiraDevicesRoot.children">
        <p-tree
            [value]="admiraDevicesRoot.children"
            [styleClass]="'my-tree'"
            selectionMode="single"
            [(selection)]="selectedNode"
            (onNodeSelect)="nodeSelected($event.node)"
            (onNodeExpand)="nodeExpand($event.node)"
        ></p-tree>
    </div>
    <div class="dialog-footer">
        <button [disabled]="!selectedNode" class="search-button" type="button" (click)="chooseDestination()" pRipple>
            <img src="assets/icons/check-icon.svg" alt="" />Übernehmen
        </button>
        <button
            class="dialog-secondary-button"
            type="button"
            pButton
            icon="pi pi-times"
            (click)="closeDialog()"
            label="Abbrechen"
        ></button>
    </div>
</div>
