import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Duration, fromMilliseconds } from '../../shared/duration';
import { RestrictionDto } from '../shared/restriction.model';
import { parseTimeSpan } from '../../shared/timespan';
import { relativeLengthInTimeRange, relativePositionInTimeRange } from '../../shared/time-range.helpers';

@Component({
    selector: 'app-player-restriction-marker',
    templateUrl: './player-restriction-marker.component.html',
    styleUrls: ['./player-restriction-marker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerRestrictionMarkerComponent implements OnChanges {
    @Input()
    vtcIn: Duration = fromMilliseconds(0);

    @Input()
    vtcOut: Duration = fromMilliseconds(0);

    @Input()
    restrictions: RestrictionDto[] = [];

    playerRestrictions: { relativePosLeft: number; relativeWidth: number }[] = [];

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.restrictions.length > 0) {
            this.playerRestrictions = this.restrictions.map((v: RestrictionDto) => {
                const entryVtcIn = parseTimeSpan(v.vtcIn);
                const entryVtcOut = parseTimeSpan(v.vtcOut);
                return {
                    relativePosLeft: relativePositionInTimeRange(entryVtcIn, this.vtcIn, this.vtcOut),
                    relativeWidth: relativeLengthInTimeRange(entryVtcIn, entryVtcOut, this.vtcIn, this.vtcOut),
                };
            });
        }
    }
}
