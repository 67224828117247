import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
dayjs.extend(durationPlugin);

export interface Duration {
    add(d: Duration): Duration;
    subtract(d: Duration): Duration;

    /** Gets the total duration in milliseconds */
    asMilliseconds(): number;

    /** Gets the total duration in seconds */
    asSeconds(): number;

    /** Gets the hour portion of the duration */
    getHours(): number;

    /** Gets the minutes portion of the duration */
    getMinutes(): number;

    /** Gets the seconds portion of the duration */
    getSeconds(): number;

    /** Gets the milliseconds portion of the duration */
    getMilliseconds(): number;

    /** See https://day.js.org/docs/en/durations/format */
    format(fmt: string): string;

    /** To make it distinct from durationPlugin.Duration */
    tag: any;
}

export function fromMilliseconds(ms: number): Duration {
    const d = dayjs.duration(ms);
    return new Wrapper(d);
}

export function fromSeconds(sec: number): Duration {
    const d = dayjs.duration(1000 * sec);
    return new Wrapper(d);
}

export function fromObject(args: {
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
}): Duration {
    const fullArgs = Object.assign(
        {
            years: 0,
            months: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
        },
        args
    );
    const d = dayjs.duration(fullArgs);
    return new Wrapper(d);
}

class Wrapper implements Duration {
    constructor(private _d: durationPlugin.Duration) {}

    add(d: Duration): Duration {
        const other = d as Wrapper;
        const result = this._d.add(other._d);
        return new Wrapper(result);
    }

    asMilliseconds(): number {
        return this._d.asMilliseconds();
    }

    asSeconds(): number {
        return this._d.asMilliseconds() / 1000;
    }

    getMilliseconds(): number {
        return this._d.milliseconds();
    }

    getHours(): number {
        return this._d.hours();
    }

    getMinutes(): number {
        return this._d.minutes();
    }

    getSeconds(): number {
        return this._d.seconds();
    }

    format(fmt: string): string {
        const d0 = this._d;
        const d1 = dayjs.duration({
            years: d0.years(),
            months: d0.months(),
            days: d0.days(),
            hours: d0.hours(),
            minutes: d0.minutes(),
            seconds: d0.seconds(),
            milliseconds: Math.floor(d0.milliseconds()),
        });
        return d1.format(fmt);
    }

    subtract(d: Duration): Duration {
        const other = d as Wrapper;
        const result = this._d.subtract(other._d);
        return new Wrapper(result);
    }

    tag = 'Wrapper';
}
