import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';

export interface ITreeNode {
    id: string;
    label: string;
    children: ITreeNode[];
}

@Component({
    selector: 'faro-tree-select',
    templateUrl: './tree-select.component.html',
    styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent implements OnChanges {
    @Input()
    header?: string;

    @Input()
    isLoading: boolean = false;

    @Input()
    excludedNodes: string[] = [];

    @Input()
    treeEntries: ITreeNode[] = [];

    @Output()
    excludedNodesChanged = new EventEmitter<string[]>();

    showSelectAll: boolean = true;
    summarySelectedState: any = null;

    filteredList: any[] = [];
    selectedNodes: TreeNodeModel[] = [];

    _treeModel: TreeNodeModel = createDummyTreeModel();

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if ('treeEntries' in changes) {
            this.populateTreeModel();
        }
        if ('excludedNodes' in changes || 'treeEntries' in changes) {
            this.populateSelectedNodes();
        }
    }

    private populateTreeModel() {
        // build treeModel from entries
        this._treeModel = createModel(this.treeEntries);
        this.filteredList = this._treeModel.children;

        // calculate number of nodes
        visitTreePostorder(this._treeModel, (node: TreeNodeModel) => {
            node.count = 1 + node.children.reduce((acc, node) => acc + node.count, 0);
        });
    }

    private populateSelectedNodes() {
        // 1. calculate node.excluded (top-down -> Preorder)
        visitTreePreorder(this._treeModel, (node: TreeNodeModel) => {
            const excluded = this.excludedNodes.includes(node.key);
            const parentExcluded = node.parent ? (node.parent as TreeNodeModel).excluded : false;
            node.excluded = excluded || parentExcluded;
            return true;
        });

        // 2. calculate node.selected (bottom-up -> Postorder)
        visitTreePostorder(this._treeModel, (node: TreeNodeModel) => {
            if (node.key !== 'dummy') {
                const excluded = node.excluded;
                const allChildrenSelected = node.children?.every(child => child.selected);
                node.selected = !excluded && allChildrenSelected;
            }
        });

        // 3. calculate node.partiallySelected (bottom-up)
        visitTreePostorder(this._treeModel, (node: TreeNodeModel) => {
            if (node.key !== 'dummy') {
                const excluded = node.excluded;
                const anyChildPartialSelectedOrSelected = node.children.some(
                    child => child.partialSelected || child.selected
                );
                node.partialSelected = !excluded && !node.selected && anyChildPartialSelectedOrSelected;
            }
        });

        // 4. populateSelectedNode if node.selected && node.key !== 'dummy'
        this.selectedNodes = [];
        visitTreePostorder(this._treeModel, (node: TreeNodeModel) => {
            if (node.selected && node.key !== 'dummy') {
                this.selectedNodes.push(node);
            }
        });
        this.updateSummarySelectedState();
    }

    // handling even of checkbox on the dialog-panel component
    onCheckBoxChanged(event: any): void {
        if (!event.value) {
            this.deSelectAllEntries();
            this.summarySelectedState = null;
        } else {
            this.selectAllEntries();
        }
    }

    // select all entries triggered by checkbox on the dialog-panel component
    private selectAllEntries(): void {
        this.excludedNodes = [];
        this.populateSelectedNodes();
        this.updateSummarySelectedState();
        this.excludedNodesChanged.emit(this.excludedNodes);
    }

    // deselect all entries triggered by checkbox on the dialog-panel component
    private deSelectAllEntries(): void {
        this.selectedNodes = [];
        this.updateSummarySelectedState();
        this.resetExcludedSelection();
        this.excludedNodesChanged.emit(this.excludedNodes);
    }

    anyNodeChange(): void {
        this.resetExcludedSelection();
        this.updateSummarySelectedState();
        this.excludedNodesChanged.emit(this.excludedNodes);
    }

    // Set correct state of checkbox on the dialog-panel component
    private updateSummarySelectedState(): void {
        if (this.selectedNodes.length === this._treeModel.count - 1) {
            this.summarySelectedState = true;
        } else if (this.selectedNodes.length > 0) {
            this.summarySelectedState = false;
        } else {
            this.summarySelectedState = null;
        }
    }

    // sets the correct values of the excluded selection
    private resetExcludedSelection() {
        this.excludedNodes = [];
        visitTreePostorder(this._treeModel, (node: TreeNodeModel) => {
            const isInSelectedNodesList = this.selectedNodes.includes(node);
            node.selected = isInSelectedNodesList;
            const anyChildPartialSelectedOrSelected = node.children.some(
                child => child.partialSelected || child.selected
            );
            node.partialSelected = !node.selected && anyChildPartialSelectedOrSelected;
            node.excluded = !node.selected && !node.partialSelected;
        });

        visitTreePreorder(this._treeModel, (node: TreeNodeModel) => {
            if (node.key !== 'dummy' && node.excluded) {
                this.excludedNodes.push(node.key);
                return !node.excluded; // only visit children (and possibly add to excludedSelection) if this node is not excluded
            } else {
                return true;
            }
        });
    }
}

interface TreeNodeModel extends TreeNode {
    key: string; // Overwritten for stricter typing
    count: number;
    excluded: boolean;
    selected: boolean;
    children: TreeNodeModel[]; // Overwritten for stricter typing
    label: string; // Overwritten for stricter typing
    partialSelected: boolean;
}

/**
 * Visits the tree in postorder
 * @param root
 */
function visitTreePostorder(root: TreeNodeModel, visitor: (node: any) => void) {
    root.children?.forEach((child: TreeNodeModel) => visitTreePostorder(child, visitor));
    visitor(root);
}

/**
 * Visits the tree in preorder
 * @param root
 */
function visitTreePreorder(root: TreeNodeModel, visitor: (node: any) => boolean) {
    const doContinue = visitor(root);
    if (doContinue) {
        root.children?.forEach((child: TreeNodeModel) => visitTreePreorder(child, visitor));
    }
}

function createModel(entries: ITreeNode[]): TreeNodeModel {
    const root = createDummyTreeModel();
    root.children = entries.map(v => nodeToModel(v, root));
    return root;
}

function createDummyTreeModel(): TreeNodeModel {
    return {
        key: 'dummy',
        label: 'Dummy',
        children: [],
        count: 0,
        excluded: false,
        selected: false,
        partialSelected: false,
    };
}
function nodeToModel(node: ITreeNode, parent: TreeNodeModel): TreeNodeModel {
    const nodeModel: TreeNodeModel = {
        key: node.id,
        label: node.label,
        count: node.children.length,
        excluded: false,
        children: [],
        selected: false,
        partialSelected: false,
        parent: parent,
    };
    nodeModel.children = node.children.map(child => nodeToModel(child, nodeModel));
    return nodeModel;
}
