<div
    class="input-field"
    #inputField
    [class.invalid]="dateRangeInputInvalid"
    [class.pending]="!dateRangeInputInvalid && dateRangeInputPending"
>
    <input
        [attr.aria-labelledby]="ariaLabel"
        name="displayDate"
        [(ngModel)]="dateRangeInput"
        pInputText
        [placeholder]="placeholderText"
        (focus)="onFocus()"
        (keyup)="onKeyUp()"
        oninput="this.value = this.value.replace(/[^0-9.-\s]/g, '')"
    />
    <ng-container *ngIf="!dateRangeInput">
        <button (click)="toggleCalendar($event, inputField)">
            <img src="assets/icons/input-calendar.svg" alt="Kalender öffnen" />
        </button>
    </ng-container>
    <ng-container *ngIf="dateRangeInput">
        <button (click)="clearDateRange()">
            <i class="pi pi-times" aria-label="Datumseingabe löschen"></i>
        </button>
    </ng-container>
</div>
<p-overlayPanel
    #calendarPanel
    [dismissable]="true"
    [showCloseIcon]="showClose"
    (onShow)="showCalendar()"
    (onHide)="calendarHidden = true"
    styleClass="date-range-input-overlay-panel"
>
    <ng-template pTemplate>
        <p-calendar [(ngModel)]="calendarValue" [inline]="true" selectionMode="range" [maxDate]="maximumDate">
        </p-calendar>
    </ng-template>
</p-overlayPanel>
