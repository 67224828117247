import { DateFacetPresetComponentModel } from '@faro/ngx-faro-ui';

export const datePresets: DateFacetPresetComponentModel[] = [
    {
        label: 'Gestern',
        preset: () => {
            return { from: todayAdd(-1), to: todayAdd(-1) };
        },
    },
    {
        label: 'Letzte 2 Jahre',
        preset: () => {
            return { from: todayAdd(-730), to: today() };
        },
    },
    {
        label: 'Letzte 7 Tage',
        preset: () => {
            return { from: todayAdd(-7), to: today() };
        },
    },
    {
        label: 'Letzte 5 Jahre',
        preset: () => {
            return { from: todayAdd(-1826), to: today() };
        },
    },
    {
        label: 'Letzte 12 Monate',
        preset: () => {
            return { from: todayAdd(-365), to: today() };
        },
    },
    {
        label: 'Letzte 10 Jahre',
        preset: () => {
            return { from: todayAdd(-3652), to: today() };
        },
    },
];

function today(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}

function todayAdd(daysToAdd: number): Date {
    const d = today();
    d.setDate(d.getDate() + daysToAdd);
    return d;
}
