<ng-container *ngrxLet="entryList$; let entryList">
    <div class="shopping-cart-hitlist-header">
        <h2 class="shopping-cart-hitlist-header-title" *ngIf="entryList && entryList.length > 0">
            {{ entryList.length }} Treffer
        </h2>
        <app-shopping-cart-hitlist-controls
            *ngIf="entryList && entryList.length > 0"
        ></app-shopping-cart-hitlist-controls>
    </div>

    <div class="shopping-cart-entries-container" *ngIf="entryList && entryList.length > 0 && !loading">
        <div>
            <p-table
                [value]="entryList"
                [columns]="cols"
                responsiveLayout="scroll"
                scrollHeight="var(--shopping-cart-table-scroll-height)"
                [scrollable]="true"
                [autoLayout]="true"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of cols" class="{{ col.scssHeaderClass }}">
                            {{ col.header }}
                        </th>
                        <th class="action-header"><span class="h-offscreen">Warenkorb Aktionen</span></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entry let-i="rowIndex">
                    <tr
                        [appHighlightRow]="i"
                        (highlightedRowChanged)="highlightedRowIndex = $event"
                        class="table-row"
                        [ngClass]="{'bold-row' : entry['status'] === SearchResultCartStatusDto.Active}"
                        (click)="entry['status'] === SearchResultCartStatusDto.Active ? openActiveCart() :openShoppingCartPreview(entry['id'])"
                    >
                        <td *ngFor="let col of cols" class="{{ col.scssColumnClass }}">
                            <ng-container *ngIf="col.type === 'accessibility-link'">
                                <a
                                    (click)="$event.preventDefault()"
                                    class="accessibility-link"
                                    [href]="'shopping-cart/preview/' + entry['id']"
                                    >{{ entry[col.field] }}</a
                                >
                            </ng-container>
                            <ng-container *ngIf="col.type === 'text'">
                                {{ entry[col.field] }}
                            </ng-container>
                            <ng-container *ngIf="col.type === 'date'">
                                {{ entry[col.field] | date: "dd.MM.yyyy" }}
                            </ng-container>
                            <ng-container *ngIf="col.type === 'cart-status'">
                                {{ getOrderStatusDisplayLabel(entry[col.field]) }}
                            </ng-container>
                        </td>
                        <td class="action-icon-td" (click)="showActions($event, i)">
                            <button
                                data-no-search
                                pRipple
                                type="button"
                                class="p-table-icon-button"
                                *ngIf="highlightedRowIndex !== i"
                                (click)="showActions($event, i)"
                            >
                                <img src="assets/icons/ellipsis.svg" alt="Optionen" />
                            </button>
                        </td>
                        <div class="p-table-action-button-container" *ngIf="highlightedRowIndex === i">
                            <button
                                data-no-search
                                title="Warenkorb aktivieren"
                                pRipple
                                type="button"
                                class="p-table-action-button"
                                *ngIf="entry['canActivate']"
                                (click)="activateShoppingCart($event, entry)"
                            >
                                <img src="assets/icons/arrow-top-right-white.svg" alt="Warenkorb aktivieren" />
                            </button>
                            <button
                                data-no-search
                                title="Warenkorb duplizieren und aktivieren"
                                pRipple
                                type="button"
                                class="p-table-action-button"
                                [ngClass]="{ 'single-button': !entry['canActivate'] }"
                                (click)="duplicateShoppingCart($event, entry)"
                            >
                                <img src="assets/icons/duplicate.svg" alt="Warenkorb duplizieren und aktivieren" />
                            </button>
                        </div>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <ng-container *ngIf="(!entryList || entryList.length === 0) && !loading">
        <app-empty-content [infoText]="'keine Warenkörbe gefunden'"></app-empty-content>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="loading-container">
            <p-progressSpinner strokeWidth="2" animationDuration="5s" styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </ng-container>
</ng-container>
