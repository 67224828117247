import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { inputTextFields, ShoppingCartSearchMaskInputModel } from './shopping-cart-search-mask-input.model';
import { ShoppingCartOrderStatus } from '../../shopping-cart-state/shopping-cart.state';
import { Store } from '@ngrx/store';
import {
    selectSearchRequested,
    selectShoppingCartSearchDateRange,
    selectShoppingCartSearchOptions,
} from '../../shopping-cart-state/shopping-cart.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
    resetAllShoppingCartSearchOptions,
    updateShoppingCartSearchOptionsInputFields,
    updateDateRange,
    updateSelectedOrderStatus,
} from '../../shopping-cart-state/shopping-cart.actions';
import { DateOutputComponentModel } from '@faro/ngx-faro-ui';
import { DateRange } from '../../../search/search-state/search-options.state';
import { ShoppingCartStatus } from '@faro/order-angular-client';

@Component({
    selector: 'app-shopping-cart-search-mask',
    templateUrl: './shopping-cart-search-mask.component.html',
    styleUrls: ['./shopping-cart-search-mask.component.scss'],
})
export class ShoppingCartSearchMaskComponent implements OnInit, OnDestroy {
    @Input()
    searchParamsSet: boolean = false;

    searchMaskInputFields: ShoppingCartSearchMaskInputModel[] = inputTextFields;
    searchMaskOrderStatusDropDown = searchMaskOrderStatusDropDownValues;
    selectedOrderStatus: ShoppingCartStatus | null = searchMaskOrderStatusDropDownValues[0].value;
    selectedDateRange$: Observable<DateRange | undefined>;

    private _destroyed$ = new Subject<void>();

    constructor(private store: Store) {
        this.selectedDateRange$ = this.store.select(selectShoppingCartSearchDateRange);
    }

    ngOnInit() {
        this.store
            .select(selectShoppingCartSearchOptions)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: any) => {
                this.searchMaskInputFields.map((v: ShoppingCartSearchMaskInputModel) => {
                    v.value = data[v.field];
                });
                this.selectedOrderStatus = data['orderStatus']
                    ? data['orderStatus']
                    : searchMaskOrderStatusDropDownValues[0].value;
            });

        this.store
            .select(selectSearchRequested)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                if (data) {
                    this.resetShoppingCartSearchOptionsInputFields();
                }
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    clearInput(label: string) {
        this.searchMaskInputFields.map(v => {
            if (v.label === label) {
                v.value = '';
            }
            this.resetShoppingCartSearchOptionsInputFields();
        });
    }

    clearAll() {
        this.store.dispatch(resetAllShoppingCartSearchOptions());
    }

    resetShoppingCartSearchOptionsInputFields() {
        const updatedSearchOptions: UpdateInputFields = {
            description: this.getValueByField('description'),
            number: this.getValueByField('number'),
            pid: this.getValueByField('pid'),
            firstName: this.getValueByField('firstName'),
            lastName: this.getValueByField('lastName'),
        };
        this.store.dispatch(
            updateShoppingCartSearchOptionsInputFields({ shoppingCartSearchOptions: updatedSearchOptions })
        );
    }

    orderStatusSelectionChanged() {
        this.store.dispatch(updateSelectedOrderStatus({ orderStatus: this.selectedOrderStatus }));
    }

    onDateRangeChanged(newDate: DateOutputComponentModel | null) {
        this.store.dispatch(updateDateRange({ from: newDate?.range?.from ?? null, to: newDate?.range?.to ?? null }));
    }

    private getValueByField(field: string): string {
        const value = this.searchMaskInputFields.filter(v => v.field === field);
        return value[0] ? value[0].value.toString() : '';
    }
}

const searchMaskOrderStatusDropDownValues: OrderStatusDropDownModel[] = [
    {
        label: 'Status auswählen',
        value: null,
    },
    {
        label: ShoppingCartOrderStatus.NOT_ORDERED,
        value: ShoppingCartStatus.UnderConstruction,
    },
    { label: ShoppingCartOrderStatus.ORDERED, value: ShoppingCartStatus.Dispatching },
    { label: ShoppingCartOrderStatus.DELIVERED, value: ShoppingCartStatus.Delivered },
    { label: ShoppingCartOrderStatus.CLOSED, value: ShoppingCartStatus.Closed },
];

interface OrderStatusDropDownModel {
    label: ShoppingCartOrderStatus | string;
    value: ShoppingCartStatus | null;
}

interface UpdateInputFields {
    description: string;
    number: string;
    pid: string;
    firstName: string;
    lastName: string;
}
