import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'blobToObjectURL',
})
export class BlobToObjectUrlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(blob: Blob | null): SafeUrl | null {
        return blob
            ? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
            : null;
    }
}
