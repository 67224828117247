import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { EnvelopeDto, EnvelopeSampleDto } from '@faro/metadata-angular-client';
import { Store } from '@ngrx/store';
import { selectEnvelopeData } from '../../details/details-state/details.selectors';
import { curveBasis } from 'd3-shape';
import { Duration, fromMilliseconds } from '../../shared/duration';
import { TimeService } from '../../details/shared/services/time.service';
import { ResizeObserver } from 'resize-observer';
import { getEnvelopeData } from '../../details/details-state/details.actions';
import { parseTimeSpan } from '../../shared/timespan';
import { ResizeObserverEntry } from 'resize-observer/lib/ResizeObserverEntry';

export interface ChartTimeCodes {
    vtcIn: number;
    vtcOut: number;
}

@Component({
    selector: 'app-envelope',
    templateUrl: './envelope.component.html',
    styleUrls: ['./envelope.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvelopeComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input()
    itemId: string | undefined;

    @Input()
    programId: string = '';

    @Input()
    mediaCutVtcIn: Duration = fromMilliseconds(0);

    @Input()
    mediaCutVtcOut: Duration = fromMilliseconds(0);

    @Input()
    mediaCutId: string = '';

    @Input() markerVtcIn: Duration | undefined;
    @Input() markerVtcOut: Duration | undefined;

    /**
     * Envelope
     * */
    envelopeOptions = EnvelopeOptions;
    envelopeLoaded = false;
    envelopeData: EnvelopeDto | undefined;
    xAxisTicks: string[] = [];
    rightChannelMulti: ChartData[] = [];
    leftChannelMulti: ChartData[] = [];
    zoomLevel = 1;
    relativePosition: number | undefined = 0;
    sliderTimeCodes: ChartTimeCodes = {
        vtcIn: 0,
        vtcOut: 0,
    };

    private vtcIn: Duration = fromMilliseconds(0);
    private vtcOut: Duration = fromMilliseconds(0);
    private _destroyed$ = new Subject<void>();
    private _resizeObserver: ResizeObserver;

    @ViewChild('chart')
    chart: ElementRef | undefined;

    constructor(private store: Store, private timeService: TimeService, private cdr: ChangeDetectorRef) {
        this._resizeObserver = new ResizeObserver((_: ResizeObserverEntry[]) => {
            this.setChartData();
        });
    }

    ngOnInit() {
        this.store
            .select(selectEnvelopeData)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                if (data) {
                    this.envelopeData = data;
                    this.xAxisTicks = this.envelopeData.rightChannel.map((v: EnvelopeSampleDto) => {
                        return v.vtc;
                    });
                    this.createCorrectDataModel(this.envelopeData.rightChannel, this.envelopeData.leftChannel);
                    this.cdr.detectChanges();
                }
            });
    }

    ngOnChanges(_: SimpleChanges) {
        if (this.mediaCutId) {
            this.vtcIn = this.mediaCutVtcIn;
            this.vtcOut = this.mediaCutVtcOut;
            this.sliderTimeCodes.vtcIn = calcVtcForSlider(this.vtcIn);
            this.sliderTimeCodes.vtcOut = calcVtcForSlider(this.vtcOut);
            this.dispatchEnvelopeAction();
            this.updateRelativePosition();
        }
    }

    ngAfterViewInit() {
        this._resizeObserver.observe(this.chart?.nativeElement);
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._resizeObserver.disconnect();
    }

    updateRelativePosition() {
        this.timeService.currentVtc$.pipe(takeUntil(this._destroyed$)).subscribe((data: Duration | null) => {
            this.relativePosition = calcVtcForSlider(data);

            if (this.relativePosition && this.relativePosition > this.sliderTimeCodes.vtcOut) {
                this.getNewImageAfterSliderReachedEnd();
            }
            if (this.relativePosition && this.relativePosition < this.sliderTimeCodes.vtcIn) {
                this.getPreviousImage();
            }
            this.cdr.detectChanges();
        });
    }

    setChartData() {
        this.rightChannelMulti = [...this.rightChannelMulti];
        this.leftChannelMulti = [...this.leftChannelMulti];
        this.cdr.detectChanges();
    }

    getNewImageAfterSliderReachedEnd() {
        if (this.sliderTimeCodes.vtcOut !== calcVtcForSlider(this.vtcOut)) {
            const diff =
                this.sliderTimeCodes.vtcOut - this.sliderTimeCodes.vtcIn >= 1
                    ? this.sliderTimeCodes.vtcOut - this.sliderTimeCodes.vtcIn
                    : 1;
            this.sliderTimeCodes.vtcIn = this.sliderTimeCodes.vtcOut;
            this.sliderTimeCodes.vtcOut =
                this.sliderTimeCodes.vtcIn + diff <= calcVtcForSlider(this.vtcOut)
                    ? this.sliderTimeCodes.vtcIn + diff
                    : calcVtcForSlider(this.vtcOut);
            this.dispatchEnvelopeAction();
            this.getCurrentVtc();
        }
    }

    getPreviousImage() {
        if (this.sliderTimeCodes.vtcIn > 0) {
            const diff =
                this.sliderTimeCodes.vtcOut - this.sliderTimeCodes.vtcIn >= 1
                    ? this.sliderTimeCodes.vtcOut - this.sliderTimeCodes.vtcIn
                    : 1;
            this.sliderTimeCodes.vtcOut = this.sliderTimeCodes.vtcIn;
            this.sliderTimeCodes.vtcIn =
                this.sliderTimeCodes.vtcOut - diff >= 0 ? this.sliderTimeCodes.vtcOut - diff : 0;
            this.dispatchEnvelopeAction();
            this.getCurrentVtc();
        }
    }

    private getCurrentVtc() {
        this.relativePosition = calcVtcForSlider(this.timeService.getCurrentVtc());
        this.cdr.detectChanges();
    }

    createCorrectDataModel(rightChannelData: EnvelopeSampleDto[], leftChannelData: EnvelopeSampleDto[]) {
        let rightData = this.splitDataAtMarker(this.markerVtcIn, this.markerVtcOut, rightChannelData);
        let leftData = this.splitDataAtMarker(this.markerVtcIn, this.markerVtcOut, leftChannelData);

        this.rightChannelMulti = [
            ...this.getChartDataTuple('peak', 'rms', rightData[0]),
            ...this.getChartDataTuple('cutPeak', 'cutRms', rightData[1]),
            ...this.getChartDataTuple('peak', 'rms', rightData[2]),
        ];

        this.leftChannelMulti = [
            ...this.getChartDataTuple('peak', 'rms', leftData[0]),
            ...this.getChartDataTuple('cutPeak', 'cutRms', leftData[1]),
            ...this.getChartDataTuple('peak', 'rms', leftData[2]),
        ];
        this.envelopeLoaded = true;
    }

    getChartDataTuple(firstChartName: string, secondChartName: string, data: EnvelopeSampleDto[]): ChartData[] {
        return [
            this.getChartData(firstChartName, generatePeakSeries(data)),
            this.getChartData(secondChartName, generateRMSSeries(data)),
        ];
    }

    getChartData(name: string, series: SeriesData[]): ChartData {
        return {
            name: name,
            series: series,
        };
    }

    splitDataAtMarker(
        markerIn: Duration | undefined,
        markerOut: Duration | undefined,
        data: EnvelopeSampleDto[]
    ): EnvelopeSampleDto[][] {
        let highlightStartIndex = 0;
        let highlightEndIndex = 0;
        if (markerIn && markerOut && markerIn.asMilliseconds() < markerOut.asMilliseconds()) {
            highlightStartIndex = data.findIndex(
                x => parseTimeSpan(x.vtc).asMilliseconds() > markerIn.asMilliseconds()
            );
            highlightStartIndex = highlightStartIndex < 0 ? data.length - 1 : highlightStartIndex;
            highlightEndIndex = data.findIndex(
                x => parseTimeSpan(x.vtc).asMilliseconds() > markerOut.asMilliseconds(),
                highlightStartIndex
            );
            highlightEndIndex = highlightEndIndex < 0 ? data.length - 1 : highlightEndIndex;
        }
        return [
            data.slice(0, highlightStartIndex + 1),
            data.slice(highlightStartIndex, highlightEndIndex + 1),
            data.slice(highlightEndIndex),
        ];
    }

    onRelativePositionChanged(newSliderValue: number | undefined) {
        if (newSliderValue) {
            this.timeService.jumpToVtc(calcVtcForJumpToVtc(newSliderValue));
        }
    }

    onRelativePositionHandleActivated() {
        this.timeService.pauseDataLoading(true);
    }

    @HostListener('window:mouseup')
    onRelativePositionHandleDeactivated(): void {
        this.timeService.pauseDataLoading(false);
    }

    zoom(type: string) {
        const currentTime = calcVtcForSlider(this.timeService.getCurrentVtc());
        switch (type) {
            case 'IN':
                if (!this.maxZoomInReached()) {
                    this.zoomLevel++;
                    const diff = this.sliderTimeCodes.vtcOut - this.sliderTimeCodes.vtcIn;
                    this.sliderTimeCodes.vtcIn = currentTime;
                    this.sliderTimeCodes.vtcOut = diff / this.zoomLevel + currentTime;
                }
                break;
            case 'OUT':
                this.zoomLevel--;
                if (this.zoomLevel === 1) {
                    this.sliderTimeCodes.vtcIn = calcVtcForSlider(this.vtcIn);
                    this.sliderTimeCodes.vtcOut = calcVtcForSlider(this.vtcOut);
                } else {
                    const diff = this.sliderTimeCodes.vtcOut - this.sliderTimeCodes.vtcIn;
                    this.sliderTimeCodes.vtcIn = currentTime;
                    this.sliderTimeCodes.vtcOut = diff * (this.zoomLevel + 1) + currentTime;
                }
                break;
            case 'ORIGINAL':
                this.zoomLevel = 1;
                this.sliderTimeCodes.vtcIn = calcVtcForSlider(this.vtcIn);
                this.sliderTimeCodes.vtcOut = calcVtcForSlider(this.vtcOut);
                break;
        }
        this.dispatchEnvelopeAction();
    }

    dispatchEnvelopeAction() {
        if (this.itemId) {
            this.store.dispatch(
                getEnvelopeData({
                    programId: this.programId,
                    itemId: this.itemId,
                    mediaCutId: this.mediaCutId,
                    vtcFrom: this.sliderTimeCodes.vtcIn
                        ? calcVtcForRequest(this.sliderTimeCodes.vtcIn)
                        : calcVtcForRequest(this.vtcIn.asMilliseconds()),
                    vtcTo: this.sliderTimeCodes.vtcOut
                        ? calcVtcForRequest(this.sliderTimeCodes.vtcOut)
                        : calcVtcForRequest(this.vtcOut.asMilliseconds()),
                })
            );
        } else {
            this.store.dispatch(
                getEnvelopeData({
                    programId: this.programId,
                    mediaCutId: this.mediaCutId,
                    vtcFrom: this.sliderTimeCodes.vtcIn
                        ? calcVtcForRequest(this.sliderTimeCodes.vtcIn)
                        : calcVtcForRequest(this.vtcIn.asMilliseconds()),
                    vtcTo: this.sliderTimeCodes.vtcOut
                        ? calcVtcForRequest(this.sliderTimeCodes.vtcOut)
                        : calcVtcForRequest(this.vtcOut.asMilliseconds()),
                })
            );
        }
    }

    maxZoomInReached(): boolean {
        return Math.floor(this.sliderTimeCodes.vtcOut) - Math.round(this.sliderTimeCodes.vtcIn) <= 20.0;
    }

    maxZoomOutReached(): boolean {
        return (
            this.sliderTimeCodes.vtcOut >= calcVtcForSlider(this.vtcOut) &&
            this.sliderTimeCodes.vtcIn === calcVtcForSlider(this.vtcIn)
        );
    }
}

interface ChartData {
    name: string;
    series: SeriesData[] | undefined;
}

interface SeriesData {
    name: string;
    value: number;
}

const EnvelopeOptions = {
    legend: false,
    showLabels: false,
    animations: false,
    xAxis: false,
    yAxis: false,
    showYAxisLabel: false,
    showXAxisLabel: false,
    timeline: false,
    tooltipDisabled: true,
    yAxisTicks: [0.0, 0.06, 0.2, 0.96, 1.0],
    curve: curveBasis,
    colorScheme: [
        { name: 'peak', value: '#DADAD2' },
        { name: 'rms', value: '#CAC8BF' },
        { name: 'cutPeak', value: '#BACDD7' },
        { name: 'cutRms', value: '#B1C4CC' },
    ],
};

function generatePeakSeries(data: EnvelopeSampleDto[] | undefined): SeriesData[] {
    return (
        data?.map((data: EnvelopeSampleDto) => {
            return {
                name: data.vtc,
                value: data.peak,
            };
        }) || []
    );
}

function generateRMSSeries(data: EnvelopeSampleDto[] | undefined): SeriesData[] {
    if (data) {
        return data.map((data: EnvelopeSampleDto) => {
            return {
                name: data.vtc,
                value: data.rms,
            };
        });
    } else {
        return [];
    }
}

function calcVtcForSlider(vtc: Duration | null): number {
    return vtc ? vtc.asMilliseconds() / 1000 : 0;
}

function calcVtcForRequest(vtc: number): string {
    return fromMilliseconds(vtc * 1000)
        .format('HH:mm:ss.ms')
        .toString();
}

function calcVtcForJumpToVtc(vtc: number): Duration {
    return fromMilliseconds(vtc * 1000);
}
