<div class="active-shopping-cart-details">
    <ng-container *ngIf="!loading">
        <div class="shopping-cart-name">
            <faro-inline-edit-field
                *ngIf="!readonly"
                placeholder="Warenkorb-Name"
                [iconAltText]="'Warenkorb-Name ändern'"
                [displayString]="shoppingCartInfo.name"
                (displayStringChanged)="onShoppingCartNameChanged($event)"
            ></faro-inline-edit-field>
        </div>
        <h2 class="preview-cart-name" *ngIf="readonly" [ngClass]="{ 'empty-string': !shoppingCartInfo.name }">
            {{ shoppingCartInfo.name ? shoppingCartInfo.name : "Warenkorb-Name" }}
        </h2>
        <div class="status">{{ getStatusDisplayString(shoppingCartInfo.status) }}</div>
        <div class="size-warning" *ngIf="shoppingCartInfo.isSizeLimitExceeded">
            <img src="assets/icons/warning-icon.svg" alt="" /> Maximale Grösse überschritten
        </div>
        <div class="double-column info-group">
            <div class="label">Erstelldatum</div>
            <div class="label">Nummer</div>
            <div class="value">{{ shoppingCartInfo.creationDate | date: "dd.MM.yyyy" }}</div>
            <div class="value">{{ shoppingCartInfo.number }}</div>
        </div>
        <ng-container *ngIf="readonly && shoppingCartInfo.status !== 'UnderConstruction'">
            <div *ngIf="readonly" class="info-group">
                <div class="label">E-Mail</div>
                <div class="value">{{ shoppingCartInfo.eMail }}</div>
            </div>
            <div class="info-group" *ngIf="readonly">
                <div class="label">Export-Ziel</div>
                <div class="value">{{ exportDeviceDisplayString() }}</div>
            </div>
            <div class="info-group" *ngIf="readonly">
                <div class="label">Export-Datum</div>
                <div class="value">{{ shoppingCartInfo.exportDate | date: "dd.MM.yyyy" }}</div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="loading-container">
            <p-progressSpinner strokeWidth="2" animationDuration="5s" styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </ng-container>
</div>
<hr class="divider" />
<div class="active-shopping-cart-details shopping-cart-input-field search-field-container">
    <label>MediaExplorer PID</label>
    <div class="input-field">
        <input [placeholder]="readonly ? 'keine PID' : 'PID eingeben'"
               [(ngModel)]="shoppingCartInfo.productionId"
               (input)="onPidChanged(shoppingCartInfo.productionId)"
               type="text"
               pInputText
               [readonly]="readonly"
        />
    </div>
</div>
