import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { NavigationMetadataDto } from '@faro/metadata-angular-client/model/navigationMetadataDto';
import { selectDetailsState } from '../details-state/details.selectors';
import {
    getDetailItemNavInformation,
    getDetailProgramNavInformation,
    setDetailAvComponent,
    getDetailMediaCuts,
    setMediaSelection,
} from '../details-state/details.actions';
import { Hit } from '@faro/searchapi-angular-client';
import { selectHitlist } from '../../search/search-state/search-result.selectors';

@Component({
    selector: 'app-detail-main',
    templateUrl: './detail-main.component.html',
    styleUrls: ['./detail-main.component.scss'],
})
export class DetailMainComponent implements OnInit, OnDestroy {
    readonly navigationInfo$: Observable<NavigationMetadataDto | null>;

    readonly hitlist$: Observable<Hit[]>;

    headerItemId: string | undefined;
    isItemSelected: boolean = false;

    private _destroyed$ = new Subject<void>();

    constructor(private route: ActivatedRoute, private router: Router, private store: Store) {
        this.navigationInfo$ = store.select(selectDetailsState).pipe(map(s => s.nav || null));
        this.hitlist$ = store.select(selectHitlist);
    }

    ngOnInit(): void {
        combineLatest([this.route.params, this.route.queryParams])
            .pipe(takeUntil(this._destroyed$))
            .subscribe(([params, queryParams]) => {
                const programId = params['programId'];
                const itemId = params['itemId'];
                const itemCrumb = queryParams['item_crumb'];

                this.headerItemId = itemId || itemCrumb;

                this.isItemSelected = programId && itemId;
                this.store.dispatch(
                    setDetailAvComponent({
                        program: programId,
                        item: itemId,
                        contentType: this.isItemSelected ? 'item' : 'program',
                    })
                );

                this.applyInitialMediaSelection(queryParams);

                if (programId && !itemId) {
                    this.store.dispatch(getDetailProgramNavInformation({ programId }));
                    this.store.dispatch(getDetailMediaCuts({ programId }));
                } else if (programId && itemId) {
                    this.store.dispatch(getDetailItemNavInformation({ programId, itemId }));
                    this.store.dispatch(getDetailMediaCuts({ programId, itemId }));
                }
            });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private applyInitialMediaSelection(queryParams: any) {
        const mediaCut = queryParams['media-cut'];
        const audioTrack = queryParams['audio-track'];
        if (mediaCut || audioTrack) {
            this.store.dispatch(setMediaSelection({ mediaCut, audioTrack }));
        }
    }
}
