import { UserState } from './user.state';
import { createReducer, on } from '@ngrx/store';
import { setUserInformation } from './user.actions';

export const initialState: UserState = {
    userInfo: undefined,
};

export const userReducer = createReducer(
    initialState,
    on(setUserInformation, (state: UserState, { userInfo }): UserState => {
        return {
            ...state,
            userInfo: userInfo,
        };
    })
);
