import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-info-dialog',
    templateUrl: './search-info-dialog.component.html',
    styleUrls: ['./search-info-dialog.component.scss'],
})
export class SearchInfoDialogComponent {
    searchTypes: {
        title: string;
        image: string;
        description: {
            main: string;
            examples: {
                description: string;
                example: string;
            }[];
        };
    }[] = searchTypeDescriptions;

    doNotDisplayMessageAgain: boolean = false;

    constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

    accept() {
        this.ref.close({ accept: true, doNotShowMessage: this.doNotDisplayMessageAgain });
    }

    closeDialog() {
        this.ref.close();
    }

    openInfo(): void {
        window.open('../../../../assets/documents/info.pdf');
    }
}

const searchTypeDescriptions: {
    title: string;
    image: string;
    description: {
        main: string;
        examples: {
            description: string;
            example: string;
        }[];
    };
}[] = [
    {
        title: 'Thema',
        image: '../../assets/images/info-item.svg',
        description: {
            main: 'Für thematische Suchen. Beispiele:',
            examples: [
                {
                    description: 'Ich will wissen, was im Frühling 2020 zu Covid berichtet wurde.',
                    example: 'Eingabe: «Covid» im Suchfeld und «1.3.2020–30.6.2020» im Datumsfeld',
                },
                {
                    description: 'Ich suche Beiträge zum Thema «Kosten im Gesundheitswesen».',
                    example:
                        'Eingabe: «Kosten Gesundheitswesen» im Suchfeld und «Gesundheitswesen» im Filter «Schlagwörter»',
                },
            ],
        },
    },
    {
        title: 'Bild',
        image: '../../assets/images/info-sequence.svg',
        description: {
            main: 'Suche nach Videosequenzen. Beispiele:',
            examples: [
                {
                    description: 'Ich will eine Videosequenz Jugendlichen an/auf einem See in der Schweiz.',
                    example: 'Eingabe: «Jugendliche See» im Suchfeld und «Schweiz» im Filter «Land»',
                },
                {
                    description: 'Ich will eine Nahaufnahme einer Hand.',
                    example: 'Eingabe: «Hand» im Suchfeld und «Nahaufnahme (GPL)» im Filter «Kameraeinstellungen»',
                },
            ],
        },
    },
    {
        title: 'Wort',
        image: '../../assets/images/info-closedcaption.svg',
        description: {
            main: 'Suche nach Gesprochenem. Beispiele:',
            examples: [
                {
                    description: 'Ich will das Zitat von Bundesrat Ogi «Freude herrscht».',
                    example: 'Eingabe: «Freude herrscht» (mit Anführungszeichen) im Suchfeld',
                },
                {
                    description: 'Ich will den Lachanfall von Hans-Rudolf Merz wegen des Bündnerfleisches.',
                    example: 'Eingabe: «Bündnerfleisch» im Suchfeld und «Merz, Hans-Rudolf» im Filter «Personen»',
                },
            ],
        },
    },
];
