import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, Observable, Subject, takeUntil } from 'rxjs';
import { ShoppingCartDto } from '@faro/order-angular-client/model/shoppingCartDto';
import { Store } from '@ngrx/store';
import {
    activateShoppingCart,
    duplicateShoppingCart,
    getSelectedShoppingCart,
} from '../shopping-cart-state/shopping-cart.actions';
import {
    selectLoadingShoppingCart,
    selectSelectedShoppingCart,
    selectShoppingCartSearchResult,
} from '../shopping-cart-state/shopping-cart.selectors';
import { ShoppingCartEntryDto } from '@faro/order-angular-client/model/shoppingCartEntryDto';
import { SearchResultDto } from '@faro/order-angular-client';
import { PaginationValues } from '../../ui-library/hitlist-paginator/paginator.interface';
import { PrintReportService } from '../shared/print-report.service';

@Component({
    selector: 'app-shopping-cart-preview',
    templateUrl: './shopping-cart-preview.component.html',
    styleUrls: ['./shopping-cart-preview.component.scss'],
})
export class ShoppingCartPreviewComponent implements OnDestroy {
    selectedShoppingCart$: Observable<ShoppingCartDto>;
    loadingShoppingCart$: Observable<boolean>;
    shoppingCartSearchResult$: Observable<SearchResultDto[]>;
    cartId: string = '';
    private _destroyed$ = new Subject<void>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly store: Store,
        private router: Router,
        private printService: PrintReportService
    ) {
        this.activatedRoute.params.pipe(takeUntil(this._destroyed$)).subscribe(data => {
            this.cartId = data['cartId'];
            if (this.cartId) {
                this.store.dispatch(getSelectedShoppingCart({ cartId: this.cartId }));
            }
        });
        this.shoppingCartSearchResult$ = this.store.select(selectShoppingCartSearchResult);
        this.selectedShoppingCart$ = this.store.select(selectSelectedShoppingCart).pipe(
            filter(v => !!v),
            map(v => v as ShoppingCartDto)
        );
        this.loadingShoppingCart$ = this.store.select(selectLoadingShoppingCart);
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    activateShoppingCart() {
        this.store.dispatch(activateShoppingCart({ cartId: this.cartId }));
    }

    duplicateShoppingCart() {
        this.store.dispatch(duplicateShoppingCart({ cartId: this.cartId }));
    }

    hasEntries(entryArray: ShoppingCartEntryDto[] | any[] | undefined) {
        return (entryArray && entryArray.length > 0) ?? false;
    }

    getSelectedIndex(id: string, searchResult: SearchResultDto[]): number {
        return searchResult.findIndex(v => v.id === id);
    }

    onPaginationRequested(type: PaginationValues, shoppingCartSearchResult: SearchResultDto[], index: number): void {
        if (shoppingCartSearchResult.length === 0) return;

        let shoppingCartId: string;
        switch (type) {
            case 'first':
                shoppingCartId = shoppingCartSearchResult[0].id;
                this.navigateToPage(shoppingCartId);
                break;
            case 'last':
                shoppingCartId = shoppingCartSearchResult[shoppingCartSearchResult.length - 1].id;
                this.navigateToPage(shoppingCartId);
                break;
            case 'next':
                shoppingCartId = shoppingCartSearchResult[index + 1].id;
                this.navigateToPage(shoppingCartId);
                break;
            case 'prev':
                shoppingCartId = shoppingCartSearchResult[index - 1].id;
                this.navigateToPage(shoppingCartId);
                break;
        }
    }

    navigateToPage(cartId: string) {
        this.router.navigateByUrl(`shopping-cart/preview/${cartId}`).then();
    }

    printShoppingCartReport(shoppingCart: ShoppingCartDto) {
        this.printService.printMultiReport(shoppingCart.entries, shoppingCart.name);
    }
}
