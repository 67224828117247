import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { getListEntries, InformationEntry, InformationKeys, itemMap } from './information-list.model';
import { DialogService } from 'primeng/dynamicdialog';
import { MusicDialogComponent } from '../../music-dialog/music-dialog.component';
import { Store } from '@ngrx/store';
import {
    getDetailItemInformation,
    getDetailProgramInformation,
    notifyOfDownloadError,
} from '../../details-state/details.actions';
import { DetailInformationModel } from '../../shared/details.interfaces';
import {
    DocumentMetadataDto,
    DocumentsService,
    ItemInfoMetadataDto,
    LinkedItemMetadataDto,
    PersonDto,
    ProgramInfoMetadataDto,
    SportRankingDto,
} from '@faro/metadata-angular-client';
import { selectDetailInformation, selectLoadingInformationTab } from '../../details-state/details.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { downloadBlob } from '../../../shared/download-blob';
import { Router } from '@angular/router';
import { accessibilityDurationString } from '../../../shared/accessibility-duration.helper';
import { parseTimeSpan } from '../../../shared/timespan';
import { Duration } from '../../../shared/duration';
import { TransmissionDto } from '@faro/metadata-angular-client/model/transmissionDto';

const hideRowIfNoContentKeys = new Set<InformationKeys>([
    InformationKeys.DESCRIPTORS,
    InformationKeys.FORM,
    InformationKeys.GEODESCRIPTORS,
]);

@Component({
    selector: 'app-information-content',
    templateUrl: './information-content.component.html',
    styleUrls: ['./information-content.component.scss'],
})
export class InformationContentComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    itemId: string | undefined;

    @Input()
    programId: string = '';

    @Input()
    contentType: string = '';

    @Input()
    keywords: string[] = [];

    loadingInformationTab$: Observable<boolean>;
    detailsContent: DetailInformationModel | undefined;

    private _destroyed$ = new Subject<void>();

    listMap: Map<InformationKeys, InformationEntry> = new Map<InformationKeys, InformationEntry>();
    detailInformationEntries: DetailInformationEntries[] = [];

    informationKeys = InformationKeys; // To use type in html

    constructor(
        private readonly dialogService: DialogService,
        private store: Store,
        private documentService: DocumentsService,
        private router: Router
    ) {
        this.loadingInformationTab$ = store.select(selectLoadingInformationTab);
    }

    ngOnInit() {
        this.store
            .select(selectDetailInformation)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                if (this.contentType === 'item') {
                    this.detailsContent = {
                        itemInfo: data.itemInfo || undefined,
                        programInfo: undefined,
                    };
                } else {
                    this.detailsContent = {
                        itemInfo: undefined,
                        programInfo: data.programInfo || undefined,
                    };
                }
                this.listMap = getListEntries(this.contentType);
                this.generateEntryList();
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.contentType === 'item') {
            this.store.dispatch(getDetailItemInformation({ programId: this.programId, itemId: this.itemId! }));
        } else {
            this.store.dispatch(getDetailProgramInformation({ programId: this.programId }));
        }
    }

    getContent(
        key: InformationKeys
    ):
        | string
        | string[]
        | boolean
        | DocumentMetadataDto[]
        | LinkedItemMetadataDto[]
        | TransmissionDto[]
        | PersonDto[]
        | SportRankingDto[]
        | Duration {
        let objectKey = this.listMap.get(key)?.objectKey;
        if (this.detailsContent && objectKey && (this.detailsContent.itemInfo || this.detailsContent.programInfo)) {
            if (this.detailsContent.itemInfo) {
                if (key === InformationKeys.DURATION) {
                    const duration = this.detailsContent.itemInfo[objectKey as keyof ItemInfoMetadataDto];
                    return parseTimeSpan(duration as string);
                }
                if (key === InformationKeys.SPORT) {
                    const sportInfo: SportRankingDto[] = this.detailsContent.itemInfo![
                        objectKey as keyof ItemInfoMetadataDto
                    ] as SportRankingDto[];
                    return sportInfo.length > 0 ? sportInfo : '';
                }
                return this.detailsContent.itemInfo[objectKey as keyof ItemInfoMetadataDto];
            } else {
                if (key === InformationKeys.DURATION) {
                    const duration = this.detailsContent.programInfo![objectKey as keyof ProgramInfoMetadataDto];
                    return parseTimeSpan(duration as string);
                }
                return this.detailsContent.programInfo![objectKey as keyof ProgramInfoMetadataDto];
            }
        } else {
            return '';
        }
    }

    generateEntryList() {
        this.detailInformationEntries = [];
        this.listMap.forEach((value, key) => {
            if (key === InformationKeys.FDES) return;
            this.detailInformationEntries.push({
                label: this.listMap.get(key)?.label || '',
                type: this.listMap.get(key)?.type || '',
                content: this.getContent(key),
                key: InformationKeys[key],
            });
        });
        this.amendFdesToTopic();
    }

    amendFdesToTopic() {
        let key = this.listMap.get(InformationKeys.FDES)?.objectKey as keyof ItemInfoMetadataDto;
        let fdes = this.detailsContent && this.detailsContent.itemInfo && this.detailsContent.itemInfo[key];
        if (fdes) {
            let topicIndex = this.detailInformationEntries.findIndex(entry => entry.key === InformationKeys.TOPIC);
            if (topicIndex >= 0) {
                let abstract = this.detailInformationEntries[topicIndex].content;
                this.detailInformationEntries[topicIndex].content = `${abstract} (${fdes})`;
            } else {
                this.detailInformationEntries.push({
                    label: itemMap.get(InformationKeys.TOPIC)?.label || '',
                    type: this.listMap.get(InformationKeys.TOPIC)?.type || '',
                    content: `(${fdes})`,
                    key: InformationKeys[InformationKeys.TOPIC],
                });
            }
        }
    }

    openMusicDialog(): void {
        this.dialogService.open(MusicDialogComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'music-dialog',
            data: {
                title: 'Verwendete Musik',
                contentType: this.contentType,
                itemId: this.detailsContent?.itemInfo?.itemId,
                programId: this.detailsContent?.itemInfo?.programId,
            },
        });
    }

    downloadAttachment(id: string, filename: string): void {
        this.documentService.documentsGet(id).subscribe({
            next: (blob: Blob) => {
                downloadBlob(document, blob, filename);
            },
            error: (err: HttpErrorResponse) => {
                this.store.dispatch(notifyOfDownloadError({ errorResponse: err }));
            },
        });
    }

    goToLinkedItem(programId: string, itemId: string, event: Event) {
        event.preventDefault();
        return this.router.navigateByUrl(`details/${programId}/${itemId}`);
    }

    getAccessibilityDurationString(duration: Duration): string {
        return duration ? accessibilityDurationString(duration) : 'keine Sendedauer';
    }

    hideRow(entry: DetailInformationEntries): boolean {
        return hideRowIfNoContentKeys.has(entry.key) && entry.content.length === 0;
    }
}

interface DetailInformationEntries {
    label: string;
    type: string;
    content: any;
    key: InformationKeys;
}
