import { TimeCodeInfo } from '../backend-api/time-code-info.model';
import { Duration } from '../../shared/duration';
import { formatTimeSpan, parseTimeSpan } from '../../shared/timespan';

export class MediaTimeMapping {
    private readonly _entries: MapEntry[];

    constructor(ltcMap: TimeCodeInfo[]) {
        this._entries = ltcMap.map(x => {
            const ltcIn = parseTimeSpan(x.ltcIn);
            const ltcOut = parseTimeSpan(x.ltcOut);

            return {
                ltcIn,
                vtcIn: parseTimeSpan(x.vtcIn),
                rtcIn: parseTimeSpan(x.tcIn),
                length: ltcOut.subtract(ltcIn),
            };
        });
    }

    ltcToVtc(ltc: Duration): Duration {
        const ltcMs = ltc.asMilliseconds();

        const entry = this._entries.find(
            e => e.ltcIn.asMilliseconds() <= ltcMs && ltcMs <= e.ltcIn.asMilliseconds() + e.length.asMilliseconds()
        );

        if (!entry) {
            throw Error(`Virtual time code lookup failed for LTC ${format(ltc)}`);
        }

        const delta = ltc.subtract(entry.ltcIn);
        return entry.vtcIn.add(delta);
    }

    vtcToLtc(vtc: Duration): Duration {
        const vtcMs = vtc.asMilliseconds();

        const entry = this._entries.find(
            e => e.vtcIn.asMilliseconds() <= vtcMs && vtcMs <= e.vtcIn.asMilliseconds() + e.length.asMilliseconds()
        );

        if (!entry) {
            throw Error(`Linear time code lookup failed for VTC ${format(vtc)}`);
        }

        const delta = vtc.subtract(entry.vtcIn);
        return entry.ltcIn.add(delta);
    }

    vtcToRtc(vtc: Duration): Duration {
        const vtcMs = vtc.asMilliseconds();

        const entry = this._entries.find(
            e => e.vtcIn.asMilliseconds() <= vtcMs && vtcMs <= e.vtcIn.asMilliseconds() + e.length.asMilliseconds()
        );

        if (!entry) {
            throw Error(`Real time code lookup failed for VTC ${format(vtc)}`);
        }

        const delta = vtc.subtract(entry.vtcIn);
        return entry.rtcIn.add(delta);
    }
}

interface MapEntry {
    ltcIn: Duration;
    vtcIn: Duration;
    rtcIn: Duration;
    length: Duration;
}

function format(d: Duration): string {
    return formatTimeSpan(d);
}
