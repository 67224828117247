export interface ColumnsModel {
    field: string;
    header: string;
    scssHeaderClass: string;
    scssColumnClass: string;
    type: string;
}

export const columns: ColumnsModel[] = [
    {
        field: 'name',
        header: 'Bezeichnung',
        scssHeaderClass: 'name-header',
        scssColumnClass: 'name-column',
        type: 'text',
    },
    {
        field: 'number',
        header: 'Nummer',
        scssHeaderClass: 'number-header',
        scssColumnClass: 'number-column',
        type: 'accessibility-link',
    },
    {
        field: 'numberOfEntries',
        header: 'Einträge',
        scssHeaderClass: 'entries-header',
        scssColumnClass: 'entries-column',
        type: 'text',
    },
    {
        field: 'creationDate',
        header: 'Erstelldatum',
        scssHeaderClass: 'creation-date-header',
        scssColumnClass: 'creation-date-column',
        type: 'date',
    },
    {
        field: 'status',
        header: 'Status',
        scssHeaderClass: 'status-header',
        scssColumnClass: 'status-column',
        type: 'cart-status',
    },
    {
        field: 'owner',
        header: 'Ersteller:in',
        scssHeaderClass: 'creator-header',
        scssColumnClass: 'creator-column',
        type: 'text',
    },
];
