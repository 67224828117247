import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject, takeUntil } from 'rxjs';
import { StorageCategoryFilters } from '../../profile-service/search-profile.interfaces';
import { setDefaultProfileSettings, updateProfileSettings } from '../../search-state/search-profile.actions';
import {
    selectDisplaySportFilters,
    selectExcludedStorageCategories,
    selectSearchProfileDefault,
    selectSearchProfileExcludedMediaTypes,
    selectSearchProfileSearchInDocumentsText,
    selectStorageCategoryFilters,
} from '../../search-state/search-profile.selectors';
import { getAvailableStorageCategories } from '../../search-state/search-options.actions';
import {
    selectAvailableStorageCategories,
    selectLoadingStorageCategories,
} from '../../search-state/search-options.selectors';
import { StorageCategory } from '@faro/profile-angular-client';
import { SearchSettings } from '@faro/profile-angular-client/model/searchSettings';

@Component({
    selector: 'app-profile-filters',
    templateUrl: './profile-filters.component.html',
    styleUrls: ['./profile-filters.component.scss'],
})
export class ProfileFiltersComponent implements OnDestroy {
    storageCategories$: Observable<StorageCategory[]>;
    isLoadingCategories$: Observable<boolean>;
    areProfileSettingsDefault$: Observable<boolean>;

    excludedStorageCategories: string[] = [];
    searchInDocumentTexts: boolean = false;
    includeSportsFilters: boolean = false;

    availableFilters = [
        {
            id: 'no_sport',
            label: 'Sport ausblenden',
            active: false,
        },
        {
            id: 'raw_material',
            label: 'Nur Rohmaterial',
            active: false,
        },
    ];

    availableMediaTypes = [
        {
            id: 'audio',
            label: 'Audio',
            active: true,
        },
        {
            id: 'video',
            label: 'Video',
            active: true,
        },
    ];

    private _destroyed$: Subject<void> = new Subject<void>();

    constructor(private ref: DynamicDialogRef, private store: Store, public config: DynamicDialogConfig) {
        this.isLoadingCategories$ = this.store.select(selectLoadingStorageCategories);
        this.storageCategories$ = this.store.select(selectAvailableStorageCategories);
        this.areProfileSettingsDefault$ = store.select(selectSearchProfileDefault);
        this.store
            .select(selectStorageCategoryFilters)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(filters => {
                this.setActiveFilters(filters);
                this.loadStorageCategories();
            });

        this.store
            .select(selectSearchProfileSearchInDocumentsText)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.searchInDocumentTexts = data;
            });

        this.store
            .select(selectDisplaySportFilters)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.includeSportsFilters = data;
            });

        this.store
            .select(selectSearchProfileExcludedMediaTypes)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(mediaTypes => {
                this.setActiveMediaTypes(mediaTypes);
            });

        this.store
            .select(selectExcludedStorageCategories)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.excludedStorageCategories = JSON.parse(JSON.stringify(data));
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private setActiveFilters(filters: string[]) {
        if (filters.length === 0) this.availableFilters.map(availableFilter => (availableFilter.active = false));
        for (let filter of filters) {
            const f = this.availableFilters.find(availableFilter => availableFilter.id === filter);
            if (f) {
                f.active = true;
            }
        }
    }

    private setActiveMediaTypes(mediaTypes: string[]) {
        if (mediaTypes.length === 0)
            this.availableMediaTypes.map(availableMediaType => (availableMediaType.active = true));
        for (let mediaType of mediaTypes) {
            const m = this.availableMediaTypes.find(availableMediaType => availableMediaType.id === mediaType);
            if (m) {
                m.active = false;
            }
        }
    }

    closeDialog() {
        this.ref.close();
    }

    filterTree() {
        this.loadStorageCategories();
    }

    resetSearchSettings() {
        this.store.dispatch(setDefaultProfileSettings());
    }

    private loadStorageCategories() {
        const filters = this.getActiveFilters();
        this.store.dispatch(getAvailableStorageCategories({ filters }));
    }

    private getActiveFilters(): StorageCategoryFilters[] {
        let filters: StorageCategoryFilters[] = [];
        this.availableFilters
            .filter(filter => filter.active)
            .map(activeFilter => {
                activeFilter.id === 'no_sport'
                    ? filters.push(StorageCategoryFilters.SPORT)
                    : filters.push(StorageCategoryFilters.RAW);
            });
        return filters;
    }

    onExcludedSelectionChange(event: string[]) {
        this.excludedStorageCategories = event;
    }

    save() {
        const settings: SearchSettings = {
            storageCategorySettings: {
                excluded: this.excludedStorageCategories,
                filters: this.getActiveFilters(),
            },
            excludedMediaTypes: this.availableMediaTypes.filter(m => !m.active).map(m => m.id),
            searchInDocumentTexts: this.searchInDocumentTexts,
            includeSportsFilters: this.includeSportsFilters,
            isDefault: false,
        };
        this.store.dispatch(
            updateProfileSettings({
                searchProfile: settings,
            })
        );
        this.ref.close();
    }
}
