<ng-container *ngrxLet="selectedShoppingCart$; let selectedShoppingCart">
    <ng-container *ngrxLet="shoppingCartSearchResult$; let shoppingCartSearchResult">
        <app-shopping-cart-header
            [cart]="selectedShoppingCart"
            [showPaginator]="hasEntries(shoppingCartSearchResult)"
            [paginatorLength]="shoppingCartSearchResult.length"
            [paginatorIndex]="getSelectedIndex(selectedShoppingCart.id, shoppingCartSearchResult)"
            [showPrint]="hasEntries(selectedShoppingCart.entries)"
            (printReport)="printShoppingCartReport(selectedShoppingCart)"
            (paginationRequested)="
                onPaginationRequested(
                    $event,
                    shoppingCartSearchResult,
                    getSelectedIndex(selectedShoppingCart.id, shoppingCartSearchResult)
                )
            "
        ></app-shopping-cart-header>
    </ng-container>

    <div class="shopping-cart-preview">
        <div class="side-bar">
            <app-shopping-cart-info
                [shoppingCart]="selectedShoppingCart"
                [loading]="loadingShoppingCart$ | async"
                [readonly]="true"
                class="preview-details"
            ></app-shopping-cart-info>
            <div class="shopping-cart-actions">
                <div class="search-button-container">
                    <button
                        *ngIf="selectedShoppingCart.canActivate"
                        pRipple
                        type="button"
                        class="secondary-button"
                        (click)="activateShoppingCart()"
                    >
                        <img src="/assets/icons/arrow-top-right-dark.svg" alt="" />Warenkorb aktivieren
                    </button>
                    <button
                        [ngStyle]="{ 'margin-top': selectedShoppingCart.canActivate ? '10px' : '0px' }"
                        pRipple
                        type="button"
                        class="search-button"
                        (click)="duplicateShoppingCart()"
                    >
                        <img src="/assets/icons/duplicate.svg" alt="" />Warenkorb duplizieren
                    </button>
                </div>
            </div>
        </div>
        <div class="entries-container">
            <app-shopping-cart-entries
                [cartId]="selectedShoppingCart.id"
                [entries]="selectedShoppingCart.entries"
                [entriesMetadata]="$any(selectedShoppingCart.entriesMetadata)"
                [entriesMediaCutMetadata]="selectedShoppingCart.mediaCutsMetadata"
                [readonly]="true"
            ></app-shopping-cart-entries>
        </div>
    </div>
</ng-container>
