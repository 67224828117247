import { DetailsState } from './details.state';
import { createReducer, on } from '@ngrx/store';
import {
    setDetailItemNavInformation,
    getDetailItemSequence,
    getDetailItemInformation,
    getDetailItemNavInformation,
    setDetailItemInformation,
    setDetailItemSequence,
    setDetailProgramInformation,
    setDetailProgramSequence,
    getDetailProgramSequence,
    getDetailProgramInformation,
    getDetailItemSubtitleTranscript,
    getDetailProgramSubtitleTranscript,
    setDetailItemSubtitleTranscript,
    setDetailProgramSubtitleTranscript,
    setDetailProgramMusicInformation,
    setDetailItemMusicInformation,
    getDetailProgramNavInformation,
    setDetailProgramNavInformation,
    setDetailOrderVtcIn,
    setDetailOrderVtcOut,
    setEnvelopeData,
    setLightTableData,
    getLightTable,
    setMediaSelection,
    setPlayerMediaCut,
    setDetailAvComponent,
    setHoveredTimeFrame,
    clearHoveredTimeFrame,
    setDetailMediaCuts,
    setDetailShoppingCartVtcInVtcOut,
    setPreviousPlayerState,
} from './details.actions';
import { parseTimeSpan } from '../../shared/timespan';
import { timeCodeSelectionFromVtcIn, timeCodeSelectionFromVtcOut } from '../shared/valid-in-out.model';
import { DetailsStateMediaSelection, updatePlayerMediaCut, updateMediaSelection } from './media-state.model';
import { AudioTrack } from '@faro/metadata-angular-client';

export const initialState: DetailsState = {
    component: undefined,
    nav: undefined,
    tabs: {
        information: {
            itemInfo: undefined,
            programInfo: undefined,
        },
        sequence: [],
        subtitleTranscript: [],
        hoveredTimeRange: { vtcIn: undefined, vtcOut: undefined },
    },
    musicInformation: {
        itemMusic: [],
        programMusic: [],
    },
    loading: {
        navigation: false,
        sequenceTab: false,
        informationTab: false,
        subtitleTranscriptTab: false,
        lightTable: false,
    },
    orderVtcIn: undefined,
    orderVtcOut: undefined,
    inOutSelectionChanged: false,
    isInOutSelectionValid: true,
    envelope: undefined,
    lightTable: [],
    mediaSelection: {
        mediaCut: undefined,
        audioTrack: AudioTrack.Stereo1And2,
    },
    mediaCuts: undefined,
    playerMediaCut: undefined,
    previousPlayerState: false,
};

export const detailsReducer = createReducer(
    initialState,
    on(setDetailAvComponent, (state: DetailsState, { program, item, contentType }): DetailsState => {
        return {
            ...initialState,
            component: {
                program,
                item,
                type: contentType,
            },
        };
    }),
    on(setMediaSelection, (state: DetailsState, { mediaCut, audioTrack }): DetailsState => {
        const mediaSelection: DetailsStateMediaSelection = {
            mediaCut: mediaCut || state.mediaSelection.mediaCut,
            audioTrack: audioTrack || state.mediaSelection.audioTrack,
        };
        return {
            ...state,
            mediaSelection,
            playerMediaCut: updatePlayerMediaCut(state.playerMediaCut, mediaSelection),
        };
    }),
    on(setPlayerMediaCut, (state: DetailsState, { mediaCut }): DetailsState => {
        return {
            ...state,
            playerMediaCut: mediaCut,
        };
    }),
    on(setDetailMediaCuts, (state: DetailsState, { mediaCuts }): DetailsState => {
        return {
            ...state,
            mediaCuts,
        };
    }),
    on(
        setDetailItemNavInformation,
        setDetailProgramNavInformation,
        (state: DetailsState, { detailNavInformation }): DetailsState => {
            const mediaSelection = updateMediaSelection(state.mediaSelection, detailNavInformation);
            const vtcIn = state.orderVtcIn ? state.orderVtcIn : parseTimeSpan(detailNavInformation.vtcIn);
            const vtcOut = state.orderVtcOut ? state.orderVtcOut : parseTimeSpan(detailNavInformation.vtcOut);

            return {
                ...state,
                nav: detailNavInformation,
                mediaSelection,
                playerMediaCut: updatePlayerMediaCut(state.playerMediaCut, mediaSelection),
                loading: { ...state.loading, navigation: false },
                orderVtcIn: vtcIn,
                orderVtcOut: vtcOut,
            };
        }
    ),
    on(getDetailItemNavInformation, getDetailProgramNavInformation, (state: DetailsState): DetailsState => {
        return { ...state, loading: { ...state.loading, navigation: true } };
    }),
    on(setDetailItemInformation, (state: DetailsState, { contentInformation }): DetailsState => {
        return {
            ...state,
            tabs: { ...state.tabs, information: { ...state.tabs.information, itemInfo: contentInformation } },
            loading: { ...state.loading, informationTab: false },
        };
    }),
    on(setDetailProgramInformation, (state: DetailsState, { contentInformation }): DetailsState => {
        return {
            ...state,
            tabs: {
                ...state.tabs,
                information: { ...state.tabs.information, programInfo: contentInformation },
            },
            loading: { ...state.loading, informationTab: false },
        };
    }),
    on(setDetailItemSequence, setDetailProgramSequence, (state: DetailsState, { contentSequence }): DetailsState => {
        return {
            ...state,
            tabs: { ...state.tabs, sequence: contentSequence },
            loading: { ...state.loading, sequenceTab: false },
        };
    }),
    on(
        setDetailItemSubtitleTranscript,
        setDetailProgramSubtitleTranscript,
        (state: DetailsState, { contentSubtitleTranscript }): DetailsState => {
            return {
                ...state,
                tabs: { ...state.tabs, subtitleTranscript: contentSubtitleTranscript },
                loading: { ...state.loading, subtitleTranscriptTab: false },
            };
        }
    ),
    on(getDetailItemSequence, getDetailProgramSequence, (state: DetailsState): DetailsState => {
        return { ...state, loading: { ...state.loading, sequenceTab: true } };
    }),
    on(getDetailItemInformation, getDetailProgramInformation, (state: DetailsState): DetailsState => {
        return { ...state, loading: { ...state.loading, informationTab: true } };
    }),
    on(getDetailItemSubtitleTranscript, getDetailProgramSubtitleTranscript, (state: DetailsState): DetailsState => {
        return { ...state, loading: { ...state.loading, subtitleTranscriptTab: true } };
    }),
    on(setDetailItemMusicInformation, (state: DetailsState, { musicInformation }): DetailsState => {
        return { ...state, musicInformation: { ...state.musicInformation, itemMusic: musicInformation } };
    }),
    on(setDetailProgramMusicInformation, (state: DetailsState, { musicInformation }): DetailsState => {
        return { ...state, musicInformation: { ...state.musicInformation, programMusic: musicInformation } };
    }),
    on(setDetailOrderVtcIn, (state: DetailsState, { vtcIn }): DetailsState => {
        const previousSelection = {
            vtcIn: state.orderVtcIn,
            vtcOut: state.orderVtcOut,
        };
        const { updatedVtcIn, updatedVtcOut } = timeCodeSelectionFromVtcIn(previousSelection, vtcIn);
        const isValid = updatedVtcIn !== undefined && updatedVtcOut !== undefined;
        return {
            ...state,
            orderVtcIn: updatedVtcIn,
            orderVtcOut: updatedVtcOut,
            isInOutSelectionValid: isValid,
            inOutSelectionChanged: true,
        };
    }),
    on(setDetailOrderVtcOut, (state: DetailsState, { vtcOut }): DetailsState => {
        const previousSelection = {
            vtcIn: state.orderVtcIn,
            vtcOut: state.orderVtcOut,
        };
        const { updatedVtcIn, updatedVtcOut } = timeCodeSelectionFromVtcOut(previousSelection, vtcOut);
        const isValid = updatedVtcIn !== undefined && updatedVtcOut !== undefined;
        return {
            ...state,
            orderVtcOut: updatedVtcOut,
            orderVtcIn: updatedVtcIn,
            isInOutSelectionValid: isValid,
            inOutSelectionChanged: true,
        };
    }),
    on(setDetailShoppingCartVtcInVtcOut, (state: DetailsState, { vtcIn, vtcOut }): DetailsState => {
        return {
            ...state,
            orderVtcOut: vtcOut,
            orderVtcIn: vtcIn,
        };
    }),
    on(setEnvelopeData, (state: DetailsState, { envelopeData }): DetailsState => {
        return { ...state, envelope: envelopeData };
    }),
    on(getLightTable, (state: DetailsState): DetailsState => {
        return {
            ...state,
            loading: { ...state.loading, lightTable: true },
        };
    }),
    on(setLightTableData, (state: DetailsState, { lightTableData }): DetailsState => {
        return { ...state, lightTable: lightTableData, loading: { ...state.loading, lightTable: false } };
    }),
    on(setHoveredTimeFrame, (state: DetailsState, { hoveredVtcIn, hoveredVtcOut }): DetailsState => {
        return { ...state, tabs: { ...state.tabs, hoveredTimeRange: { vtcIn: hoveredVtcIn, vtcOut: hoveredVtcOut } } };
    }),
    on(clearHoveredTimeFrame, (state: DetailsState): DetailsState => {
        return {
            ...state,
            tabs: { ...state.tabs, hoveredTimeRange: { vtcIn: undefined, vtcOut: undefined } },
        };
    }),
    on(setPreviousPlayerState, (state: DetailsState, { playerState }): DetailsState => {
        return {
            ...state,
            previousPlayerState: playerState,
        };
    })
);
