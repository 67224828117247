<app-detail-header
    *ngrxLet="navigationInfo$; let navigationInfo"
    [programInfo]="navigationInfo"
    [itemId]="headerItemId"
    [isItemHighlighted]="isItemSelected"
    [hitlist]="(hitlist$ | async) || []"
>
    <button
        *ngIf="isItemSelected"
        type="button"
        class="print-button"
        pRipple
        (click)="detailContent.printReport(navigationInfo, headerItemId!)"
    >
        <img src="assets/icons/printer.svg" alt="Beitrags-Report herunterladen" />
    </button>
</app-detail-header>
<app-detail-content #detailContent [readonly]="false"></app-detail-content>
