import { ShoppingCartDto } from '@faro/order-angular-client/model/shoppingCartDto';
import {
    AdmiraDeviceDto,
    AdmiraFolder,
    CartEntryErrorDto,
    SearchResultDto,
    ShoppingCartStatus,
} from '@faro/order-angular-client';
import { MediaCutDto } from '@faro/metadata-angular-client';

export interface ShoppingCartState {
    activeShoppingCart: ShoppingCartDto | undefined;
    selectedShoppingCart: ShoppingCartDto | undefined;
    shoppingCartSearchResult: SearchResultDto[];
    searchOptions: ShoppingCartSearchOptions;
    searchRequested: boolean;
    initialSearchRequested: boolean;
    dialogAvailableMaterial: MediaCutDto[];
    displayActivateNewCartDialog: boolean;
    activeCartEntryErrors: CartEntryErrorDto[];
    scrollTopPosition: number;
    loading: {
        shoppingCart: boolean;
        entryMaterial: boolean;
        shoppingCartSearch: boolean;
    };
    orderingShoppingCart: boolean;
    admira: {
        admiraDevices: AdmiraDeviceDto[];
        admiraFolders: {
            deviceId: string | undefined;
            folders: AdmiraFolder | undefined;
        };
        selectedAdmiraDevice: AdmiraDeviceDto | undefined;
        deviceRootPath: string;
        deviceRootName: string;
    };
    exportSettings: {
        eMail: string;
        exportDate: Date;
    };
}

export interface ShoppingCartSearchOptions {
    description: string;
    number: string;
    pid: string;
    firstName: string;
    lastName: string;
    orderStatus: ShoppingCartStatus | null;
    queryDate: DateRange;
    sorting: ShoppingCartSorting;
    searchFieldsHaveBeenReset: boolean;
    nameFieldsHaveBeenUpdated: boolean;
}

export enum ShoppingCartOrderStatus {
    NOT_ORDERED = 'In Bearbeitung',
    ORDERED = 'In Auslieferung',
    DELIVERED = 'Ausgeliefert',
    CLOSED = 'Abgeschlossen',
    ACTIVE = 'Aktiv',
}

export interface DateRange {
    from: Date | null;
    to: Date | null;
}

export interface ShoppingCartSorting {
    sortBy: ShoppingCartHitlistSortingAttribute;
    sortDirection: ShoppingCartHitlistSortingOrder;
}

export enum ShoppingCartHitlistSortingAttribute {
    DESCRIPTION,
    NUMBER,
    ENTRIES,
    CREATION_DATE,
    ORDER_STATE,
    CREATOR,
}

export enum ShoppingCartHitlistSortingOrder {
    DESCENDING,
    ASCENDING,
}

export interface ShoppingCartHitlistSortingAttributeOption {
    value: ShoppingCartHitlistSortingAttribute;
}

export const SHOPPING_CART_HITLIST_SORTING_ATTRIBUTE_OPTIONS: ShoppingCartHitlistSortingAttributeOption[] = [
    {
        value: ShoppingCartHitlistSortingAttribute.DESCRIPTION,
    },
    {
        value: ShoppingCartHitlistSortingAttribute.NUMBER,
    },
    {
        value: ShoppingCartHitlistSortingAttribute.ENTRIES,
    },
    {
        value: ShoppingCartHitlistSortingAttribute.CREATION_DATE,
    },
    {
        value: ShoppingCartHitlistSortingAttribute.ORDER_STATE,
    },
    {
        value: ShoppingCartHitlistSortingAttribute.CREATOR,
    },
];
