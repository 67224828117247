<div class="panel">
    <div class="header" (click)="toggleCollapsed($event)">
        <ng-container *ngIf="countLabel > 0">
            <!--Role required for screen reader-->
            <div class="option-count" role="main" attr.aria-label="{{ countLabel }} {{ header }} gewählt" data-cy="facet-panel-option-count">
                {{ countLabel }}
            </div>
        </ng-container>
        <span
            ><h3 class="facet-panel-header-title">{{ header }}</h3></span
        >
        <ng-container *ngIf="deleteIcon && countLabel > 0">
            <button class="delete-button" (click)="deleteIconClicked($event)" data-cy="facet-panel-delete-button">
                <i [ngClass]="deleteIcon" class="highlight" attr.aria-label="Filter {{ header }} löschen"></i>
            </button>
        </ng-container>
        <ng-container *ngIf="headerIcon">
            <button (click)="headerIconClicked($event)" data-cy="facet-panel-header-button">
                <i
                    [ngClass]="headerIcon"
                    [class.highlight]="headerIconHighLighted"
                    [attr.aria-label]="headerIconAriaLabel"
                ></i>
            </button>
        </ng-container>

        <button (click)="toggleCollapsed($event)">
            <i
                class="pi pi-angle-left"
                [class.expanded]="!collapsed"
                [attr.aria-label]="collapsed ? 'Filter ' + header + ' aufklappen' : 'Filter ' + header + ' zuklappen'"
            ></i>
        </button>
    </div>

    <div class="content" [@collapsibleContent]="collapsed ? 'collapsed' : 'expanded'" [hidden]="collapsed">
        <ng-content></ng-content>
    </div>
</div>
