<div class="container" *ngrxLet="selectedSearchValue$; let selectedSearchValue">
    <h3>Hinweise zu den Suchoptionen</h3>
    <div class="image-container">
        <img src="/assets/images/empty-state-{{selectedSearchValue.value}}.svg" alt=""/>
    </div>
    <div class="toggle-container">
        <app-search-toggle></app-search-toggle>
    </div>
    <div class="information">
        <h3>{{getTextToDisplay(selectedSearchValue.value).title}}</h3>
        <div>
            {{getTextToDisplay(selectedSearchValue.value).description}}
        </div>
        <div class="secondary-text">
            {{getTextToDisplay(selectedSearchValue.value).example}}
        </div>
    </div>
    <div class="link-container" (click)="openInfoDialog()">
        <img src="/assets/icons/info-blue.svg">
        <a>Tipps zur Suche anzeigen</a>
    </div>
</div>
