import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[appHighlightRow]',
})
export class HighlightRowDirective {
    @Input()
    appHighlightRow: number = -1;

    @Output()
    highlightedRowChanged = new EventEmitter<number>();

    private fadeInTimeout: number = 0;
    private timeOut: ReturnType<typeof setTimeout> = setTimeout(() => {}, 0);

    constructor() {}

    @HostListener('mouseenter') onMouseEnter() {
        this.timeOut = setTimeout(() => {
            this.highlightedRowChanged.emit(this.appHighlightRow);
        }, this.fadeInTimeout);
    }

    @HostListener('mouseleave') onMouseLeave() {
        clearTimeout(this.timeOut);
        this.highlightedRowChanged.emit(undefined);
    }
}
