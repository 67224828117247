import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
    @Input()
    crumbs: BreadcrumbModel[] = [];

    @Input()
    activeCrumb: string | undefined;

    @Input()
    breadcrumbArrow: string = '';

    @Output()
    breadCrumbSelectionChange = new EventEmitter<BreadcrumbModel>();

    constructor() {}

    breadCrumbClick(crumb: BreadcrumbModel) {
        this.breadCrumbSelectionChange.emit(crumb);
    }
}

export interface BreadcrumbModel {
    label: string;
}
