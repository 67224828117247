import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'faro-facet-panel',
    templateUrl: './facet-panel.component.html',
    styleUrls: ['./facet-panel.component.scss'],
    animations: [
        trigger('collapsibleContent', [
            state(
                'collapsed',
                style({
                    height: 0,
                })
            ),
            state(
                'expanded',
                style({
                    height: '*',
                })
            ),
            transition('collapsed => expanded', [animate('300ms ease-in-out')]),
            transition('expanded => collapsed', [animate('300ms ease-in-out')]),
        ]),
    ],
})
export class FacetPanelComponent {
    private _countLabel = 0;

    @Input()
    header?: string;

    @Input()
    collapsed: boolean = true;

    @Output()
    collapsedChange = new EventEmitter<boolean>();

    @Input()
    headerIcon?: string;

    @Input()
    deleteIcon?: string;

    @Input()
    headerIconHighLighted = false;

    @Input()
    deleteIconHighLighted = false;

    @Input()
    headerIconAriaLabel = '';

    @Input()
    set countLabel(panelCountLabel: number) {
        this._countLabel = panelCountLabel;
    }

    get countLabel(): number {
        return this._countLabel;
    }

    @Output()
    headerIconClick = new EventEmitter<Event>();

    @Output()
    deleteIconClick = new EventEmitter<Event>();

    constructor() {}

    toggleCollapsed(event: Event): void {
        event.stopPropagation();
        this.collapsed = !this.collapsed;
        this.collapsedChange.emit(this.collapsed);
    }

    headerIconClicked(event: Event): void {
        event.stopPropagation();
        this.headerIconClick.emit(event);
    }

    deleteIconClicked(event: Event): void {
        event.stopPropagation();
        this.deleteIconClick.emit(event);
    }
}
