import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { addToHistory } from './route-history.actions';
import { selectRouteHistory } from './route-history.selectors';
import { StoreUtilsService } from '../../state/store-utils.service';
import { RouteHistory } from './route-history.state';

@Injectable({
    providedIn: 'root',
})
export class RouteHistoryService {
    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly storeUtils: StoreUtilsService
    ) {}

    subscribeToRouteChanges(): Subscription {
        return this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                map(e => <NavigationEnd>e)
            )
            .subscribe(e => {
                this.store.dispatch(addToHistory({ url: e.url }));
            });
    }

    /**
     * Navigates to the latest url matching the given predicate.
     * Does nothing if no match was found.
     * Returns the same promise as @see Router#navigate
     */
    async navigateToLatestMatchingUrl(pred: FindByUrlPredicate): Promise<boolean> {
        const urls = await this.storeUtils.snapshot(selectRouteHistory).then((state: RouteHistory) => {
            return state.urls;
        });
        const url = findLatestUrl(urls, pred);
        let navigated = false;
        if (url) {
            navigated = await this.router.navigateByUrl(url);
        }
        return navigated;
    }
}

function findLatestUrl(urls: string[], pred: FindByUrlPredicate): string | null {
    for (let i = urls.length - 1; i >= 0; i--) {
        const url = urls[i];
        if (pred(url)) return url;
    }
    return null;
}

/**
 * Type of functions accepting a predicate to match urls and returning
 * an url with the match result or null if no match was found.
 */
export type FindByUrlFunction = (pred: FindByUrlPredicate) => string | null;

export type FindByUrlPredicate = (url: string) => boolean;
