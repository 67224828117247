<div class="shopping-cart-search" [ngClass]="{ 'filters-set': searchParamsSet }">
    <div class="shopping-cart-input-field search-field-container">
        <ng-container *ngFor="let inputField of searchMaskInputFields">
            <label id="{{ inputField.label }}" class="label">{{ inputField.label }}</label>
            <div class="input-field">
                <input
                    [attr.aria-labelledby]="inputField.label"
                    [(ngModel)]="inputField.value"
                    type="text"
                    pInputText
                    placeholder="Text eingeben"
                    (input)="resetShoppingCartSearchOptionsInputFields()"
                />
                <button *ngIf="inputField.value" (click)="clearInput(inputField.label)">
                    <i class="pi pi-times" [attr.aria-label]="inputField.label + ' löschen'"></i>
                </button>
            </div>
        </ng-container>
        <div class="shopping-cart-input-field search-field-container">
            <label id="status" class="label">Status</label>
            <div class="shopping-cart-hit-list-dropdown-container" #dropdownList>
                <p-dropdown
                    aria-labelledby="status"
                    placeholder="Status auswählen"
                    [appendTo]="dropdownList"
                    class="shopping-cart-hit-list-dropdown"
                    scrollHeight="unset"
                    [ngClass]="{ 'no-value-selected': !selectedOrderStatus }"
                    [options]="searchMaskOrderStatusDropDown"
                    [(ngModel)]="selectedOrderStatus"
                    (onChange)="orderStatusSelectionChanged()"
                ></p-dropdown>
            </div>
        </div>
        <div class="shopping-cart-input-field search-field-container">
            <label id="datum" class="label">Datum</label>
            <faro-date-range-input
                [dateRange]="selectedDateRange$ | async"
                (changed)="onDateRangeChanged($event)"
                [placeholderText]="'Datum eingeben'"
                ariaLabel="datum"
            ></faro-date-range-input>
        </div>
    </div>
</div>
