import { ShoppingCartEntryType } from '@faro/order-angular-client/model/shoppingCartEntryType';
import * as dayjs from 'dayjs';
import { ComponentInfoDto, MediaCutDto } from '@faro/metadata-angular-client';
import { ShoppingCartEntryDto } from '@faro/order-angular-client/model/shoppingCartEntryDto';
import { mediaCutDescription } from '../shared/media-cut-description.helper';

export interface ColumnsModel {
    field: string;
    header: string;
    scssHeaderClass: string;
    scssColumnClass: string;
    type: string;
}

export const columns: ColumnsModel[] = [
    {
        field: 'keyframeId',
        header: 'Medien',
        scssHeaderClass: 'media-header',
        scssColumnClass: 'media-column',
        type: 'image',
    },
    {
        field: 'description',
        header: 'Sendung/Beitrag/Sequenz',
        scssHeaderClass: 'item-header',
        scssColumnClass: 'item-column',
        type: 'item',
    },
    {
        field: 'mediaSelection',
        header: 'Material/Format',
        scssHeaderClass: 'material-header',
        scssColumnClass: 'material-column',
        type: 'text',
    },
    {
        field: 'rightsAndUsage',
        header: 'Rechte',
        scssHeaderClass: 'rights-header',
        scssColumnClass: 'rights-column',
        type: 'icon',
    },
    {
        field: 'remark',
        header: 'Vermerk',
        scssHeaderClass: 'remark-header',
        scssColumnClass: 'remark-column',
        type: 'inline-edit',
    },
];

export function entryDescription(entryType: ShoppingCartEntryType, metaData: any): string {
    let entryDescriptionString: string = '';
    const transmissionDate = metaData.transmissionDate ? dayjs(metaData.transmissionDate).format('DD.MM.YYYY') : '';
    const recordDate = metaData.recordDate ? dayjs(metaData.recordDate).format('DD.MM.YYYY') : '';
    const programTitle = metaData.programTitle;
    const itemTitle = metaData.itemTitle ?? '';
    const sequenceTitle = metaData.sequenceTitle ?? '';
    switch (entryType) {
        case 'Item': {
            entryDescriptionString = `${programTitle}${itemTitle ? '\n' + itemTitle : ''} \n${transmissionDate === '' ? recordDate : transmissionDate}`;
            break;
        }
        case 'Program': {
            entryDescriptionString = `${programTitle}\n${transmissionDate}`;
            break;
        }
        case 'Sequence': {
            entryDescriptionString = `${programTitle} ${itemTitle ? '\n' + itemTitle : ''} ${
                sequenceTitle ? '\n' + sequenceTitle : ''
            } \n${transmissionDate === '' ? recordDate : transmissionDate}`;
            break;
        }
        case 'TCSelection': {
            entryDescriptionString = `IN/OUT: ${programTitle} ${itemTitle ? '\n' + itemTitle : ''} ${
                sequenceTitle ? '\n' + sequenceTitle : ''
            } \n${transmissionDate === '' ? recordDate : transmissionDate}`;
            break;
        }
    }
    return entryDescriptionString;
}

export function entryMaterials(
    mediaCutMetadata: any[],
    selectedMediaCuts?: string[],
    itemId?: string | null
): string[] {
    if (!selectedMediaCuts || selectedMediaCuts.length === 0) return [];
    let materials: string[];
    if (itemId) {
        const mediaCut = selectedMediaCuts.map(selectedMcId => {
            return mediaCutMetadata.filter(mc => mc.id === selectedMcId && mc.forItem === itemId)[0];
        });
        materials = mediaCut.map(mc => mediaCutInfoString(mc));
    } else {
        const mediaCut = selectedMediaCuts.map(selectedMcId => {
            return mediaCutMetadata.filter(mc => mc.id === selectedMcId)[0];
        });
        materials = mediaCut.map(mc => mediaCutInfoString(mc));
    }
    return materials;
}

export function concatConstraintsTooltip(entryMetadata: (ComponentInfoDto & { entryId: string }) | undefined): string {
    const rightsString =
        entryMetadata && entryMetadata.rights.length > 0
            ? entryMetadata.rights
                  .map((v: any) => {
                      return `\n ${v}`;
                  })
                  .join(',')
            : '';
    const constraintString =
        entryMetadata && entryMetadata.usageConstraints.length > 0
            ? entryMetadata.usageConstraints
                  .map((v: any) => {
                      return `\n ${v}`;
                  })
                  .join(',')
            : '';

    const constraintRemarkString =
        entryMetadata && entryMetadata.usageConstraintRemarks.length > 0
            ? entryMetadata.usageConstraintRemarks
                  .map((v: any) => {
                      return `\n ${v}`;
                  })
                  .join(',')
            : '';

    return `${rightsString} ${constraintString} ${constraintRemarkString}`;
}

export function mediaCutInfoString(mc: MediaCutDto) {
    let materialString = mediaCutDescription(mc);

    // Special cases:
    const isFuzzy = mc.mediaCutType === 'Fuzzy';
    const group = `${mc.group?.total! > 1 ? mc.group?.number + '/' + mc.group?.total : ''}`;

    if (isFuzzy) materialString = `${group} Standard-verlinktes File ${mc.fileFormat}`;
    switch (mc.mediaType) {
        case 'Essence': {
            materialString = `${group} Nicht-Standard-Archivformat`;
            break;
        }
        case 'Other': {
            isFuzzy ? (materialString = `${group} Standard-verlinktes File`) : (materialString = `Andere`);
            break;
        }
    }

    return materialString;
}
