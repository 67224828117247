import { createAction, props } from '@ngrx/store';
import { UserInformation } from './user.state';

export enum UserActions {
    GET_USER_INFORMATION = '[User] Get user information',
    SET_USER_INFORMATION = '[User] Set user information',
}

export const getUserInformation = createAction(UserActions.GET_USER_INFORMATION);

export const setUserInformation = createAction(
    UserActions.SET_USER_INFORMATION,
    props<{ userInfo: UserInformation }>()
);
