import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'faro-inline-edit-field',
    templateUrl: './inline-edit-field.component.html',
    styleUrls: ['./inline-edit-field.component.scss'],
})
export class InlineEditFieldComponent {
    @Input()
    placeholder: string = '';

    @Input()
    iconAltText: string = '';

    @Input()
    displayString: string = '';

    @Input()
    iconPlacementRight: boolean = false;

    @Output()
    displayStringChanged = new EventEmitter<string>();

    @Output()
    inputFieldFocused = new EventEmitter();

    @Output()
    lostFocus = new EventEmitter();

    isInEditMode: boolean = false;
    tempString: string = '';
    private static _seq: number = 0;
    public inlineEditComponentId: string = 'inline_input_' + InlineEditFieldComponent._seq++;

    constructor() {}

    editString(event: Event) {
        event.stopPropagation();
        this.tempString = this.displayString;
        this.isInEditMode = true;
        setTimeout(() => {
            document.getElementById(this.inlineEditComponentId)?.focus();
        });
    }

    changeString(event: Event): void {
        if ((event as KeyboardEvent).key === 'Enter') {
            this.updateString();
        }
        if ((event as KeyboardEvent).key === 'Escape') {
            this.isInEditMode = false;
            this.lostFocus.emit();
        }
    }

    onBlur() {
        this.updateString();
    }

    onFocus() {
        this.inputFieldFocused.emit();
    }

    clickOnInputField(event: Event) {
        event.stopPropagation();
    }

    updateString() {
        this.displayString = this.tempString;
        this.isInEditMode = false;
        this.lostFocus.emit();
        this.displayStringChanged.emit(this.displayString);
    }
}
