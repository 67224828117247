<div class="dialog-content" role="dialog" aria-labelledby="dialogTitle">
    <div class="dialog-header">
        <h2 class="title" id="dialogTitle">
            <img src="assets/icons/warning-icon.svg" alt="" /> {{ config.data.title }}
        </h2>
        <div>
            <button pRipple class="close-icon" autofocus aria-label="Dialog schliessen" (click)="closeDialog()">
                <img src="assets/icons/close-icon.svg" alt="Dialog schliessen" />
            </button>
        </div>
    </div>
    <div class="information-text">
        Wenn Sie diese Auswahl in den Warenkorb legen, wird die Limite überschritten und der Warenkorb kann nicht bestellt werden.    </div>
    <div class="dialog-footer">
        <button class="search-button" type="button" (click)="accept()" pRipple>
            <img src="assets/icons/check-icon.svg" alt="" />
            Trotzdem in den Warenkorb
        </button>
        <button
            class="dialog-secondary-button"
            type="button"
            pButton
            icon="pi pi-times"
            (click)="closeDialog()"
            label="Nicht in den Warenkorb"
        ></button>
    </div>
</div>
