import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ConfirmationDialogService } from '../../../shared/services/confirmation-dialog.service';
import {
    selectAudioLabel,
    selectDetailMediaSelection,
    selectDetailSubtitleTranscript,
    selectLoadingSubtitleTranscriptTab,
} from '../../details-state/details.selectors';
import { Observable, of, Subject, takeUntil, catchError } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    clearHoveredTimeFrame,
    getDetailItemSubtitleTranscript,
    getDetailProgramSubtitleTranscript,
    setHoveredTimeFrame,
} from '../../details-state/details.actions';
import { parseTimeSpan } from '../../../shared/timespan';
import { TimeService } from '../../shared/services/time.service';
import { TypedSubtitleTranscriptLineDto, TypedSubtitleTranscriptMetadataDto } from '../../shared/typed_metadata.model';
import { Duration } from '../../../shared/duration';
import { KeyframesService } from '@faro/metadata-angular-client';
import { StoreUtilsService } from '../../../state/store-utils.service';
import {
    OrderRemarkDialogContentAndConfig,
    OrderRemarkResponse,
} from '../../../shared/dialogs/order-remark-dialog/order-remark-dialog-content-and.config';
import { focusElement } from '../../../shared/focus-element.helper';
import { addItemToActiveShoppingCart } from '../../../order/shopping-cart-state/shopping-cart.actions';
import { OrderParamService } from '../../shared/services/order-param.service';
import { AddShoppingCartEntryRequestDto } from '@faro/order-angular-client';
import { accessibilityDurationString } from '../../../shared/accessibility-duration.helper';

@Component({
    selector: 'app-subtitle-transcript-content',
    templateUrl: './subtitle-transcript-content.component.html',
    styleUrls: ['./subtitle-transcript-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubtitleTranscriptContentComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    itemId: string | undefined;

    @Input()
    programId: string = '';

    @Input()
    contentType: string = '';

    @Input()
    readonly: boolean = false;

    @Input()
    keywords: string[] = [];

    loadingSubtitleTranscriptTab$: Observable<boolean>;
    currentVtc$: Observable<Duration | null>;

    highlightedRowIndex: number | undefined;

    activeSubtitleTranscriptLine: string = '';
    subtitleTranscriptEntryIndex: number = -1;

    tableHeader = [
        { value: 'Medien', scssClass: 'media-header' },
        { value: 'Untertitel/Transkript', scssClass: 'subtitle-transcript-header' },
    ];

    tableRows: TypedSubtitleTranscriptMetadataDto[] = [];

    private _destroyed$ = new Subject<void>();

    constructor(
        private readonly confirmationDialogService: ConfirmationDialogService,
        private readonly keyframeService: KeyframesService,
        private readonly storeUtils: StoreUtilsService,
        private readonly orderParamService: OrderParamService,
        private store: Store,
        private timeService: TimeService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone
    ) {
        this.loadingSubtitleTranscriptTab$ = this.store.select(selectLoadingSubtitleTranscriptTab);
        this.currentVtc$ = this.timeService.currentVtc$;
    }

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            this.timeService.currentVtc$.pipe(takeUntil(this._destroyed$)).subscribe(currentVtc => {
                const subtitleTranscriptEntryIndex = this.getActiveSubtitleTranscript(currentVtc, this.tableRows);
                const subtitleTranscriptEntry = this.tableRows[subtitleTranscriptEntryIndex];
                let activeSubtitleTranscriptLine = '';

                if (subtitleTranscriptEntry) {
                    const subtitleTranscriptLineEntry =
                        subtitleTranscriptEntry.lines[
                            this.getActiveSubtitleTranscript(currentVtc, subtitleTranscriptEntry.lines)
                        ];
                    activeSubtitleTranscriptLine = subtitleTranscriptLineEntry?.text || '';
                }

                if (this.subtitleTranscriptEntryIndex !== subtitleTranscriptEntryIndex) {
                    this.subtitleTranscriptEntryIndex = subtitleTranscriptEntryIndex;
                    this.cdr.detectChanges();
                }

                if (this.activeSubtitleTranscriptLine !== activeSubtitleTranscriptLine) {
                    this.activeSubtitleTranscriptLine = activeSubtitleTranscriptLine;
                    this.cdr.detectChanges();
                }
            });
        });

        this.store
            .select(selectDetailSubtitleTranscript)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((content: TypedSubtitleTranscriptMetadataDto[] | undefined) => {
                this.tableRows = (content || []).map(s => ({
                    ...s,
                    length: s.length.split('.')[0] ?? '0',
                    duration: parseTimeSpan(s.length),
                    keyframeBlob$: this.getKeyframeBlob(s.closedCaptionKeyframeId),
                }));
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.programId) return;

        if (this.contentType === 'item') {
            this.store.dispatch(getDetailItemSubtitleTranscript({ programId: this.programId, itemId: this.itemId! }));
        } else {
            this.store.dispatch(getDetailProgramSubtitleTranscript({ programId: this.programId }));
        }
    }

    setHoveredTimeFrame(sequence: TypedSubtitleTranscriptMetadataDto) {
        this.store.dispatch(
            setHoveredTimeFrame({
                hoveredVtcIn: parseTimeSpan(sequence.vtcIn),
                hoveredVtcOut: parseTimeSpan(sequence.vtcOut),
            })
        );
    }

    clearHoveredTimeFrame(): void {
        this.store.dispatch(clearHoveredTimeFrame());
    }

    getActiveSubtitleTranscript(
        currentVtc: Duration | null,
        entryArray: TypedSubtitleTranscriptMetadataDto[] | TypedSubtitleTranscriptLineDto[]
    ): number {
        if (!currentVtc) return -1;

        const currentVtcMs = currentVtc.asMilliseconds();

        return entryArray.findIndex(
            (subtitleTranscript: TypedSubtitleTranscriptMetadataDto | TypedSubtitleTranscriptLineDto) => {
                return (
                    currentVtcMs >= subtitleTranscript.vtcInDuration.asMilliseconds() &&
                    currentVtcMs <= subtitleTranscript.vtcOutDuration.asMilliseconds()
                );
            }
        );
    }

    showActions(event: Event, index: number): void {
        event.stopPropagation();
        this.highlightedRowIndex = index;
    }

    jumpToTimeCode(event: Event, timeCode: TypedSubtitleTranscriptLineDto) {
        event.stopPropagation();
        const vtcIn = parseTimeSpan(timeCode.vtcIn);
        this.timeService.jumpToVtc(vtcIn);
    }

    async orderWithRemark(event: Event, entry: TypedSubtitleTranscriptMetadataDto, id: string) {
        event.stopPropagation();
        const audioLabel = await this.storeUtils.snapshot(selectAudioLabel);
        const selectedMedia = await this.storeUtils.snapshot(selectDetailMediaSelection);
        const selectionIds = await this.orderParamService.getSelectionIds();
        const selectedContent: OrderRemarkDialogContentAndConfig = {
            audioTrack: audioLabel,
            displayCheckBox: true,
        };
        this.confirmationDialogService
            .openRemarkDialog(selectedContent)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: OrderRemarkResponse) => {
                if (data) {
                    const item: AddShoppingCartEntryRequestDto = {
                        itemId: selectionIds.itemId,
                        programId: selectionIds.programId,
                        vtcIn: entry.vtcIn,
                        vtcOut: entry.vtcOut,
                        selectedAudioTrack: data.orderAudioOnly ? selectedMedia.audioTrack : undefined,
                        remark: data.orderRemark,
                        ignoreUserRestrictions: false,
                        ignoreSizeLimit: false,
                    };
                    this.store.dispatch(addItemToActiveShoppingCart({ params: item }));
                }
                focusElement(id);
            });
    }

    async addToShoppingCart(event: Event, entry: TypedSubtitleTranscriptMetadataDto, id: string) {
        event.stopPropagation();
        const selectionIds = await this.orderParamService.getSelectionIds();
        const item: AddShoppingCartEntryRequestDto = {
            itemId: selectionIds.itemId,
            programId: selectionIds.programId,
            vtcIn: entry.vtcIn,
            vtcOut: entry.vtcOut,
            ignoreUserRestrictions: false,
            ignoreSizeLimit: false,
        };
        this.store.dispatch(addItemToActiveShoppingCart({ params: item }));
        focusElement(id);
    }

    getKeyframeBlob(keyframeId: string | null | undefined): Observable<Blob | null> {
        return keyframeId && keyframeId !== '00000000-0000-0000-0000-000000000000'
            ? this.keyframeService.keyframesGetForClosedCaption(keyframeId).pipe(catchError(_ => of(null)))
            : of(null);
    }

    navigateToSequence(sequence: TypedSubtitleTranscriptMetadataDto): void {
        const vtcIn = parseTimeSpan(sequence.vtcIn);
        try {
            this.timeService.jumpToVtc(vtcIn);
        } catch (_) {
            // silently ignore this error - might occur if the current media cut does not "contain" all sequences
        }
    }

    getAccessibilityDurationString(duration: Duration): string {
        return accessibilityDurationString(duration);
    }
}
