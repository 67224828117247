<ng-container *ngIf="!isInEditMode">
    <div
        class="display-string"
        [ngStyle]="{ 'justify-content': iconPlacementRight ? 'space-between' : 'normal' }"
        (click)="editString($event)"
    >
        <div class="display-string-entry" [ngClass]="{ 'empty-string': !displayString }">
            {{ displayString ? displayString : placeholder }}
        </div>
        <button pRipple type="button" class="edit-button" (click)="editString($event)">
            <img class="edit-image" src="assets/icons/edit.svg" [alt]="iconAltText" />
        </button>
    </div>
</ng-container>
<ng-container *ngIf="isInEditMode">
    <div class="input-field-container">
        <input
            id="{{ inlineEditComponentId }}"
            class="inline-input-field"
            pInputText
            type="text"
            [(ngModel)]="tempString"
            (keydown)="changeString($event)"
            (blur)="onBlur()"
            (focus)="onFocus()"
            (click)="clickOnInputField($event)"
        />
    </div>
</ng-container>
