import {
    ShoppingCartHitlistSortingAttribute,
    ShoppingCartHitlistSortingAttributeOption,
} from '../../../shopping-cart-state/shopping-cart.state';

export interface SortingAttributeValues {
    label: string;
    value: ShoppingCartHitlistSortingAttribute;
}

export function sortingAttributesToLabel(
    options: ShoppingCartHitlistSortingAttributeOption[] | undefined
): SortingAttributeValues[] {
    let optionFields: SortingAttributeValues[] = [];
    options?.forEach(f => {
        switch (f.value) {
            case ShoppingCartHitlistSortingAttribute.DESCRIPTION:
                optionFields.push({
                    label: 'Bezeichnung',
                    value: f.value,
                });
                break;
            case ShoppingCartHitlistSortingAttribute.NUMBER:
                optionFields.push({
                    label: 'Nummer',
                    value: f.value,
                });
                break;
            case ShoppingCartHitlistSortingAttribute.ENTRIES:
                optionFields.push({
                    label: 'Einträge',
                    value: f.value,
                });
                break;
            case ShoppingCartHitlistSortingAttribute.CREATION_DATE:
                optionFields.push({
                    label: 'Erstelldatum',
                    value: f.value,
                });
                break;
            case ShoppingCartHitlistSortingAttribute.ORDER_STATE:
                optionFields.push({
                    label: 'Status',
                    value: f.value,
                });
                break;
            case ShoppingCartHitlistSortingAttribute.CREATOR:
                optionFields.push({
                    label: 'Ersteller:in',
                    value: f.value,
                });
                break;
        }
    });
    return optionFields;
}
