import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiLibraryModule } from '../ui-library/ui-library.module';
import { SharedModule } from '../shared/shared.module';
import { ShoppingCartMainComponent } from './shopping-cart-main/shopping-cart-main.component';
import { OrderRoutingModule } from './order-routing.module';
import { ActiveShoppingCartComponent } from './active-shopping-cart/active-shopping-cart.component';
import { ShoppingCartHeaderComponent } from './shopping-cart-header/shopping-cart-header.component';
import { ShoppingCartInfoComponent } from './shopping-cart-info/shopping-cart-info.component';
import { FaroUiModule } from '@faro/ngx-faro-ui';
import { ExportSettingsComponent } from './active-shopping-cart/export-settings/export-settings.component';
import { SearchShoppingCartComponent } from './search-shopping-cart/search-shopping-cart.component';
import { ShoppingCartSearchMaskComponent } from './search-shopping-cart/shopping-cart-search-mask/shopping-cart-search-mask.component';
import { ShoppingCartEntriesComponent } from './shopping-cart-entries/shopping-cart-entries.component';
import { ShoppingCartPreviewComponent } from './shopping-cart-preview/shopping-cart-preview.component';
import { ShoppingCartDetailComponent } from './shopping-cart-detail/shopping-cart-detail.component';
import { ShoppingCartHitlistComponent } from './search-shopping-cart/shopping-cart-hitlist/shopping-cart-hitlist.component';
import { DetailsModule } from '../details/details.module';
import { ShoppingCartHitlistControlsComponent } from './search-shopping-cart/shopping-cart-hitlist/shopping-cart-hitlist-controls/shopping-cart-hitlist-controls.component';
import { FormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { ShoppingCartEffects } from './shopping-cart-state/shopping-cart.effects';
import { ExportDestinationDialogComponent } from './active-shopping-cart/export-settings/export-destination-dialog/export-destination-dialog.component';
import {
    ActiveShoppingCartService,
    ShoppingCartService,
    OrderService,
    ReportService,
    ShoppingCartEntryService,
    ShoppingCartSearchService,
} from '@faro/order-angular-client';
import { ErrorEffects } from './shopping-cart-state/error.effects';
import { MediaSelectionDialogComponent } from './shopping-cart-entries/media-selection-dialog/media-selection-dialog.component';
import { DeleteEntryDialogComponent } from './shopping-cart-entries/delete-entry-dialog/delete-entry-dialog.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { TimeService } from '../details/shared/services/time.service';
import { UserSettingsService } from '@faro/profile-angular-client';
import { NotificationEffects } from './shopping-cart-state/notification.effects';
import { EmptyContentComponent } from './shared/empty-content/empty-content.component';

@NgModule({
    declarations: [
        ShoppingCartMainComponent,
        ActiveShoppingCartComponent,
        ShoppingCartHeaderComponent,
        ShoppingCartInfoComponent,
        ExportSettingsComponent,
        SearchShoppingCartComponent,
        ShoppingCartSearchMaskComponent,
        ShoppingCartEntriesComponent,
        ShoppingCartPreviewComponent,
        ShoppingCartDetailComponent,
        ShoppingCartHitlistComponent,
        ShoppingCartHitlistControlsComponent,
        ExportDestinationDialogComponent,
        MediaSelectionDialogComponent,
        DeleteEntryDialogComponent,
        OrderSuccessComponent,
        EmptyContentComponent,
    ],
    imports: [
        CommonModule,
        OrderRoutingModule,
        UiLibraryModule,
        SharedModule,
        FaroUiModule,
        DetailsModule,
        FormsModule,
        LetDirective, PushPipe,
        EffectsModule.forFeature([ShoppingCartEffects, ErrorEffects, NotificationEffects]),
    ],
    providers: [
        ActiveShoppingCartService,
        ShoppingCartService,
        OrderService,
        ReportService,
        ShoppingCartEntryService,
        ShoppingCartSearchService,
        TimeService,
        UserSettingsService,
    ],
    exports: [ShoppingCartHeaderComponent],
})
export class OrderModule {}
