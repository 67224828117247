<ng-container *ngrxLet="isLoading$; let isLoading">
    <div class="container">
        <div *ngIf="showNoSearch$ | async" class="container-empty-state">
            <app-no-search></app-no-search>
        </div>

        <div *ngIf="showNoHits$ | async" class="container-empty-state">
            <app-no-hits></app-no-hits>
        </div>
        <div *ngIf="isLoading" class="search-overlay">
            <p-progressSpinner strokeWidth="2" styleClass="custom-spinner"></p-progressSpinner>
            <div class="search-text">Suche läuft</div>
            <button
                class="cancel-button"
                pButton
                icon="pi pi-times"
                (click)="onCancelSearch()"
                label="Abbrechen"
            ></button>
        </div>

        <div [hidden]="hideHitlist$ | async">
            <div class="hitlist-header">
                <div>
                    <h2 class="hitlist-header-title">
                        <ng-container *ngrxLet="totalHitCount$; let totalHitCount">
                            <span *ngIf="isLoading">Suche Treffer</span>
                            <ng-container *ngIf="!isLoading">
                                <span *ngIf="(totalHitCount ?? 0) >= 10000">Mehr als </span>
                                <span *ngIf="totalHitCount">{{ totalHitCount | numberSeparator }} Treffer</span>
                            </ng-container>
                        </ng-container>
                    </h2>
                </div>
                <app-hitlist-controls></app-hitlist-controls>
            </div>
            <ng-container>
                <app-table-hitlist
                    [hitlist]="tableHitlist$ | async"
                    [isLoadingNextPageIndicator]="isLoadingNextPage"
                    [isSearchRestricted]="(areProfileFiltersSet$ | async) === false"
                ></app-table-hitlist>
            </ng-container>
        </div>
    </div>
</ng-container>
