import {
    HitlistSortingAttribute,
    HitlistSortingAttributeOption,
} from 'src/app/search/search-state/search-options.state';

export interface SortingAttributeValues {
    label: string;
    value: HitlistSortingAttribute;
}

export function sortingAttributesToLabel(options: HitlistSortingAttributeOption[]): SortingAttributeValues[] {
    let optionFields: SortingAttributeValues[] = [];
    options.forEach(f => {
        switch (f.value) {
            case HitlistSortingAttribute.RELEVANCE:
                optionFields.push({
                    label: 'Relevanz',
                    value: f.value,
                });
                break;
            case HitlistSortingAttribute.DATE:
                optionFields.push({
                    label: 'Datum',
                    value: f.value,
                });
                break;
            case HitlistSortingAttribute.PROGRAM:
                optionFields.push({
                    label: 'Sendung',
                    value: f.value,
                });
                break;
            case HitlistSortingAttribute.DURATION:
                optionFields.push({
                    label: 'Dauer',
                    value: f.value,
                });
                break;
        }
    });
    return optionFields;
}
