import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getUserInformation, setUserInformation } from './user.actions';
import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs';
import { UserService } from '../auth/user.service';
import { UserInformation } from './user.state';

@Injectable()
export class UserEffects {
    constructor(private actions$: Actions, private userService: UserService) {}

    getUserInformation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUserInformation),
            switchMap(() => {
                return this.userService.userInformation$.pipe(take(1)).pipe(
                    map((userInfo: UserInformation) => {
                        return setUserInformation({ userInfo });
                    })
                );
            })
        );
    });
}
