import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { ProfileFiltersComponent } from '../../search-input/profile-filters/profile-filters.component';
import { selectSearchProfileDefault } from '../../search-state/search-profile.selectors';
import { selectSearchResultTerm } from '../../search-state/search-result.selectors';

@Component({
    selector: 'app-no-hits',
    templateUrl: './no-hits.component.html',
    styleUrls: ['./no-hits.component.scss'],
})
export class NoHitsComponent {
    searchTerm$: Observable<string | null | undefined>;
    areProfileFiltersSet$: Observable<boolean | undefined>;

    constructor(private readonly store: Store, private readonly dialogService: DialogService) {
        this.searchTerm$ = this.store.select(selectSearchResultTerm);
        this.areProfileFiltersSet$ = this.store.select(selectSearchProfileDefault);
    }

    openProfileFiltersDialog(): void {
        this.dialogService.open(ProfileFiltersComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'profile-dialog',
            data: { title: 'Sucheinstellungen' },
        });
    }
}
