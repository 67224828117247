<div [id]="flowPlayerId" #flowPlayer class="flow-player use-play-3" [ngStyle]="{ height: isAudioProgram ? '0' : '100%' }" (mouseenter)="displayMainControls()" (mousemove)="mouseMoving()" [ngClass]="{'flow-player43' : aspectRatio === '4:3'}">
    <ng-container *ngIf="!isAudioProgram">
        <div class="seek-overlay" *ngrxLet="seekOverlayUrl$; let seekOverlayUrl" [ngClass]="{'seek-overlay-43' : aspectRatio === '4:3'}">
            <img *ngIf="seekOverlayUrl" [src]="seekOverlayUrl" alt="" [ngStyle]="{'height': aspectRatio === '4:3' ? heightOnAspectRatio43 : '100%', 'width':aspectRatio === '4:3' ? widthOnAspectRatio43 : '100%'}" />
        </div>
    </ng-container>
</div>
<ng-container *ngrxLet="playing$; let playing">
    <div class="player-controls" *ngIf="playerLoaded" (mouseenter)="mouseEnterControls()" (mouseleave)="mouseLeaveControls()" [ngClass]="{'player-controls-absolut' : !isAudioProgram, 'hide-controls': !isAudioProgram && hideMainControl, 'un-hide-controls' : !isAudioProgram && !hideMainControl}">
        <div class="slider-container" *ngrxLet="mediaCut$; let mediaCut">
            <p-slider
                class="progress-bar"
                [min]="0"
                [max]="1"
                [step]="0.001"
                [ngModel]="relativePosition"
                (mousedown)="onRelativePositionHandleActivated()"
                (onChange)="onRelativePositionChanged($event.value)"
                (keyup)="onSpaceKeyUp($event, playing)"
            ></p-slider>
            <ng-container>
                <app-player-cut-marker
                    *ngIf="mediaCut"
                    [markerVtcIn]="markerVtcIn"
                    [markerVtcOut]="markerVtcOut"
                    [totalRangeFrom]="mediaCut.vtcIn"
                    [totalRangeTo]="mediaCut.vtcOut"
                ></app-player-cut-marker>
            </ng-container>
            <ng-container>
                <app-player-restriction-marker
                    *ngIf="mediaCut"
                    [vtcIn]="mediaCut.vtcIn"
                    [vtcOut]="mediaCut.vtcOut"
                    [restrictions]="restrictions"
                ></app-player-restriction-marker>
            </ng-container>
        </div>
        <div class="player-main-controls-container" #controls >
            <div class="left-controls">
                <button
                    id="pause-button"
                    *ngIf="playing"
                    pRipple
                    title="Pause (Leertaste)"
                    type="button"
                    class="player-button player-main-control-button"
                    (click)="pause()"
                    data-cy="player-pause-button"
                >
                    <img src="assets/icons/player-pause.svg" alt="Pause" />
                </button>
                <button
                    id="play-button"
                    *ngIf="!playing"
                    pRipple
                    title="Abspielen (Leertaste)"
                    type="button"
                    class="player-button player-main-control-button"
                    (click)="play()"
                    data-cy="player-play-button"
                >
                    <img src="assets/icons/player-play.svg" alt="Abspielen" />
                </button>
                <div class="playback-rate-button-container">
                    <button
                        pRipple
                        type="button"
                        role="button"
                        aria-haspopup="true"
                        aria-label="Abspielgeschwindigkeit"
                        title="Abspielgeschwindigkeit (a)"
                        [attr.aria-expanded]="playBackRateMenu.visible"
                        class="player-button player-main-control-button"
                        (click)="
                            openMenuAndSetFocus($event, playBackRateMenu);
                            closeOtherMenus($event, materialMenu, audioMenu)
                        "
                    >
                        {{ selectedPlaybackRateLabel }}
                    </button>
                    <p-menu
                        class="playback-menu"
                        role="menu"
                        #playBackRateMenu
                        [model]="playbackRateOptions"
                        [popup]="true"
                        (mouseleave)="closeMenuOnMouseLeave($event, playBackRateMenu)"
                    ></p-menu>
                    <div class="not-default-audio-setting" *ngIf="!isDefaultAudioOption"></div>
                </div>
                <div class="volume-control" (mouseenter)="showVolumeBar()" (mouseleave)="hideVolumeBar()">
                    <button
                        pRipple
                        type="button"
                        class="player-button player-main-control-button"
                        (click)="toggleMute()"
                    >
                        <ng-container *ngIf="!isMuted"
                            ><img src="assets/icons/player-volume.svg" alt="Lautstärke" aria-label="Stummschalten"
                        /></ng-container>
                        <ng-container *ngIf="isMuted"
                            ><img src="assets/icons/player-mute.svg" alt="Stumm" aria-label="Ton einschalten"
                        /></ng-container>
                    </button>
                    <div
                        class="volume-bar-container"
                        [@displayHideVolumeBar]="displayVolumeBar ? 'displayVolumeBar' : 'hideVolumeBar'"
                    >
                        <p-slider
                            class="volume-bar"
                            [ngModel]="volumeLevel"
                            orientation="horizontal"
                            [min]="0"
                            [max]="1"
                            [step]="0.01"
                            aria-label="Lautstärke anpassen"
                            (onChange)="onVolumeLevelChanged($event.value)"
                        ></p-slider>
                    </div>
                </div>
                <div class="audio-settings-button-container">
                    <button
                        pRipple
                        type="button"
                        role="button"
                        aria-haspopup="true"
                        [attr.aria-expanded]="audioMenu.visible"
                        class="player-button player-main-control-button"
                        (click)="
                            openMenuAndSetFocus($event, audioMenu);
                            closeOtherMenus($event, materialMenu, playBackRateMenu)
                        "
                    >
                        <img src="assets/icons/player-audio-settings.svg" alt="Audio Einstellungen" />
                    </button>
                    <p-menu
                        class="audio-menu"
                        role="menu"
                        #audioMenu
                        [model]="audioOptions"
                        [popup]="true"
                        (mouseleave)="closeMenuOnMouseLeave($event, audioMenu)"
                    ></p-menu>
                    <div class="not-default-audio-setting" *ngIf="!isDefaultAudioOption"></div>
                </div>
                <div class="material-button-container">
                    <button
                        pRipple
                        type="button"
                        role="button"
                        aria-haspopup="true"
                        [attr.aria-expanded]="materialMenu.visible"
                        class="player-button player-main-control-button material-button"
                        [ngClass]="{'material-label-volume' : displayVolumeBar && !hideControlButtons && !hideRemainingControlButtons, 'material-label-medium' : hideControlButtons, 'material-label-small' : hideRemainingControlButtons}"
                        (click)="
                            openMenuAndSetFocus($event, materialMenu);
                            closeOtherMenus($event, audioMenu, playBackRateMenu)
                        "
                    >
                        {{ selectedMaterialLabel }}
                    </button>
                    <p-menu
                        class="material-menu"
                        role="menu"
                        #materialMenu
                        [model]="materialOptions"
                        [popup]="true"
                        (mouseleave)="closeMenuOnMouseLeave($event, materialMenu)"
                    ></p-menu>
                    <div class="not-default-audio-setting" *ngIf="!isDefaultAudioOption"></div>
                </div>
            </div>
            <div class="middle-controls">
                <button
                    *ngIf="!hideControlButtons"
                    pRipple
                    type="button"
                    class="player-button player-secondary-control-button"
                    (click)="jumpToStart()"
                >
                    <img src="assets/icons/player-first.svg" alt="Zum Anfang" />
                </button>
                <button
                    *ngIf="!hideControlButtons"
                    pRipple
                    type="button"
                    class="player-button player-secondary-control-button"
                    aria-label="60 Sekunden zurück"
                    (click)="jump(-60)"
                >
                    -60
                </button>
                <button
                    *ngIf="!hideRemainingControlButtons"
                    pRipple
                    type="button"
                    class="player-button player-secondary-control-button"
                    aria-label="10 Sekunden zurück"
                    (click)="jump(-10)"
                >
                    -10
                </button>
                <button
                    *ngIf="!hideRemainingControlButtons"
                    pRipple
                    type="button"
                    class="player-button player-secondary-control-button"
                    aria-label="1 Sekunde zurück"
                    (click)="jump(-1)"
                >
                    -1
                </button>
                <div class="player-label" data-cy="player-current-time-label">{{ currentTimeLabel }}</div>
                <button
                    *ngIf="!hideRemainingControlButtons"
                    pRipple
                    type="button"
                    class="player-button player-secondary-control-button"
                    aria-label="10 Sekunden vor"
                    (click)="jump(+10)"
                >
                    +10
                </button>
                <button
                    *ngIf="!hideRemainingControlButtons"
                    pRipple
                    type="button"
                    class="player-button player-secondary-control-button"
                    aria-label="60 Sekunden vor"
                    (click)="jump(+60)"
                >
                    +60
                </button>
            </div>
        </div>
    </div>
</ng-container>
