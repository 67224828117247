import { createAction, props } from '@ngrx/store';

export enum RouteHistoryActions {
    ADD_TO_HISTORY = '[RouteHistory] Add to history',
}

export const addToHistory = createAction(
    RouteHistoryActions.ADD_TO_HISTORY,
    props<{ url: string; }>()
);
