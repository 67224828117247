<ng-container *ngrxLet="loadingLightTable$; let loading">
    <ng-container *ngIf="lightTableData && lightTableData.length > 0 && !loading">
        <div #lightTable class="light-table-container" data-cy="light-table">
            <ng-container *ngFor="let keyframeData of lightTableData">
                <div
                    class="image-container"
                    [ngClass]="{
                        'active-keyframe-scroll': keyframeData.id === activeKeyframeId,
                        'no-access': !keyframeData.visible,
                        'image-container43' : mediaAspectRatio === '4:3',
                        'image-container169' : mediaAspectRatio === '16:9'
                    }"
                    (click)="navigateTo(keyframeData.vtcInDuration)"
                >
                    <div *ngIf="keyframeData.id === activeKeyframeId" class="active-keyframe-border"></div>
                    <div *ngIf="selectionKeyframes[0] === keyframeData.id && markVtcIn" class="in-out-label in-label">
                        IN
                    </div>
                    <div class="hovered-keyframe" *ngIf="hoveredKeyframes.indexOf(keyframeData.id) > -1"></div>
                    <img draggable="false"
                        [src]="keyframeData.visible ? keyframeData.keyFrame : 'assets/images/no-access-keyframe.png'"
                        alt=""
                        [ngClass]="{ 'contains-in-out': selectionKeyframes.indexOf(keyframeData.id) > -1 }"
                    />
                    <div
                        *ngIf="selectionKeyframes[selectionKeyframes.length - 1] === keyframeData.id && markVtcOut"
                        class="in-out-label out-label"
                    >
                        OUT
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="loading-container">
            <p-progressSpinner strokeWidth="2" animationDuration="5s" styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </ng-container>
</ng-container>
