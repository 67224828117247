import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
    columns,
    ColumnsModel,
    entryDescription,
    concatConstraintsTooltip,
    entryMaterials,
} from './shopping-cart-entries.model';
import { Router } from '@angular/router';
import { ShoppingCartEntryDto } from '@faro/order-angular-client/model/shoppingCartEntryDto';
import { Store } from '@ngrx/store';
import {
    changeActiveShoppingCartEntryPosition,
    deleteActiveShoppingCartEntry,
    getAvailableMaterial,
    setSelectedMaterial,
    setActiveShoppingCartEntryRemark,
    setScrollTopPosition,
} from '../shopping-cart-state/shopping-cart.actions';
import { catchError, Observable, of, Subject, takeUntil } from 'rxjs';
import { ComponentInfoDto, KeyframesService, MediaCutDto } from '@faro/metadata-angular-client';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MediaSelectionDialogComponent } from './media-selection-dialog/media-selection-dialog.component';
import { DeleteEntryDialogComponent } from './delete-entry-dialog/delete-entry-dialog.component';
import { selectEntryErrors, selectScrollTopPosition } from '../shopping-cart-state/shopping-cart.selectors';
import { SafeUrl } from '@angular/platform-browser';
import { parseTimeSpan } from '../../shared/timespan';
import { Duration, fromMilliseconds } from '../../shared/duration';
import { formatAudioTrack } from '../shared/media-cut-description.helper';
import { accessibilityDurationString } from '../../shared/accessibility-duration.helper';
import { CartEntryErrorDto } from '@faro/order-angular-client';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-shopping-cart-entries',
    templateUrl: './shopping-cart-entries.component.html',
    styleUrls: ['./shopping-cart-entries.component.scss'],
})
export class ShoppingCartEntriesComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input()
    readonly: boolean = false;

    @Input()
    loading: boolean | null = false;

    @Input()
    entries: ShoppingCartEntryDto[] | undefined = [];

    @Input()
    entriesMetadata: Array<ComponentInfoDto & { entryId: string }> | undefined = [];

    @Input()
    entriesMediaCutMetadata: MediaCutDto[] = [];

    @Input()
    cartId: string = '';

    @ViewChild('scrollTable') scrollTable: Table | undefined;

    hasEntries: boolean = false;

    highlightedRowIndex: number | undefined;

    cols: ColumnsModel[] = columns;

    entryList: ShoppingCartEntryModel[] = [];

    isActiveCart: boolean = false;

    scrollTopPosition: number = 0;

    currentRemarkFocus: number | undefined = undefined;

    private _destroyed$ = new Subject<void>();

    constructor(
        private router: Router,
        private readonly store: Store,
        private readonly keyframeService: KeyframesService,
        private readonly dialogService: DialogService,
        private ref: DynamicDialogRef
    ) {
        this.isActiveCart = router.url.includes('active');
    }

    ngOnInit() {
        this.store
            .select(selectEntryErrors)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: CartEntryErrorDto[]) => {
                this.resetErrorMessages();
                data.forEach(error => {
                    this.setErrorMessage(error);
                });
                if (data.length > 0) {
                    const firstEntryWithError = data[0].cartEntryId;
                    if (firstEntryWithError && this.getScrollElement()) {
                        this.scrollToFirstEntryWithError(firstEntryWithError);
                    }
                }
            });
        this.store
            .select(selectScrollTopPosition)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.scrollTopPosition = data;
            });
    }

    ngAfterViewInit() {
        const $scrollElement = this.getScrollElement();
        if ($scrollElement) $scrollElement.scroll(0, this.scrollTopPosition);
    }

    scrollToFirstEntryWithError(entryId: string) {
        const rowWithError = document.getElementById(entryId);
        if (rowWithError) {
            rowWithError.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
        }
    }

    resetErrorMessages() {
        this.entryList.forEach(v => (v.entryError = ''));
    }

    setErrorMessage(error: CartEntryErrorDto) {
        let cartEntry = this.entryList.find(v => v.id === error.cartEntryId);
        if (cartEntry) {
            cartEntry.entryError = error.errorMessage;
        }
    }

    ngOnChanges(_: SimpleChanges) {
        if (this.entries) {
            this.entryList = this.entries?.map(entry => {
                const entryMetadata = this.entriesMetadata?.filter(metadata => entry.id === metadata.entryId)[0];
                const mediaCutMetadata = entry.selectedMediaCuts?.map(mcId => {
                    return this.entriesMediaCutMetadata.filter(v => v.id === mcId)[0];
                });
                const displayTitle: string = entryDescription(entry.entryType, entryMetadata);
                let materials: string[];
                if (entry.selectedAudioTrack) {
                    materials = [formatAudioTrack(entry.selectedAudioTrack)];
                } else {
                    materials = entryMaterials(
                        this.entriesMediaCutMetadata,
                        entry.selectedMediaCuts,
                        entry.itemId ? entry.itemId : null
                    );
                }

                const tooltipString = concatConstraintsTooltip(entryMetadata);
                const tooltip = `<div>Rechtehinweis: <span>${tooltipString}</span></div>`;

                return {
                    id: entry.id,
                    itemId: entry.itemId,
                    programId: entry.programId,
                    sequenceId: entry.sequenceId,
                    rights: entryMetadata?.rights ?? [],
                    usageConstraintRemarks: entryMetadata?.usageConstraintRemarks ?? [],
                    usageConstraints: entryMetadata?.usageConstraints ?? [],
                    description: displayTitle,
                    remark: entry.remark,
                    mediaSelection: materials,
                    selectedAudioTrack: entry.selectedAudioTrack,
                    length: parseTimeSpan(entry.exportLength),
                    transmissionDate: entryMetadata?.transmissionDate,
                    recordDate: entryMetadata?.recordDate,
                    rightsTooltip: tooltip,
                    offsetTooltip: tooltipString,
                    keyframeBlob$: this.getKeyframeBlob(entryMetadata?.keyframeId),
                    entryError: '',
                    selectedMediaCuts: entry.selectedMediaCuts ?? [],
                    mediaCutsMetadata: mediaCutMetadata ?? [],
                    selectionVtcIn: entry.vtcIn ?? undefined,
                    selectionVtcOut: entry.vtcOut ?? undefined,
                };
            });
            this.hasEntries = this.entryList && this.entryList.length > 0;
        }
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    showActions(event: Event, index: number): void {
        this.highlightedRowIndex = index;
        event.stopPropagation();
    }

    rowReordered() {
        if (this.entryList) {
            const entryIds = this.entryList.map(entry => entry.id);
            this.store.dispatch(changeActiveShoppingCartEntryPosition({ entryIds }));
        }
    }

    async goToDetailView(entryId: string) {
        this.isActiveCart
            ? await this.router.navigate(['shopping-cart/active/', entryId])
            : await this.router.navigate(['shopping-cart/preview/', this.cartId, entryId]);
        const $scrollElement = this.getScrollElement();
        this.store.dispatch(setScrollTopPosition({ scrollTopPosition: $scrollElement?.scrollTop || 0 }));
    }

    getScrollElement() {
        const $scrollTable = this.scrollTable?.el.nativeElement as HTMLElement;
        return $scrollTable?.querySelector<HTMLElement>('div.p-datatable-wrapper');
    }

    deleteEntry(event: Event, entry: any) {
        event.stopPropagation();
        this.ref = this.dialogService.open(DeleteEntryDialogComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'cart-entry-delete-dialog',
            data: {
                title: 'Eintrag löschen?',
            },
        });

        this.ref.onClose.pipe(takeUntil(this._destroyed$)).subscribe(data => {
            if (data) {
                this.store.dispatch(deleteActiveShoppingCartEntry({ entryId: entry.id }));
            }
        });
    }

    async openMediaSelection(event: Event, entry: ShoppingCartEntryModel) {
        event.stopPropagation();
        this.store.dispatch(
            getAvailableMaterial({
                programId: entry.programId,
                itemId: entry.itemId ?? undefined,
                sequenceId: entry.sequenceId ?? undefined,
                selectionVtcIn: entry.selectionVtcIn,
                selectionVtcOut: entry.selectionVtcOut,
            })
        );
        const selectedEntryMetadata = this.entriesMetadata?.filter(v => v.entryId === entry.id)[0];
        const itemTitle = selectedEntryMetadata?.itemTitle;
        const programTitle = selectedEntryMetadata?.programTitle;
        let dialogTitle = entry.itemId && itemTitle ? itemTitle : programTitle;
        this.ref = this.dialogService.open(MediaSelectionDialogComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'cart-entry-media-selection-dialog',
            data: {
                title: 'Versionen',
                entryTitle: dialogTitle,
                selectedMedia: entry.mediaCutsMetadata,
            },
        });

        this.ref.onClose.pipe(takeUntil(this._destroyed$)).subscribe((data: string[]) => {
            if (data) {
                this.store.dispatch(setSelectedMaterial({ entryId: entry.id, materialIds: data }));
            }
        });
    }

    setFocus(index: number) {
        this.currentRemarkFocus = index;
    }

    resetFocus() {
        this.currentRemarkFocus = undefined;
    }

    isDisplayVisible(index: number): boolean {
        if (this.readonly) return true;
        if (this.currentRemarkFocus === undefined) {
            return this.highlightedRowIndex !== index;
        } else {
            return this.highlightedRowIndex !== index && this.currentRemarkFocus !== index;
        }
    }

    isInlineVisible(index: number): boolean {
        if (this.readonly) return false;
        if (this.currentRemarkFocus === undefined) {
            return this.highlightedRowIndex === index;
        } else {
            return this.currentRemarkFocus === index || this.highlightedRowIndex === index;
        }
    }

    onEntryNoteChanged(event: string, entryId: string, index: number) {
        this.entryList[index].remark = event;
        this.store.dispatch(setActiveShoppingCartEntryRemark({ entryId: entryId, note: JSON.stringify(event) }));
        this.currentRemarkFocus = undefined;
    }

    getKeyframeBlob(keyframeId: number | null | undefined): Observable<Blob | null> {
        return keyframeId && keyframeId > 0
            ? this.keyframeService.keyframesGet(keyframeId).pipe(catchError(_ => of(null)))
            : of(null);
    }

    hasConstraints(entry: ShoppingCartEntryModel): boolean {
        return (
            entry.usageConstraints.length > 0 ||
            entry.usageConstraintRemarks.length > 0 ||
            entry.rights.filter(r => r !== 'Rechte bei SRF/SRG' && r !== 'Rechte bei SWI/SRG' && r !== 'Rechte bei PZBdh / SRG' && r !== 'Rechte bei RTR').length > 0
        ); // Some rights values are not considered to be a constraint
    }

    getAccessibilityDurationString(duration: Duration): string {
        return duration !== null ? accessibilityDurationString(duration) : "unbekannt";
    }
}

interface ShoppingCartEntryModel {
    id: string;
    itemId?: string | null;
    programId: string;
    sequenceId?: string | null;
    description: string;
    remark: string;
    length: Duration | null;
    rights: string[];
    usageConstraintRemarks: string[];
    usageConstraints: string[];
    transmissionDate?: string | null;
    recordDate?: string | null;
    rightsTooltip: string;
    keyframeBlob$: SafeUrl;
    entryError: string;
    selectedMediaCuts: string[];
    mediaCutsMetadata: MediaCutDto[];
    selectionVtcIn?: string;
    selectionVtcOut?: string;
}
