<div class="paginator-container">
    <button pRipple type="button" class="paginator-button" (click)="loadFirstHit()" [disabled]="hitIndex === 0">
        <img [src]="paginatorImgSrcAndDescription.first.icon" [alt]="paginatorImgSrcAndDescription.first.description" />
    </button>
    <button
        pRipple
        type="button"
        class="paginator-button paginator-button-next-prev"
        [disabled]="hitIndex === 0"
        (click)="loadPreviousHit()"
    >
        <img [src]="paginatorImgSrcAndDescription.prev.icon" [alt]="paginatorImgSrcAndDescription.prev.description" />
    </button>
    <div class="page-indicator">{{ hitIndex + 1 }} / {{ hitListLength }}</div>
    <button
        pRipple
        type="button"
        class="paginator-button paginator-button-next-prev"
        (click)="loadNextHit()"
        [disabled]="hitIndex === hitListLength - 1"
    >
        <img [src]="paginatorImgSrcAndDescription.next.icon" [alt]="paginatorImgSrcAndDescription.next.description" />
    </button>
    <button
        pRipple
        type="button"
        class="paginator-button"
        (click)="loadLastHit()"
        [disabled]="hitIndex === hitListLength - 1"
    >
        <img [src]="paginatorImgSrcAndDescription.last.icon" [alt]="paginatorImgSrcAndDescription.last.description" />
    </button>
</div>
