import { Injectable } from '@angular/core';
import { StoreUtilsService } from '../../../state/store-utils.service';
import {
    selectDetailAvComponent,
    selectDetailOrderVtcIn,
    selectDetailOrderVtcOut,
} from '../../details-state/details.selectors';
import { Duration } from '../../../shared/duration';

@Injectable({
    providedIn: 'root',
})
export class OrderParamService {
    constructor(private storeUtils: StoreUtilsService) {}

    async getSelectionIds(): Promise<SelectionIds> {
        return {
            itemId: await this.storeUtils.snapshot(selectDetailAvComponent).then(x => (x ? x.item : '')),
            programId: await this.storeUtils.snapshot(selectDetailAvComponent).then(x => (x ? x.program : '')),
        };
    }

    async getVtcInOutSelection(): Promise<VtcInOutSelection> {
        return {
            vtcIn: await this.storeUtils.snapshot(selectDetailOrderVtcIn),
            vtcOut: await this.storeUtils.snapshot(selectDetailOrderVtcOut),
        };
    }
}

interface SelectionIds {
    itemId: string | undefined;
    programId: string;
}

interface VtcInOutSelection {
    vtcIn: Duration | undefined;
    vtcOut: Duration | undefined;
}
