import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberSeparator',
})
export class NumberSeparatorPipe implements PipeTransform {
    transform(value: number | string | undefined | null): string {
        if (!Number(value) || value === undefined || value == null) {
            return '';
        }
        const decimalParts = value.toString().split('.');
        const reversedSegments = reverseString(decimalParts[0]).match(/.{1,3}/g);
        return decimalParts.length > 1
            ? reverseString(reversedSegments?.join("'")) + '.' + decimalParts[1]
            : reverseString(reversedSegments?.join("'"));
    }
}

function reverseString(input: string | undefined | null): string {
    return input !== null && input !== undefined ? input.split('').reverse().join('') : '';
}
