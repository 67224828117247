<ng-container *ngrxLet="cartInfo$; let cartInfo">
    <app-shopping-cart-header
        [cart]="cartInfo"
        [entryTitle]="entryTitle"
        [itemId]="entryId"
        [showPaginator]="true"
        [showPrint]="true"
        [paginatorIndex]="activeIndex"
        [paginatorLength]="cartInfo.entries?.length ?? 0"
        (paginationRequested)="onPaginationRequested($event, cartInfo.entries, activeIndex, cartInfo.id)"
        (printReport)="printShoppingCartReport()"
    ></app-shopping-cart-header>
    <app-detail-content [readonly]="true"></app-detail-content>
</ng-container>
