<div class="container">
    <div class="control-section">
        <div class="controls">
            <faro-hit-list-actions
                [sortingByValues]="sortingAttributeOptions"
                [sortingOrderValues]="[
                    { value: sortingOrderEnum.DESCENDING, icon: 'pi pi-arrow-down', ariaLabel: 'Absteigend sortieren' },
                    { value: sortingOrderEnum.ASCENDING, icon: 'pi pi-arrow-up', ariaLabel: 'Aufsteigend sortieren' }
                ]"
                [selectedSortingBy]="sortingAttribute"
                [selectedSortingOrder]="sortingOrder$ | async"
                (sortingByChange)="sortingAttributeChanged($event)"
                (sortingOrderChange)="setSortOrder($event)"
            >
            </faro-hit-list-actions>
        </div>
    </div>
</div>
