import { NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitterModule } from 'primeng/splitter';
import { TabViewModule } from 'primeng/tabview';
import { SliderModule } from 'primeng/slider';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { HitlistPaginatorComponent } from './hitlist-paginator/hitlist-paginator.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { TreeModule } from 'primeng/tree';

const primeNgModules = [
    InputTextModule,
    CheckboxModule,
    SelectButtonModule,
    DynamicDialogModule,
    TooltipModule,
    RippleModule,
    TriStateCheckboxModule,
    ButtonModule,
    CalendarModule,
    OverlayPanelModule,
    ScrollPanelModule,
    AutoCompleteModule,
    TableModule,
    ToastModule,
    ProgressSpinnerModule,
    DropdownModule,
    InputSwitchModule,
    SplitterModule,
    TabViewModule,
    SliderModule,
    MenuModule,
    ContextMenuModule,
    TreeModule,
    MenubarModule,
];

const primeNgServices = [DialogService];

@NgModule({
    declarations: [HitlistPaginatorComponent, BreadcrumbsComponent],
    imports: [...primeNgModules, NgxChartsModule],
    exports: [...primeNgModules, NgxChartsModule, HitlistPaginatorComponent, BreadcrumbsComponent],
    providers: [...primeNgServices],
})
export class UiLibraryModule {}
