import { NullableDate } from '../../shared/nullable-date.interfaces';

export interface DateRangeComponentModel {
    from: NullableDate;
    to: NullableDate;
}

export interface DateOutputComponentModel {
    range: DateRangeComponentModel | null;
    changeThroughUserAction: boolean;
}

export type CalendarValue = NullableDate[];

export interface DateRangeInput {
    range: DateRangeComponentModel;
    origin: DateRangeOrigin;
}

export enum DateRangeOrigin {
    Calendar,
    Preset,
    Manual,
    External,
}

export type DateFacetRangeComponentModel = DateRangeComponentModel;
