import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchProfileState } from './search-profile.state';

export const searchProfileFeatureKey = 'searchProfile';

export const selectSearchProfile = createFeatureSelector<SearchProfileState>(searchProfileFeatureKey);

export const selectExcludedStorageCategories = createSelector(
    selectSearchProfile,
    (state: SearchProfileState) => state.settings.storageCategorySettings.excluded
);

export const selectSearchProfileDefault = createSelector(
    selectSearchProfile,
    (state: SearchProfileState) => state.settings.isDefault
);

export const selectSearchProfileExcludedMediaTypes = createSelector(
    selectSearchProfile,
    (state: SearchProfileState) => state.settings.excludedMediaTypes
);

export const selectSearchProfileSearchInDocumentsText = createSelector(
    selectSearchProfile,
    (state: SearchProfileState) => state.settings.searchInDocumentTexts
);

export const selectDisplaySportFilters = createSelector(
    selectSearchProfile,
    (state: SearchProfileState): boolean => state.settings.includeSportsFilters
);

export const selectStorageCategoryFilters = createSelector(
    selectSearchProfile,
    (state: SearchProfileState) => state.settings.storageCategorySettings.filters
);

export const selectFacetCollapsedState = createSelector(
    selectSearchProfile,
    (state: SearchProfileState): Map<string, boolean> => state.facetCollapsedState
);

export const selectDateFacetCollapsedState = createSelector(
    selectSearchProfile,
    (state: SearchProfileState): boolean => state.dateFacetCollapsedState
);
