import {
    ShoppingCartHitlistSortingAttribute,
    ShoppingCartHitlistSortingOrder,
    ShoppingCartOrderStatus,
} from './shopping-cart.state';
import { SearchResultCartStatusDto, SortFieldDto, SortOrderDto } from '@faro/order-angular-client';

export function getSortValueField(attribute: ShoppingCartHitlistSortingAttribute): SortFieldDto {
    switch (attribute) {
        case ShoppingCartHitlistSortingAttribute.DESCRIPTION:
            return SortFieldDto.Name;
        case ShoppingCartHitlistSortingAttribute.ORDER_STATE:
            return SortFieldDto.Status;
        case ShoppingCartHitlistSortingAttribute.CREATOR:
            return SortFieldDto.Owner;
        case ShoppingCartHitlistSortingAttribute.CREATION_DATE:
            return SortFieldDto.CreationDate;
        case ShoppingCartHitlistSortingAttribute.ENTRIES:
            return SortFieldDto.NumberOfEntries;
        case ShoppingCartHitlistSortingAttribute.NUMBER:
            return SortFieldDto.Number;
    }
}

export function getOrderStatusLabel(attribute: SearchResultCartStatusDto): ShoppingCartOrderStatus {
    switch (attribute) {
        case SearchResultCartStatusDto.UnderConstruction:
            return ShoppingCartOrderStatus.NOT_ORDERED;
        case SearchResultCartStatusDto.Closed:
            return ShoppingCartOrderStatus.CLOSED;
        case SearchResultCartStatusDto.Delivered:
            return ShoppingCartOrderStatus.DELIVERED;
        case SearchResultCartStatusDto.Dispatching:
            return ShoppingCartOrderStatus.ORDERED;
        case SearchResultCartStatusDto.Active:
            return ShoppingCartOrderStatus.ACTIVE;
        default:
            return ShoppingCartOrderStatus.NOT_ORDERED;
    }
}

export function getSortDirection(direction: ShoppingCartHitlistSortingOrder): SortOrderDto {
    switch (direction) {
        case ShoppingCartHitlistSortingOrder.ASCENDING:
            return SortOrderDto.Ascending;
        case ShoppingCartHitlistSortingOrder.DESCENDING:
            return SortOrderDto.Descending;
    }
}
