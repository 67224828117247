import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvelopeComponent } from './envelope/envelope.component';
import { UiLibraryModule } from '../ui-library/ui-library.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [EnvelopeComponent],
    imports: [CommonModule, UiLibraryModule, FormsModule, SharedModule],
    exports: [EnvelopeComponent],
})
export class EnvelopeModule {}
