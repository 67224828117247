import {
    HitlistSortingAttribute,
    HitlistSortingOrder,
    SearchFieldSelectionEnum,
    SearchOptions,
} from './search-options.state';
import {
    ExcludeField,
    FaroFacetSearchRequest,
    FaroSearchRequest,
    FilterType,
    SortInfo,
    SortOrder,
    ValueFilter,
} from '@faro/searchapi-angular-client';
import { ItemFacetKeys } from '../filters/facet.selectors';

export const createFaroSearchRequest = (searchOptions: SearchOptions): FaroSearchRequest => {
    return {
        ...createFaroSearchBase(searchOptions),
        page: 1,
        matchFilters: [],
        sorting: createSortInfo(searchOptions),
    };
};

const createSortInfo = (state: SearchOptions): SortInfo[] => {
    const isImageSearchType = state.searchType.value === SearchFieldSelectionEnum.IMAGE;

    enum ImageSortingAttributeKey {
        RELEVANCE = '_score',
        ITEM_DATE = 'Sequence.Hitlist.C3.Value.sort',
        ITEM_NR = 'Sequence.Hitlist.C8.Value.sort',
        PROGRAM_PRES_TITLE = 'Sequence.Hitlist.C2.Value.sort',
        PROGRAM_EMISSION = 'Sequence.Hitlist.C9.Value.sort', // use program id to order multiple emissions of the same program
        DURATION = 'Sequence.Hitlist.C7.Value.sort',
    }
    enum SortingAttributeKeys {
        RELEVANCE = '_score',
        ITEM_DATE = 'Item.Hitlist.C3.Value.sort',
        ITEM_NR = 'Item.Hitlist.C8.Value.sort',
        PROGRAM_PRES_TITLE = 'Item.Hitlist.C2.Value.sort',
        PROGRAM_EMISSION = 'Item.Hitlist.C9.Value.sort', // use program id to order multiple emissions of the same program
        DURATION = 'Item.Hitlist.C7.Value.sort',
    }

    const order = state.sorting.order === HitlistSortingOrder.ASCENDING ? SortOrder.Ascending : SortOrder.Descending;

    let sortInfos: SortInfo[] = [];
    switch (state.sorting.attribute) {
        case HitlistSortingAttribute.RELEVANCE:
            sortInfos = [
                {
                    path: isImageSearchType ? ImageSortingAttributeKey.RELEVANCE : SortingAttributeKeys.RELEVANCE,
                    order,
                },
            ];
            break;
        case HitlistSortingAttribute.DATE:
            sortInfos = [
                {
                    path: isImageSearchType ? ImageSortingAttributeKey.ITEM_DATE : SortingAttributeKeys.ITEM_DATE,
                    order,
                },
                {
                    path: isImageSearchType
                        ? ImageSortingAttributeKey.PROGRAM_PRES_TITLE
                        : SortingAttributeKeys.PROGRAM_PRES_TITLE,
                    order: SortOrder.Ascending,
                },
                {
                    path: isImageSearchType ? ImageSortingAttributeKey.ITEM_NR : SortingAttributeKeys.ITEM_NR,
                    order: SortOrder.Ascending,
                },
            ];
            break;
        case HitlistSortingAttribute.PROGRAM:
            sortInfos = [
                {
                    path: isImageSearchType
                        ? ImageSortingAttributeKey.PROGRAM_PRES_TITLE
                        : SortingAttributeKeys.PROGRAM_PRES_TITLE,
                    order,
                },
                {
                    path: isImageSearchType
                        ? ImageSortingAttributeKey.PROGRAM_EMISSION
                        : SortingAttributeKeys.PROGRAM_EMISSION,
                    order: SortOrder.Ascending,
                },
                {
                    path: isImageSearchType ? ImageSortingAttributeKey.ITEM_NR : SortingAttributeKeys.ITEM_NR,
                    order: SortOrder.Ascending,
                },
            ];
            break;
        case HitlistSortingAttribute.DURATION:
            sortInfos = [
                { path: isImageSearchType ? ImageSortingAttributeKey.DURATION : SortingAttributeKeys.DURATION, order },
            ];
            break;
        default:
            throw new Error('Unknown sorting attribute');
    }

    return sortInfos;
};

export const createFaroFacetSearchRequest = (
    facetQuery: string,
    facetField: string,
    searchOptions: SearchOptions
): FaroFacetSearchRequest => {
    return {
        ...createFaroSearchBase(searchOptions),
        facetField: facetField,
        facetQuery: facetQuery,
    };
};

const createFaroSearchBase = (searchOptions: SearchOptions): FaroSearchRequestBase => {
    const isImageSearchType = searchOptions.searchType.value === SearchFieldSelectionEnum.IMAGE;
    const filtersWithOrWithoutSportsFilters = !searchOptions.includeSportsFilters
        ? searchOptions.filters.filter(
              v =>
                  v.field !== (isImageSearchType ? ItemFacetKeys.IMAGE_SPORT : ItemFacetKeys.SPORT) &&
                  v.field !== (isImageSearchType ? ItemFacetKeys.IMAGE_EVENT : ItemFacetKeys.EVENT) &&
                  v.field !== (isImageSearchType ? ItemFacetKeys.IMAGE_YEAR : ItemFacetKeys.YEAR)
          )
        : searchOptions.filters;
    const imageSearchAudioFilter: ValueFilter = {
        field: 'Sequence.Item.Program.MediaLinks.Entry.keyword',
        filterType: 'Exclusion',
        value: 'Audio',
    };
    const audioAlreadyExcluded = searchOptions.excludedMediaTypes.excludeAudio;

    const finalFilters = isImageSearchType && !audioAlreadyExcluded
        ? [...filtersWithOrWithoutSportsFilters, imageSearchAudioFilter]
        : filtersWithOrWithoutSportsFilters;

    const mappedProfileFiltersDependingOnSearchType = searchOptions.profileFilters.map(f => {
        return {
            ...f,
            field: isImageSearchType
                ? 'Sequence.Item.Program.StorageCategory.CategoryLevels.AllIds'
                : 'Item.Program.StorageCategory.CategoryLevels.AllIds',
        };
    });
    const searchBase = {
        exact: false,
        excludeFields: [],
        valueFilters: [...finalFilters, ...mappedProfileFiltersDependingOnSearchType],
        valueFilterOperators: searchOptions.filterOperators,
        query: searchOptions.query,
        searchType: searchOptions.searchType.value,
        dateFilter: {
            from: null,
            to: null,
            isActive: false,
        },
    };
    setDateFilter(searchBase, searchOptions);
    setSearchParams(searchBase, searchOptions);
    return searchBase;
};

const setDateFilter = (searchBase: FaroSearchRequestBase, searchOptions: SearchOptions): void => {
    const from = convertDateToFaroString(searchOptions.dateFilter.from);
    const to = convertDateToFaroString(searchOptions.dateFilter.to);
    /** dateFilter.isActive is not deserialized in backend, value does not matter*/
    searchBase.dateFilter = { from, to, isActive: false };
};

const convertDateToFaroString = (date: Date | null): string | null => {
    const dateToString = (num: number): string => num.toString().padStart(2, '0');
    return date === null
        ? null
        : `${date.getFullYear()}-${dateToString(date.getMonth() + 1)}-${dateToString(date.getDate())}`;
};

const setSearchParams = (searchBase: FaroSearchRequestBase, searchOptions: SearchOptions): void => {
    setDopeSheetFilter(searchBase, searchOptions);
    setMediaTypeFilter(searchBase, searchOptions);
};

const setDopeSheetFilter = (searchBase: FaroSearchRequestBase, searchOptions: SearchOptions): void => {
    const isImageSearchType = searchOptions.searchType.value === SearchFieldSelectionEnum.IMAGE;
    const dopeSheetFilterKeys: (string | undefined | null)[] =
        searchOptions.searchType.value === SearchFieldSelectionEnum.TOPIC
            ? ['ItemFields', 'Item.Program.Documents.Text', 'Item.Documents.Text']
            : [];
    searchBase.excludeFields = searchBase.excludeFields.filter(f => !dopeSheetFilterKeys.includes(f.field));

    if (!searchOptions.itemSearchOptions.includingDopeSheets) {
        dopeSheetFilterKeys.forEach(key => {
            searchBase.excludeFields.push({ field: key });
        });
    } else {
        searchBase.excludeFields.push({
            field: isImageSearchType ? 'SequenceFieldsWithoutDocuments' : 'ItemFieldsWithoutDocuments',
        });
    }
};

const setMediaTypeFilter = (searchBase: FaroSearchRequestBase, searchOptions: SearchOptions): void => {
    const isImageSearchType = searchOptions.searchType.value === SearchFieldSelectionEnum.IMAGE;
    const mediaFilterKeys = isImageSearchType
        ? 'Sequence.Item.Program.MediaLinks.Entry.keyword'
        : 'Item.Program.MediaLinks.Entry.keyword';
    searchBase.excludeFields = searchBase.excludeFields.filter(f => f.field !== mediaFilterKeys);

    if (searchOptions.excludedMediaTypes.excludeAudio) {
        searchBase.valueFilters.push({
            field: mediaFilterKeys,
            filterType: FilterType.Exclusion,
            value: 'Audio',
        });
    }
    if (searchOptions.excludedMediaTypes.excludeVideo) {
        searchBase.valueFilters.push({
            field: mediaFilterKeys,
            filterType: FilterType.Exclusion,
            value: 'SD-Video',
        });
        searchBase.valueFilters.push({
            field: mediaFilterKeys,
            filterType: FilterType.Exclusion,
            value: 'HD-Video',
        });
    }
};

interface FaroSearchRequestBase {
    exact: boolean;
    excludeFields: ExcludeField[];
    valueFilters: ValueFilter[];
    query: string;
    searchType: string;
    dateFilter: { from: string | null; to: string | null; isActive: boolean };
}
