import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';

export const userFeatureKey = 'user';

export const selectUser = createFeatureSelector<UserState>(userFeatureKey);

export const selectUserId = createSelector(selectUser, (state: UserState) => state.userInfo?.id);

export const selectUserEmail = createSelector(selectUser, (state: UserState) => state.userInfo?.eMail);

export const selectUserName = createSelector(selectUser, (state: UserState) => state.userInfo?.name);
