import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlightedIso8601Date',
})
export class HighlightedIso8601DatePipe implements PipeTransform {
    transform(value: string | null | undefined): string {
        if (!value) return '';
        const tokens = value.split('-');
        if (tokens.length !== 3) return '';
        return `${tokens[2]}.${tokens[1]}.${tokens[0]}`;
    }
}
