import { createReducer, on } from '@ngrx/store';
import { RouteHistory } from './route-history.state';
import { addToHistory } from './route-history.actions';

export const initialState: RouteHistory = {
    urls: [],
};

export const routeHistoryReducer = createReducer(
    initialState,
    on(addToHistory, (state: RouteHistory, { url }): RouteHistory => {
        return {
            ...state,
            urls: [...state.urls, url],
        };
    })
);
