<div class="section-bar-container">
    <div class="descriptor">{{ displayString }}</div>
    <div class="section-bar">
        <ng-container *ngFor="let section of sectionEntries">
            <div
                (click)="jumpToSection(section.vtcIn)"
                class="section"
                [style.left.%]="section.relativePosLeft"
                [style.width.%]="section.relativeWidth"
            ></div>
        </ng-container>
    </div>
</div>
