<!--https://www.w3.org/WAI/ARIA/apg/example-index/breadcrumb/index.html-->
<div class="breadcrumb-header">
    <div class="breadcrumbs">
        <nav aria-label="Breadcrumbs" class="breadcrumb-nav">
            <ol>
                <ng-container *ngFor="let crumb of crumbs">
                    <li>
                        <button
                            class="breadcrumb"
                            data-cy="breadcrumb"
                            (click)="breadCrumbClick(crumb)"
                            [attr.aria-current]="activeCrumb === crumb.label ? activeCrumb : undefined"
                            [ngStyle]="{ 'font-weight': activeCrumb === crumb.label ? '700' : '400' }"
                        >
                            {{ crumb.label }}
                        </button>
                        <img class="breadcrumb-icon" [src]="breadcrumbArrow" alt="" />
                    </li>
                </ng-container>
            </ol>
        </nav>
    </div>
</div>
