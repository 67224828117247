import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    selectLoadingShoppingCartSearch,
    selectShowResetSearchParamsButton,
} from '../shopping-cart-state/shopping-cart.selectors';
import {
    resetAllShoppingCartSearchOptions,
    searchShoppingCarts,
    setActiveUserSearchOptions,
} from '../shopping-cart-state/shopping-cart.actions';
import { selectUserName } from '../../state/user.selectors';

@Component({
    selector: 'app-search-shopping-cart',
    templateUrl: './search-shopping-cart.component.html',
    styleUrls: ['./search-shopping-cart.component.scss'],
})
export class SearchShoppingCartComponent implements OnInit, OnDestroy {
    /**
     * Allows to click on enter anywhere on the page and search is started
     * */
    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const enterFromNoSearch = (event.target as HTMLElement).hasAttribute('data-no-search'); //Attribute set on input element and buttons
        if (event.key === 'Enter' && !enterFromNoSearch) {
            this.search();
        }
    }

    displayResetSearchParamsButton$: Observable<boolean>;
    loadingShoppingCarts$: Observable<boolean>;
    private _destroyed$ = new Subject<void>();

    constructor(private store: Store) {
        this.displayResetSearchParamsButton$ = this.store.select(selectShowResetSearchParamsButton);
        this.loadingShoppingCarts$ = this.store.select(selectLoadingShoppingCartSearch);
    }

    ngOnInit() {
        this.store
            .select(selectUserName)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                if (data) {
                    const split = data.split(' ');
                    const firstAndLastName = {
                        firstName: split[0],
                        lastName: split[1] ?? '',
                    };
                    this.store.dispatch(setActiveUserSearchOptions({ user: firstAndLastName }));
                }
            });

        this.store.dispatch(searchShoppingCarts());
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    search() {
        this.store.dispatch(searchShoppingCarts());
    }

    resetAllFilters() {
        this.store.dispatch(resetAllShoppingCartSearchOptions());
    }
}
