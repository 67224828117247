import { Translation } from 'primeng/api';

export const PRIMENG_TRANSLATIONS_GERMAN: Translation = {
    startsWith: 'beginnt mit',
    contains: 'enthält',
    notContains: 'enthält nicht',
    endsWith: 'endet mit',
    equals: 'ist gleich',
    notEquals: 'ist nicht gleich',
    noFilter: 'kein Filter',
    lt: 'weniger als',
    lte: 'weniger als oder gleich',
    gt: 'grösser als',
    gte: 'grösser als oder gleich',
    is: 'ist',
    isNot: 'ist nicht',
    before: 'bevor',
    after: 'nachher',
    dateIs: 'Datum ist',
    dateIsNot: 'Datum ist nicht',
    dateBefore: 'Datum ist vor',
    dateAfter: 'Datum ist nach',
    clear: 'Löschen',
    apply: 'Anwenden',
    matchAll: 'alle passen',
    matchAny: 'einige passen',
    addRule: 'Regel hinzufügen',
    removeRule: 'Regel entfernen',
    accept: 'Ja',
    reject: 'Nein',
    choose: 'Wählen',
    upload: 'Hochladen',
    cancel: 'Abbrechen',
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    dateFormat: 'dd.mm.yyyy',
    firstDayOfWeek: 1,
    today: 'Heute',
    weekHeader: 'Wo',
    weak: 'schwach',
    medium: 'mittel',
    strong: 'stark',
    passwordPrompt: 'Geben Sie ein Passwort ein',
    emptyMessage: 'Keine Resultate gefunden',
    emptyFilterMessage: 'Keine Resultate gefunden',
};
