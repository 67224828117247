import { Injectable } from '@angular/core';
import { ReportService } from '@faro/metadata-angular-client';
import { downloadBlob } from '../../shared/download-blob';
import { HttpErrorResponse } from '@angular/common/http';
import { notifyOfDownloadError } from '../../details/details-state/details.actions';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class PrintReportService {
    constructor(private reportService: ReportService, private readonly store: Store) {}

    printMultiReport(cartEntries: any, shoppingCartName: string) {
        this.reportService.reportGetReportForMultipleItems(toMultiItemReportRequestDto(cartEntries)).subscribe({
            next: (blob: Blob) => {
                const filename = `FARO Report Beitragsdetails - ${shoppingCartName}.docx`;
                downloadBlob(document, blob, filename);
            },
            error: (err: HttpErrorResponse) => {
                this.store.dispatch(notifyOfDownloadError({ errorResponse: err }));
            },
        });
    }

    printItemReport(programId: string, itemId: string, programTitle: string) {
        this.reportService.reportGetItemDetailsReport(programId, itemId).subscribe({
            next: (blob: Blob) => {
                const filename = programTitle
                    ? `FARO Report Beitragsdetails - ${programTitle}.docx`
                    : 'FARO Report Beitragsdetails.docx';
                downloadBlob(document, blob, filename);
            },
            error: (err: HttpErrorResponse) => {
                this.store.dispatch(notifyOfDownloadError({ errorResponse: err }));
            },
        });
    }
}

function toMultiItemReportRequestDto(cartEntries: any): { programId: string; itemId: string | undefined,
        programVtcIn: string | undefined, programVtcOut: string | undefined }[] | undefined {
    return cartEntries
        .map((e: any) => {
            return {
                programId: e.programId,
                itemId: e.itemId,
                programVtcIn: e.vtcIn,
                programVtcOut: e.vtcOut,
            };
        });
}
