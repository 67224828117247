import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    SHOPPING_CART_HITLIST_SORTING_ATTRIBUTE_OPTIONS,
    ShoppingCartHitlistSortingAttribute,
    ShoppingCartHitlistSortingAttributeOption,
    ShoppingCartHitlistSortingOrder,
    ShoppingCartSorting,
} from '../../../shopping-cart-state/shopping-cart.state';
import { sortingAttributesToLabel, SortingAttributeValues } from './shopping-cart-hitlist-controls.model';
import { Store } from '@ngrx/store';
import {
    updateShoppingCartSearchOrderBy,
    updateShoppingCartSearchOrderDirection,
} from '../../../shopping-cart-state/shopping-cart.actions';
import { Subject, takeUntil } from 'rxjs';
import { selectShoppingCartSortingInformation } from '../../../shopping-cart-state/shopping-cart.selectors';

@Component({
    selector: 'app-shopping-cart-hitlist-controls',
    templateUrl: './shopping-cart-hitlist-controls.component.html',
    styleUrls: ['./shopping-cart-hitlist-controls.component.scss'],
})
export class ShoppingCartHitlistControlsComponent implements OnInit, OnDestroy {
    sortingAttributeOptions: SortingAttributeValues[] = [];
    sortingOrderEnum = ShoppingCartHitlistSortingOrder;
    sortingAttribute: SortingAttributeValues | undefined;
    sortingDirection: ShoppingCartHitlistSortingOrder | undefined;

    private _destroyed$ = new Subject<void>();

    constructor(private store: Store) {
        this.sortingAttributeOptions = sortingAttributesToLabel(SHOPPING_CART_HITLIST_SORTING_ATTRIBUTE_OPTIONS);
    }

    ngOnInit() {
        this.store
            .select(selectShoppingCartSortingInformation)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: ShoppingCartSorting) => {
                const sortingAttributeLabels = sortingAttributesToLabel([getAttributeOption(data.sortBy)]);
                this.sortingAttribute = sortingAttributeLabels[0];
                this.sortingDirection = data.sortDirection;
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    sortingAttributeChanged(event: { label: string; value: number }) {
        if (event.value !== this.sortingAttribute?.value) {
            this.store.dispatch(updateShoppingCartSearchOrderBy({ orderBy: event.value }));
        }
    }

    setSortOrder(event: number) {
        if (event !== this.sortingDirection) {
            this.store.dispatch(updateShoppingCartSearchOrderDirection({ orderDirection: event }));
        }
    }
}

function getAttributeOption(sortBy: ShoppingCartHitlistSortingAttribute): ShoppingCartHitlistSortingAttributeOption {
    const option = SHOPPING_CART_HITLIST_SORTING_ATTRIBUTE_OPTIONS.find(option => option.value === sortBy);
    if (option) {
        return option;
    } else {
        return SHOPPING_CART_HITLIST_SORTING_ATTRIBUTE_OPTIONS[0];
    }
}
