import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    getSortingDirection,
    setHitlistSortingAttribute,
    setHitlistSortingOrder,
} from '../../../search-state/search-options.actions';
import {
    HITLIST_SORTING_ATTRIBUTE_OPTIONS,
    HitlistSortingAttribute,
    HitlistSortingAttributeOption,
    HitlistSortingOrder,
} from '../../../search-state/search-options.state';
import {
    selectHitlistSortingAttribute,
    selectHitlistSortingAttributeOption,
    selectHitlistSortingOrder,
} from '../../../search-state/search-options.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';
import { StoreUtilsService } from '../../../../state/store-utils.service';
import { sortingAttributesToLabel, SortingAttributeValues } from './hitlist-controls.model';

@Component({
    selector: 'app-hitlist-controls',
    templateUrl: './hitlist-controls.component.html',
    styleUrls: ['./hitlist-controls.component.scss'],
})
export class HitlistControlsComponent implements OnInit, OnDestroy {
    sortingOrderEnum = HitlistSortingOrder;
    sortingAttributeOptions: SortingAttributeValues[] = [];

    sortingAttribute: SortingAttributeValues | undefined;
    sortingOrder$: Observable<HitlistSortingOrder> | undefined;
    private _destroyed$ = new Subject<void>();

    constructor(private readonly store: Store, private readonly storeUtils: StoreUtilsService) {}

    ngOnInit() {
        this.store.dispatch(getSortingDirection());
        this.sortingOrder$ = this.store.select(selectHitlistSortingOrder).pipe(takeUntil(this._destroyed$));
        this.sortingAttributeOptions = sortingAttributesToLabel(HITLIST_SORTING_ATTRIBUTE_OPTIONS);
        this.store
            .select(selectHitlistSortingAttributeOption)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: HitlistSortingAttributeOption | undefined) => {
                data
                    ? (this.sortingAttribute = sortingAttributesToLabel([data])[0])
                    : (this.sortingAttribute = this.sortingAttributeOptions[0]);
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    async setSortOrder(order: HitlistSortingOrder): Promise<void> {
        const currentOrder = await this.storeUtils.snapshot(selectHitlistSortingOrder);
        if (order !== currentOrder) {
            this.store.dispatch(setHitlistSortingOrder({ sortingOrder: order }));
        }
    }

    async sortingAttributeChanged(event: { value: HitlistSortingAttribute }): Promise<void> {
        const currentAttribute = await this.storeUtils.snapshot(selectHitlistSortingAttribute);
        if (event.value !== currentAttribute) {
            this.store.dispatch(setHitlistSortingAttribute({ sortingAttribute: event.value }));
        }
    }
}
