import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    createNewShoppingCart,
    getActiveShoppingCart,
    getUserExportTarget,
    orderActiveShoppingCart,
} from '../shopping-cart-state/shopping-cart.actions';
import { filter, map, Observable, Subject, takeUntil } from 'rxjs';
import { ShoppingCartDto } from '@faro/order-angular-client/model/shoppingCartDto';
import {
    selectActiveShoppingCart,
    selectLoadingShoppingCart,
    selectOrderingShoppingCart,
    selectSelectedAdmiraDevice,
    selectSelectedAdmiraDeviceRootName,
    selectSelectedAdmiraDeviceRootPath,
} from '../shopping-cart-state/shopping-cart.selectors';
import { PrintReportService } from '../shared/print-report.service';
import { concatLatestFrom } from '@ngrx/operators';
import { AdmiraDeviceDto } from '@faro/order-angular-client';

@Component({
    selector: 'app-active-shopping-cart',
    templateUrl: './active-shopping-cart.component.html',
    styleUrls: ['./active-shopping-cart.component.scss'],
})
export class ActiveShoppingCartComponent implements OnInit, OnDestroy {
    activeShoppingCart$: Observable<ShoppingCartDto>;
    loadingShoppingCart$: Observable<boolean>;
    orderingShoppingCart$: Observable<boolean>;
    exportTargetRootPath$: Observable<string>;
    exportTargetRootName$: Observable<string>;
    selectedDevice$: Observable<AdmiraDeviceDto | undefined>;
    displayString: string = '';
    private _destroyed$ = new Subject<void>();
    constructor(private store: Store, private readonly printService: PrintReportService) {
        this.store.dispatch(getActiveShoppingCart());
        this.store.dispatch(getUserExportTarget());
        this.activeShoppingCart$ = this.store.select(selectActiveShoppingCart).pipe(
            filter(v => !!v),
            map(v => v as ShoppingCartDto)
        );
        this.loadingShoppingCart$ = this.store.select(selectLoadingShoppingCart);
        this.exportTargetRootPath$ = this.store.select(selectSelectedAdmiraDeviceRootPath);
        this.exportTargetRootName$ = this.store.select(selectSelectedAdmiraDeviceRootName);
        this.selectedDevice$ = this.store.select(selectSelectedAdmiraDevice);
        this.orderingShoppingCart$ = this.store.select(selectOrderingShoppingCart);
    }

    ngOnInit() {
        this.selectedDevice$
            .pipe(concatLatestFrom(_ => this.exportTargetRootPath$))
            .pipe(concatLatestFrom(_ => this.exportTargetRootName$))
            .pipe(takeUntil(this._destroyed$))
            .subscribe(([[device, path], name]) => {
                const devicePath = device?.devicePath?.split(path)[1] ?? '';
                this.displayString = name ? `${name}/${devicePath}` : '';
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    createNewShoppingCart() {
        this.store.dispatch(createNewShoppingCart());
    }

    orderShoppingCart() {
        this.store.dispatch(orderActiveShoppingCart());
    }

    hasEntries(shoppingCart: ShoppingCartDto): boolean {
        return (shoppingCart.entries && shoppingCart.entries.length > 0) ?? false;
    }

    printShoppingCartReport(shoppingCart: ShoppingCartDto) {
        this.printService.printMultiReport(shoppingCart.entries, shoppingCart.name);
    }
}
