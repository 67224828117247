<div class="shopping-cart-header">
    <ng-container><img class="shopping-cart-polygon" src="assets/icons/polygon.svg" alt="" /></ng-container>
    <app-breadcrumbs
        breadcrumbArrow="/assets/icons/breadcrumb-arrow-white.svg"
        [crumbs]="crumbs"
        (breadCrumbSelectionChange)="navigateToCrumb($event)"
        [activeCrumb]="activeCrumb?.label"
    ></app-breadcrumbs>
    <div class="right-actions">
        <button type="button" pRipple class="cart-header-button" (click)="onSwitchCartViewButtonClicked()">
            {{isOnActiveShoppingCart ? 'Alle Warenkörbe anzeigen' : 'Zum aktiven Warenkorb'}}
        </button>
        <app-hitlist-paginator
            *ngIf="showPaginator"
            [hitIndex]="paginatorIndex"
            [hitListLength]="paginatorLength"
            [paginatorImgSrcAndDescription]="paginatorImgSrcAndDescription"
            (pageChanged)="onPageChange($event)"
        ></app-hitlist-paginator>
        <button *ngIf="showPrint" type="button" class="print-button" pRipple (click)="print()">
            <img src="assets/icons/printer-white.svg" alt="Beitrags-Report herunterladen" />
        </button>
        <div *ngIf="!showPrint" class="print-button-placeholder"></div>
    </div>
</div>
