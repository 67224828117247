import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { relativeLengthInTimeRange, relativePositionInTimeRange } from '../../shared/time-range.helpers';
import { Duration, fromMilliseconds } from '../../shared/duration';

@Component({
    selector: 'app-player-cut-marker',
    templateUrl: './player-cut-marker.component.html',
    styleUrls: ['./player-cut-marker.component.scss'],
})
export class PlayerCutMarkerComponent implements OnChanges {
    @Input() markerVtcIn: Duration | undefined;
    @Input() markerVtcOut: Duration | undefined;
    @Input() totalRangeFrom: Duration = fromMilliseconds(0);
    @Input() totalRangeTo: Duration = fromMilliseconds(0);

    relativePosLeft: number | undefined;
    relativeWidth: number | undefined;
    isValidRange = false;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        this.isValidRange =
            this.markerVtcIn !== undefined &&
            this.markerVtcOut !== undefined &&
            this.markerVtcIn.asMilliseconds() < this.markerVtcOut.asMilliseconds() &&
            this.totalRangeFrom.asMilliseconds() < this.totalRangeTo.asMilliseconds();

        if (this.isValidRange) {
            this.relativePosLeft = relativePositionInTimeRange(
                this.markerVtcIn!,
                this.totalRangeFrom,
                this.totalRangeTo
            );
            this.relativePosLeft = this.relativePosLeft < 0 ? 0 : this.relativePosLeft;

            this.relativeWidth = relativeLengthInTimeRange(
                this.markerVtcIn!,
                this.markerVtcOut!,
                this.totalRangeFrom,
                this.totalRangeTo
            );
            this.relativeWidth = this.relativeWidth > 100 ? 100 : this.relativeWidth;
        }
    }
}
