<div class="detail-header">
    <app-breadcrumbs
        [crumbs]="crumbs"
        breadcrumbArrow="/assets/icons/breadcrumb-arrow.svg"
        [activeCrumb]="activeCrumb?.label"
        (breadCrumbSelectionChange)="navigateToCrumb($event)"
    ></app-breadcrumbs>

    <div class="right-actions">
        <app-hitlist-paginator
            *ngIf="paginator.show"
            [hitIndex]="paginator.index"
            [hitListLength]="paginator.length"
            (pageChanged)="onPaginatorChanged($event)"
            [paginatorImgSrcAndDescription]="paginatorImgSrcAndDescription"
        ></app-hitlist-paginator>
        <ng-content></ng-content>
    </div>
</div>
