import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DetailsState } from './details.state';
import { formatRtcAsDefaultValue, formatRtcUsingMap } from '../../player/shared/time-code-format.model';
import { audioTrackDescription } from './media-cut.helpers';
import { parseTimeSpan } from '../../shared/timespan';

export const detailsStateFeatureKey = 'details';

export const selectDetailsState = createFeatureSelector<DetailsState>(detailsStateFeatureKey);

export const selectDetailAvComponent = createSelector(selectDetailsState, (state: DetailsState) => state.component);

export const selectDetailMediaSelection = createSelector(
    selectDetailsState,
    (state: DetailsState) => state.mediaSelection
);

export const selectDetailNav = createSelector(selectDetailsState, (state: DetailsState) => state.nav);

export const selectDetailMediaCuts = createSelector(selectDetailsState, (state: DetailsState) => state.mediaCuts);

export const selectDetailPlayerMediaCut = createSelector(
    selectDetailsState,
    (state: DetailsState) => state.playerMediaCut
);

export const selectDetailEverythingMedia = createSelector(
    selectDetailAvComponent,
    selectDetailMediaSelection,
    selectDetailMediaCuts,
    selectDetailNav,
    (component, mediaSelection, mediaCuts, nav) => ({
        component,
        mediaSelection,
        mediaCuts,
        isAudioProgram: nav?.isAudioProgram || false,
    })
);

export const selectDetailFormatRtc = createSelector(selectDetailPlayerMediaCut, mediaCut =>
    mediaCut ? formatRtcUsingMap(mediaCut.timeCodeMap, mediaCut.frameRate) : formatRtcAsDefaultValue
);

export const selectDetailSequence = createSelector(selectDetailsState, (state: DetailsState) => state.tabs.sequence);

export const selectDetailInformation = createSelector(
    selectDetailsState,
    (state: DetailsState) => state.tabs.information
);

export const selectDetailSubtitleTranscript = createSelector(
    selectDetailsState,
    (state: DetailsState) => state.tabs.subtitleTranscript
);

export const selectLoading = createSelector(selectDetailsState, (state: DetailsState) => state.loading);

export const selectLoadingInformationTab = createSelector(selectLoading, loading => loading.informationTab);

export const selectLoadingSubtitleTranscriptTab = createSelector(
    selectLoading,
    loading => loading.subtitleTranscriptTab
);

export const selectLoadingSequenceTab = createSelector(selectLoading, loading => loading.sequenceTab);

export const selectMusicInformation = createSelector(
    selectDetailsState,
    (state: DetailsState) => state.musicInformation
);

export const selectDetailOrderVtcIn = createSelector(selectDetailsState, (state: DetailsState) => state.orderVtcIn);

export const selectDetailOrderVtcOut = createSelector(selectDetailsState, (state: DetailsState) => state.orderVtcOut);

export const selectIsInOutSelectionValid = createSelector(
    selectDetailsState,
    (state: DetailsState) => state.isInOutSelectionValid
);

export const selectEnvelopeData = createSelector(selectDetailsState, (state: DetailsState) => state.envelope);

export const selectLightTableData = createSelector(selectDetailsState, (state: DetailsState) => state.lightTable);

export const selectLoadingLightTable = createSelector(selectLoading, loading => loading.lightTable);

export const selectTabs = createSelector(selectDetailsState, (state: DetailsState) => state.tabs);

export const selectHoveredTimeFrame = createSelector(selectTabs, tabs => tabs.hoveredTimeRange);

export const selectSequenceInformation = createSelector(selectDetailSequence, sequences => {
    return (
        sequences
            ?.filter(sequence => sequence.vtcInDuration !== null && sequence.vtcOutDuration !== null)
            .map(sequence => {
                return {
                    vtcIn: sequence.vtcInDuration!,
                    vtcOut: sequence.vtcOutDuration!,
                };
            }) || []
    );
});

export const selectProgramItemInformation = createSelector(selectDetailNav, avInformation => {
    return avInformation?.items.map(items => {
        return {
            vtcIn: parseTimeSpan(items.vtcIn ?? '0'),
            vtcOut: parseTimeSpan(items.vtcOut ?? '0'),
        };
    });
});

export const selectProgramSequenceInformation = createSelector(selectDetailNav, avInformation => {
    return avInformation?.sequences
        .filter(item => item.vtcIn !== null && item.vtcOut !== null)
        .map(item => {
            return {
                vtcIn: parseTimeSpan(item.vtcIn!),
                vtcOut: parseTimeSpan(item.vtcOut!),
            };
        });
});

export const selectAudioLabel = createSelector(selectDetailMediaSelection, selectDetailMediaCuts, (sel, mediaCuts) => {
    const selectedMediaCut = mediaCuts?.find(mc => mc.id === sel.mediaCut);
    const selectedAudioTrack = selectedMediaCut?.audioTracks.find(at => at.id === sel.audioTrack);
    return selectedAudioTrack ? audioTrackDescription(selectedAudioTrack) : '';
});

export const selectInOutSelectionChanged = createSelector(selectDetailsState, state => state.inOutSelectionChanged);

export const selectPreviousPlayerState = createSelector(selectDetailsState, state => state.previousPlayerState);
