import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
    selector: 'app-order-remark-dialog',
    templateUrl: './order-remark-dialog.component.html',
    styleUrls: ['./order-remark-dialog.component.scss'],
})
export class OrderRemarkDialogComponent {
    remarkForm = this.formBuilder.group({
        orderRemark: '',
        orderAudioOnly: false,
    });

    constructor(private ref: DynamicDialogRef, private formBuilder: UntypedFormBuilder, public config: DynamicDialogConfig) {}

    closeDialog() {
        this.ref.close();
    }

    orderWithRemark() {
        this.ref.close(this.remarkForm.value);
    }
}
