export function convertQueryToKeywords(query: string): string[] {
    if (query.length <= 0) return [];

    const sanitizedQuery = removeAsterisk(query);

    const re = /"(?<phrase>[^"]+)"|(?<simpleTerm>[^" ]+)/gm;
    const matches = sanitizedQuery.matchAll(re);
    let keywords: string[] = [];

    for (let match of matches) {
        if (match.groups) keywords.push(match.groups['phrase'] ?? match.groups['simpleTerm']);
    }
    return keywords;
}

export function removeAsterisk(query: string): string {
    return query.split('*').join('');
}
