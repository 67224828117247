<app-shopping-cart-header [showPaginator]="false" [showPrint]="false"></app-shopping-cart-header>
<div class="search-shopping-cart-view container">
    <div class="side-bar grid-sidebar">
        <ng-container *ngrxLet="displayResetSearchParamsButton$; let displayResetSearchParamsButton">
            <section class="search-section" role="search" aria-label="Suchmaske">
                <app-shopping-cart-search-mask
                    [searchParamsSet]="displayResetSearchParamsButton"
                ></app-shopping-cart-search-mask>
                <div class="shopping-cart-actions">
                    <div class="search-button-container">
                        <button
                            pRipple
                            type="button"
                            class="secondary-button"
                            *ngIf="displayResetSearchParamsButton"
                            (click)="resetAllFilters()"
                        >
                            <img src="/assets/icons/trash.svg" alt="" />Alle Filter zurücksetzen
                        </button>
                        <button
                            pRipple
                            type="button"
                            class="search-button"
                            [ngClass]="{ 'filters-set': displayResetSearchParamsButton }"
                            (click)="search()"
                        >
                            <img src="/assets/icons/search-white.svg" alt="" />Suchen
                        </button>
                    </div>
                </div>
            </section>
        </ng-container>
    </div>
    <div class="grid-content">
        <app-shopping-cart-hitlist [loading]="loadingShoppingCarts$ | async"></app-shopping-cart-hitlist>
    </div>
</div>
