import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouteHistoryService } from '../../shared/route-history/route-history.service';

@Component({
    selector: 'app-shopping-cart-main',
    templateUrl: './shopping-cart-main.component.html',
    styleUrls: ['./shopping-cart-main.component.scss'],
})
export class ShoppingCartMainComponent {
    constructor(private readonly router: Router, private readonly routeHistory: RouteHistoryService) {
        window.addEventListener(
            'closeShoppingCartView',
            event => {
                event.stopImmediatePropagation();
                this.closeShoppingCart().then();
            },
            { once: true } /*Required as the event is fired multiple times*/
        );
    }

    async closeShoppingCart() {
        const navigated = await this.routeHistory.navigateToLatestMatchingUrl((url: string) => {
            return !url.startsWith('/shopping-cart');
        });
        return navigated || (await this.router.navigate(['search']));
    }
}
