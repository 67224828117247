import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchResult } from './search-result.state';
import { Hit } from '@faro/searchapi-angular-client';

export const searchResponseFeatureKey = 'searchResult';

export const selectSearchResponse = createFeatureSelector<SearchResult>(searchResponseFeatureKey);

export const selectTotalHitCount = createSelector(
    selectSearchResponse,
    (state: SearchResult) => state?.lastResponse?.hitList?.totalHitCount
);

export const selectTotalPageCount = createSelector(
    selectSearchResponse,
    (state: SearchResult) => state?.lastResponse?.hitList?.totalPageCount
);

export const selectHitlist = createSelector(selectSearchResponse, (state: SearchResult): Hit[] => {
    return state.isBeforeFirstSearch ? noHits : state.lastResponse?.hitList?.hits || noHits;
});

const noHits: Hit[] = [];

export const selectIsLoading = createSelector(selectSearchResponse, (state: SearchResult): boolean => {
    return state.isLoading;
});

export const selectIsLoadingNextPage = createSelector(selectSearchResponse, (state: SearchResult): boolean => {
    return state.isLoadingNextPage;
});

export const selectCurrentPage = createSelector(selectSearchResponse, (state: SearchResult): number | undefined => {
    return state.lastResponse?.request?.page;
});

export const selectIsBeforeFirstSearch = createSelector(selectSearchResponse, (state: SearchResult): boolean => {
    return state.isBeforeFirstSearch;
});

export const selectDisplaySavedSearch = createSelector(selectSearchResponse, (state: SearchResult): boolean => {
    return state.displaySavedSearch;
});

export const selectScrollTopPosition = createSelector(selectSearchResponse, (state: SearchResult): number => {
    return state.scrollTopPosition;
});

export const selectShowNoHits = createSelector(selectSearchResponse, (state: SearchResult): boolean => {
    return state.isBeforeFirstSearch
        ? false
        : state.isLoading
        ? false
        : state.hasError
        ? false
        : (state.lastResponse?.hitList?.totalHitCount ?? 0) === 0;
});

export const selectShowHitlist = createSelector(selectSearchResponse, (state: SearchResult): boolean => {
    return state.isBeforeFirstSearch && !state.displaySavedSearch
        ? false
        : state.hasError
        ? false
        : state.isLoading
        ? true
        : (state.lastResponse?.hitList?.totalHitCount ?? 0) > 0;
});

export const selectSearchResultTerm = createSelector(
    selectSearchResponse,
    (state: SearchResult): string | undefined | null => {
        return state.lastResponse?.request?.query;
    }
);

export const selectIsLoadingFacet = createSelector(
    selectSearchResponse,
    (state: SearchResult): Map<string, boolean> => {
        return state.isLoadingFacet;
    }
);

export const selectSearchCanceled = createSelector(selectSearchResponse, (state: SearchResult): boolean => {
    return state.isCanceled;
});
