import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { notifyOfDetailError, notifyOfDownloadError, notifyOfEnvelopeError } from './details.actions';

@Injectable()
export class ErrorEffects {
    constructor(private readonly actions$: Actions, private readonly notification: NotificationService) {}

    showDetailErrorNotificationEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfDetailError),
                tap(action => {
                    this.notification.notifyOfDetailsError(action.errorResponse.status);
                    console.error(action.errorResponse);
                })
            ),
        { dispatch: false }
    );

    showDownloadErrorNotificationEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfDownloadError),
                tap(action => {
                    this.notification.notifyOfDownloadError(action.errorResponse.status);
                    console.error(action.errorResponse);
                })
            ),
        { dispatch: false }
    );

    showEnvelopeErrorNotificationEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfEnvelopeError),
                tap(action => {
                    this.notification.notifyOfEnveloperError();
                    console.error(action.errorResponse);
                })
            ),
        { dispatch: false }
    );
}
