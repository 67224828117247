import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { SearchSettings } from '@faro/profile-angular-client/model/searchSettings';

export enum SearchProfileActions {
    GET_SEARCH_PROFILE_SETTINGS = '[Search Profile Settings] Get Search Profile Settings',
    SET_SEARCH_PROFILE_SETTINGS = '[Search Profile Settings] Set Search Profile Settings',
    PUT_SEARCH_PROFILE_SETTINGS = '[Search Profile Settings] Put Search Profile Settings',
    NOTIFY_OF_PROFILE_SEARCH_ERROR = '[Search Profile Settings] Error on getting Search Profile Settings',
    SET_FACETS_COLLAPSED_STATE = '[Search Profile Settings] Set the collapsed facets',
    SET_DATE_FACETS_COLLAPSED_STATE = '[Search Profile Settings] Set the collapsed date facet',
    SET_DEFAULT_PROFILE_SETTINGS = '[Search Profile Settings] Set the default profile settings',
}

/**
 * search-settings
 */
export const getSearchProfile = createAction(SearchProfileActions.GET_SEARCH_PROFILE_SETTINGS);

export const updateProfileSettings = createAction(
    SearchProfileActions.PUT_SEARCH_PROFILE_SETTINGS,
    props<{ searchProfile: SearchSettings }>()
);

export const setSearchProfileSettings = createAction(
    SearchProfileActions.SET_SEARCH_PROFILE_SETTINGS,
    props<{ searchProfile: SearchSettings }>()
);

export const setFacetCollapsedState = createAction(
    SearchProfileActions.SET_FACETS_COLLAPSED_STATE,
    props<{ facets: Map<string, boolean> }>()
);

export const setDateFacetCollapsedState = createAction(
    SearchProfileActions.SET_DATE_FACETS_COLLAPSED_STATE,
    props<{ dateFacetCollapsedState: boolean }>()
);

export const setDefaultProfileSettings = createAction(SearchProfileActions.SET_DEFAULT_PROFILE_SETTINGS);

/**
 * errors
 */

export const notifyOfProfileError = createAction(
    SearchProfileActions.NOTIFY_OF_PROFILE_SEARCH_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);
