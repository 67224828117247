import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-highlight',
    templateUrl: './highlight.component.html',
    styleUrls: ['./highlight.component.scss'],
})
export class HighlightComponent implements OnInit {
    @Input() text: string | undefined;
    @Input() keywords: string[] | undefined;

    textAndStyle: TextAndStyle[] = [];

    style = Style;

    constructor() {}

    ngOnInit(): void {
        this.textAndStyle = this.splitToHighlight(this.text, this.keywords);
    }

    splitToHighlight(text: string | undefined, keywords: string[] | undefined): TextAndStyle[] {
        if (!text) return [{ text: '', style: Style.normal }];
        if (!keywords || keywords.length <= 0) return [{ text: text, style: Style.normal }];

        // Sort longer keywords before shorter keywords. This ensures that if a keyword contains another, the target
        //  string is first splitted by longer keywords. E.g. "Frauenstreik" before "Frauen". Otherwise, the highlighter
        //  would mark "<mark>Frauen</mark>streik" instead of "<mark>Frauenstreik</mark>"
        const lowerCaseKeywords = keywords
            .map(e => e.toLowerCase())
            .sort((a,b) => b.length - a.length);

        // Case-insensitive splitting
        const lowercaseSplit = this.arrayOfKeywordSplitStrings(text.toLowerCase(), lowerCaseKeywords);

        // Cut original string like the case-insensitive array
        const splitText = this.cutStringLikeArray(text, lowercaseSplit);

        return this.mapToTextAndStyle(splitText, lowerCaseKeywords);
    }

    arrayOfKeywordSplitStrings(text: string, keywords: string[]): string[] {
        let splitText = [text];
        for (let i = 0; i < keywords.length; i++) {
            for (let j = 0; j < splitText.length; j++) {
                // ensure that we do not split keywords any further (e.g. do not split keyword "Frauenstreik" with
                //  keyword "Frauen")
                if (!keywords.includes(splitText[j])) {
                    splitText.splice(j, 1, ...this.splitByKeyword(splitText[j], keywords[i]));
                }
            }
        }
        return splitText;
    }

    mapToTextAndStyle(splitText: string[], keywords: string[]): TextAndStyle[] {
        let textAndStyle: TextAndStyle[] = [];

        for (let i = 0; i < splitText.length; i++) {
            const style = keywords.includes(splitText[i].toLowerCase()) ? Style.highlight : Style.normal;
            textAndStyle.push({ text: splitText[i], style: style });
        }
        return textAndStyle;
    }

    splitByKeyword(text: string, keyword: string): string[] {
        let pieces = text.split(keyword);
        let piecesWithKeyword = [];
        for (let i = 0; i < pieces.length; i++) {
            if (!(pieces[i] === '')) {
                piecesWithKeyword.push(pieces[i]);
                if (i !== pieces.length - 1) piecesWithKeyword.push(keyword);
            } else {
                if (i !== pieces.length - 1) piecesWithKeyword.push(keyword);
            }
        }
        return piecesWithKeyword;
    }

    cutStringLikeArray(text: string, splitExample: string[]): string[] {
        let pieces = [];
        let cutIndex = 0;
        let endCutIndex = 0;
        for (let i = 0; i < splitExample.length; i++) {
            endCutIndex = cutIndex + splitExample[i].length;
            pieces.push(text.substring(cutIndex, endCutIndex));
            cutIndex = endCutIndex;
        }
        return pieces;
    }
}

interface TextAndStyle {
    text: string;
    style: Style;
}

export enum Style {
    normal = 'normal',
    highlight = 'highlight',
}
