import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-delete-entry-dialog',
    templateUrl: './delete-entry-dialog.component.html',
    styleUrls: ['./delete-entry-dialog.component.scss'],
})
export class DeleteEntryDialogComponent {
    constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

    closeDialog() {
        this.ref.close();
    }

    accept() {
        this.ref.close(true);
    }
}
