import { AudioTrack, NavigationMetadataDto } from '@faro/metadata-angular-client';
import { MediaCutInfo } from '../../player/shared/media-cut-info';

export function updateMediaSelection(
    currentSelection: DetailsStateMediaSelection,
    nav: NavigationMetadataDto
): DetailsStateMediaSelection {

    function isValidInNav(mediaCutId: string | undefined): boolean {
        return mediaCutId !== undefined && nav.mediaCuts.indexOf(mediaCutId) >= 0;
    }

    return {
        mediaCut: isValidInNav(currentSelection.mediaCut)
            ? currentSelection.mediaCut
            : nav.defaultMediaCutId || undefined,
        audioTrack: currentSelection.audioTrack,
    };
}

export function updatePlayerMediaCut(
    mediaCut: MediaCutInfo | undefined,
    selection: DetailsStateMediaSelection
): MediaCutInfo | undefined {
    // only keep the media cut if it still matches the selection
    return mediaCut?.mediaCutId === selection.mediaCut ? mediaCut : undefined;
}

export interface DetailsStateMediaSelection {
    mediaCut: string | undefined;
    audioTrack: AudioTrack;
    // audioTrackLabel?: string;
}
