import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule, LogLevel, PublicEventsService } from 'angular-auth-oidc-client';
import { SharedModule } from '../shared/shared.module';
import { registerAuthNotifications } from './register-auth-notifications';
import { NotificationService } from '../shared/services/notification.service';
import { environment } from '../../environments/environment';
import { AuthInterceptor } from './auth-interceptor';
import { UserService } from './user.service';

@NgModule({
    imports: [
        AuthModule.forRoot({
            config: {
                authority: environment.oidcAuthority,
                redirectUrl: window.location.origin,
                postLogoutRedirectUri: window.location.origin,
                clientId: 'faroweb',
                scope: 'openid profile offline_access faroapi farouserdata',
                responseType: 'code',
                silentRenew: true,
                useRefreshToken: true,
                renewTimeBeforeTokenExpiresInSeconds: 30,
                logLevel: LogLevel.Error,
            },
        }),
        SharedModule,
    ],
    exports: [AuthModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: registerAuthNotifications,
            deps: [PublicEventsService, NotificationService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        UserService,
    ],
    declarations: [],
})
export class AuthConfigModule {}
