import { Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, debounce, map, Observable, Subject, Subscription, take, takeUntil, timer } from 'rxjs';
import {
    DateFacetOutputComponentModel,
    DateFacetPresetComponentModel,
    ListFacetComponent,
    ListFacetComponentModel,
    ListFacetItemComponentModel,
} from '@faro/ngx-faro-ui';
import {
    resetAllFilters,
    resetFiltersForFacet,
    setFilterOperator,
    setProfileFilters,
    updateDateFacetRange,
    updateFacetSearchInput,
    updateFilter,
} from '../search-state/search-options.actions';
import {
    selectIsBeforeFirstSearch,
    selectIsLoading,
    selectIsLoadingFacet,
} from '../search-state/search-result.selectors';
import {
    DateRange,
    FilterOperator,
    SEARCH_FILTERS,
    SearchFieldSelectionOption,
    ValueFilterOperator,
} from '../search-state/search-options.state';
import {
    selectAllExcludedStorageCategories,
    selectAllFacetSearchResetTriggered,
    selectDateRange,
    selectFilterOperator,
    selectSearchFieldSelection,
    selectSearchUpdateAvailable,
    selectShowFilterInformation,
} from '../search-state/search-options.selectors';
import { dispatchSearch } from '../search-state/search-result.actions';
import { FilterType, ValueFilter } from '@faro/searchapi-angular-client';
import {
    selectDateFacetCollapsedState,
    selectDisplaySportFilters,
    selectFacetCollapsedState,
} from '../search-state/search-profile.selectors';
import { setDateFacetCollapsedState, setFacetCollapsedState } from '../search-state/search-profile.actions';
import { createFacetMap, isDisplayFacetVisible } from './display-facets.model';
import { datePresets } from './date-facet.selectors';
import { NavigationStart, Router } from '@angular/router';
import { StoreUtilsService } from '../../state/store-utils.service';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
    @ViewChildren(ListFacetComponent)
    facets: QueryList<ListFacetComponent> | undefined;

    /**
     * Allows to click on enter anywhere on the page and search is started
     * */
    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const enterFromInteractionItem = (event.target as HTMLElement).hasAttribute('data-no-search'); //Attribute set on input element and buttons that are visible
        const enterFromSearchTypeSelector = (event.target as HTMLElement).parentElement?.className.includes(
            'p-selectbutton'
        );
        const enterFromTreeItem = (event.target as HTMLElement).className.includes('p-treenode-content');
        const enterFromDateFacet = (event.target as HTMLElement).className.includes('range');
        const enterFromFacetHeader = (event.target as HTMLElement).parentElement?.className.includes('header');
        if (
            event.key === 'Enter' &&
            !this.isLoading &&
            !enterFromInteractionItem &&
            !enterFromSearchTypeSelector &&
            !enterFromDateFacet &&
            !enterFromTreeItem &&
            !enterFromFacetHeader
        ) {
            this.search();
        }
    }

    isLoading: boolean = false;
    updateFacetQuery$ = new Subject<{ facetField: string; query: string }>();
    updateFacetQuerySubscription: Subscription;
    showResetAllFiltersButton$: Observable<boolean>;
    showUpdateSearchButton$: Observable<boolean>;
    dateRange$: Observable<DateRange>;
    isBeforeFirstSearch$: Observable<boolean>;
    displaySearchButton$: Observable<boolean>;
    displayUpdateSearchButton$: Observable<boolean>;
    facetsToDisplay: string[] = [];
    dateFacetPresets: DateFacetPresetComponentModel[] = datePresets;
    dateFacetCollapsedState: boolean = true;
    selectedSearchType: SearchFieldSelectionOption = SEARCH_FILTERS[0];
    displaySportsFacets: boolean = false;
    filterOperators: ValueFilterOperator[] = [];

    /**
     * Facet Observables
     */
    private facetMap: Map<string, any>;

    private _destroyed$ = new Subject<void>();

    constructor(private readonly store: Store, private router: Router, private storeUtils: StoreUtilsService) {
        this.facetMap = createFacetMap(store);

        store
            .select(selectAllExcludedStorageCategories)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.store.dispatch(setProfileFilters({ filters: data }));
            });

        store
            .select(selectSearchFieldSelection)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(async data => {
                this.selectedSearchType = data;
                const displaySportFacets = await this.storeUtils.snapshot(selectDisplaySportFilters);
                this.facetsToDisplay = isDisplayFacetVisible(data, displaySportFacets);
            });

        store
            .select(selectIsLoadingFacet)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(map => {
                map.forEach((v: boolean, k: string) => {
                    this.setFacetOptions(k, v);
                });
            });

        store
            .select(selectFacetCollapsedState)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(map => {
                map.forEach((v: boolean, k: string) => {
                    this.setCollapsedState(k, v);
                });
            });

        store
            .select(selectDateFacetCollapsedState)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.dateFacetCollapsedState = data;
            });

        store
            .select(selectFilterOperator)
            .pipe(take(1))
            .subscribe(data => {
                this.filterOperators = data;
            });

        this.updateFacetQuerySubscription = this.updateFacetQuery$.pipe(debounce(_ => timer(500))).subscribe(update => {
            this.store.dispatch(updateFacetSearchInput(update));
        });

        this.dateRange$ = this.store.select(selectDateRange).pipe(
            map(({ from, to }) => {
                return { from, to };
            })
        );

        this.showResetAllFiltersButton$ = this.store.select(selectShowFilterInformation);
        this.isBeforeFirstSearch$ = this.store.select(selectIsBeforeFirstSearch);
        this.showUpdateSearchButton$ = this.store.select(selectSearchUpdateAvailable);

        this.displaySearchButton$ = this.showUpdateSearchButton$.pipe(
            combineLatestWith(this.isBeforeFirstSearch$),
            map(([searchUpdate, firstSearch]) => !searchUpdate || firstSearch)
        );

        this.displayUpdateSearchButton$ = this.showUpdateSearchButton$.pipe(
            combineLatestWith(this.isBeforeFirstSearch$),
            map(([searchUpdate, firstSearch]) => searchUpdate && !firstSearch)
        );
    }

    ngOnInit() {
        this.store
            .select(selectIsLoading)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.isLoading = data;
            });

        this.store
            .select(selectIsBeforeFirstSearch)
            .pipe(take(1))
            .subscribe(data => {
                if (data) {
                    let collapsedMap = new Map<string, boolean>();
                    this.facetMap.forEach(e => {
                        collapsedMap.set(e.facetOptions.field, e.facetOptions.collapsed);
                    });
                    this.store.dispatch(setFacetCollapsedState({ facets: collapsedMap }));
                }
            });

        this.router.events.pipe(takeUntil(this._destroyed$)).subscribe(data => {
            if (data instanceof NavigationStart) {
                let collapsedMap = new Map<string, boolean>();
                this.facetMap.forEach(e => {
                    collapsedMap.set(e.facetOptions.field, e.facetOptions.collapsed);
                });
                this.store.dispatch(setFacetCollapsedState({ facets: collapsedMap }));
            }
        });

        this.store
            .select(selectDisplaySportFilters)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.displaySportsFacets = data;
                this.facetsToDisplay = isDisplayFacetVisible(this.selectedSearchType, data);
            });

        this.store
            .select(selectAllFacetSearchResetTriggered)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                if (data) {
                    this.clearFacetSearch();
                }
            });
    }

    ngOnDestroy() {
        this.updateFacetQuerySubscription.unsubscribe();
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    facetSelectionChanged(facetElement: ListFacetItemComponentModel): void {
        const filter = mapListFacetElementComponentModelToFilter(facetElement);
        this.store.dispatch(updateFilter({ filter }));
    }

    facetQueryChanged(e: { facetField: string; query: string }): void {
        this.updateFacetQuery$.next(e);
    }

    resetFacetSelections(field: string): void {
        this.store.dispatch(resetFiltersForFacet({ field }));
    }

    onDateFacetChanged(newDate: DateFacetOutputComponentModel | null): void {
        this.store.dispatch(
            updateDateFacetRange({ from: newDate?.range?.from ?? null, to: newDate?.range?.to ?? null })
        );
    }

    private setFacetOptions(field: string | null | undefined, loading: boolean): void {
        if (!field) return;
        this.facetMap.forEach(f => {
            if (f.facetOptions.field === field) {
                f.facetOptions.field = field;
                f.facetOptions.isLoading = loading;
            }
        });
    }

    private setCollapsedState(field: string | null | undefined, collapsed: boolean) {
        if (!field) return;
        this.facetMap.forEach(f => {
            if (f.facetOptions.field === field) {
                f.facetOptions.collapsed = collapsed;
            }
        });
    }

    search(): void {
        this.store.dispatch(dispatchSearch());
    }

    resetAllFilters(): void {
        this.store.dispatch(resetAllFilters());
        this.clearFacetSearch();
    }

    clearFacetSearch() {
        this.facets?.map(facet => facet.clearSearch());
    }

    getObservable(key: string): Observable<ListFacetComponentModel> {
        return this.facetMap.get(key).facet;
    }

    getFacetHeader(key: string): string {
        return this.facetMap.get(key).header;
    }

    getFacetOptionsField(key: string): string {
        return this.facetMap.get(key).facetOptions.field;
    }

    getFacetOptionsSearchModes(key: string): any {
        return this.facetMap.get(key).facetOptions.searchModes || [];
    }

    getFacetOptionsLoading(key: string): boolean {
        return this.facetMap.get(key).facetOptions.isLoading;
    }

    setFacetCollapsed(collapsedState: boolean, key: string): void {
        if (!collapsedState) {
            this.scrollToFacet(key);
        }
        if (key.startsWith('IMAGE_')) {
            const standardKey = key.split('IMAGE_')[1];
            if (this.facetMap.get(standardKey)) {
                this.facetMap.get(standardKey).facetOptions.collapsed = collapsedState;
            }
        } else {
            const imageKey = `IMAGE_${key}`;
            if (this.facetMap.get(imageKey)) {
                this.facetMap.get(imageKey).facetOptions.collapsed = collapsedState;
            }
        }
        this.facetMap.get(key).facetOptions.collapsed = collapsedState;
    }

    setDateFacetCollapsed(collapsedState: boolean): void {
        this.store.dispatch(setDateFacetCollapsedState({ dateFacetCollapsedState: collapsedState }));
    }

    getFacetOptionsCollapsed(key: string): boolean {
        return this.facetMap.get(key)?.facetOptions.collapsed;
    }

    getCountLabel(key: string): Observable<number | null> {
        return this.facetMap.get(key).facetCountLabel;
    }

    setFacetSearchMode(event: string, key: string) {
        const operator = event === 'AND' ? FilterOperator.AND : FilterOperator.OR;
        this.store.dispatch(setFilterOperator({ field: key, operator }));
    }

    getSelectedFacetOperator(key: string): string {
        const field = this.getFacetOptionsField(key);
        const valueFilterOperator = this.filterOperators.filter(k => k.field === field)[0] ?? null;
        return valueFilterOperator ? valueFilterOperator.operator.toUpperCase() : 'OR';
    }

    scrollToFacet(key: string) {
        const facet = document.getElementById(this.getFacetOptionsField(key));
        if (facet) {
            setTimeout(() => {
                facet.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
            }, 300);
        }
    }
}

function mapListFacetElementComponentModelToFilter(facetElement: ListFacetItemComponentModel): ValueFilter {
    return {
        field: facetElement.field,
        value: facetElement.value,
        filterType: mapListFacetCheckBoxStateComponentModelToFilterType(facetElement.state),
    };
}

function mapListFacetCheckBoxStateComponentModelToFilterType(input: boolean | null): FilterType {
    switch (input) {
        case true:
            return FilterType.Inclusion;
        case false:
            return FilterType.Exclusion;
        default:
            return FilterType.None;
    }
}
