import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    HitListActionsSortingByComponentModel,
    HitListActionsSortingOrderComponentModel,
} from './hit-list-actions.interfaces.component';

@Component({
    selector: 'faro-hit-list-actions',
    templateUrl: './hit-list-actions.component.html',
    styleUrls: ['./hit-list-actions.component.scss'],
})
export class HitListActionsComponent implements OnInit {
    @Input()
    sortingByValues: HitListActionsSortingByComponentModel[] = [];

    @Input()
    sortingOrderValues: HitListActionsSortingOrderComponentModel[] = [];

    @Input()
    selectedSortingBy: HitListActionsSortingByComponentModel | undefined | null;

    @Input()
    selectedSortingOrder: any | undefined | null;

    @Output()
    sortingByChange = new EventEmitter<any>();

    @Output()
    sortingOrderChange = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {
        if (!this.selectedSortingBy && this.sortingByValues?.length > 0) {
            this.selectedSortingBy = this.sortingByValues[0];
        }
        if (!this.selectedSortingOrder && this.sortingOrderValues?.length > 0) {
            this.selectedSortingOrder = this.sortingOrderValues[0].value;
        }
    }

    sortingByChanged(sortingBy: HitListActionsSortingByComponentModel): void {
        this.sortingByChange.emit(sortingBy);
    }

    sortingOrderChanged(order: any): void {
        this.sortingOrderChange.emit(order);
    }
}
