<div *ngIf="items?.length ?? 0 > 0" class="list">
    <ng-container *ngFor="let item of items; let i = index; trackBy: identity">
        <div class="item" [class.disabled]="item.cardinality === 0">
            <p-triStateCheckbox
                role="checkbox"
                [attr.aria-label]="item.term"
                [attr.aria-checked]="item.state"
                [ngModel]="item.state"
                (onChange)="onCheckboxChanged(item, $event)"
                id="{{ listFacetSectionComponentId + '_' + i }}"
            >
            </p-triStateCheckbox>
            <span
                class="term"
                [ngClass]="{ 'term-deselected': item.state === false }"
                (mouseover)="setTooltip($event, item.term)"
                >{{ item.term }}</span
            >
            <ng-container *ngIf="item.cardinality">
                <div class="cardinality">
                    <span>{{ item.cardinality }}</span>
                </div>
            </ng-container>
            <ng-container *ngIf="item.cardinality === 0">
                <span class="cardinality-zero">-</span>
            </ng-container>
        </div>
    </ng-container>
</div>
