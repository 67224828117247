<div class="dialog-content" role="dialog" aria-labelledby="dialogTitle">
    <div class="dialog-header">
        <h2 class="title" id="dialogTitle">{{ config.data.title }}</h2>
        <div>
            <button pRipple class="close-icon" aria-label="Dialog schliessen" (click)="closeDialog()">
                <img src="assets/icons/close-icon.svg" alt="Dialog schliessen" />
            </button>
        </div>
    </div>
    <div class="information-text">Soll der Eintrag wirklich aus dem Warenkorb enfernt werden?</div>
    <div class="dialog-footer">
        <button class="search-button" type="button" (click)="accept()" pRipple autofocus data-cy="delete-shopping-entry-dialog-button">
            <img src="assets/icons/trash-white.svg" alt="" />Löschen
        </button>
        <button
            class="dialog-secondary-button"
            type="button"
            pButton
            icon="pi pi-times"
            (click)="closeDialog()"
            label="Abbrechen"
        ></button>
    </div>
</div>
