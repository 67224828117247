import { Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    selectDetailFormatRtc,
    selectDetailOrderVtcIn,
    selectDetailOrderVtcOut,
    selectIsInOutSelectionValid,
} from '../details-state/details.selectors';
import { Observable, of, Subject, takeUntil, combineLatestWith, map } from 'rxjs';
import { setDetailOrderVtcIn, setDetailOrderVtcOut } from '../details-state/details.actions';
import { TimeService } from '../shared/services/time.service';
import { OrderRemarkResponse } from '../../shared/dialogs/order-remark-dialog/order-remark-dialog-content-and.config';

@Component({
    selector: 'app-in-out-time-code',
    templateUrl: './in-out-time-code.component.html',
    styleUrls: ['./in-out-time-code.component.scss'],
})
export class InOutTimeCodeComponent implements OnDestroy {
    @Input()
    readonly: boolean = false;

    @Output()
    orderInOutSelection = new EventEmitter<{ remark: OrderRemarkResponse }>();

    inTimeCode$ = of('');
    outTimeCode$ = of('');

    private _destroyed$ = new Subject<void>();

    constructor(private store: Store, private timeService: TimeService) {
        const formatRtc$ = this.store.select(selectDetailFormatRtc);

        this.inTimeCode$ = this.store.select(selectDetailOrderVtcIn).pipe(
            takeUntil(this._destroyed$),
            combineLatestWith(formatRtc$),
            map(([vtc, formatRtc]) => (vtc ? formatRtc(vtc, '-') : '-'))
        );

        this.outTimeCode$ = this.store.select(selectDetailOrderVtcOut).pipe(
            takeUntil(this._destroyed$),
            combineLatestWith(formatRtc$),
            map(([vtc, formatRtc]) => (vtc ? formatRtc(vtc, '-') : '-'))
        );
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    setValues(type: string) {
        const currentVtc = this.timeService.getCurrentVtc();
        if (!currentVtc) return;

        if (type === 'IN') {
            this.store.dispatch(setDetailOrderVtcIn({ vtcIn: currentVtc }));
        } else {
            this.store.dispatch(setDetailOrderVtcOut({ vtcOut: currentVtc }));
        }
    }

    @HostListener('window:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const keyFromInputElement = (event.target as HTMLElement).tagName.toLowerCase()
            ? (event.target as HTMLElement).tagName.toLowerCase() === 'input'
            : false;
        if (!keyFromInputElement) {
            if (event.key === 'i') {
                this.setValues('IN');
            }
            if (event.key === 'o') {
                this.setValues('OUT');
            }
        }
    }
}
