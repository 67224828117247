import { Injectable } from '@angular/core';
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StoreUtilsService {
    constructor(private readonly store: Store) {}

    snapshot<T>(selector: MemoizedSelector<object, T, DefaultProjectorFn<T>>): Promise<T> {
        return firstValueFrom(this.store.select(selector));
    }
}
