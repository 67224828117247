import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListFacetItemComponentModel } from '../list-facet.interfaces';

@Component({
    selector: 'faro-list-facet-section',
    templateUrl: './list-facet-section.component.html',
    styleUrls: ['./list-facet-section.component.scss'],
})
export class ListFacetSectionComponent {
    @Input()
    items: ListFacetItemComponentModel[] | undefined;

    @Output()
    checkBoxChanged = new EventEmitter<ListFacetItemComponentModel>();

    private static _seq: number = 0;
    public listFacetSectionComponentId: string = 'list_facet_section_' + ListFacetSectionComponent._seq++;

    onCheckboxChanged(element: ListFacetItemComponentModel, event: any): void {
        element.state = event.value;
        this.checkBoxChanged.emit(element);
    }

    /**
     * Function to put focus back on checkbox after EventEmitter
     * */
    identity(index: any) {
        return index;
    }

    setTooltip(event: MouseEvent, term: string) {
        const element = event.currentTarget as HTMLElement;
        if (element && element.scrollWidth > element.clientWidth) {
            element.setAttribute('title', term ?? '');
        } else {
            element.setAttribute('title', '');
        }
    }
}
