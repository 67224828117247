<div class="dialog-content" aria-labelledby="dialogTitle" id="remarkDialog">
    <div class="dialog-header">
        <h2 class="title" id="dialogTitle">{{ config.data.title }}</h2>
        <div>
            <button
                data-no-search
                id="orderRemarkClose"
                pRipple
                class="close-icon"
                aria-label="Dialog schliessen"
                (click)="closeDialog()"
            >
                <img src="assets/icons/close-icon.svg" alt="Dialog schliessen" />
            </button>
        </div>
    </div>
    <form class="remark-form" [formGroup]="remarkForm" (ngSubmit)="orderWithRemark()">
        <div class="search-field-container">
            <div class="input-field">
                <input
                    data-no-search
                    pInputText
                    id="orderRemarkInput"
                    type="text"
                    placeholder="Vermerk"
                    autofocus
                    formControlName="orderRemark"
                    autocomplete="off"
                />
            </div>
        </div>
        <div class="checkbox-container" *ngIf="config.data.contentAndConfig.displayCheckBox">
            <p-checkbox
                formControlName="orderAudioOnly"
                [binary]="true"
                label="Nur aktive Audiospur bestellen"
            ></p-checkbox>
            <div class="audio-label">{{ config.data.contentAndConfig.audioTrack }}</div>
        </div>
        <div class="dialog-footer">
            <button data-no-search id="orderRemarkSubmit" class="search-button" type="submit" pRipple>
                <img src="assets/icons/shopping-cart-white.svg" alt="" />In den Warenkorb
            </button>
            <button
                data-no-search
                id="orderRemarkCancel"
                class="dialog-secondary-button"
                type="button"
                pButton
                icon="pi pi-times"
                (click)="closeDialog()"
                label="Abbrechen"
            ></button>
        </div>
    </form>
</div>
