<div class="header-actions">
    <p-menu #profileMenu [popup]="true" [model]="profileMenuItems"></p-menu>
    <button class="profile-action" (click)="openMenuAndSetFocus($event, profileMenu)" data-cy="header-profile-button">
        <img src="assets/icons/profile.svg" alt="Profil Aktionen" />
        <p>{{ username$ | async }}</p>
    </button>
    <button pRipple type="button" class="p-header-button" (click)="openInfo()" data-cy="header-info-button">
        <img src="assets/icons/info.svg" alt="Information" />
    </button>
    <button pRipple type="button" class="p-header-button" (click)="onShoppingCartClick(isInShoppingCart)" data-cy="header-shopping-cart-button">
        <img src="assets/icons/shopping-cart.svg" alt="Warenkorb" *ngIf="!isInShoppingCart" />
        <img src="assets/icons/close-icon.svg" alt="Warenkorb-Sicht verlassen" *ngIf="isInShoppingCart" />
        <div *ngIf="!isInShoppingCart && (nrOfShoppingCartItems$ | async) as nrOfShoppingCartItems" class="cart-items">
            {{ nrOfShoppingCartItems }}
        </div>
    </button>
</div>
