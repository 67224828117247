import { Injectable } from '@angular/core';
import { Hit } from '@faro/searchapi-angular-client';
import { TableHitComponentModel } from './table-hitlist/table-hit-component.model';
import { accessibilityDurationString } from '../../../shared/accessibility-duration.helper';
import { fromMilliseconds } from '../../../shared/duration';
import { SearchFieldSelectionEnum, SearchFieldSelectionOption } from '../../search-state/search-options.state';

@Injectable({
    providedIn: 'root',
})
export class HitMapperService {
    constructor() {}

    mapHitToTableHitComponentModel = (
        hit: Hit,
        selectedSearchOption: SearchFieldSelectionOption
    ): TableHitComponentModel => {
        const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
        return {
            hitId: hit.id,
            programId: this.programId(hit, selectedSearchOption),
            itemId: isImageSearchType ? getItemId(hit)?.toLowerCase() : undefined,
            title: getTitle(hit, selectedSearchOption),
            date: getDate(hit, selectedSearchOption),
            duration: getDuration(hit, selectedSearchOption),
            durationAccessibilityString: this.getAccessibilityDurationString(getDuration(hit, selectedSearchOption)),
            description: `<span>${getDescription(
                hit,
                selectedSearchOption
            )}</span><span class="h-offscreen">${getDescriptionCellTip(hit, selectedSearchOption)}</span>`,
            descriptionCellTip: getDescriptionCellTip(hit, selectedSearchOption),
            itemTitle: `<a class="accessibility-link" href="details/${this.programId(hit, selectedSearchOption)}/${
                isImageSearchType ? getItemId(hit) : hit.id
            }"> ${getItemTitle(hit, selectedSearchOption)}</a><span class="h-offscreen">${getItemTitleCellTip(
                hit,
                selectedSearchOption
            )}</span>`,
            itemTitleCellTip: getItemTitleCellTip(hit, selectedSearchOption),
            source: getSource(hit, selectedSearchOption),
            keyframeId: getKeyframeIndex(hit, selectedSearchOption),
        };
    };

    programId(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
        return getProgramId(hit, selectedSearchOption)?.toLowerCase();
    }

    getAccessibilityDurationString(duration: string | undefined): string {
        const parsedDuration = fromMilliseconds(parseInt(duration ?? '0'));
        return accessibilityDurationString(parsedDuration);
    }
}

enum ItemHitKeys {
    NUMBER = 'Item.Hitlist.ItemNr',
    PROGRAM_TITLE = 'Item.Hitlist.PresTitle',
    ITEM_TITLE = 'Item.Hitlist.PresentationBTIT',
    DESCRIPTION = 'Item.Hitlist.PresentationABS',
    DATE = 'Item.Hitlist.VirtDateStart',
    DURATION = 'Item.Hitlist.Length',
    KEYFRAME = 'Item.Hitlist.KF',
    SOURCE = 'Item.Hitlist.PresRight',
    PROGRAM_ID = 'Item.Hitlist.ProgramId',
}

enum SequenceHitKeys {
    NUMBER = 'Sequence.Hitlist.ItemNr',
    PROGRAM_TITLE = 'Sequence.Hitlist.PresTitle',
    ITEM_TITLE = 'Sequence.Hitlist.PresentationBTIT',
    DESCRIPTION = 'Sequence.Hitlist.PresentationABS',
    DATE = 'Sequence.Hitlist.VirtDateStart',
    DURATION = 'Sequence.Hitlist.Length',
    KEYFRAME = 'Sequence.Hitlist.KF',
    SOURCE = 'Sequence.Hitlist.PresRight',
    PROGRAM_ID = 'Sequence.Hitlist.ProgramId',
    ITEM_ID = 'Sequence.Hitlist.ItemId',
}

function getHitPropertyValue(hit: Hit, keys: [ItemHitKeys | SequenceHitKeys], isCellTip: boolean): string {
    if (isCellTip) {
        return hit.items?.[keys[0]]?.cellTip || '';
    } else {
        return hit.items?.[keys[0]]?.value || '';
    }
}

function getTitle(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    return getHitPropertyValue(
        hit,
        isImageSearchType ? [SequenceHitKeys.PROGRAM_TITLE] : [ItemHitKeys.PROGRAM_TITLE],
        false
    );
}

function getItemTitle(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    return getHitPropertyValue(hit, isImageSearchType ? [SequenceHitKeys.ITEM_TITLE] : [ItemHitKeys.ITEM_TITLE], false);
}

function getItemTitleCellTip(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    return getHitPropertyValue(hit, isImageSearchType ? [SequenceHitKeys.ITEM_TITLE] : [ItemHitKeys.ITEM_TITLE], true);
}

function getDate(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    return getHitPropertyValue(hit, isImageSearchType ? [SequenceHitKeys.DATE] : [ItemHitKeys.DATE], false);
}

function getDuration(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    return getHitPropertyValue(hit, isImageSearchType ? [SequenceHitKeys.DURATION] : [ItemHitKeys.DURATION], false);
}

function getDescription(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    return getHitPropertyValue(
        hit,
        isImageSearchType ? [SequenceHitKeys.DESCRIPTION] : [ItemHitKeys.DESCRIPTION],
        false
    );
}

function getDescriptionCellTip(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    return getHitPropertyValue(
        hit,
        isImageSearchType ? [SequenceHitKeys.DESCRIPTION] : [ItemHitKeys.DESCRIPTION],
        true
    );
}

function getSource(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    return getHitPropertyValue(hit, isImageSearchType ? [SequenceHitKeys.SOURCE] : [ItemHitKeys.SOURCE], false);
}

function getProgramId(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): string | undefined {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    return getHitPropertyValue(hit, isImageSearchType ? [SequenceHitKeys.PROGRAM_ID] : [ItemHitKeys.PROGRAM_ID], false);
}

function getItemId(hit: Hit): string | undefined {
    return getHitPropertyValue(hit, [SequenceHitKeys.ITEM_ID], false);
}

function getKeyframeIndex(hit: Hit, selectedSearchOption: SearchFieldSelectionOption): number {
    const isImageSearchType = selectedSearchOption.value === SearchFieldSelectionEnum.IMAGE;
    const keyframeIdString = getHitPropertyValue(
        hit,
        isImageSearchType ? [SequenceHitKeys.KEYFRAME] : [ItemHitKeys.KEYFRAME],
        false
    );
    return Number.parseInt(keyframeIdString!); // Number.parseInt can handle null/undefined
}
