import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShoppingCartState } from './shopping-cart.state';

export const shoppingCartFeatureKey = 'shoppingCart';

export const selectShoppingCart = createFeatureSelector<ShoppingCartState>(shoppingCartFeatureKey);

export const selectShoppingCartSearchOptions = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.searchOptions
);

export const selectShoppingCartSearchDateRange = createSelector(
    selectShoppingCartSearchOptions,
    searchOptions => searchOptions.queryDate
);

export const selectShoppingCartSortingInformation = createSelector(
    selectShoppingCartSearchOptions,
    searchOptions => searchOptions.sorting
);

export const selectShowResetSearchParamsButton = createSelector(
    selectShoppingCartSearchOptions,
    searchOptions =>
        searchOptions.description.length > 0 ||
        searchOptions.number.length > 0 ||
        searchOptions.pid.length > 0 ||
        searchOptions.firstName.length > 0 ||
        searchOptions.lastName.length > 0 ||
        searchOptions.queryDate.from !== null ||
        searchOptions.queryDate.to !== null ||
        searchOptions.orderStatus !== null
);

export const selectSearchRequested = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.searchRequested
);

export const selectInitialSearchRequested = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.initialSearchRequested
);

export const selectDisplayNewShoppingCartDialog = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.displayActivateNewCartDialog
);

export const selectActiveShoppingCart = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.activeShoppingCart
);

export const selectSelectedShoppingCart = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.selectedShoppingCart
);

export const selectLoading = createSelector(selectShoppingCart, (state: ShoppingCartState) => state.loading);

export const selectLoadingShoppingCart = createSelector(selectLoading, loading => loading.shoppingCart);

export const selectLoadingEntryMaterial = createSelector(selectLoading, loading => loading.entryMaterial);

export const selectLoadingShoppingCartSearch = createSelector(selectLoading, loading => loading.shoppingCartSearch);

export const selectDialogAvailableMaterial = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.dialogAvailableMaterial
);

export const selectEntryErrors = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.activeCartEntryErrors
);

export const selectShoppingCartSearchResult = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.shoppingCartSearchResult
);

export const selectAdmira = createSelector(selectShoppingCart, (state: ShoppingCartState) => state.admira);

export const selectAdmiraDevices = createSelector(selectAdmira, admira => admira.admiraDevices);

export const selectAdmiraFolders = createSelector(selectAdmira, admira => admira.admiraFolders);

export const selectSelectedAdmiraDevice = createSelector(selectAdmira, admira => admira.selectedAdmiraDevice);

export const selectSelectedAdmiraDeviceRootPath = createSelector(selectAdmira, admira => admira.deviceRootPath);

export const selectSelectedAdmiraDeviceRootName = createSelector(selectAdmira, admira => admira.deviceRootName);

export const selectExportSettings = createSelector(
    selectShoppingCart,
    (state: ShoppingCartState) => state.exportSettings
);

export const selectScrollTopPosition = createSelector(selectShoppingCart, (state: ShoppingCartState): number => {
    return state.scrollTopPosition;
});

export const selectOrderingShoppingCart = createSelector(selectShoppingCart, (state: ShoppingCartState): boolean => {
    return state.orderingShoppingCart;
});
