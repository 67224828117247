import { Duration } from './duration';

export function accessibilityDurationString(duration: Duration): string {
    let durationString = '';
    const hours = duration.getHours();
    const minutes = duration.getMinutes();
    const seconds = duration.getSeconds();
    if (duration) {
        if (hours !== 0) {
            durationString += hours + (hours > 1 ? ' Stunden ' : ' Stunde');
        }
        if (minutes !== 0) {
            durationString += minutes + (minutes > 1 ? ' Minuten ' : ' Minute');
        }
        if (seconds !== 0) {
            durationString += seconds + (seconds > 1 ? ' Sekunden ' : ' Sekunde');
        }
    }
    return durationString;
}
