import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, distinctUntilChanged, map, Observable, Subscription } from 'rxjs';
import { NullableDate } from '../../shared/nullable-date.interfaces';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { PrimeNGConfig } from 'primeng/api';
import { PRIMENG_TRANSLATIONS_GERMAN } from '../../shared/primeng-de.translations';
import { DateFacetRangeComponentModel, DateRangeOrigin } from '../date-range-input/date-range.interface';
import {
    DateFacetCalendarValue,
    DateFacetDateRangeInput,
    DateFacetOutputComponentModel,
    DateFacetPresetComponentModel,
} from './date-facet.interfaces';

@Component({
    selector: 'faro-date-facet',
    templateUrl: './date-facet.component.html',
    styleUrls: ['./date-facet.component.scss'],
})
export class DateFacetComponent implements OnInit, OnDestroy {
    @ViewChild('dateFacetInput', { static: false })
    dateFacetInput: ElementRef | undefined;

    @Input()
    header?: string;

    @Input()
    showDeleteIcon: boolean = false;

    @Input()
    requiresEnterToSetDate: boolean = false;

    @Input()
    applyCalendarSelectionInstantly: boolean = false;

    @Input()
    placeholderText: string = '';

    private _collapsed = true;

    @Input()
    get collapsed(): boolean {
        return this._collapsed;
    }

    set collapsed(v: boolean) {
        this._collapsed = v;
        if (this._collapsed && !this.calendarHidden) {
            this._calendarPanel?.hide();
        }
    }

    @Input()
    set dateRange(range: DateFacetRangeComponentModel | undefined | null) {
        this.setDateRange([range?.from ?? null, range?.to ?? null], DateRangeOrigin.External);
    }

    @Input()
    presets: DateFacetPresetComponentModel[] = [];

    @Input()
    showClose: boolean = false;

    @Output()
    changed = new EventEmitter<DateFacetOutputComponentModel>();

    @Output()
    dateFacetCollapsedChanged = new EventEmitter<boolean>();

    calendarHidden = true;

    @ViewChild('calendarPanel')
    private _calendarPanel?: OverlayPanel;

    private _dateRangeChanges$ = new BehaviorSubject<DateFacetDateRangeInput>({
        range: { from: null, to: null },
        origin: DateRangeOrigin.Calendar,
    });

    private _subscription: Subscription;

    dateRangeIsSet$: Observable<boolean>;
    dateRangeInputInvalid = false;
    dateRangeInputPending = false;

    private _calendarValue: DateFacetCalendarValue = [];

    get calendarValue(): DateFacetCalendarValue {
        return this._calendarValue;
    }

    set calendarValue(dates: DateFacetCalendarValue) {
        if (dates.filter(d => d !== null).length === 2) {
            this.setDateRange(dates, DateRangeOrigin.Calendar);
            this._calendarPanel?.hide();
        }
    }

    private dateExpression = /^(?<d>(0?[1-9]|[12][0-9]|3[01]))\.(?<m>(0?[1-9]|1[012]))\.(?<y>(\d{2}|\d{4}))$/;
    private yearDateExpression = /^(?<y>(\d{4}))$/;
    private monthYearDateExpression = /^(?<m>(0?[1-9]|1[012]))\.(?<y>(\d{2}|\d{4}))$/;

    private _dateRangeInput = '';
    /**
     * Date range that is entered manually into input field
     * */
    private _manualRange: DateFacetRangeComponentModel | null = null;

    get dateRangeInput(): string {
        return this._dateRangeInput;
    }

    set dateRangeInput(v: string) {
        this._dateRangeInput = v;
        this.dateRangeInputPending = true;
        const trimmedInput = v.trim();
        if (!trimmedInput) {
            this._manualRange = { from: null, to: null };
            this.dateRangeInputInvalid = false;
        } else {
            this._manualRange = this.parseRange(trimmedInput);
            this.dateRangeInputInvalid = this._manualRange === null;
        }
    }

    constructor(private readonly primeConfig: PrimeNGConfig) {
        this._subscription = this._dateRangeChanges$.subscribe(ri => {
            this._calendarValue = [];
            if (ri.range.from) {
                this._calendarValue.push(ri.range.from);
            }
            if (ri.range.to && !this.areEqual(ri.range.from, ri.range.to)) {
                this._calendarValue.push(ri.range.to);
            }

            if (ri.origin !== DateRangeOrigin.Manual) {
                this._dateRangeInput = this.formatDateRange(ri.range);
            }
        });

        this.dateRangeIsSet$ = this._dateRangeChanges$.pipe(
            map(ri => ri.range),
            map(r => r.from !== null || r.to !== null)
        );

        const sub = this._dateRangeChanges$
            .pipe(
                distinctUntilChanged(
                    (prev, curr) =>
                        this.areEqual(prev.range.from, curr.range.from) && this.areEqual(prev.range.to, curr.range.to)
                )
            )
            .subscribe((r: DateFacetDateRangeInput): void => {
                this.changed.emit({
                    range: r.range.from === null && r.range.to === null ? null : r.range,
                    changeThroughUserAction: r.origin !== DateRangeOrigin.External,
                });
            });

        this._subscription.add(sub);
    }

    ngOnInit(): void {
        this.primeConfig.setTranslation(PRIMENG_TRANSLATIONS_GERMAN);
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private areEqual(a: NullableDate, b: NullableDate): boolean {
        return a?.getTime() === b?.getTime();
    }

    private formatDateRange(range: DateFacetRangeComponentModel): string {
        if (range?.from && range?.to && this.areEqual(range.from, range.to)) {
            return range.from.toLocaleDateString('de-CH');
        }

        if (range?.from && range?.to && !this.areEqual(range.from, range.to)) {
            return range.from.toLocaleDateString('de-CH') + ' - ' + range.to.toLocaleDateString('de-CH');
        }

        if (range?.from && !range?.to) {
            return range.from.toLocaleDateString('de-CH') + ' -';
        }
        if (!range?.from && range?.to) {
            return '- ' + range.to.toLocaleDateString('de-CH');
        }

        return '';
    }

    private parseRange(string: string): DateFacetRangeComponentModel | null {
        const parts = string.split('-').map(s => s.trim());
        if (parts.length === 1) {
            const date = this.parseDate(parts[0]);
            if (date.type === 'onlyYear') {
                if (date.date instanceof Date) {
                    const endOfYear = dayjs(date.date).endOf('year').toDate();
                    return { from: date.date, to: endOfYear };
                }
            }
            if (date.type === 'monthYear') {
                if (date.date instanceof Date) {
                    const lastDayOfMonth = dayjs(date.date).endOf('month').date();
                    return {
                        from: date.date,
                        to: new Date(date.date.getFullYear(), date.date.getMonth(), lastDayOfMonth),
                    };
                }
            }
            if (date.date instanceof Date) {
                return { from: date.date, to: date.date };
            }
        } else if (parts.length === 2) {
            const from = this.parseDate(parts[0]);
            if (from.date === 'invalid') {
                return null;
            }
            const to = this.parseDate(parts[1]);
            if (to.date === 'invalid') {
                return null;
            }

            if (from || to) {
                return { from: from.date, to: to.date };
            }
        }

        return null;
    }

    private parseDate(s: string): { date: NullableDate | 'invalid'; type?: string } {
        if (s.length === 0) {
            return { date: null };
        }

        const regularDateMatches = this.dateExpression.exec(s);
        const onlyYearMatch = this.yearDateExpression.exec(s);
        const monthYearMatch = this.monthYearDateExpression.exec(s);

        const possibleDateFormats: string[] = [
            'MM.DD.YYYY',
            'M.D.YYYY',
            'MM.D.YYYY',
            'M.DD.YYYY',
            'MM.DD.YY',
            'M.D.YY',
            'M.DD.YY',
            'MM.D.YY',
        ];

        if (regularDateMatches !== null) {
            const day = regularDateMatches.groups?.['d'];
            const month = regularDateMatches.groups?.['m'];
            const year = regularDateMatches.groups?.['y'];
            if (day && month && year) {
                const dayOfMonth = parseInt(day);
                const date = dayjs(`${month}.${day}.${year}`, possibleDateFormats).toDate();

                // to prevent that '31.04.yyyy' results in 'May 1 ..'
                if (dayOfMonth === date.getDate()) {
                    return { date: date };
                }
            }
        }
        if (monthYearMatch !== null) {
            const month = monthYearMatch.groups?.['m'];
            const year = monthYearMatch.groups?.['y'];
            if (year && month) {
                const date = dayjs(`${month}.1.${year}`, possibleDateFormats).toDate();
                return { date, type: 'monthYear' };
            }
        }
        if (onlyYearMatch !== null) {
            const year = onlyYearMatch.groups?.['y'];
            if (year) {
                const date = dayjs(`1.1.${year}`, possibleDateFormats).toDate();
                return { date, type: 'onlyYear' };
            }
        }

        return { date: 'invalid' };
    }

    private setDateRange(dates: NullableDate[], origin: DateRangeOrigin): void {
        if (dates.length === 0) {
            this._dateRangeChanges$.next({ range: { from: null, to: null }, origin: origin });
        } else if (dates.length === 1) {
            this._dateRangeChanges$.next({ range: { from: dates[0], to: dates[0] }, origin: origin });
        } else {
            this._dateRangeChanges$.next({ range: { from: dates[0], to: dates[1] }, origin: origin });
        }
        this.dateRangeInputInvalid = false;
        this.dateRangeInputPending = false;
    }

    toggleCalendar(event: Event, target: HTMLElement): void {
        if (this._collapsed && this.calendarHidden) {
            this._collapsed = false;
        }
        if (this._calendarPanel) {
            this._calendarPanel.toggle(event, target);
        }
    }

    clearDateRange(): void {
        this._dateRangeChanges$.next({ range: { from: null, to: null }, origin: DateRangeOrigin.Calendar });
        this.dateRangeInputInvalid = false;
        this.dateRangeInputPending = false;
    }

    applyPreset(preset: DateFacetPresetComponentModel): void {
        this._dateRangeChanges$.next({ range: preset.preset(), origin: DateRangeOrigin.Preset });
        this.dateRangeInputInvalid = false;
        this.dateRangeInputPending = false;
    }

    onFocus(): void {
        if (this._calendarPanel) {
            this._calendarPanel.hide();
        }
    }

    onKeyUp(event: KeyboardEvent): void {
        const setNewDateEntry = this._manualRange !== null && (!this.requiresEnterToSetDate || event.key === 'Enter');
        if (setNewDateEntry) {
            this.setNewDateRange();
        }

        if (event.key === 'Enter' && this.dateFacetInput) {
            this.dateFacetInput.nativeElement.blur();
        }
    }

    startDateValidation() {
        if (this._manualRange !== null) {
            this.setNewDateRange();
        }
    }

    setNewDateRange() {
        this._dateRangeChanges$.next(<DateFacetDateRangeInput>{
            range: this._manualRange,
            origin: DateRangeOrigin.Manual,
        });
        this.dateRangeInputPending = false;
    }

    showCalendar() {
        if (this._dateRangeChanges$.value.range.from === null && this._dateRangeChanges$.value.range.to === null) {
            this._calendarValue.push(new Date());
        }
    }

    collapsedChanged(event: boolean) {
        this.dateFacetCollapsedChanged.emit(event);
    }

    modelChange(event: any) {
        if (this.applyCalendarSelectionInstantly) {
            this.setDateRange(event, DateRangeOrigin.Calendar);
        }
    }
}
