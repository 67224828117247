import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './state';
import { CoreModule } from './core/core.module';
import { SearchModule } from './search/search.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { UiLibraryModule } from './ui-library/ui-library.module';
import { AuthConfigModule } from './auth/auth-config.module';
import { ApiModule as SearchApiModule, Configuration as SearchApiConfiguration } from '@faro/searchapi-angular-client';
import { DetailsModule } from './details/details.module';
import { PlayerModule } from './player/player.module';
import {
    ApiModule as MetaDataApiModule,
    Configuration as MetadataApiConfiguration,
} from '@faro/metadata-angular-client';
import { ApiModule as OrderApiModule, Configuration as OrderApiConfiguration } from '@faro/order-angular-client';
import { ApiModule as ProfileModule, Configuration as ProfileApiConfiguration } from '@faro/profile-angular-client';
import { OrderModule } from './order/order.module';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { RestrictedContentDialogComponent } from './shared/dialogs/restricted-content-dialog/restricted-content-dialog.component';
import { OrderLimitDialogComponent } from './shared/dialogs/order-limit-dialog/order-limit-dialog.component';
import { OrderRemarkDialogComponent } from './shared/dialogs/order-remark-dialog/order-remark-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { UserEffects } from './state/user.effects';

function apiConfigFactory(): SearchApiConfiguration {
    return new SearchApiConfiguration({ basePath: environment.searchApiBaseUrl });
}

function apiMetaDataConfigFactory(): MetadataApiConfiguration {
    return new MetadataApiConfiguration({ basePath: environment.metadataApiBaseUrl });
}

function apiOrderConfigFactory(): OrderApiConfiguration {
    return new OrderApiConfiguration({ basePath: environment.orderApiBaseUrl });
}

function apiProfileConfigFactory(): ProfileApiConfiguration {
    return new ProfileApiConfiguration({ basePath: environment.profileApiBaseUrl });
}

@NgModule({
    declarations: [
        AppComponent,
        RestrictedContentDialogComponent,
        OrderRemarkDialogComponent,
        OrderLimitDialogComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        EffectsModule.forRoot([UserEffects]),
        SearchApiModule.forRoot(apiConfigFactory),
        MetaDataApiModule.forRoot(apiMetaDataConfigFactory),
        OrderApiModule.forRoot(apiOrderConfigFactory),
        ProfileModule.forRoot(apiProfileConfigFactory),
        CoreModule,
        SearchModule,
        DetailsModule,
        PlayerModule,
        OrderModule,
        FormsModule,
        ReactiveFormsModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production , connectInZone: true}),
        UiLibraryModule,
        AuthConfigModule,
        DialogModule,
    ],
    providers: [DynamicDialogRef],
    bootstrap: [AppComponent],
})
export class AppModule {}
