import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { FaroFacetSearchResponse, FaroSearchResponse } from '@faro/searchapi-angular-client';

export enum SearchResultActions {
    DISPATCH_SEARCH = '[Search Request] Dispatch Search',
    DISPATCH_EMPTY_SEARCH = '[Search Request] Dispatch an empty Search',
    DISPATCH_EMPTY_TYPE_CHANGE_SEARCH = '[Search Request] Dispatch an empty type change Search',
    DISPATCH_SEARCH_NEXT = '[Search Request] Dispatch Search without reloading',
    CANCEL_SEARCH = '[Search Response] Search Canceled',
    SET_SEARCH_RESPONSE = '[Search Response] Search Succeeded',
    SET_EMPTY_SEARCH_RESPONSE = '[Search Response] Search empty search Succeeded',
    NOTIFY_OF_SEARCH_ERROR = '[Search Response] Search Failed',
    SET_FACET_SEARCH_RESPONSE = '[Search Response] Facet Search Succeeded',
    NOTIFY_OF_FACET_SEARCH_ERROR = '[Search Response] Facet Search Failed',
    SET_SCROLL_POSITION = '[Search Response] Sets scroll position of hitlist',
    SET_FACETS_ONLY = '[Search Response] Set facets only',
}

export const dispatchSearch = createAction(SearchResultActions.DISPATCH_SEARCH);

export const dispatchEmptySearch = createAction(SearchResultActions.DISPATCH_EMPTY_SEARCH);

export const dispatchEmptySearchAfterTypeChange = createAction(SearchResultActions.DISPATCH_EMPTY_TYPE_CHANGE_SEARCH);

export const dispatchSearchNextPage = createAction(SearchResultActions.DISPATCH_SEARCH_NEXT);

export const cancelSearch = createAction(SearchResultActions.CANCEL_SEARCH);

export const setScrollTopPosition = createAction(
    SearchResultActions.SET_SCROLL_POSITION,
    props<{ scrollTopPosition: number }>()
);

export const setSearchResponse = createAction(
    SearchResultActions.SET_SEARCH_RESPONSE,
    props<{ searchResponse: FaroSearchResponse }>()
);

export const setEmptySearchResponse = createAction(
    SearchResultActions.SET_EMPTY_SEARCH_RESPONSE,
    props<{ searchResponse: FaroSearchResponse }>()
);

export const setFacetsOnly = createAction(
    SearchResultActions.SET_FACETS_ONLY,
    props<{ searchResponse: FaroSearchResponse }>()
);

export const notifyOfSearchError = createAction(
    SearchResultActions.NOTIFY_OF_SEARCH_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const setFacetSearchResponse = createAction(
    SearchResultActions.SET_FACET_SEARCH_RESPONSE,
    props<{ facetSearchResponse: FaroFacetSearchResponse }>()
);

export const notifyOfFacetSearchError = createAction(
    SearchResultActions.NOTIFY_OF_FACET_SEARCH_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);
