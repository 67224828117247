import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Duration, fromMilliseconds } from '../../shared/duration';
import { relativeLengthInTimeRange, relativePositionInTimeRange } from '../../shared/time-range.helpers';

@Component({
    selector: 'app-sections-bar',
    templateUrl: './sections-bar.component.html',
    styleUrls: ['./sections-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionsBarComponent implements OnChanges {
    @Input()
    sections: { vtcIn: Duration; vtcOut: Duration }[] = [];

    @Input()
    vtcIn: Duration = fromMilliseconds(0);

    @Input()
    vtcOut: Duration = fromMilliseconds(0);

    @Input()
    displayString: string = '';

    @Output()
    sectionClicked = new EventEmitter<{ vtcIn: Duration }>();

    sectionEntries: { relativePosLeft: number; relativeWidth: number; vtcIn: Duration }[] = [];

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.sections && this.sections.length > 0) {
            this.sectionEntries = this.sections.map((v: { vtcIn: Duration; vtcOut: Duration }) => {
                const entryVtcIn = v.vtcIn;
                const entryVtcOut = v.vtcOut;
                return {
                    relativePosLeft: relativePositionInTimeRange(entryVtcIn, this.vtcIn, this.vtcOut),
                    relativeWidth: relativeLengthInTimeRange(entryVtcIn, entryVtcOut, this.vtcIn, this.vtcOut),
                    vtcIn: entryVtcIn,
                };
            });
        }
    }

    jumpToSection(vtcIn: Duration) {
        this.sectionClicked.emit({ vtcIn });
    }
}
