<p-overlayPanel
    #calendarPanel
    [dismissable]="true"
    [showCloseIcon]="showClose"
    (onShow)="showCalendar()"
    (onHide)="calendarHidden = true"
    [focusOnShow]="true"
    styleClass="date-facet-overlay-panel"
>
    <ng-template pTemplate>
        <p-calendar
            [(ngModel)]="calendarValue"
            [inline]="true"
            selectionMode="range"
            (ngModelChange)="modelChange($event)"
        >
        </p-calendar>
    </ng-template>
</p-overlayPanel>

<div #dateFacet>
    <faro-facet-panel
        [header]="header"
        [countLabel]="dateRangeInput ? 1 : 0"
        [(collapsed)]="collapsed"
        headerIcon="pi pi-calendar"
        [deleteIcon]="showDeleteIcon ? 'pi pi-trash' : ''"
        (deleteIconClick)="clearDateRange()"
        (headerIconClick)="toggleCalendar($event, dateFacet)"
        [headerIconHighLighted]="(dateRangeIsSet$ | async) || false"
        (collapsedChange)="collapsedChanged($event)"
        [headerIconAriaLabel]="'Kalender öffnen'"
    >
        <div class="range-field-container">
            <div
                class="range-field"
                [class.invalid]="dateRangeInputInvalid"
                [class.pending]="!dateRangeInputInvalid && dateRangeInputPending"
            >
                <input
                    #dateFacetInput
                    class="range"
                    oninput="this.value = this.value.replace(/[^0-9.-\s]/g, '')"
                    [(ngModel)]="dateRangeInput"
                    (focus)="onFocus()"
                    (keyup)="onKeyUp($event)"
                    (blur)="startDateValidation()"
                    [placeholder]="placeholderText"
                    data-cy="data-range-input"
                />
                <button *ngIf="dateRangeInput" (click)="clearDateRange()">
                    <i class="pi pi-times" aria-label="Datumseingabe löschen"></i>
                </button>
            </div>
        </div>
        <div class="presets-container">
            <ng-container *ngFor="let preset of presets">
                <div class="preset">
                    <button pRipple (click)="applyPreset(preset)">{{ preset.label }}</button>
                </div>
            </ng-container>
        </div>
    </faro-facet-panel>
</div>
