<div class="input-field" #inputField>
    <input
        name="displayDate"
        [(ngModel)]="displayDate"
        pInputText
        [placeholder]="placeholder"
        (ngModelChange)="onChange($event)"
        oninput="this.value = this.value.replace(/[^0-9.-\s]/g, '')"
    />
    <ng-container *ngIf="!displayDate">
        <button (click)="toggleCalendar($event, inputField)" aria-label="Kalender öffnen">
            <img src="assets/icons/input-calendar.svg" />
        </button>
    </ng-container>
    <ng-container *ngIf="displayDate">
        <button (click)="clearDate()">
            <i class="pi pi-times" aria-label="Datumseingabe löschen"></i>
        </button>
    </ng-container>
</div>
<p-overlayPanel #singleDateCalendarPanel [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
        <p-calendar
            dateFormat="dd.mm.yyyy"
            [inline]="true"
            (onSelect)="onDateSelected($event)"
            [maxDate]="maxDate"
            [minDate]="minDate"
        >
        </p-calendar>
    </ng-template>
</p-overlayPanel>
