/**
 * Parses a .NET TimeSpan of the format [hh:mm:]ss[.fff] into a duration object.
 * @example parseTimeSpan('01:02:03') === dayjs.duration({hours: 1, minutes: 2, seconds: 3})
 * @example parseTimeSpan('01:02:03.240') === dayjs.duration({hours: 1, minutes: 2, seconds: 3, milliseconds: 240})
 * @example parseTimeSpan('3.240') === dayjs.duration({seconds: 3, milliseconds: 240})
 * @example parseTimeSpan('301.240') === dayjs.duration({seconds: 301, milliseconds: 240})
 * @example parseTimeSpan('301') === dayjs.duration({seconds: 301})
 */
import { fromObject, Duration, fromMilliseconds } from './duration';

export function parseTimeSpan(s: string): Duration {
    if (isTimeSpan(s)) {
        return parseDotNetTimeSpan(s);
    } else {
        return parseTimeSpanString(s);
    }
}

export function formatTimeSpan(s: Duration | undefined): string {
    return s ? s.format('HH:mm:ss.SSS') : '';
}

function isTimeSpan(obj: any): any {
    return obj && typeof obj.ticks === 'number';
}

function parseDotNetTimeSpan(s: any): Duration {
    throw new Error('Let this crash so that we can test it once available...');

    // There are 10,000 ticks in a millisecond, see https://docs.microsoft.com/en-us/dotnet/api/system.timespan.ticks?view=net-6.0
    const ms = (s.ticks || 0) / 10000;
    return fromMilliseconds(ms);
}

function parseTimeSpanString(s: string) {
    const timeSpanRegex = /^(?:(?<hh>\d\d):(?<mm>\d\d):)?(?<ss>\d+)(?:\.(?<ms>\d\d\d)\d*)?$/;
    const result = timeSpanRegex.exec(s);

    if (result === null || result.groups === null) throw new Error(`Got invalid timespan value ${s}`);

    const hh = result.groups!['hh'];
    const mm = result.groups!['mm'];
    const ss = result.groups!['ss'];
    const ms = result.groups!['ms'];

    return fromObject({
        hours: hh ? parseInt(hh) : 0,
        minutes: mm ? parseInt(mm) : 0,
        seconds: ss ? parseInt(ss) : 0,
        milliseconds: ms ? parseInt(ms) : 0,
    });
}
