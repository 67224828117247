<div *ngIf="trafficLights" class="container">
    <ng-container *ngFor="let trafficLight of trafficLights">
        <div
            class="traffic-light"
            [pTooltip]="trafficLight.label + ':\n' + trafficLight.description"
            tooltipPosition="top"
            [ngClass]="getColorForTrafficLight(trafficLight)"
            [attr.role]="'meter'"
            [attr.aria-label]="trafficLight.label"
            [attr.aria-valuetext]="trafficLight.description"
        ></div>
    </ng-container>
</div>
