import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, distinctUntilChanged, map, Observable, Subscription } from 'rxjs';
import { NullableDate } from '../../shared/nullable-date.interfaces';
import { PrimeNGConfig } from 'primeng/api';
import { PRIMENG_TRANSLATIONS_GERMAN } from '../../shared/primeng-de.translations';
import {
    CalendarValue,
    DateOutputComponentModel,
    DateRangeComponentModel,
    DateRangeInput,
    DateRangeOrigin,
} from './date-range.interface';

@Component({
    selector: 'faro-date-range-input',
    templateUrl: './date-range-input.component.html',
    styleUrls: ['./date-range-input.component.scss'],
})
export class DateRangeInputComponent implements OnInit, OnDestroy {
    @Input()
    placeholderText: string = '';

    @Input()
    ariaLabel: string = '';

    @Input()
    set dateRange(range: DateRangeComponentModel | undefined | null) {
        this.setDateRange([range?.from ?? null, range?.to ?? null], DateRangeOrigin.External);
    }

    @Input()
    showClose: boolean = false;

    @Output()
    changed = new EventEmitter<DateOutputComponentModel>();

    calendarHidden = true;
    dateRangeIsSet$: Observable<boolean>;
    dateRangeInputInvalid = false;
    dateRangeInputPending = false;
    maximumDate = new Date();

    @ViewChild('calendarPanel')
    private _calendarPanel?: OverlayPanel;

    private _dateRangeChanges$ = new BehaviorSubject<DateRangeInput>({
        range: { from: null, to: null },
        origin: DateRangeOrigin.Calendar,
    });

    private _subscription: Subscription;

    private _calendarValue: CalendarValue = [];

    get calendarValue(): CalendarValue {
        return this._calendarValue;
    }

    set calendarValue(dates: CalendarValue) {
        if (dates.filter(d => d !== null).length === 2) {
            this.setDateRange(dates, DateRangeOrigin.Calendar);
            this._calendarPanel?.hide();
        }
    }

    private dateExpression = /^(?<d>(0?[1-9]|[12]\d|3[01]))\.(?<m>(0?[1-9]|1[012]))\.(?<y>(\d{4}))$/;

    private _dateRangeInput = '';
    private _manualRange: DateRangeComponentModel | null = null;

    get dateRangeInput(): string {
        return this._dateRangeInput;
    }

    set dateRangeInput(v: string) {
        this._dateRangeInput = v;
        this.dateRangeInputPending = true;
        const trimmedInput = v.trim();
        if (!trimmedInput) {
            this._manualRange = { from: null, to: null };
            this.dateRangeInputInvalid = false;
        } else {
            this._manualRange = this.parseRange(trimmedInput);
            this.dateRangeInputInvalid = this._manualRange === null;
        }
    }

    constructor(private readonly primeConfig: PrimeNGConfig) {
        this._subscription = this._dateRangeChanges$.subscribe(ri => {
            this._calendarValue = [];
            if (ri.range.from) {
                this._calendarValue.push(ri.range.from);
            }
            if (ri.range.to && !areEqual(ri.range.from, ri.range.to)) {
                this._calendarValue.push(ri.range.to);
            }

            if (ri.origin !== DateRangeOrigin.Manual) {
                this._dateRangeInput = this.formatDateRange(ri.range);
            }
        });

        this.dateRangeIsSet$ = this._dateRangeChanges$.pipe(
            map(ri => ri.range),
            map(r => r.from !== null || r.to !== null)
        );

        const sub = this._dateRangeChanges$
            .pipe(
                distinctUntilChanged(
                    (prev, curr) => areEqual(prev.range.from, curr.range.from) && areEqual(prev.range.to, curr.range.to)
                )
            )
            .subscribe((r: DateRangeInput): void => {
                this.changed.emit({
                    range: r.range.from === null && r.range.to === null ? null : r.range,
                    changeThroughUserAction: r.origin !== DateRangeOrigin.External,
                });
            });

        this._subscription.add(sub);
    }

    ngOnInit(): void {
        this.primeConfig.setTranslation(PRIMENG_TRANSLATIONS_GERMAN);
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private formatDateRange(range: DateRangeComponentModel): string {
        if (range?.from && range?.to && areEqual(range.from, range.to)) {
            return range.from.toLocaleDateString('de-CH');
        }

        if (range?.from && range?.to && !areEqual(range.from, range.to)) {
            return range.from.toLocaleDateString('de-CH') + ' - ' + range.to.toLocaleDateString('de-CH');
        }

        if (range?.from && !range?.to) {
            return range.from.toLocaleDateString('de-CH') + ' -';
        }
        if (!range?.from && range?.to) {
            return '- ' + range.to.toLocaleDateString('de-CH');
        }

        return '';
    }

    private parseRange(string: string): DateRangeComponentModel | null {
        const parts = string.split('-').map(s => s.trim());

        if (parts.length === 1) {
            const date = this.parseDate(parts[0]);
            if (date instanceof Date) {
                return { from: date, to: date };
            }
        } else if (parts.length === 2) {
            const from = this.parseDate(parts[0]);
            if (from === 'invalid') {
                return null;
            }
            const to = this.parseDate(parts[1]);
            if (to === 'invalid') {
                return null;
            }

            if (from || to) {
                return { from: from, to: to };
            }
        }

        return null;
    }

    private parseDate(s: string): NullableDate | 'invalid' {
        if (s.length === 0) {
            return null;
        }

        const matches = this.dateExpression.exec(s);
        if (matches !== null) {
            const day = matches.groups?.['d'];
            const month = matches.groups?.['m'];
            const year = matches.groups?.['y'];
            if (day && month && year) {
                const dayOfMonth = parseInt(day);
                const date = new Date(parseInt(year), parseInt(month) - 1, dayOfMonth);

                // to prevent that '31.04.yyyy' results in 'May 1 ..'
                if (dayOfMonth === date.getDate()) {
                    return date;
                }
            }
        }

        return 'invalid';
    }

    private setDateRange(dates: NullableDate[], origin: DateRangeOrigin): void {
        if (dates.length === 0) {
            this._dateRangeChanges$.next({ range: { from: null, to: null }, origin: origin });
        } else if (dates.length === 1) {
            this._dateRangeChanges$.next({ range: { from: dates[0], to: dates[0] }, origin: origin });
        } else {
            this._dateRangeChanges$.next({ range: { from: dates[0], to: dates[1] }, origin: origin });
        }
        this.dateRangeInputInvalid = false;
        this.dateRangeInputPending = false;
    }

    toggleCalendar(event: Event, target: HTMLElement): void {
        if (this._calendarPanel) {
            this._calendarPanel.toggle(event, target);
        }
    }

    clearDateRange(): void {
        this._dateRangeChanges$.next({ range: { from: null, to: null }, origin: DateRangeOrigin.Calendar });
        this.dateRangeInputInvalid = false;
        this.dateRangeInputPending = false;
    }

    onFocus(): void {
        if (this._calendarPanel) {
            this._calendarPanel.hide();
        }
    }

    onKeyUp(): void {
        if (this._manualRange !== null) {
            this._dateRangeChanges$.next(<DateRangeInput>{
                range: this._manualRange,
                origin: DateRangeOrigin.Manual,
            });
            this.dateRangeInputPending = false;
        }
    }

    showCalendar() {
        if (this._dateRangeChanges$.value.range.from === null && this._dateRangeChanges$.value.range.to === null) {
            this._calendarValue.push(new Date());
        }
    }
}

function areEqual(a: NullableDate, b: NullableDate): boolean {
    return a?.getTime() === b?.getTime();
}
