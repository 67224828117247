import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
    AudioTrack,
    EnvelopeDto,
    MediaCutDto,
    MusicMetadataDto,
    NavigationMetadataDto,
} from '@faro/metadata-angular-client';
import { Duration } from '../../shared/duration';
import {
    TypedItemInformationModel,
    TypedLightTableKeyFrameDto,
    TypedProgramInformationModel,
    TypedSequenceMetadataDto,
    TypedSubtitleTranscriptMetadataDto,
} from '../shared/typed_metadata.model';
import { MediaCutInfo } from '../../player/shared/media-cut-info';

export enum DetailsActions {
    SET_DETAIL_ITEM_NAV_INFORMATION = '[Selection Details] Set detail item navigation information',
    GET_DETAIL_ITEM_NAV_INFORMATION = '[Selection Details] Get detail item navigation information',
    GET_DETAIL_ITEM_SEQUENCE = '[Select Details] Get display item information for sequence',
    GET_DETAIL_ITEM_INFORMATION = '[Select Details] Get display item information for information',
    GET_DETAIL_ITEM_SUBTITLE_TRANSCRIPT = '[Select Details] Get display item information for subtitle transcript',
    SET_DETAIL_ITEM_SEQUENCE = '[Select Details] Set display item information for sequence',
    SET_DETAIL_ITEM_INFORMATION = '[Select Details] Set display item information for information',
    SET_DETAIL_ITEM_SUBTITLE_TRANSCRIPT = '[Select Details] Set display item information for subtitle transcript',
    GET_DETAIL_ITEM_MUSIC_INFORMATION = '[Select Details] Get display item information for music dialog',
    SET_DETAIL_ITEM_MUSIC_INFORMATION = '[Select Details] Set display item information for music dialog',
    GET_DETAIL_LIGHT_TABLE = '[Select Details] Get light table',
    SET_DETAIL_PROGRAM_NAV_INFORMATION = '[Selection Details] Set detail program navigation information',
    GET_DETAIL_PROGRAM_NAV_INFORMATION = '[Selection Details] Get detail program navigation information',
    GET_DETAIL_PROGRAM_SEQUENCE = '[Select Details] Get display program information for sequence',
    GET_DETAIL_PROGRAM_INFORMATION = '[Select Details] Get display program information for information',
    GET_DETAIL_PROGRAM_SUBTITLE_TRANSCRIPT = '[Select Details] Get display program information for subtitle transcript',
    SET_DETAIL_PROGRAM_SEQUENCE = '[Select Details] Set display program information for sequence',
    SET_DETAIL_PROGRAM_INFORMATION = '[Select Details] Set display program information for information',
    SET_DETAIL_PROGRAM_SUBTITLE_TRANSCRIPT = '[Select Details] Set display program information for subtitle transcript',
    GET_DETAIL_PROGRAM_MUSIC_INFORMATION = '[Select Details] Get display program information for music dialog',
    SET_DETAIL_PROGRAM_MUSIC_INFORMATION = '[Select Details] Set display program information for music dialog',
    SET_DETAIL_ORDER_VTC_IN = '[Select Details] Set vtc in',
    SET_DETAIL_ORDER_SHOPPING_CART_VTC_IN_VTC_OUT = '[Select Details] Set vtc in and vtc out from shopping cart tc selection',
    GET_DETAIL_MEDIA_CUTS = '[Select Details] Get media cuts (from metadata service)',
    SET_DETAIL_MEDIA_CUTS = '[Select Details] Set media cuts (from metadata service)',
    SET_DETAIL_ORDER_VTC_OUT = '[Select Details] Set vtc out',
    GET_ENVELOPE_DATA = '[Select Details] Get envelope data',
    SET_ENVELOPE_DATA = '[Select Details] Set envelope data for envelope chart',
    SET_LIGHT_TABLE_DATA = '[Select Details] Set light table data',
    NOTIFY_OF_DETAIL_ERROR = '[Selection Details] Throws error that details call did not work',
    NOTIFY_OF_DOWNLOAD_ERROR = '[Selection Details] Throws error that file could not be downloaded',
    NOTIFY_OF_ENVELOPE_ERROR = '[Select Details] Notify of error in envelope request',
    SET_MEDIA_SELECTION = '[Select Details] Set media selection',
    SET_MEDIA_CUT = '[Select Details] Set media cut',
    SET_AV_COMPONENT = '[Select Details] Set AV component',
    UPDATE_HOVERED_TIME_FRAME = '[Select Details] update hovered vtcIn and vtcOut',
    CLEAR_HOVERED_TIME_FRAME = '[Select Details] clear hovered vtcIn and vtcOut',
    SET_PREVIOUS_PLAYER_STATE = '[Select Details] set previous player state',
}

export const setMediaSelection = createAction(
    DetailsActions.SET_MEDIA_SELECTION,
    props<{ mediaCut?: string; audioTrack?: AudioTrack }>()
);

export const setPlayerMediaCut = createAction(
    DetailsActions.SET_MEDIA_CUT,
    props<{ mediaCut: MediaCutInfo | undefined }>()
);

export const setDetailAvComponent = createAction(
    DetailsActions.SET_AV_COMPONENT,
    props<{ program: string; item: string | undefined; contentType: 'program' | 'item' }>()
);

export const getDetailItemNavInformation = createAction(
    DetailsActions.GET_DETAIL_ITEM_NAV_INFORMATION,
    props<{ programId: string; itemId: string }>()
);

export const setDetailItemNavInformation = createAction(
    DetailsActions.SET_DETAIL_ITEM_NAV_INFORMATION,
    props<{ detailNavInformation: NavigationMetadataDto }>()
);

export const getDetailProgramNavInformation = createAction(
    DetailsActions.GET_DETAIL_PROGRAM_NAV_INFORMATION,
    props<{ programId: string }>()
);

export const setDetailProgramNavInformation = createAction(
    DetailsActions.SET_DETAIL_PROGRAM_NAV_INFORMATION,
    props<{ detailNavInformation: NavigationMetadataDto }>()
);

export const getDetailItemSequence = createAction(
    DetailsActions.GET_DETAIL_ITEM_SEQUENCE,
    props<{ programId: string; itemId: string }>()
);

export const setDetailItemSequence = createAction(
    DetailsActions.SET_DETAIL_ITEM_SEQUENCE,
    props<{ contentSequence: TypedSequenceMetadataDto[] }>()
);

export const getDetailItemInformation = createAction(
    DetailsActions.GET_DETAIL_ITEM_INFORMATION,
    props<{ programId: string; itemId: string }>()
);

export const setDetailItemInformation = createAction(
    DetailsActions.SET_DETAIL_ITEM_INFORMATION,
    props<{ contentInformation: TypedItemInformationModel }>()
);

export const getDetailItemSubtitleTranscript = createAction(
    DetailsActions.GET_DETAIL_ITEM_SUBTITLE_TRANSCRIPT,
    props<{ programId: string; itemId: string }>()
);

export const setDetailItemSubtitleTranscript = createAction(
    DetailsActions.SET_DETAIL_ITEM_SUBTITLE_TRANSCRIPT,
    props<{ contentSubtitleTranscript: TypedSubtitleTranscriptMetadataDto[] }>()
);

export const getDetailItemMusicInformation = createAction(
    DetailsActions.GET_DETAIL_ITEM_MUSIC_INFORMATION,
    props<{ programId: string; itemId: string }>()
);

export const setDetailItemMusicInformation = createAction(
    DetailsActions.SET_DETAIL_ITEM_MUSIC_INFORMATION,
    props<{ musicInformation: MusicMetadataDto[] }>()
);

export const getDetailProgramSequence = createAction(
    DetailsActions.GET_DETAIL_PROGRAM_SEQUENCE,
    props<{ programId: string }>()
);

export const setDetailProgramSequence = createAction(
    DetailsActions.SET_DETAIL_PROGRAM_SEQUENCE,
    props<{ contentSequence: TypedSequenceMetadataDto[] }>()
);

export const getDetailProgramInformation = createAction(
    DetailsActions.GET_DETAIL_PROGRAM_INFORMATION,
    props<{ programId: string }>()
);

export const setDetailProgramInformation = createAction(
    DetailsActions.SET_DETAIL_PROGRAM_INFORMATION,
    props<{ contentInformation: TypedProgramInformationModel }>()
);

export const getDetailProgramSubtitleTranscript = createAction(
    DetailsActions.GET_DETAIL_PROGRAM_SUBTITLE_TRANSCRIPT,
    props<{ programId: string }>()
);

export const setDetailProgramSubtitleTranscript = createAction(
    DetailsActions.SET_DETAIL_PROGRAM_SUBTITLE_TRANSCRIPT,
    props<{ contentSubtitleTranscript: TypedSubtitleTranscriptMetadataDto[] }>()
);

export const getDetailProgramMusicInformation = createAction(
    DetailsActions.GET_DETAIL_PROGRAM_MUSIC_INFORMATION,
    props<{ programId: string }>()
);

export const setDetailProgramMusicInformation = createAction(
    DetailsActions.SET_DETAIL_PROGRAM_MUSIC_INFORMATION,
    props<{ musicInformation: MusicMetadataDto[] }>()
);

export const setDetailOrderVtcIn = createAction(DetailsActions.SET_DETAIL_ORDER_VTC_IN, props<{ vtcIn: Duration }>());

export const setDetailOrderVtcOut = createAction(
    DetailsActions.SET_DETAIL_ORDER_VTC_OUT,
    props<{ vtcOut: Duration }>()
);

export const setDetailShoppingCartVtcInVtcOut = createAction(
    DetailsActions.SET_DETAIL_ORDER_SHOPPING_CART_VTC_IN_VTC_OUT,
    props<{ vtcIn: Duration; vtcOut: Duration }>()
);

export const getDetailMediaCuts = createAction(
    DetailsActions.GET_DETAIL_MEDIA_CUTS,
    props<{ programId: string; itemId?: string }>()
);

export const setDetailMediaCuts = createAction(
    DetailsActions.SET_DETAIL_MEDIA_CUTS,
    props<{ mediaCuts: MediaCutDto[] }>()
);

export const getEnvelopeData = createAction(
    DetailsActions.GET_ENVELOPE_DATA,
    props<{
        mediaCutId: string;
        programId: string;
        itemId?: string;
        vtcFrom: string;
        vtcTo: string;
    }>()
);

export const setEnvelopeData = createAction(DetailsActions.SET_ENVELOPE_DATA, props<{ envelopeData: EnvelopeDto }>());

export const getLightTable = createAction(
    DetailsActions.GET_DETAIL_LIGHT_TABLE,
    props<{
        mediaCutId: string;
        programId: string;
        itemId?: string;
    }>()
);

export const setHoveredTimeFrame = createAction(
    DetailsActions.UPDATE_HOVERED_TIME_FRAME,
    props<{ hoveredVtcIn: Duration; hoveredVtcOut: Duration }>()
);

export const clearHoveredTimeFrame = createAction(DetailsActions.CLEAR_HOVERED_TIME_FRAME);

export const setLightTableData = createAction(
    DetailsActions.SET_LIGHT_TABLE_DATA,
    props<{ lightTableData: TypedLightTableKeyFrameDto[] }>()
);

export const setPreviousPlayerState = createAction(
    DetailsActions.SET_PREVIOUS_PLAYER_STATE,
    props<{ playerState: boolean }>()
);

export const notifyOfDetailError = createAction(
    DetailsActions.NOTIFY_OF_DETAIL_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfDownloadError = createAction(
    DetailsActions.NOTIFY_OF_DOWNLOAD_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfEnvelopeError = createAction(
    DetailsActions.NOTIFY_OF_ENVELOPE_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);
