import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerComponent } from './player/player.component';
import { UiLibraryModule } from '../ui-library/ui-library.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { EnvelopeModule } from '../envelope/envelope.module';
import { PlayerRestrictionMarkerComponent } from './player-restriction-marker/player-restriction-marker.component';
import { PlayerCutMarkerComponent } from './player-cut-marker/player-cut-marker.component';
@NgModule({
    declarations: [PlayerComponent, PlayerRestrictionMarkerComponent, PlayerCutMarkerComponent],
    imports: [CommonModule, UiLibraryModule, FormsModule, SharedModule, EnvelopeModule, LetDirective, PushPipe],
    exports: [PlayerComponent],
})
export class PlayerModule {}
