import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private securityService: OidcSecurityService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const regex = /^(\/)?(api|hlsproxy)/;

        const allowAddToken = regex.test(req.url);
        if (!allowAddToken) {
            return next.handle(req);
        }

        req = addFaroClientTypeHeader(req);

        return this.securityService.getAccessToken().pipe(
            switchMap(token => {
                if (token) {
                    req = addAuthorizationHeader(req, token);
                }
                return next.handle(req);
            })
        );
    }
}

function addFaroClientTypeHeader(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
        headers: req.headers.set('X-faro-client-type', 'faroweb'),
    });
}

function addAuthorizationHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
}

function hostname(url: string): string | undefined {
    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch {
        return undefined;
    }
}
