import { Component, Input } from '@angular/core';

@Component({
    selector: 'faro-dialog-panel',
    templateUrl: './dialog-panel.component.html',
    styleUrls: ['./dialog-panel.component.scss'],
})
export class DialogPanelComponent {
    constructor() {}

    @Input()
    header?: string;
}
