import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailMainComponent } from './detail-main/detail-main.component';
import { DetailContentComponent } from './detail-content/detail-content.component';
import { DetailsRoutingModule } from './details-routing.module';
import { UiLibraryModule } from '../ui-library/ui-library.module';
import { LightTableViewComponent } from './light-table-view/light-table-view.component';
import { SequenceContentComponent } from './tabs/sequence-content/sequence-content.component';
import { SubtitleTranscriptContentComponent } from './tabs/subtitle-transcript-content/subtitle-transcript-content.component';
import { InformationContentComponent } from './tabs/information-content/information-content.component';
import { SharedModule } from '../shared/shared.module';
import { DetailHeaderComponent } from './detail-header/detail-header.component';
import { MusicDialogComponent } from './music-dialog/music-dialog.component';
import { RestrictionBarComponent } from './restriction-bar/restriction-bar.component';
import { AuthConfigModule } from '../auth/auth-config.module';
import { PlayerModule } from '../player/player.module';
import { EffectsModule } from '@ngrx/effects';
import { DetailsEffects } from './details-state/details.effects';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ErrorEffects } from './details-state/error.effects';
import { InOutTimeCodeComponent } from './in-out-time-code/in-out-time-code.component';
import { EnvelopeModule } from '../envelope/envelope.module';
import { SectionsBarComponent } from './sections-bar/sections-bar.component';
import { RestrictionDialogComponent } from './tabs/sequence-content/restriction-dialog/restriction-dialog.component';
import { HighlightComponent } from './highlight/highlight.component';

@NgModule({
    declarations: [
        DetailMainComponent,
        DetailContentComponent,
        LightTableViewComponent,
        SequenceContentComponent,
        SubtitleTranscriptContentComponent,
        InformationContentComponent,
        DetailHeaderComponent,
        MusicDialogComponent,
        RestrictionBarComponent,
        InOutTimeCodeComponent,
        SectionsBarComponent,
        RestrictionDialogComponent,
        HighlightComponent,
    ],
    imports: [
        CommonModule,
        DetailsRoutingModule,
        UiLibraryModule,
        SharedModule,
        AuthConfigModule,
        LetDirective, PushPipe,
        PlayerModule,
        EnvelopeModule,
        EffectsModule.forFeature([DetailsEffects, ErrorEffects]),
    ],
    providers: [], //DetailsService, MediaCutService],
    exports: [DetailContentComponent],
})
export class DetailsModule {}
