<div class="container" [class.focused]="focused" (click)="focusAutoComplete()">
    <span class="p-float-label">
        <p-autoComplete
            #autoComplete
            inputId="autocomplete"
            [(ngModel)]="value"
            [suggestions]="suggestions"
            (completeMethod)="updateSuggestions($event)"
            (onFocus)="focused = true"
            (onBlur)="focused = false"
        >
            <ng-template let-suggestion pTemplate="item">
                <div [innerHTML]="emphasizeQuery(suggestion) | unsanitizedHtml"></div>
            </ng-template>
        </p-autoComplete>
        <label for="autocomplete">{{ label }}</label>
    </span>
</div>
