import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'unsanitizedHtml',
})
export class UnsanitizedHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value.replace(/\r\n/g, '<br>'));
    }
}
