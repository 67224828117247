import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectSearchFieldSelection } from '../../search-state/search-options.selectors';
import { SearchFieldSelectionOption } from '../../search-state/search-options.state';
import { SearchInfoDialogComponent } from '../../search-input/info-dialog/search-info-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-no-search',
    templateUrl: './no-search.component.html',
    styleUrls: ['./no-search.component.scss'],
})
export class NoSearchComponent {
    selectedSearchValue$: Observable<SearchFieldSelectionOption>;
    constructor(private store: Store, private dialogService: DialogService) {
        this.selectedSearchValue$ = this.store.select(selectSearchFieldSelection);
    }

    openInfoDialog(): void {
        this.dialogService.open(SearchInfoDialogComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'info-dialog',
            data: { title: 'Suchinformationen', deactivatable: false },
        });
    }

    getTextToDisplay(type: string) {
        switch (type) {
            case 'item':
                return {
                    title: 'Für thematische Suchen',
                    description: 'z.B. alles zum Thema «Kosten im Gesundheitswesen»',
                    example:
                        'Eingabe: «Kosten Gesundheitswesen» im Suchfeld und «Gesundheitswesen» im Filter «Schlagwörter»',
                };
            case 'sequence':
                return {
                    title: 'Suche nach Videosequenzen',
                    description: 'z.B. eine Nahaufnahme einer Hand',
                    example: 'Eingabe: «Hand» im Suchfeld und «Nahaufnahme (GPL)» im Filter «Kameraeinstellungen»',
                };
            case 'closedcaption':
                return {
                    title: 'Suche nach Gesprochenem',
                    description: 'z.B. das Zitat von Bundesrat Ogi «Freude herrscht»',
                    example: 'Eingabe: «Freude herrscht» (mit Anführungszeichen) im Suchfeld',
                };
            default:
                return {
                    title: '',
                    description: '',
                    example: '',
                };
        }
    }
}
