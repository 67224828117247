import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private readonly messageService: MessageService) {}

    notifyOfSearchError(): void {
        const summary = 'Fehler: Suche';
        const detail = 'Bei der Suche ist ein Fehler aufgetreten, es konnte keine Trefferliste geladen werden.';
        this.showToast(summary, detail, 'error');
    }

    notifyOfFacetSearchError(): void {
        const summary = 'Fehler: Facettensuche';
        const detail =
            'Bei der Facettensuche ist ein Fehler aufgetreten, es konnte keine neuen Einträge geladen werden.';
        this.showToast(summary, detail, 'error');
    }

    notifyOfProfileSearchError(): void {
        const summary = 'Fehler: Profil';
        const detail = 'Beim Abfragen des Profils ist ein Fehler aufgetreten.';
        this.showToast(summary, detail, 'error');
    }

    notifyOfDetailsError(status: number): void {
        const summary = status === 401 ? 'Keine Berechtigung' : 'Fehler: Details';
        const detail =
            status === 401
                ? 'Sie besitzen keine Berechtigung diesen Beitrag anzuschauen'
                : 'Beim Abfragen der Details ist ein Fehler aufgetreten.';
        this.showToast(summary, detail, 'error');
    }

    notifyOfDownloadError(status: number): void {
        const summary = status === 401 ? 'Keine Berechtigung' : 'Fehler: Download';
        const detail =
            status === 401
                ? 'Die Datei konnte wegen fehlender Berechtigung nicht heruntergeladen werden.'
                : 'Die Datei konnte nicht heruntergeladen werden.';
        this.showToast(summary, detail, 'error');
    }

    notifyOfEnveloperError(): void {
        const summary = 'Fehler: Hüllkurve';
        const detail = 'Beim Abfragen der Hüllkurven Information ist ein Fehler aufgetreten.';
        this.showToast(summary, detail, 'error');
    }

    notifyOfAuthError(): void {
        const summary = 'Fehler: Authentisierung';
        const detail = 'Bei der Authentisierung des Benutzers ist ein Fehler aufgetreten.';
        this.showToast(summary, detail, 'error');
    }

    notifyOfActiveShoppingCartGetInformationError(): void {
        const summary = 'Fehler: Aktiver Warenkorb';
        const detail = 'Der aktive Warenkorb konnte nicht geladen werden.';
        this.showToast(summary, detail, 'error');
    }

    notifyOfSelectedShoppingCartGetInformationError(): void {
        const summary = 'Fehler: Warenkorb';
        const detail = 'Der gesuchte Warenkorb konnte nicht geladen werden.';
        this.showToast(summary, detail, 'error');
    }

    notifyOfOrderShoppingCartError(errorMessage: HttpErrorResponse): void {
        const summary = 'Warenkorb konnte nicht bestellt werden';
        const detail = errorMessage.error.errorMessage;
        this.showToast(summary, detail, 'error');
    }

    notifyOfAddItemSuccessfully(): void {
        const summary = 'Eintrag hinzugefügt';
        const detail = 'Der Eintrag wurde zum Warenkorb hinzugefügt';
        this.showToast(summary, detail, 'success');
    }

    notifyOfItemAlreadyExists(): void {
        const summary = 'Eintrag bereits vorhanden';
        const detail = 'Der Eintrag ist bereits im Warenkorb';
        this.showToast(summary, detail, 'info');
    }

    notifyOfAdmiraDeviceError(): void {
        const summary = 'Exportziel';
        const detail = 'Exportziel konnte nicht geladen werden';
        this.showToast(summary, detail, 'error');
    }

    private showToast(summary: string, detail: string, severity: string): void {
        this.messageService.add({
            ...TOAST_CONFIG,
            severity: severity,
            summary,
            detail,
        });
    }
}

const TOAST_CONFIG = {
    closable: false,
    life: 3000,
};
