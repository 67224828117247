import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { MusicMetadataDto } from '@faro/metadata-angular-client';
import { Store } from '@ngrx/store';
import { selectMusicInformation } from '../details-state/details.selectors';
import { getDetailItemMusicInformation, getDetailProgramMusicInformation } from '../details-state/details.actions';
import { MusicInformation } from '../details-state/details.state';

@Component({
    selector: 'app-music-dialog',
    templateUrl: './music-dialog.component.html',
    styleUrls: ['./music-dialog.component.scss'],
})
export class MusicDialogComponent implements OnInit, OnDestroy {
    tableHeader = [
        { value: 'Komponist:in', scssClass: 'media-header' },
        { value: 'Interpret:in', scssClass: 'country-header' },
        { value: 'Titel', scssClass: 'description-header' },
        { value: 'Dauer', scssClass: 'value-header' },
        { value: 'Label', scssClass: 'usage-header' },
        { value: 'Bemerkungen', scssClass: 'usage-header' },
    ];

    itemMusicRows: MusicMetadataDto[] | undefined = [];
    programMusicRows: MusicMetadataDto[] | undefined = [];
    loading: boolean = true;

    private _destroyed$ = new Subject<void>();

    constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig, private store: Store) {}

    ngOnInit() {
        this.loading = true;

        this.store.dispatch(
            getDetailItemMusicInformation({
                programId: this.config.data.programId,
                itemId: this.config.data.itemId,
            })
        );
        this.store.dispatch(getDetailProgramMusicInformation({ programId: this.config.data.programId }));

        this.store
            .select(selectMusicInformation)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: MusicInformation) => {
                this.itemMusicRows = data.itemMusic;
                this.programMusicRows = data.programMusic;
                this.loading = false;
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    closeDialog() {
        this.ref.close();
    }
}
