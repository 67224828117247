import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full',
    },
    {
        path: 'search',
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
        canLoad: [AutoLoginAllRoutesGuard],
    },
    {
        path: 'details',
        loadChildren: () => import('./details/details.module').then(m => m.DetailsModule),
        canLoad: [AutoLoginAllRoutesGuard],
    },
    {
        path: 'shopping-cart',
        loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
        canLoad: [AutoLoginAllRoutesGuard],
    },
    {
        // Wildcard route which navigates to "search" if none of the above routes match.
        // This has been added since many users have URLs to the old Faro Webclient in their browser
        // caches or bookmarks (e.g. https://medienarchiv.srf.ch/Faroweb/Pages/LoggedIn/Login.aspx).
        path: '**',
        redirectTo: 'search',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
