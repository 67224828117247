import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BreadcrumbModel } from '../../ui-library/breadcrumbs/breadcrumbs.component';
import { Router } from '@angular/router';
import { ShoppingCartDto } from '@faro/order-angular-client/model/shoppingCartDto';
import { PaginationValues } from '../../ui-library/hitlist-paginator/paginator.interface';

@Component({
    selector: 'app-shopping-cart-header',
    templateUrl: './shopping-cart-header.component.html',
    styleUrls: ['./shopping-cart-header.component.scss'],
})
export class ShoppingCartHeaderComponent implements OnChanges {
    @Input()
    showPrint: boolean = true;

    @Input()
    showPaginator: boolean = true;

    @Input()
    cart: ShoppingCartDto | null = null;

    @Input()
    itemId: string = '';

    @Input()
    entryTitle: string = '';

    @Input()
    paginatorIndex: number = 0;

    @Input()
    paginatorLength: number = 0;

    @Output()
    paginationRequested = new EventEmitter<PaginationValues>();

    @Output()
    printReport = new EventEmitter<void>();

    isOnActiveShoppingCart = false;

    paginatorImgSrcAndDescription = {
        first: { icon: '/assets/icons/paginator-fast-back-white.svg', description: 'Erster Warenkorb' },
        prev: { icon: '/assets/icons/paginator-prev-white.svg', description: 'Vorheriger Warenkorb' },
        next: { icon: '/assets/icons/paginator-next-white.svg', description: 'Nächster Warenkorb' },
        last: { icon: '/assets/icons/paginator-fast-forward-white.svg', description: 'Letzer Warenkorb' },
    };

    constructor(private router: Router) {
        this.isOnActiveShoppingCart = this.router.url.includes('active');
        this.resetCrumbs();
    }

    crumbs: Crumb[] = [];
    activeCrumb: Crumb | undefined;

    ngOnChanges(changes: SimpleChanges) {
        this.resetCrumbs();
        this.setActiveCrumb();
    }

    private resetCrumbs(): void {
        this.crumbs = [
            {
                label: this.isOnActiveShoppingCart ? 'Aktiver Warenkorb' : 'Alle Warenkörbe',
                type: CrumbType.SHOPPING_CART,
                id: 'HITLIST',
            },
        ];

        if (this.cart && !this.isOnActiveShoppingCart) {
            this.crumbs.push({
                label: this.cart.name || this.cart.number,
                type: CrumbType.SELECTED_CART,
                id: this.cart.id,
            });
        }

        if (this.entryTitle) {
            this.crumbs.push({
                label: this.entryTitle,
                type: CrumbType.ITEM,
                id: this.itemId,
            });
        }
    }

    print() {
        this.printReport.emit();
    }

    onPageChange(type: PaginationValues) {
        this.paginationRequested.emit(type);
    }

    private setActiveCrumb() {
        const highlightedId = this.itemId ? this.itemId : this.cart?.id;
        this.activeCrumb = this.crumbs.find(c => c.id === highlightedId);
    }

    async navigateToCrumb(c: BreadcrumbModel): Promise<boolean> {
        const crumb = c as Crumb;
        switch (crumb.type) {
            case CrumbType.ITEM:
                return this.router.navigate(['shopping-cart/preview', this.cart?.id, crumb.id]);
            case CrumbType.SELECTED_CART:
                return this.isOnActiveShoppingCart
                    ? this.router.navigate(['shopping-cart/active'])
                    : this.router.navigate(['shopping-cart/preview', crumb.id]);
            default:
                return this.isOnActiveShoppingCart
                    ? this.router.navigate(['shopping-cart/active'])
                    : this.router.navigate(['shopping-cart/search']);
        }
    }

    onSwitchCartViewButtonClicked() {
        if (this.isOnActiveShoppingCart) {
            this.router.navigate(['shopping-cart/search']);
        } else {
            this.router.navigate(['shopping-cart/active']);
        }
    }
}

export interface Crumb extends BreadcrumbModel {
    type: CrumbType;
    id: string;
}

enum CrumbType {
    ITEM = 'item',
    SELECTED_CART = 'selected-cart',
    SHOPPING_CART = 'shopping-cart',
}
