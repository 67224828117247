import { Injectable, OnDestroy } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderRemarkDialogComponent } from '../dialogs/order-remark-dialog/order-remark-dialog.component';
import { Observable, Subject } from 'rxjs';
import { OrderRemarkDialogContentAndConfig } from '../dialogs/order-remark-dialog/order-remark-dialog-content-and.config';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationDialogService implements OnDestroy {
    private _destroyed$ = new Subject<void>();
    constructor(private readonly dialogService: DialogService, private ref: DynamicDialogRef) {}

    openRemarkDialog(contentAndConfig: OrderRemarkDialogContentAndConfig): Observable<any> {
        this.ref = this.dialogService.open(OrderRemarkDialogComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'order-dialog',
            data: { title: 'Vermerk zur Bestellung', contentAndConfig: contentAndConfig },
        });
        return this.ref.onClose;
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
