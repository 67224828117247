import { ValueFilter } from '@faro/searchapi-angular-client';
import { StorageCategory } from '@faro/profile-angular-client';

export interface SearchOptions {
    query: string;
    searchType: SearchFieldSelectionOption;
    itemSearchOptions: ItemSearchOptions;
    excludedMediaTypes: MediaSearchOptions;
    availableStorageCategories: StorageCategory[];
    allExcludedStorageCategories: string[];
    allExcludedStorageCategoriesOutdated: boolean;
    filterOperators: ValueFilterOperator[];
    filters: ValueFilter[];
    profileFilters: ValueFilter[];
    includeSportsFilters: boolean;
    isSearchUpdateAvailable: boolean;
    resetAllFacetSearchResetTriggered: boolean;
    sorting: {
        attribute: HitlistSortingAttribute;
        order: HitlistSortingOrder;
    };
    dateFilter: DateRange;
    loading: {
        storageCategories: boolean;
    };
    displayActivateSearchInfoDialog: boolean;
}

export interface DateRange {
    from: Date | null;
    to: Date | null;
}

export interface ItemSearchOptions extends SearchOptionsBase {
    includingDopeSheets: boolean;
}

export interface MediaSearchOptions {
    excludeAudio: boolean;
    excludeVideo: boolean;
}

export interface SearchOptionsBase {
    highDefinitionOnly: boolean;
    includingRecyclings: boolean;
}

export enum SearchFieldSelectionEnum {
    TOPIC = 'item',
    IMAGE = 'sequence',
    WORD = 'closedcaption',
}

export interface SearchFieldSelectionOption {
    value: SearchFieldSelectionEnum;
}

export const SEARCH_FILTERS: SearchFieldSelectionOption[] = [
    {
        value: SearchFieldSelectionEnum.TOPIC,
    },
    {
        value: SearchFieldSelectionEnum.IMAGE,
    },
    {
        value: SearchFieldSelectionEnum.WORD,
    },
];

export enum HitlistSortingAttribute {
    RELEVANCE,
    DATE,
    PROGRAM,
    DURATION,
}

export enum HitlistSortingOrder {
    DESCENDING,
    ASCENDING,
}

export interface HitlistSortingAttributeOption {
    value: HitlistSortingAttribute;
}

export const HITLIST_SORTING_ATTRIBUTE_OPTIONS: HitlistSortingAttributeOption[] = [
    {
        value: HitlistSortingAttribute.RELEVANCE,
    },
    {
        value: HitlistSortingAttribute.DATE,
    },
    {
        value: HitlistSortingAttribute.PROGRAM,
    },
    {
        value: HitlistSortingAttribute.DURATION,
    },
];

export interface ValueFilterOperator {
    field: string;
    operator: FilterOperator;
}

export enum FilterOperator {
    AND = 'And',
    OR = 'Or',
}
