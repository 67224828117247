<p-table [value]="tableData" responsiveLayout="scroll" selectionMode="single">
    <ng-template pTemplate="header">
        <tr>
            <ng-container *ngFor="let h of tableHeaders">
                <th>{{ h }}</th>
            </ng-container>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-hit let-i="rowIndex">
        <tr
            (mouseenter)="showRowActions(i)"
            (mouseleave)="hideRowActions()"
            [ngStyle]="{ opacity: searching ? '.2' : '1' }"
        >
            <td class="hit-list-image"><img [src]="hit.media" /></td>
            <td class="hit-list-program">{{ hit.program }}</td>
            <td>{{ hit.date }}</td>
            <td (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()" style="position: relative">
                {{ hit.title }}
            </td>
            <td (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()" style="position: relative">
                {{ hit.topic }}
                <span *ngIf="isTooltip && currentIndex === i">
                    <div
                        class="tooltip"
                        style="position: absolute; bottom: 0; left: -50%"
                        [innerHtml]="hit.tooltip"
                    ></div>
                </span>
            </td>
            <td>{{ hit.source }}</td>
            <td>{{ hit.length }}</td>
            <td style="text-align: right; width: 120px">
                <button
                    pButton
                    type="button"
                    icon="pi pi-ellipsis-v"
                    class="p-table-icon-button"
                    *ngIf="currentIndex !== i"
                ></button>
                <button
                    style="margin-right: 10px"
                    pButton
                    type="button"
                    icon="pi pi-info-circle"
                    class="p-table-action-button"
                    *ngIf="currentIndex === i"
                    (click)="goToDetails(hit)"
                ></button>
                <button
                    pButton
                    type="button"
                    icon="pi pi-shopping-cart"
                    class="p-table-action-button"
                    *ngIf="currentIndex === i"
                    (click)="addToShoppingCart(hit)"
                ></button>
            </td>
        </tr>
    </ng-template>
</p-table>
