import { Duration } from '../../shared/duration';

export function timeCodeSelectionFromVtcIn(
    previousSelection: { vtcIn: Duration | undefined; vtcOut: Duration | undefined },
    vtcIn: Duration
): { updatedVtcIn: Duration | undefined; updatedVtcOut: Duration | undefined } {
    if (vtcIn.asMilliseconds() < (previousSelection.vtcOut?.asMilliseconds() ?? Number.MIN_SAFE_INTEGER)) {
        return { updatedVtcIn: vtcIn, updatedVtcOut: previousSelection.vtcOut };
    } else {
        return { updatedVtcIn: vtcIn, updatedVtcOut: undefined };
    }
}

export function timeCodeSelectionFromVtcOut(
    previousSelection: { vtcIn: Duration | undefined; vtcOut: Duration | undefined },
    vtcOut: Duration
): { updatedVtcIn: Duration | undefined; updatedVtcOut: Duration | undefined } {
    if (vtcOut.asMilliseconds() > (previousSelection.vtcIn?.asMilliseconds() ?? Number.MAX_SAFE_INTEGER)) {
        return { updatedVtcIn: previousSelection.vtcIn, updatedVtcOut: vtcOut };
    } else {
        return { updatedVtcIn: undefined, updatedVtcOut: vtcOut };
    }
}
