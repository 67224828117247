import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HlsManifestInfo } from './hls-manifest-info.model';
import { MediaFilter } from './media-filter.model';
import { AudioTrack } from './audiotrack.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HlsService {
    private readonly _apiBasePath: string;
    private readonly _manifestBasePath: string;

    constructor(private http: HttpClient) {
        const basePath = environment.hlsProxyBaseUrl;
        this._apiBasePath = basePath;
        this._manifestBasePath = basePath.startsWith('/')
            ? basePath
            : `/${basePath}`;
    }

    getHlsManifest(args: GetHlsManifestArgs): Observable<HlsManifestInfo> {
        const url = `${this._apiBasePath}/api/hls/gethlsmanifest`;
        return this.http.get<HlsManifestInfo>(url, {
            params: getHlsManifestParams(args),
        });
    }

    getExtendHlsManifest(manifestId: string): Observable<HlsManifestInfo> {
        const url = `${this._apiBasePath}/api/hls/extendHlsManifest`;
        return this.http.get<HlsManifestInfo>(url, {
            params: { manifestId },
        });
    }

    getStillImage(args: GetStillImageArgs): Observable<Blob> {
        const url = `${this._apiBasePath}/api/hls/getStillImage`;
        return this.http.get(url, {
            params: getStillImageParams(args),
            responseType: 'blob' as 'blob',
        });
    }

    getLicenceToken(): Observable<string> {
        const url = `${this._apiBasePath}/api/flow-player-licence-token`;
        return this.http.get(url, {
            responseType: 'text'
        });
    }

    getManifestUrl(relativeManifestUrl: string): string {
        return `${this._manifestBasePath}/${relativeManifestUrl}`;
    }
}

export interface GetHlsManifestArgs {
    mediaCutId: string;
    mediaLevel: MediaFilter;
    audioTrack: AudioTrack;
    vtcIn?: string;
    vtcOut?: string;
    itemId?: string;
    shotId?: string;
    framePos?: number;
}

export interface GetStillImageArgs {
    mediaCutId: string;
    mediaLevel: MediaFilter;
    vtcIn: string;
}

function getStillImageParams(args: GetStillImageArgs): HttpParams {
    return Object.entries(args)
        .filter(([k, v]) => v != undefined)
        .reduce((acc, param) => {
            const paramName = getHlsManifestParamName(param[0] as keyof GetStillImageArgs);
            const paramValue = param[1] as string | number;
            return acc.set(paramName, paramValue);
        }, new HttpParams());
}

function getHlsManifestParams(args: GetHlsManifestArgs): HttpParams {
    return Object.entries(args)
        .filter(([k, v]) => v != undefined)
        .reduce((acc, param) => {
            const paramName = getHlsManifestParamName(param[0] as keyof GetHlsManifestArgs);
            const paramValue = param[1] as string | number;
            return acc.set(paramName, paramValue);
        }, new HttpParams());
}

function getHlsManifestParamName(key: keyof GetHlsManifestArgs | keyof GetStillImageArgs): string {
    switch (key) {
        case 'mediaCutId':
            return 'cutid';
        case 'mediaLevel':
            return 'filter';
        case 'audioTrack':
            return 'at';
        case 'vtcIn':
            return 'vtcin';
        case 'vtcOut':
            return 'vtcout';
        case 'itemId':
            return 'itemid';
        case 'shotId':
            return 'shotid';
        case 'framePos':
            return 'framePos';
    }
}
