import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from './filters/filters.component';
import { ContentComponent } from './content/content.component';
import { HitlistComponent } from './content/hitlist/hitlist.component';
import { FormsModule } from '@angular/forms';
import { FaroUiModule } from '@faro/ngx-faro-ui';
import { UiLibraryModule } from '../ui-library/ui-library.module';
import { TableHitlistComponent } from './content/hitlist/table-hitlist/table-hitlist.component';
import { HitlistControlsComponent } from './content/hitlist/hitlist-controls/hitlist-controls.component';
import { NoSearchComponent } from './content/no-search/no-search.component';
import { NoHitsComponent } from './content/no-hits/no-hits.component';
import { EffectsModule } from '@ngrx/effects';
import { SearchEffects } from './search-state/search.effects';
import { SearchService } from '@faro/searchapi-angular-client';
import { SharedModule } from '../shared/shared.module';
import { ErrorEffects } from './search-state/error.effects';
import { SearchInputComponent } from './search-input/search-input.component';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ProfileFiltersComponent } from './search-input/profile-filters/profile-filters.component';
import { SearchRoutingModule } from './search-routing.module';
import { AuthConfigModule } from '../auth/auth-config.module';
import { MillisecondsToDurationPipe } from '../shared/pipes/milliseconds-to-duration.pipe';
import { SearchInfoDialogComponent } from './search-input/info-dialog/search-info-dialog.component';
import { SearchToggleComponent } from './shared/search-toggle/search-toggle.component';

@NgModule({
    declarations: [
        FiltersComponent,
        ContentComponent,
        HitlistComponent,
        TableHitlistComponent,
        HitlistControlsComponent,
        NoSearchComponent,
        NoHitsComponent,
        SearchInputComponent,
        ProfileFiltersComponent,
        SearchInfoDialogComponent,
        SearchToggleComponent,
    ],
    exports: [FiltersComponent, ContentComponent],
    imports: [
        CommonModule,
        SearchRoutingModule,
        FormsModule,
        EffectsModule.forFeature([SearchEffects, ErrorEffects]),
        FaroUiModule,
        SharedModule,
        AuthConfigModule,
        UiLibraryModule,
        LetDirective, PushPipe,
    ],
    providers: [SearchService, MillisecondsToDurationPipe],
})
export class SearchModule {}
