import {
    asyncScheduler,
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    EMPTY,
    interval,
    merge,
    Observable,
    of,
    scan,
    scheduled,
    Subject,
    switchMap,
    throttleTime,
    catchError,
    combineLatestWith,
    filter,
    map,
    lastValueFrom, takeUntil,
} from 'rxjs';
import { GetHlsManifestArgs, HlsService } from '../backend-api/hls.service';
import { MediaFilter } from '../backend-api/media-filter.model';
import { AudioTrack } from '../backend-api/audiotrack.model';
import { HlsManifestInfo } from '../backend-api/hls-manifest-info.model';
import { MediaTimeMapping } from '../shared/media-time-mapping.model';
import { Duration, fromMilliseconds, fromSeconds } from '../../shared/duration';
import { formatTimeSpan, parseTimeSpan } from '../../shared/timespan';
import { MediaInformation } from './media-information.interface';
import { MediaCutInfo } from '../shared/media-cut-info';

export class HlsBackendFlowPlayer {
    private _playing$ = new BehaviorSubject(false);
    playing$ = this._playing$.asObservable();

    public seeking$!: Observable<boolean>; // initialized in "init"

    private _playedToEnd$ = new Subject();

    /** Emitted when the current media is played to the end */
    public playedToEnd$: Observable<unknown> = this._playedToEnd$.asObservable();

    private _mediaLoaded$ = new Subject<MediaCutInfo>();
    mediaLoaded$ = this._mediaLoaded$.asObservable();

    currentLinearTime$!: Observable<Duration>; // initialized in "init"

    playerState: boolean  = false;

    private _mediaInfo: MediaInformation | null = null;
    private _mediaCutInfo: MediaCutInfo | null = null;
    private _manifestInfo: ManifestInfo = defaultManifestInfo;

    private _playerTime$!: Observable<Duration>; // initialized in "init"
    private _seekSeq = 0;
    private _seekRequest$ = new Subject<SeekRequestType>();
    private _seekCompleted$ = new Subject<number>();

    private _player!: FlowPlayer; // initialized in "init"
    private _destroyed$: Subject<void> = new Subject<void>();
    private initialSeekRequest: boolean | undefined = true;

    constructor(private readonly videoService: HlsService) {
        //solves issue when multiple media cuts are loaded at the same time
        this._seekRequest$.pipe(takeUntil(this._destroyed$)).subscribe(data => {
            if (!data.initial && this.playerState) {
                setTimeout(() => this._player.play(), 800);
            }
        })
    }

    async init(flowPlayerElementSelector: string) {
        const playerOptions = await this.getPlayerOptions();
        const player = (this._player = flowplayer(flowPlayerElementSelector, playerOptions));

        player.on('playing', () => {
            this._playing$.next(true);
        });

        player.on('pause', () => {
            this._playing$.next(false);
        });

        player.on('error', function (e: any) {
            // TODO AK: Improve logging
            console.error(e);
        });

        player.on('ended', () => {
            this._playedToEnd$.next(true);
        });

        this._playerTime$ = interval(50).pipe(
            map(_ => this._player.currentTime),
            distinctUntilChanged(),
            map(pt => {
                // current player time restricted to <= ltcOut in milliseconds
                const ltc = this._manifestInfo.ltcOffset.add(fromSeconds(pt));
                const ltcOut = this._mediaCutInfo?.ltcOut || zeroTime;
                return ltc.asMilliseconds() <= ltcOut.asMilliseconds() ? ltc : ltcOut;
            })
        );

        const time$ = merge(
            this._playerTime$.pipe(map(t => ({ type: TimeTrackingEventType.PLAYER_TIME, time: t }))),
            this._seekRequest$.pipe(map(x => ({ type: TimeTrackingEventType.SEEK_TIME, time: x.time }))),
            this._seekCompleted$.pipe(map(_ => ({ type: TimeTrackingEventType.SEEK_COMPLETED, time: null })))
        ).pipe(
            scan(
                function (acc, e) {
                    switch (e.type) {
                        case TimeTrackingEventType.PLAYER_TIME:
                            return { seeking: acc.seeking, time: acc.seeking ? acc.time : e.time! };
                        case TimeTrackingEventType.SEEK_TIME:
                            return { seeking: true, time: e.time || zeroTime };
                        case TimeTrackingEventType.SEEK_COMPLETED:
                            return { seeking: false, time: acc.time };
                    }
                },
                { seeking: false, time: zeroTime }
            )
        );

        this.currentLinearTime$ = time$.pipe(map(x => x.time));

        this.seeking$ = time$.pipe(
            map(x => x.seeking),
            distinctUntilChanged()
        );

        this.setupLoadManifest();
        this.setupLoadManifestExtension();
    }

    load(media: MediaInformation, autoStart: boolean) {
        if (this._mediaInfo && isSameMediaSelection(this._mediaInfo, media)) {
            return;
        }

        this._mediaInfo = media;
        this._mediaCutInfo = null;
        this._manifestInfo = defaultManifestInfo;

        if (autoStart) {
            this._playAfterSeek = 'yes';
        }
        this._seekRequest$.next({ time: null, seq: this._seekSeq++, initial: true });
    }

    destroy() {
        // destroy see: https://docs.flowplayer.com/player/player-api#methods
        this._player.destroy();

        this._destroyed$.next();
        this._destroyed$.complete();
    }

    isPlaying(): boolean {
        return this._playing$.value;
    }

    isPaused(): boolean {
        return !this._playing$.value;
    }

    play() {
        if (this._player.paused && this.isPaused()) {
            this._player.play();
        }
    }

    pause() {
        if (!this._player.paused && this.isPlaying()) {
            this._player.pause();
        }
    }

    private _dataLoadingPaused = new BehaviorSubject(false);

    get isDataLoadingPaused(): boolean {
        return this._dataLoadingPaused.value;
    }

    pauseDataLoading(s: boolean) {
        if (this._dataLoadingPaused.value !== s) {
            this._dataLoadingPaused.next(s);
        }
    }

    private _playAfterSeek = '';
    private _playbackRate: number = 1;

    /**
     * Sets the current player time
     * @param ltc New player time as linear time code w.r.t. currently loaded media.
     */
    setCurrentLinearTime(ltc: Duration) {
        if (!this._mediaCutInfo) return;

        // fix ltc to 1 ms
        ltc = fromMilliseconds(Math.floor(ltc.asMilliseconds()));

        if (ltc.asMilliseconds() < 0) {
            ltc = fromMilliseconds(0);
        } else if (ltc.asMilliseconds() > this._mediaCutInfo.ltcOut.asMilliseconds()) {
            ltc = this._mediaCutInfo.ltcOut;
        }

        this._playAfterSeek = this._playAfterSeek || (this.isPlaying() ? 'yes' : 'no');
        this._playbackRate = this._player.playbackRate;

        if (!this.initialSeekRequest) {
            this._player.pause();
        }

        this._seekRequest$.next({ time: ltc, seq: this._seekSeq++ });
    }

    toggleMute() {
        this._player.toggleMute();
    }

    get muted(): boolean {
        return this._player.muted;
    }

    set muted(val: boolean) {
        this._player.muted = val;
    }

    set playbackRate(val: number) {
        this._player.playbackRate = val;
    }

    get playbackRate(): number {
        return this._player.playbackRate;
    }

    get volume(): number {
        return this._player.volume;
    }

    set volume(val: number) {
        this._player.volume = val;
    }

    set aspectRatio(val: string | undefined) {
        this._player.setOpts({
            ratio: val,
        });
    }

    get aspectRatio(): string | undefined {
        return this._player.opts.ratio;
    }

    private async getPlayerOptions(): Promise<PlayerOptions> {
        let token = undefined;
        try {
            const token$ = this.videoService.getLicenceToken();
            token = await lastValueFrom(token$);
        } catch (err) {
            console.error('Error fetching flow player token', err);
        }

        return {
            type: 'application/x-mpegurl',
            autoplay: false,
            start_time: 0,
            hls: {
                native: false,
                nudgeMaxRetry: 100,
                // debug: true,
            },
            live: false,
            seekable: false,
            ratio: '16:9',
            token,
        };
    }

    private setupLoadManifest() {
        combineLatest([this._seekRequest$, this._dataLoadingPaused])
            .pipe(
                filter(([_, continuousSeeking]) => !continuousSeeking),
                switchMap(([seekRequest, _]) => {
                    const m = this._manifestInfo;
                    const seekMs = seekRequest.time?.asMilliseconds();
                    if (seekMs && m.ltcStart.asMilliseconds() <= seekMs && seekMs <= m.ltcEnd.asMilliseconds()) {
                        // we use scheduled so that result only appears asynchronously
                        // otherwise seekCompleted event can arrive before the corresponding seekTime event in
                        // the currentLinearTime$ "scan".
                        const mediaCutId = this._mediaInfo!.selectedMediaCutId;
                        return scheduled(
                            [{ manifest: m.manifest, seekRequest, error: undefined, mediaCutId }],
                            asyncScheduler
                        );
                    } else {
                        return this.loadManifest$(seekRequest);
                    }
                }),
                takeUntil(this._destroyed$)
            )
            .subscribe(x => {
                if (x.error) {
                    console.error(x.error);
                    this._seekCompleted$.next(x.seekRequest.seq);
                    return;
                }
                this.initialSeekRequest = x.seekRequest.initial

                const manifest = x.manifest!;

                if (x.seekRequest.initial) {
                    this._mediaCutInfo = parseMediaCutInfo(x.mediaCutId, manifest);
                    this._player.setOpts({
                        // frame-accurate seeking plugin, see https://docs.flowplayer.com/plugins/fas
                        fas: { frame_rate: this._mediaCutInfo!.frameRate },
                    });
                }

                if (manifest !== this._manifestInfo.manifest) {
                    this._manifestInfo = parseManifestInfo(manifest);
                    const url = this.videoService.getManifestUrl(manifest.url);
                    this._player.setSrc(url);
                }

                if (x.seekRequest.time) {
                    const ct = x.seekRequest.time.subtract(this._manifestInfo.ltcOffset);
                    this._player.currentTime = ct.asSeconds();
                } else {
                    this._player.currentTime = 0;
                }
                this._player.playbackRate = this._playbackRate;
                this._seekCompleted$.next(x.seekRequest.seq);
                if (this._playAfterSeek === 'yes') {
                    this._player.play();
                }
                this._playAfterSeek = '';

                if (x.seekRequest.initial) {
                    this._mediaLoaded$.next(this._mediaCutInfo!);
                }
            });
    }

    private setupLoadManifestExtension() {
        const extendManifest$ = this._playerTime$.pipe(
            /* only check once every 500 ms to load manifest extension...*/ throttleTime(500),
            combineLatestWith(this._playing$),
            filter(([_, playing]) => playing && !!this._mediaCutInfo && !!this._manifestInfo.manifest),
            map(([ltc, _]) => {
                const mc = this._mediaCutInfo!;
                const m = this._manifestInfo;

                const canExtendManifest = m.ltcEnd.asMilliseconds() < mc.ltcOut.asMilliseconds();
                const currentLtcInSeconds = ltc.asSeconds();
                const isCloseToEndPosition = currentLtcInSeconds + 10 >= m.ltcEnd.asSeconds();

                return canExtendManifest && isCloseToEndPosition
                    ? {
                          manifestId: this._manifestInfo.manifest!.manifestId,
                          ltcEnd: m.ltcEnd.asMilliseconds(),
                      }
                    : null;
            }),
            filter(x => x !== null),
            distinctUntilChanged((x, y) => x!.ltcEnd === y!.ltcEnd && x!.manifestId === y!.manifestId),
            map(x => x!.manifestId)
        );

        combineLatest([extendManifest$, this.seeking$])
            .pipe(
                switchMap(([manifestId, seeking]) => {
                    if (seeking) {
                        return of({ manifest: null, error: undefined });
                    } else {
                        return this.loadManifestExtension$(manifestId!);
                    }
                })
            )
            .subscribe(x => {
                if (x.error) {
                    console.error(x.error);
                    return;
                }

                if (x.manifest) {
                    if (x.manifest.manifestId === this._manifestInfo.manifest?.manifestId) {
                        this._manifestInfo = parseManifestInfo(x.manifest);
                    }
                    // else case might happen when the user seeks in between extension loading and receiving...
                }
            });
    }

    private loadManifest$(seekRequest: SeekRequestType): Observable<{
        manifest: HlsManifestInfo | null;
        mediaCutId: string;
        seekRequest: SeekRequestType;
        error?: any;
    }> {
        const media = this._mediaInfo!;
        const args: GetHlsManifestArgs = {
            mediaCutId: media.selectedMediaCutId,
            mediaLevel: media.item ? MediaFilter.Item : MediaFilter.Program,
            audioTrack: parseAudioTrack(media.selectedAudioTrackId),
        };

        if (!seekRequest.time) {
            // when time === null it is the initial loading of manifest
            args.itemId = media.item;
        } else {
            // if the manifest was already loaded we give the seekTime as vtcIn
            const mc = this._mediaCutInfo!;
            let vtcInMs = mc.timeCodeMap.ltcToVtc(seekRequest.time).asMilliseconds();

            // make sure vtcIn is <= mc.vtcOut - 25 sec...
            vtcInMs = Math.min(vtcInMs, mc.vtcOut.asMilliseconds() - 25000);

            // make sure vtcIn >= mc.vtcIn
            vtcInMs = Math.max(vtcInMs, mc.vtcIn.asMilliseconds());

            args.vtcIn = formatTimeSpan(fromMilliseconds(vtcInMs));
        }
        return this.videoService.getHlsManifest(args).pipe(
            map(manifest => ({ manifest, seekRequest, mediaCutId: args.mediaCutId })),
            catchError(err => of({ error: err, manifest: null, seekRequest, mediaCutId: args.mediaCutId }))
        );
    }

    private loadManifestExtension$(manifestId: string): Observable<{
        manifest: HlsManifestInfo | null;
        error?: any;
    }> {
        return this.videoService.getExtendHlsManifest(manifestId).pipe(
            map(manifest => ({ manifest })),
            catchError(err => of({ error: err, manifest: null }))
        );
    }

    getStillImage(vtc: Duration): Observable<Blob> {
        if (!this._mediaInfo) return EMPTY;

        const mediaInfo = this._mediaInfo!;
        return this.videoService.getStillImage({
            mediaCutId: mediaInfo.selectedMediaCutId,
            mediaLevel: mediaInfo.item ? MediaFilter.Item : MediaFilter.Program,
            vtcIn: formatTimeSpan(vtc),
        });
    }
}

function parseManifestInfo(manifest: HlsManifestInfo): ManifestInfo {
    const t = manifest.timingInfo;
    const vtcIn = parseTimeSpan(t.vtcIn);
    const vtcStart = parseTimeSpan(t.vtcStart);
    const vtcEnd = parseTimeSpan(t.vtcEnd);
    const map = new MediaTimeMapping(manifest.ltcMap);
    return {
        manifest,
        ltcStart: map.vtcToLtc(vtcStart),
        ltcEnd: map.vtcToLtc(vtcEnd),
        ltcOffset: vtcStart.subtract(vtcIn),
    };
}

const defaultManifestInfo: ManifestInfo = {
    manifest: null,
    ltcStart: fromMilliseconds(0),
    ltcEnd: fromMilliseconds(0),
    ltcOffset: fromMilliseconds(0),
};

interface ManifestInfo {
    manifest: HlsManifestInfo | null;
    ltcStart: Duration;
    ltcEnd: Duration;
    ltcOffset: Duration;
}

function parseMediaCutInfo(mediaCutId: string, manifest: HlsManifestInfo): MediaCutInfo {
    const t = manifest.timingInfo;
    const vtcIn = parseTimeSpan(t.vtcIn);
    const vtcOut = parseTimeSpan(t.vtcOut);
    const map = new MediaTimeMapping(manifest.ltcMap);
    const ltcIn = map.vtcToLtc(vtcIn);
    const ltcOut = map.vtcToLtc(vtcOut);
    const frameRate = manifest.frameRate;
    return {
        mediaCutId,
        vtcIn,
        ltcIn,
        vtcOut,
        ltcOut,
        length: vtcOut.subtract(vtcIn),
        frameRate: frameRate,
        timeCodeMap: map,
        nextCutId: manifest.nextCut,
    };
}

type SeekRequestType = { time: Duration | null; seq: number; initial?: boolean };

enum TimeTrackingEventType {
    PLAYER_TIME,
    SEEK_TIME,
    SEEK_COMPLETED,
}

const zeroTime = fromMilliseconds(0);

function parseAudioTrack(trackNr: string): AudioTrack {
    switch (trackNr) {
        case 'Stereo1And2':
            return AudioTrack.Stereo12;
        case 'Stereo3And4':
            return AudioTrack.Stereo34;
        case 'Stereo5And6':
            return AudioTrack.Stereo56;
        case 'Stereo7And8':
            return AudioTrack.Stereo78;
        default:
            return AudioTrack.Stereo12;
    }
}

function isSameMediaSelection(m1: MediaInformation, m2: MediaInformation) {
    return (
        m1.selectedMediaCutId === m2.selectedMediaCutId &&
        m1.selectedAudioTrackId === m2.selectedAudioTrackId &&
        m1.item === m2.item
    );
}
