import { AudioTrack, AudioTrackDto, MediaCutDto, MediaCutMaterialType } from '@faro/metadata-angular-client';

/** Returns a description of the given media cut */
export function mediaCutDescription(mc: MediaCutDto): string {
    const group = `${mc.group?.total! > 1 ? mc.group?.number + '/' + mc.group?.total : ''}`;
    const material = materialTypeDescription(mc.materialType);
    const aspectRatio = mc.pictureAspectRatio;
    const version = mc.versionDescriptor;
    return [group, material, aspectRatio, version].filter(x => !!x && x.length > 0).join(' ');
}

export function audioTrackDescription(at: AudioTrackDto): string {
    let result = formatAudioTrack(at.id);
    if (at.description && at.description.length > 0) {
        result += ` (${at.description})`;
    }
    return result;
}

export function formatAudioTrack(at: AudioTrack) {
    switch (at) {
        case 'None':
            return 'None';
        case 'Stereo1And2':
            return 'Stereo 1&2';
        case 'Stereo3And4':
            return 'Stereo 3&4';
        case 'Stereo5And6':
            return 'Stereo 5&6';
        case 'Stereo7And8':
            return 'Stereo 7&8';
        default:
            return '?';
    }
}

export function materialTypeDescription(mt?: MediaCutMaterialType): string {
    switch (mt) {
        case MediaCutMaterialType.Unknown:
            return 'Unbekannt';
        case MediaCutMaterialType.Program:
            return 'Programm';
        case MediaCutMaterialType.Cleanfeed:
            return 'Cleanfeed';
        default:
            return '?';
    }
}
