import {
    ItemInfoMetadataDto,
    LighttableKeyFrameDto,
    ProgramInfoMetadataDto,
    SequenceMetadataDto,
} from '@faro/metadata-angular-client';
import { Duration } from '../../shared/duration';
import { parseTimeSpan } from '../../shared/timespan';
import { SubtitleTranscriptMetadataDto } from '@faro/metadata-angular-client/model/subtitleTranscriptMetadataDto';
import { SubtitleTranscriptLineDto } from '@faro/metadata-angular-client/model/subtitleTranscriptLineDto';
import { TransmissionDto } from '@faro/metadata-angular-client/model/transmissionDto';

export interface TypedLightTableKeyFrameDto extends LighttableKeyFrameDto {
    vtcInDuration: Duration;
    vtcOutDuration: Duration;
    id: number;
}

export function toTypedLightTableKeyFrameDto(d: LighttableKeyFrameDto[]) {
    return d.map((v, index) => {
        return {
            ...v,
            id: index,
            vtcInDuration: parseTimeSpan(v.vtcIn),
            vtcOutDuration: parseTimeSpan(v.vtcOut),
        };
    });
}

export interface TypedSequenceMetadataDto extends SequenceMetadataDto {
    vtcInDuration: Duration | null;
    vtcOutDuration: Duration | null;
}

export function toTypedSequenceMetadataDto(d: SequenceMetadataDto[]) {
    return d.map(v => {
        return {
            ...v,
            vtcInDuration: v.vtcIn !== null ? parseTimeSpan(v.vtcIn) : null,
            vtcOutDuration: v.vtcOut !== null ? parseTimeSpan(v.vtcOut) : null,
        };
    });
}

export interface TypedSubtitleTranscriptMetadataDto extends SubtitleTranscriptMetadataDto {
    vtcInDuration: Duration;
    vtcOutDuration: Duration;
    lines: TypedSubtitleTranscriptLineDto[];
}

export function toTypedSubtitleTranscriptMetadataDto(d: SubtitleTranscriptMetadataDto[]) {
    return d.map(v => {
        return {
            ...v,
            vtcInDuration: parseTimeSpan(v.vtcIn),
            vtcOutDuration: parseTimeSpan(v.vtcOut),
            lines: toTypedSubtitleTranscriptLineDto(v.lines),
        };
    });
}

export interface TypedSubtitleTranscriptLineDto extends SubtitleTranscriptLineDto {
    vtcInDuration: Duration;
    vtcOutDuration: Duration;
}

export function toTypedSubtitleTranscriptLineDto(d: SubtitleTranscriptLineDto[]): TypedSubtitleTranscriptLineDto[] {
    return d.map(v => {
        return {
            ...v,
            vtcInDuration: parseTimeSpan(v.vtcIn),
            vtcOutDuration: parseTimeSpan(v.vtcOut),
        };
    });
}

export interface TypedItemInformationModel extends ItemInfoMetadataDto {
    constraintInformation: string;
    productionInformation: string;
}

export function toTypedItemInformationModel(itemInfo: ItemInfoMetadataDto): TypedItemInformationModel {
    return {
        ...itemInfo,
        constraintInformation: [itemInfo.usageConstraints, itemInfo.usageConstraintRemark].join('\r\n'),
        productionInformation: productionInfoDescription(itemInfo),
    };
}

export interface TypedProgramInformationModel extends ProgramInfoMetadataDto {
    dateChannelInformation: string;
}

export function toTypedProgramInformationModel(programInfo: ProgramInfoMetadataDto): TypedProgramInformationModel {
    return {
        ...programInfo,
        dateChannelInformation: `${programInfo.transmissions
            .filter(v => v.dateStart !== null)
            .map((v: TransmissionDto) => `${new Date(v.dateStart).toLocaleDateString('de-CH')} (${v.channelName})`)
            .join('\r\n')}`,
    };
}

function productionInfoDescription(itemInfo: ItemInfoMetadataDto): string {
    const origin = itemInfo.origin;
    const producer = itemInfo.producer;
    if (origin && producer) {
        return [origin, producer].join(' / ');
    } else if (origin) {
        return origin;
    }
    return producer;
}
