import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { RouteHistoryService } from '../../../shared/route-history/route-history.service';
import { selectUserEmail } from '../../../state/user.selectors';
import { Store } from '@ngrx/store';
import { selectActiveShoppingCart } from '../../../order/shopping-cart-state/shopping-cart.selectors';
import { ShoppingCartDto } from '@faro/order-angular-client/model/shoppingCartDto';
import { Menu } from 'primeng/menu';

@Component({
    selector: 'app-header-actions',
    templateUrl: './header-actions.component.html',
    styleUrls: ['./header-actions.component.scss'],
})
export class HeaderActionsComponent implements OnInit, OnDestroy {
    readonly profileMenuItems: MenuItem[] = [
        {
            label: 'Passwort ändern',
            icon: 'pi pi-key',
            command: () => changePassword(),
        },
        {
            label: 'Abmelden',
            icon: 'pi pi-sign-out',
            command: () => this.logout(),
        },
    ];

    username$: Observable<string | undefined>;
    private _destroyed$ = new Subject<void>();
    isInShoppingCart: boolean = false;

    nrOfShoppingCartItems$: Observable<string | undefined> = of(undefined);

    constructor(
        private readonly authService: OidcSecurityService,
        private readonly router: Router,
        private readonly routeHistory: RouteHistoryService,
        private readonly store: Store
    ) {
        this.username$ = this.store.select(selectUserEmail);
    }

    ngOnInit() {
        this.isInShoppingCart = this.router.url.includes('shopping-cart');
        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                map(e => <NavigationEnd>e)
            )
            .pipe(takeUntil(this._destroyed$))
            .subscribe(e => {
                this.isInShoppingCart = e.url.includes('shopping-cart');
            });

        this.nrOfShoppingCartItems$ = this.store.select(selectActiveShoppingCart).pipe(
            filter(v => !!v),
            map(v => v as ShoppingCartDto),
            map(v => {
                if (v.entries?.length) {
                    if (v.entries.length > 99) return ':)';
                    if (v.entries.length <= 0) return undefined;
                    return v.entries.length.toString();
                }
                return undefined;
            })
        );
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    openInfo(): void {
        window.open('../../../../assets/documents/info.pdf');
    }

    openMenuAndSetFocus(event: Event, menu: Menu) {
        menu.toggle(event);
        if (menu.visible) {
            setTimeout(() => {
                if (menu) {
                    const activeListElement = menu.el.nativeElement.getElementsByClassName('p-menuitem-link')[0];
                    if (activeListElement) {
                        activeListElement.focus();
                    }
                }
            }, 100);
        }
    }

    async onShoppingCartClick(isInShoppingCart: boolean) {
        if (!isInShoppingCart) {
            return await this.router.navigate(['/shopping-cart/active']);
        } else {
            const navigated = await this.routeHistory.navigateToLatestMatchingUrl((url: string) => {
                return !url.startsWith('/shopping-cart');
            });
            return navigated || (await this.router.navigate(['search']));
        }
    }

    private logout() {
        this.authService
            .logoff()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                console.log('logoff');
            });
    }
}

function changePassword() {
    window.location.href = `${environment.oidcAuthority}/change-password?ReturnUrl=${window.location.origin}`;
}
