import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { Duration } from '../../../shared/duration';
import { TimeProvider } from '../../../player/time-provider.model';

@Injectable({
    providedIn: 'root',
})
export class TimeService implements TimeProvider {
    private _provider$ = new BehaviorSubject<TimeProvider>(emptyTimeProvider);

    currentVtc$: Observable<Duration | null> = this._provider$.pipe(switchMap(p => p.currentVtc$));

    getCurrentVtc(): Duration | null {
        return this._provider.getCurrentVtc();
    }

    private get _provider(): TimeProvider {
        return this._provider$.value;
    }

    /**
     * Clears the current time provider.
     * Resets it to an "empty" time provider.*/
    clearProvider() {
        this.setProvider(emptyTimeProvider);
    }

    /** Sets the current time provider instance */
    setProvider(p: TimeProvider) {
        if (this._provider$.value !== p) {
            this._provider$.next(p);
        }
    }

    jumpToVtc(vtc: Duration) {
        this._provider.jumpToVtc(vtc);
    }

    pauseDataLoading(value: boolean) {
        this._provider.pauseDataLoading(value);
    }

    isDataLoadingPaused(): boolean {
        return this._provider.isDataLoadingPaused();
    }
}

const emptyTimeProvider: TimeProvider = {
    currentVtc$: of(null),

    getCurrentVtc: function () {
        return null;
    },

    jumpToVtc(_: Duration) {
        // do nothing
    },

    pauseDataLoading(_: boolean) {
        // do nothing
    },

    isDataLoadingPaused(): boolean {
        return false;
    },
};


