import { createReducer, on } from '@ngrx/store';
import {
    ShoppingCartHitlistSortingAttribute,
    ShoppingCartHitlistSortingOrder,
    ShoppingCartState,
} from './shopping-cart.state';
import {
    doNotShowNewCartDialog,
    getActiveShoppingCart,
    getAvailableMaterial,
    getSelectedShoppingCart,
    renameActiveShoppingCart,
    resetAllShoppingCartSearchOptions,
    searchShoppingCarts,
    setActiveShoppingCart,
    setAdmiraDevices,
    setAdmiraFolders,
    setAvailableMaterial,
    setEntryErrorMessages,
    setSelectedAdmiraDevice,
    setSelectedShoppingCart,
    updateDateRange,
    updateSelectedOrderStatus,
    updateShoppingCartExportDate,
    updateShoppingCartSearchOptionsInputFields,
    updateShoppingCartSearchOrderBy,
    updateShoppingCartSearchOrderDirection,
    setShoppingCartSearchResponse,
    setActiveUserSearchOptions,
    initialSearchShoppingCarts,
    updateShoppingCartExportEmail,
    orderActiveShoppingCart,
    setNewActiveShoppingCart,
    getNewActiveShoppingCart,
    setScrollTopPosition,
    notifyOfShoppingCartOrderError,
} from './shopping-cart.actions';

export const initialState: ShoppingCartState = {
    activeShoppingCart: undefined,
    selectedShoppingCart: undefined,
    shoppingCartSearchResult: [],
    searchOptions: {
        description: '',
        number: '',
        pid: '',
        firstName: '',
        lastName: '',
        orderStatus: null,
        queryDate: {
            from: null,
            to: null,
        },
        sorting: {
            sortBy: ShoppingCartHitlistSortingAttribute.NUMBER,
            sortDirection: ShoppingCartHitlistSortingOrder.DESCENDING,
        },
        searchFieldsHaveBeenReset: false,
        nameFieldsHaveBeenUpdated: false,
    },
    searchRequested: false,
    initialSearchRequested: false,
    displayActivateNewCartDialog: !localStorage.getItem('noShoppingCartDialog'),
    loading: {
        shoppingCart: false,
        entryMaterial: false,
        shoppingCartSearch: false,
    },
    orderingShoppingCart: false,
    dialogAvailableMaterial: [],
    activeCartEntryErrors: [],
    scrollTopPosition: 0,
    admira: {
        admiraDevices: [],
        admiraFolders: {
            deviceId: undefined,
            folders: undefined,
        },
        selectedAdmiraDevice: undefined,
        deviceRootPath: '',
        deviceRootName: '',
    },
    exportSettings: {
        eMail: '',
        exportDate: new Date(),
    },
};

export const shoppingCartReducer = createReducer(
    initialState,
    on(setActiveUserSearchOptions, (state: ShoppingCartState, { user }): ShoppingCartState => {
        const fieldsHaveBeenReset = state.searchOptions.searchFieldsHaveBeenReset;
        const namesHaveBeenUpdated = state.searchOptions.nameFieldsHaveBeenUpdated;
        return {
            ...state,
            searchOptions: {
                ...state.searchOptions,
                firstName: fieldsHaveBeenReset || namesHaveBeenUpdated ? state.searchOptions.firstName : user.firstName,
                lastName: fieldsHaveBeenReset || namesHaveBeenUpdated ? state.searchOptions.lastName : user.lastName,
            },
        };
    }),
    on(getActiveShoppingCart, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            loading: {
                ...state.loading,
                shoppingCart: true,
            },
            orderingShoppingCart: false,
        };
    }),
    on(getNewActiveShoppingCart, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            loading: {
                ...state.loading,
                shoppingCart: true,
            },
        };
    }),
    on(getSelectedShoppingCart, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            loading: {
                ...state.loading,
                shoppingCart: true,
            },
        };
    }),
    on(setActiveShoppingCart, (state: ShoppingCartState, { activeShoppingCart }): ShoppingCartState => {
        return {
            ...state,
            activeShoppingCart: activeShoppingCart,
            loading: {
                ...state.loading,
                shoppingCart: false,
            },
            exportSettings: {
                ...state.exportSettings,
                exportDate:
                    state.activeShoppingCart?.id === activeShoppingCart.id
                        ? state.exportSettings.exportDate
                        : new Date(),
                eMail: state.exportSettings.eMail ? state.exportSettings.eMail : activeShoppingCart.eMail,
            },
        };
    }),
    on(setNewActiveShoppingCart, (state: ShoppingCartState, { activeShoppingCart }): ShoppingCartState => {
        return {
            ...state,
            activeShoppingCart: activeShoppingCart,
            loading: {
                ...state.loading,
                shoppingCart: false,
            },
            exportSettings: {
                ...state.exportSettings,
                exportDate: new Date(),
                eMail: activeShoppingCart.eMail,
            },
        };
    }),
    on(setSelectedShoppingCart, (state: ShoppingCartState, { selectedShoppingCart }): ShoppingCartState => {
        return {
            ...state,
            selectedShoppingCart: selectedShoppingCart,
            loading: {
                ...state.loading,
                shoppingCart: false,
            },
        };
    }),
    on(setScrollTopPosition, (state: ShoppingCartState, { scrollTopPosition }): ShoppingCartState => {
        return {
            ...state,
            scrollTopPosition: scrollTopPosition,
        };
    }),
    on(renameActiveShoppingCart, (state: ShoppingCartState, { name }): ShoppingCartState => {
        return {
            ...state,
            activeShoppingCart: {
                ...state.activeShoppingCart!,
                name: name,
            },
        };
    }),
    on(resetAllShoppingCartSearchOptions, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            searchOptions: {
                ...state.searchOptions,
                description: '',
                number: '',
                pid: '',
                firstName: '',
                lastName: '',
                orderStatus: null,
                queryDate: {
                    from: null,
                    to: null,
                },
                searchFieldsHaveBeenReset: true,
            },
        };
    }),
    on(
        updateShoppingCartSearchOptionsInputFields,
        (state: ShoppingCartState, { shoppingCartSearchOptions }): ShoppingCartState => {
            return {
                ...state,
                searchOptions: {
                    ...state.searchOptions,
                    description: shoppingCartSearchOptions.description,
                    number: shoppingCartSearchOptions.number,
                    pid: shoppingCartSearchOptions.pid,
                    firstName: shoppingCartSearchOptions.firstName,
                    lastName: shoppingCartSearchOptions.lastName,
                    nameFieldsHaveBeenUpdated:
                        shoppingCartSearchOptions.firstName === state.searchOptions.firstName ||
                        shoppingCartSearchOptions.lastName === state.searchOptions.lastName,
                },
            };
        }
    ),
    on(updateDateRange, (state: ShoppingCartState, { from, to }): ShoppingCartState => {
        return {
            ...state,
            searchOptions: {
                ...state.searchOptions,
                queryDate: {
                    from: from,
                    to: to,
                },
            },
        };
    }),
    on(updateSelectedOrderStatus, (state: ShoppingCartState, { orderStatus }): ShoppingCartState => {
        return {
            ...state,
            searchOptions: {
                ...state.searchOptions,
                orderStatus: orderStatus,
            },
        };
    }),
    on(updateShoppingCartSearchOrderBy, (state: ShoppingCartState, { orderBy }): ShoppingCartState => {
        return {
            ...state,
            searchOptions: {
                ...state.searchOptions,
                sorting: {
                    ...state.searchOptions.sorting,
                    sortBy: orderBy,
                },
            },
        };
    }),
    on(updateShoppingCartSearchOrderDirection, (state: ShoppingCartState, { orderDirection }): ShoppingCartState => {
        return {
            ...state,
            searchOptions: {
                ...state.searchOptions,
                sorting: {
                    ...state.searchOptions.sorting,
                    sortDirection: orderDirection,
                },
            },
        };
    }),
    on(updateShoppingCartExportDate, (state: ShoppingCartState, { exportDate }): ShoppingCartState => {
        return {
            ...state,
            exportSettings: {
                ...state.exportSettings,
                exportDate: new Date(exportDate),
            },
        };
    }),
    on(updateShoppingCartExportEmail, (state: ShoppingCartState, { eMail }): ShoppingCartState => {
        return {
            ...state,
            exportSettings: {
                ...state.exportSettings,
                eMail: eMail,
            },
        };
    }),
    on(searchShoppingCarts, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            searchRequested: true,
            loading: {
                ...state.loading,
                shoppingCartSearch: true,
            },
        };
    }),
    on(initialSearchShoppingCarts, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            initialSearchRequested: true,
        };
    }),
    on(setShoppingCartSearchResponse, (state: ShoppingCartState, { searchResult }): ShoppingCartState => {
        return {
            ...state,
            shoppingCartSearchResult: searchResult,
            loading: {
                ...state.loading,
                shoppingCartSearch: false,
            },
        };
    }),
    on(doNotShowNewCartDialog, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            displayActivateNewCartDialog: false,
        };
    }),
    on(getAvailableMaterial, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            loading: {
                ...state.loading,
                entryMaterial: true,
            },
        };
    }),
    on(setAvailableMaterial, (state: ShoppingCartState, { material }): ShoppingCartState => {
        return {
            ...state,
            dialogAvailableMaterial: material,
            loading: {
                ...state.loading,
                entryMaterial: false,
            },
        };
    }),
    on(setEntryErrorMessages, (state: ShoppingCartState, { cartEntryErrors }): ShoppingCartState => {
        return {
            ...state,
            activeCartEntryErrors: cartEntryErrors,
            orderingShoppingCart: false,
        };
    }),
    on(setAdmiraDevices, (state: ShoppingCartState, { admiraDevices }): ShoppingCartState => {
        return {
            ...state,
            admira: {
                ...state.admira,
                admiraDevices: admiraDevices,
            },
        };
    }),
    on(setAdmiraFolders, (state: ShoppingCartState, { admiraFolders }): ShoppingCartState => {
        return {
            ...state,
            admira: {
                ...state.admira,
                admiraFolders: {
                    ...state.admira.admiraFolders,
                    deviceId: admiraFolders.deviceId,
                    folders: admiraFolders.folders,
                },
            },
        };
    }),
    on(
        setSelectedAdmiraDevice,
        (state: ShoppingCartState, { selectedAdmiraDevice, rootPath, rootName }): ShoppingCartState => {
            return {
                ...state,
                admira: {
                    ...state.admira,
                    selectedAdmiraDevice: selectedAdmiraDevice,
                    deviceRootPath: rootPath,
                    deviceRootName: rootName,
                },
            };
        }
    ),
    on(orderActiveShoppingCart, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            loading: {
                ...state.loading,
                entryMaterial: true,
            },
            orderingShoppingCart: true,
        };
    }),
    on(notifyOfShoppingCartOrderError, (state: ShoppingCartState): ShoppingCartState => {
        return {
            ...state,
            orderingShoppingCart: false,
        };
    })
);
