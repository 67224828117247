import { AdmiraDeviceDto, AdmiraFolder } from '@faro/order-angular-client';
import { TreeNode } from 'primeng/api';

export function createDummyTreeNode(): TreeNode {
    return {
        label: 'Dummy',
        data: {
            deviceId: 'Dummy',
            path: 'Dummy',
        },
        children: [],
    };
}

export function mapDeviceToTreeNode(device: AdmiraDeviceDto | undefined): TreeNode {
    return {
        key: device?.devicePath,
        label: device?.deviceName,
        data: {
            deviceId: device?.id,
            path: device?.devicePath,
        },
        parent: undefined,
        children: [],
        leaf: false,
    };
}

export function mapTreeNodeToDevice(node: TreeNode | undefined): AdmiraDeviceDto {
    return {
        id: node?.data.deviceId,
        devicePath: node?.data.path,
        deviceName: node?.label ?? '',
        ftpAccount: '',
    };
}

export function addFoldersToNode(folders: AdmiraFolder[], device: TreeNode, deviceId: string) {
    device.children = folders.map((folder: AdmiraFolder) => {
        const node: TreeNode = {
            key: folder.path,
            label: folder.name,
            data: {
                deviceId: deviceId,
                path: folder.path,
            },
            children: [],
            parent: device,
            leaf: folder.children ? folder.children.length === 0 : true,
        };
        if (folder.children) {
            addFoldersToNode(folder.children, node, deviceId);
        }
        return node;
    });
}

export function flattenTree(node: TreeNode): TreeNode[] {
    let flattedNodes: TreeNode[] = [node];
    if (node.children) {
        for (let child of node.children) {
            flattedNodes?.push(...flattenTree(child));
        }
    }
    return flattedNodes;
}

export function expandUntilTop(node: TreeNode | undefined) {
    if (node) {
        node.expanded = true;
    }
    if (node && node.parent) {
        expandUntilTop(node.parent);
    }
}
