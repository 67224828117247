import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';
import { updateSearchInput } from '../search-state/search-options.actions';
import { selectQuery } from '../search-state/search-options.selectors';
import { dispatchSearch } from '../search-state/search-result.actions';
import { ProfileFiltersComponent } from './profile-filters/profile-filters.component';
import { DialogService } from 'primeng/dynamicdialog';
import { selectSearchProfileDefault } from '../search-state/search-profile.selectors';
import { SearchInfoDialogComponent } from './info-dialog/search-info-dialog.component';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit, AfterViewInit {
    @ViewChild('inputField')
    mainInputField: ElementRef | undefined;

    query$: Observable<string>;

    searchTerm: string = '';
    areProfileSettingsDefault$: Observable<boolean | undefined>;

    constructor(public store: Store, private primeNGConfig: PrimeNGConfig, private dialogService: DialogService) {
        this.query$ = this.store.select(selectQuery);
        this.areProfileSettingsDefault$ = store.select(selectSearchProfileDefault);
    }

    ngOnInit(): void {
        this.primeNGConfig.ripple = true;
    }

    ngAfterViewInit() {
        if (this.mainInputField) {
            this.mainInputField.nativeElement.focus();
        }
    }

    stringNullOrEmpty(s: string | null): boolean {
        return s === '' || s === undefined || s === null;
    }

    clearSearchInput(): void {
        this.store.dispatch(updateSearchInput({ query: '', triggerSearch: false }));
        if (this.mainInputField) {
            this.mainInputField.nativeElement.focus();
        }
    }

    queryInputChanged(event: Event): void {
        const query = (event.target as HTMLInputElement).value;
        this.store.dispatch(updateSearchInput({ query, triggerSearch: false }));
    }

    search(event: Event): void {
        if ((event as KeyboardEvent).key === 'Enter') {
            this.store.dispatch(dispatchSearch());
        }
    }

    clearSearch() {
        this.searchTerm = '';
    }

    openProfileFiltersDialog(event: Event): void {
        (event.target as HTMLElement).blur();
        this.dialogService.open(ProfileFiltersComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'profile-dialog',
            data: { title: 'Sucheinstellungen' },
        });
    }

    openInfoDialog(event: Event): void {
        (event.target as HTMLElement).blur();
        this.dialogService.open(SearchInfoDialogComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'info-dialog',
            data: { title: 'Suchinformationen', deactivatable: false },
        });
    }
}
