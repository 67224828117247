import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { getLightTable } from '../details-state/details.actions';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
    selectDetailOrderVtcIn,
    selectDetailOrderVtcOut,
    selectHoveredTimeFrame,
    selectLightTableData,
    selectLoadingLightTable,
} from '../details-state/details.selectors';
import { TimeService } from '../shared/services/time.service';
import { Duration } from '../../shared/duration';
import { TypedLightTableKeyFrameDto } from '../shared/typed_metadata.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaCutInfo } from '../../player/shared/media-cut-info';

@Component({
    selector: 'app-light-table-view',
    templateUrl: './light-table-view.component.html',
    styleUrls: ['./light-table-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightTableViewComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    itemId: string | undefined;

    @Input()
    programId: string = '';

    @Input()
    contentType: string = '';

    @Input()
    mediaCut: MediaCutInfo | null = null;

    @Input()
    set aspectRatio(ratio: string | null | undefined) {
        this.mediaAspectRatio = ratio === '4:3 Vollbild' || ratio === '4:3 Letterbox' ? '4:3' : '16:9';
    }

    lightTableData: LightTableModel[] = [];
    loadingLightTable$: Observable<boolean>;
    activeKeyframeId: number = -1;
    autoScrolling: boolean = false;
    hoveredKeyframes: number[] = [];
    selectionKeyframes: number[] = [];
    markVtcIn: Duration | undefined;
    markVtcOut: Duration | undefined;
    mediaAspectRatio = '16:9';

    private keyframeHeight: number = 0;
    private _destroyed$ = new Subject<void>();
    private $scrollLightTable: any;

    @ViewChild('lightTable')
    set lightTable(content: ElementRef) {
        if (content) {
            this.$scrollLightTable = content.nativeElement;
        }
    }

    @HostListener('wheel')
    handleUserScroll() {
        this.autoScrolling = false;
    }

    @HostListener('touchmove')
    handleTouchMove() {
        this.autoScrolling = false;
    }

    @HostListener('mousedown', ['$event'])
    handleScrollbarClick(event: any) {
        if (event.target.localName !== 'img') {
            this.autoScrolling = false;
        }
    }

    constructor(
        private store: Store,
        private readonly timeService: TimeService,
        private zone: NgZone,
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private el: ElementRef
    ) {
        this.loadingLightTable$ = this.store.select(selectLoadingLightTable);
    }

    ngOnInit() {
        this.lightTableData = [];
        this.keyframeHeight =
            parseFloat(getComputedStyle(this.el.nativeElement).getPropertyValue('--keyframe-height')) || 0;
        this.store
            .select(selectLightTableData)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: any[]) => {
                if (data && data.length > 0) {
                    this.lightTableData = data.map(v => {
                        return {
                            ...v,
                            keyFrame: this.sanitizer.bypassSecurityTrustResourceUrl(
                                'data:image/jpg;base64,' + v.keyFrame
                            ),
                            visible: v.rights === 'Visible',
                        };
                    });
                    this.resetSelectionKeyframes();
                    this.cdr.detectChanges();
                }
            });

        this.zone.runOutsideAngular(() => {
            this.timeService.currentVtc$.pipe(takeUntil(this._destroyed$)).subscribe(currentVtc => {
                const activeKeyframe = this.getActiveKeyframe(currentVtc);
                const temp = this.activeKeyframeId;
                if (this.activeKeyframeId !== activeKeyframe) {
                    this.activeKeyframeId = activeKeyframe;
                    this.cdr.detectChanges();
                }
                if (this.$scrollLightTable) {
                    const activeKeyframeElement =
                        this.$scrollLightTable.getElementsByClassName('active-keyframe-scroll');
                    if (temp !== activeKeyframe && this.autoScrolling) {
                        this.scrollToActiveKeyFramePositions(activeKeyframeElement);
                    }
                }
            });

            this.store.select(selectHoveredTimeFrame).subscribe(data => {
                this.hoveredKeyframes = this.resetHoveredKeyframes(data.vtcIn, data.vtcOut);
                this.cdr.detectChanges();
            });

            this.store
                .select(selectDetailOrderVtcIn)
                .pipe(takeUntil(this._destroyed$))
                .subscribe(data => {
                    this.markVtcIn = data;
                    this.resetSelectionKeyframes();
                    this.cdr.detectChanges();
                });

            this.store
                .select(selectDetailOrderVtcOut)
                .pipe(takeUntil(this._destroyed$))
                .subscribe(data => {
                    this.markVtcOut = data;
                    this.resetSelectionKeyframes();
                    this.cdr.detectChanges();
                });
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    ngOnChanges(_: SimpleChanges) {
        this.lightTableData = [];

        if (!this.mediaCut) return;

        this.store.dispatch(
            getLightTable({
                mediaCutId: this.mediaCut.mediaCutId,
                programId: this.programId,
                itemId: this.contentType === 'item' ? this.itemId : undefined,
            })
        );
    }

    getActiveKeyframe(currentVtc: Duration | null): number {
        if (!currentVtc) return -1;

        const currentVtcMs = currentVtc.asMilliseconds();

        return this.lightTableData.findIndex((keyframe: TypedLightTableKeyFrameDto) => {
            return (
                currentVtcMs >= keyframe.vtcInDuration.asMilliseconds() &&
                currentVtcMs <= keyframe.vtcOutDuration.asMilliseconds()
            );
        });
    }

    resetHoveredKeyframes(vtcIn: Duration | undefined, vtcOut: Duration | undefined): number[] {
        return this.lightTableData
            .map((keyframe: TypedLightTableKeyFrameDto, index: number) =>
                vtcIn?.asMilliseconds()! <= keyframe.vtcInDuration.asMilliseconds() &&
                vtcOut?.asMilliseconds()! >= keyframe.vtcInDuration.asMilliseconds()
                    ? index
                    : -1
            )
            .filter(index => index !== -1);
    }

    resetSelectionKeyframes() {
        this.selectionKeyframes = [];
        this.lightTableData.map((keyframe: TypedLightTableKeyFrameDto, index: number) => {
            if (this.markVtcIn && !this.markVtcOut) {
                if (isVtcInKeyframe(this.markVtcIn, keyframe)) {
                    this.selectionKeyframes.push(index);
                }
            } else if (!this.markVtcIn && this.markVtcOut) {
                if (isVtcInKeyframe(this.markVtcOut, keyframe)) {
                    this.selectionKeyframes.push(index);
                }
            } else if (this.markVtcIn && this.markVtcOut) {
                if (
                    this.markVtcIn.asMilliseconds() <= keyframe.vtcOutDuration.asMilliseconds() &&
                    this.markVtcOut.asMilliseconds() >= keyframe.vtcInDuration.asMilliseconds()
                ) {
                    this.selectionKeyframes.push(index);
                }
            }
        });
        this.cdr.detectChanges();
    }

    navigateTo(vtcIn: Duration): void {
        this.timeService.jumpToVtc(vtcIn);
    }

    onPlayingStarted() {
        this.autoScrolling = true;
        if (this.$scrollLightTable) {
            const activeKeyframeElement = this.$scrollLightTable.getElementsByClassName('active-keyframe-scroll');
            if (this.autoScrolling) {
                this.scrollToActiveKeyFramePositions(activeKeyframeElement);
            }
        }
    }

    scrollToActiveKeyFramePositions(activeKeyframeElement: any) {
        if (activeKeyframeElement.length > 0) {
            const keyFrameElement = activeKeyframeElement[0];
            const keyFrameYAxis = Math.floor(keyFrameElement.offsetTop);
            const scrollYPosition = keyFrameYAxis - this.keyframeHeight;
            this.$scrollLightTable.scroll({
                top: scrollYPosition,
                left: 0,
                behavior: 'smooth',
            });
        }
    }
}

interface LightTableModel extends TypedLightTableKeyFrameDto {
    visible: boolean;
    keyFrame: string;
}

function isVtcInKeyframe(vtc: Duration, keyframe: TypedLightTableKeyFrameDto): boolean {
    return (
        vtc.asMilliseconds() <= keyframe.vtcOutDuration.asMilliseconds() &&
        vtc.asMilliseconds() >= keyframe.vtcInDuration.asMilliseconds()
    );
}
