import { NotificationService } from '../shared/services/notification.service';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';

export function registerAuthNotifications(eventsService: PublicEventsService, notificationService: NotificationService) {
    return () => {
        eventsService
            .registerForEvents()
            .pipe(filter(event => event.type === EventTypes.ConfigLoadingFailed))
            .subscribe(_ => {
                notificationService.notifyOfAuthError();
            });
    };
}
