import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { searchOptionsReducer } from '../search/search-state/search-options.reducer';
import { environment } from '../../environments/environment';
import { AppState } from './app.state';
import { searchResultReducer } from '../search/search-state/search-result.reducer';
import { searchProfileReducer } from '../search/search-state/search-profile.reducer';
import { detailsReducer } from '../details/details-state/details.reducer';
import { routeHistoryReducer } from '../shared/route-history/route-history.reducer';
import { shoppingCartReducer } from '../order/shopping-cart-state/shopping-cart.reducer';
import { userReducer } from './user.reducer';
import { sharedReducer } from './shared.reducer';

export const reducers: ActionReducerMap<AppState> = {
    searchOptions: searchOptionsReducer,
    searchResult: searchResultReducer,
    searchProfile: searchProfileReducer,
    details: detailsReducer,
    routeHistory: routeHistoryReducer,
    shoppingCart: shoppingCartReducer,
    user: userReducer,
    shared: sharedReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
