import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '../../shared/services/notification.service';
import { tap } from 'rxjs';
import {
    notifyOfAdmiraDeviceError,
    notifyOfGetActiveShoppingCartError,
    notifyOfGetSelectedShoppingCartError,
    notifyOfShoppingCartOrderError,
} from './shopping-cart.actions';

@Injectable()
export class ErrorEffects {
    constructor(private readonly actions$: Actions, private readonly notification: NotificationService) {}

    showActiveShoppingCartGetInformationErrorNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfGetActiveShoppingCartError),
                tap(() => {
                    this.notification.notifyOfActiveShoppingCartGetInformationError();
                })
            ),
        { dispatch: false }
    );

    showAdmiraDeviceError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfAdmiraDeviceError),
                tap(() => {
                    this.notification.notifyOfAdmiraDeviceError();
                })
            ),
        { dispatch: false }
    );

    showSelectedShoppingCartGetInformationErrorNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfGetSelectedShoppingCartError),
                tap(() => {
                    this.notification.notifyOfSelectedShoppingCartGetInformationError();
                })
            ),
        { dispatch: false }
    );

    showShoppingCartOrderErrorNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notifyOfShoppingCartOrderError),
                tap(action => {
                    this.notification.notifyOfOrderShoppingCartError(action.errorResponse);
                })
            ),
        { dispatch: false }
    );
}
