import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-header-banner',
    templateUrl: './header-banner.component.html',
    styleUrls: ['./header-banner.component.scss'],
})
export class HeaderBannerComponent {
    @Input() warningText: string | undefined;

    constructor() {}
}
