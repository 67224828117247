<ng-container *ngrxLet="loadingInformationTab$; let loading">
    <ng-container *ngIf="detailsContent && !loading">
        <div class="information-container">
            <div class="list">
                <table class="information-table">
                    <ng-container *ngFor="let entry of detailInformationEntries">
                        <tr *ngIf="!hideRow(entry) && (entry.key !== informationKeys.SPORT || entry.content)">
                            <td class="label">{{ entry.label }}<span class="h-offscreen">:</span></td>
                            <td
                                [ngClass]="{ 'with-restrictions': entry.type === 'constraints' }"
                                *ngIf="entry.type === 'standard' || entry.type === 'constraints'"
                            >
                                <app-highlight [text]='entry.content' [keywords]='keywords'></app-highlight>
                            </td>
                            <td *ngIf="entry.key === informationKeys.DURATION">
                                <div class="h-offscreen">
                                    {{ getAccessibilityDurationString(entry.content) }}
                                </div>
                                <div aria-hidden="true">
                                    <app-highlight  *ngIf="entry.content" [text]='(entry.content | durationFormatPipe)' [keywords]='keywords'></app-highlight>
                                </div>
                            </td>
                            <td *ngIf="entry.key === informationKeys.SPORT">
                                <table class="sport-information-table">
                                    <ng-container *ngFor="let entry of entry.content; let i = index">
                                        <tr>
                                            <td>
                                                <app-highlight [text]='entry.rank' [keywords]='keywords'></app-highlight>
                                            </td>
                                            <td>
                                                <app-highlight [text]='entry.person' [keywords]='keywords'></app-highlight>
                                            </td>
                                            <td>
                                                <app-highlight [text]='entry.team' [keywords]='keywords'></app-highlight>
                                            </td>
                                            <td>
                                                <app-highlight [text]='entry.rankingRemark' [keywords]='keywords'></app-highlight>
                                            </td>
                                            <td>
                                                <app-highlight [text]='entry.remark' [keywords]='keywords'></app-highlight>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </table>
                            </td>
                            <td *ngIf="entry.key === informationKeys.PEOPLE">
                                <table class="people-information-table">
                                    <ng-container *ngFor="let entry of entry.content; let i = index">
                                        <tr>
                                            <td>
                                                <app-highlight [text]='entry.name' [keywords]='keywords'></app-highlight>
                                            </td>
                                            <td>
                                                <app-highlight [text]='entry.role' [keywords]='keywords'></app-highlight>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </table>
                            </td>
                            <td *ngIf="entry.key === informationKeys.DESCRIPTORS">
                                <ng-container *ngIf="entry.content.length > 0">
                                    <ng-container *ngFor="let content of entry.content; let i = index">
                                        <app-highlight [text]='content' [keywords]='keywords'></app-highlight>
                                        <span *ngIf='i < entry.content.length - 1'>; </span>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td *ngIf="entry.key === informationKeys.FORM">
                                <ng-container *ngIf="entry.content.length > 0">
                                    <ng-container *ngFor="let content of entry.content; let i = index">
                                        <app-highlight [text]='content' [keywords]='keywords'></app-highlight>
                                        <span *ngIf='i < entry.content.length - 1'>; </span>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td *ngIf="entry.key === informationKeys.GEODESCRIPTORS">
                                <ng-container *ngIf="entry.content.length > 0">
                                    <ng-container *ngFor="let content of entry.content; let i = index">
                                        <app-highlight [text]='content' [keywords]='keywords'></app-highlight>
                                        <span *ngIf='i < entry.content.length - 1'>; </span>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td *ngIf="entry.key === informationKeys.DOCUMENTS">
                                <ng-container *ngIf="entry.content.length > 0">
                                    <ng-container *ngFor="let entry of entry.content; let i = index">
                                        <br *ngIf="i > 0" /><button
                                            class="info-icon-button"
                                            (click)="downloadAttachment(entry.id, entry.filename)"
                                        >
                                            <img src="assets/icons/arrow-down-circle.svg" alt="Datei Herunterladen" />
                                        <app-highlight [text]='entry.name' [keywords]='keywords'></app-highlight>
                                        </button>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td *ngIf="entry.key === informationKeys.USED_MUSIC" (click)="openMusicDialog()">
                                <button
                                    class="info-icon-button"
                                    *ngIf="detailsContent.itemInfo && detailsContent.itemInfo.hasMusic"
                                >
                                    <img src="assets/icons/arrow-top-right.svg" alt="Musikliste anzeigen" />Liste
                                    anzeigen
                                </button>
                            </td>
                            <td *ngIf="entry.key === informationKeys.LINKED_ITEMS">
                                <ng-container *ngIf="entry.content.length > 0">
                                    <ng-container *ngFor="let entry of entry.content; let i = index">
                                        <br *ngIf="i > 0" />
                                        {{ entry.role }}
                                        <a
                                            class="icon-link"
                                            href="details/{{ entry.programId }}/{{ entry.itemId }}"
                                            (click)="goToLinkedItem(entry.programId, entry.itemId, $event)"
                                            >
                                            <app-highlight [text]='entry.name || "Beitrag"' [keywords]='keywords'></app-highlight>
                                        </a>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="loading-container">
            <p-progressSpinner strokeWidth="2" animationDuration="5s" styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </ng-container>
</ng-container>
