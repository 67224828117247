import { SharedState } from './shared.state';
import { createReducer, on } from '@ngrx/store';
import { setCurrentHitId } from './shared.actions';

export const initialState: SharedState = {
    currentHitId: undefined,
};

export const sharedReducer = createReducer(
    initialState,
    on(setCurrentHitId, (state: SharedState, { hitId }): SharedState => {
        return {
            ...state,
            currentHitId: hitId,
        };
    })
);
