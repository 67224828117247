<div class="dialog-content" role="dialog" aria-labelledby="dialogTitle" aria-modal="true">
    <div class="dialog-header">
        <h2 class="title" id="dialogTitle">{{ config.data.title }}</h2>
        <div>
            <button pRipple class="close-icon" autofocus aria-label="Dialog schliessen" (click)="closeDialog()">
                <img src="assets/icons/close-icon.svg" alt="Dialog schliessen" />
            </button>
        </div>
    </div>
    <div>
        <table>
            <tr>
                <td class="rights-title">Rechte</td>
                <td>{{ config.data.rights }}</td>
            </tr>
            <tr>
                <td class="rights-title">Rechteinhaber</td>
                <td>{{ config.data.licenseHolder }}</td>
            </tr>
            <tr>
                <td class="rights-title">Verwendung</td>
                <td>{{ config.data.usageConstraints }}</td>
            </tr>
            <tr>
                <td class="rights-title">Verwendungshinweis</td>
                <td>{{ config.data.usageLimitation }}</td>
            </tr>
        </table>
    </div>
</div>
